import { continueRender, delayRender, Sequence } from 'remotion';
import { useEffect, useState } from 'react';
import { loadFont } from '@remotion/fonts';
import { TextElement } from '@/App/remotion/MainPlayer/types/Element/TextElement';

const TextElementComponent: React.FC<{
  element: TextElement;
}> = ({ element }) => {
  const { fromFrame, toFrame, details, style } = element;
  const { font } = details;
  const [handle] = useState(() => delayRender());

  useEffect(() => {
    if (font?.location && font.name) {
      loadFont({
        family: font.name,
        url: font.location
      }).then(() => {
        // eslint-disable-next-line no-console
        console.log('Font loaded - ', font.name);
        continueRender(handle);
      });
    } else {
      continueRender(handle);
    }
  }, [font?.location, font?.name]);

  const durationInFrames = Math.round(toFrame - fromFrame);

  const transformedElementStyle = {
    ...style,
    boxShadow: (style.boxShadow || [])
      .map(shadow => `${shadow.x}px ${shadow.y}px ${shadow.blur}px ${shadow.spread}px ${shadow.color}`)
      .join(',')
  };

  return (
    <Sequence from={fromFrame} durationInFrames={durationInFrames} layout="none">
      <div style={transformedElementStyle}>{details.text}</div>
    </Sequence>
  );
};

export default TextElementComponent;
