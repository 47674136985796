import { memo, useEffect, useState, useSyncExternalStore } from 'react';
import { stageTimeSpansStore } from '../stores/stageTimeSpansStore';
import { speakerOverlapsStore } from '../stores/speakerOverlaps';
import Toggle from '@/components/atoms/Toggle';

function SpeakersInTimeRange({ clipStart, clipEnd }: { clipStart: number; clipEnd: number }) {
  const timeSpans = useSyncExternalStore(stageTimeSpansStore.subscribe, stageTimeSpansStore.getSnapshot);
  const speakerOverlaps = useSyncExternalStore(speakerOverlapsStore.subscribe, speakerOverlapsStore.getSnapshot);
  const [speakersInTimeRange, setSpeakersInTimeRange] = useState<any>([]);
  const [showCurrentInputValues, setShowCurrentInputValues] = useState(false);
  const [showSpeakerOverlaps, setShowSpeakerOverlaps] = useState(false);

  useEffect(() => {
    const rangesForEachSpeaker = Object.entries(timeSpans).map(([speakerId, ranges]) => {
      if (!ranges?.length) return null;

      return {
        speakerId,
        ranges: ranges
          .trim()
          .split(',')
          .map(s => s.trim().split('-'))
      };
    });

    setSpeakersInTimeRange(rangesForEachSpeaker);
  }, [clipStart, clipEnd, timeSpans]);

  useEffect(() => {
    // get an array of objects with each object containing fromFrame and toFrame, along with the number of speakers in that section
    const _speakerOverlaps: { fromFrame: number; toFrame: number; speakerIds: number }[] = [];

    // Get all unique frame numbers where speakers start or end
    const allFramePoints = new Set<number>();
    speakersInTimeRange.forEach((speaker: any) => {
      if (!speaker) return;
      speaker.ranges.forEach((range: string[]) => {
        allFramePoints.add(Number(range[0]));
        allFramePoints.add(Number(range[1]));
      });
    });

    // Convert to sorted array
    const sortedFramePoints = Array.from(allFramePoints).sort((a, b) => a - b);

    // For each pair of consecutive frame points, count how many speakers are active
    for (let i = 0; i < sortedFramePoints.length - 1; i++) {
      const fromFrame = sortedFramePoints[i];
      const toFrame = sortedFramePoints[i + 1];

      // Count speakers active in this range
      const activeSpeakers = speakersInTimeRange.filter((speaker: any) => {
        if (!speaker) return false;
        return speaker.ranges.some((range: string[]) => {
          const start = Number(range[0]);
          const end = Number(range[1]);
          return start <= fromFrame && end >= toFrame;
        });
      });

      _speakerOverlaps.push({
        fromFrame,
        toFrame,
        speakerIds: activeSpeakers.map(s => s.speakerId)
      });
    }

    speakerOverlapsStore.set(() => _speakerOverlaps);
  }, [speakersInTimeRange]);

  return (
    <div>
      <div className="mt-6 rounded bg-gray-100 p-4">
        <div className="flex items-center gap-3 text-sm font-medium">
          <div>Current Values:</div>{' '}
          <Toggle on={showCurrentInputValues} onToggle={() => setShowCurrentInputValues(s => !s)} />
        </div>
        {showCurrentInputValues && <pre className="mt-2 text-xs">{JSON.stringify(speakersInTimeRange, null, 2)}</pre>}
      </div>
      <div className="mt-6 rounded bg-gray-100 p-4">
        <div className=" flex items-center gap-3 text-sm font-medium">
          <div>Actual Speaker Info Values:</div>
          <Toggle on={showSpeakerOverlaps} onToggle={() => setShowSpeakerOverlaps(s => !s)} />
        </div>
        {showSpeakerOverlaps && <pre className="mt-2 text-xs">{JSON.stringify(speakerOverlaps, null, 2)}</pre>}
      </div>
    </div>
  );
}

export default memo(SpeakersInTimeRange);
