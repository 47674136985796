import { useSyncExternalStore } from 'react';
import { stageTimeSpansStore } from '../stores/stageTimeSpansStore';
import { speakers } from '../temp/StageTimeSpans';
import { durationInFrames } from '../ClipPlayerV2';

const Timeline = () => {
  const timeSpans = useSyncExternalStore(stageTimeSpansStore.subscribe, stageTimeSpansStore.getSnapshot);

  const parseTimeRanges = (rangeStr: string) => {
    if (!rangeStr) return [];
    return rangeStr.split(',').map(range => {
      const [start, end] = range.split('-').map(Number);
      return { start, end };
    });
  };

  return (
    <div className="m-auto w-full grow overflow-x-scroll rounded-lg bg-gray-50 p-2">
      <div className="w-full space-y-1">
        {speakers.map(speaker => (
          <div key={speaker.id} className="flex items-center gap-4">
            <div className="flex items-center gap-2 font-medium">{speaker.id}</div>
            <div
              className="relative flex h-4 grow items-center rounded"
              style={{
                backgroundColor: `${speaker.color}20`
              }}
            >
              {parseTimeRanges(timeSpans[speaker.id]).map((range, index) => (
                <div
                  key={index}
                  className="absolute h-[6px] rounded"
                  style={{
                    backgroundColor: speaker.color,
                    left: `${(range.start * 100) / durationInFrames}%`,
                    width: `${((range.end - range.start) * 100) / durationInFrames}%`
                  }}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
