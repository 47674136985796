import React, { useRef, useEffect, useState, memo } from 'react';
import { classnames } from '@/libs/utils';

const ChapterListItem = ({ chapter, selectedChapter, setSelectedChapter, handleRenameSubmit, isDragging = false }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  useEffect(() => {
    const observer = new ResizeObserver(() => {
      if (textRef.current) {
        const element = textRef.current;
        setIsTruncated(element.scrollWidth > element.clientWidth);
      }
    });

    if (textRef.current) {
      observer.observe(textRef.current);
    }

    return () => observer.disconnect();
  }, []);

  return (
    <div className="group/chapter flex flex-[2] truncate">
      <div
        className={classnames(
          'h-full grow items-center truncate pl-1 text-2xs',
          !isDragging ? 'cursor-pointer' : 'cursor-ew-resize'
        )}
      >
        {selectedChapter?.id === chapter.id ? (
          <input
            className="w-full min-w-0 select-text truncate whitespace-nowrap rounded text-left text-2xs focus:bg-white focus:px-1 focus:outline-none focus:ring-0"
            value={selectedChapter?.title}
            onChange={e => setSelectedChapter({ ...selectedChapter, title: e.target.value })}
            onBlur={handleRenameSubmit}
            data-testid="timeline-chapter-rename-input"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                handleRenameSubmit();
              }
            }}
            autoFocus
            onFocus={e => e.target.select()}
          />
        ) : (
          <div ref={textRef} className="w-full min-w-0 select-none truncate whitespace-nowrap text-left">
            {chapter?.title}
          </div>
        )}
      </div>

      {isTruncated && !isDragging && (
        <div
          className="pointer-events-none fixed z-[60] flex w-max max-w-52 items-center justify-center rounded bg-slate-900 p-1 px-2 text-2xs text-white opacity-0  group-hover/chapter:opacity-100"
          style={{
            transform: 'translate(-40%, -120%)'
          }}
        >
          <div className="absolute -bottom-1 h-2 w-2 rotate-45 bg-slate-900"></div>
          <span className="whitespace-normal break-words">{chapter.title}</span>
        </div>
      )}
    </div>
  );
};

export default memo(ChapterListItem);
