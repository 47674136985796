import {
  FaceRecognitionStatusEnum,
  BroadcastAsset,
  MediaContentResponse,
  AssetRating,
  SavedSearches
} from '@goldcast/api/content';
import { Word } from './transcript';
import { DeletedRange, EditedWord } from '@/stores/clip';
import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';
import { RemotionConfig } from '@/App/remotion/types';

// TODO: Temporary solution till we find a way to export it from BE
export type SizeType = 'LANDSCAPE' | 'PORTRAIT' | 'SQUARE';
export type LayoutType = 'AUDIOGRAM' | 'DEFAULT' | 'GRID' | 'SPEAKER';
export type SourceType = 'AI' | 'USER';
export type TextType = 'BLOG' | 'CUSTOM' | 'EMAIL' | 'LINKEDIN' | 'TAKEAWAYS';
export type HighlightType = 'none' | 'text' | 'box';

export interface LayoutStatus {
  status: FaceRecognitionStatusEnum;
  reason: string;
  accurate: boolean;
}

export interface CaptionsPosition {
  x: number;
  y: number;
  width: number;
  height: number;
  textX?: number;
  textY?: number;
}

export enum CaptionsPlacement {
  Top = 'Top',
  Middle = 'Middle',
  Bottom = 'Bottom'
}

export interface CaptionStyles {
  scale: number;
  animation: CaptionStyle;
  caps: boolean;
  placement?: CaptionsPlacement;
  background_style_color?: string;
  font_size: number;
}

export enum CaptionStyle {
  Basic = 'Basic',
  Outline = 'Outline',
  Background = 'Background'
}

export interface AssetConfig {
  canvas_width: number;
  canvas_height: number;
  duration: number;
  background_color: string;
  background_image?: string;
  filename: string;
  videos: AssetConfigVideo[];
  captions: AssetConfigCaption[];
  isAnalysisAccurate?: boolean;
  remotion_captions?: boolean;
}

export interface AssetConfigVideo {
  start: number;
  end: number;
  x: number;
  y: number;
  z: number;
  bounding_width: number;
  bounding_height: number;
  radius?: number;
  meta: {
    source?: string;
    in_time: number;
    out_time?: number;
    excludes?: any[];
    crop_x: number;
    crop_y: number;
    crop_width: number;
    crop_height: number;
  };
}

interface AssetConfigCaption {
  style?: AssetConfigCaptionStyle;
  words?: AssetConfigCaptionWord[] | Word[];
  left_margin?: number;
  right_margin?: number;
  bottom_margin?: number;
  rotation?: number;
  z?: number;
  drift?: number;
}

export interface AssetConfigCaptionStyle {
  highlight_style?: AssetConfigCaptionStyleItem;
  regular_style?: AssetConfigCaptionStyleItem;
}

export interface AssetConfigCaptionStyleItem {
  background_color?: string;
  font?: string;
  font_color?: string;
  font_size?: number;
  font_location?: string;
  font_opacity?: string;
  scale_y?: number;
  scale_x?: number;
  border_style?: number;
  outline?: number;
  shadow?: number;
  bold?: number;
  alignment?: number;
}

interface AssetConfigCaptionWord {
  content: string;
  processed_start_time: number;
  processed_end_time: number;
  srt_index: number;
  hidden: true;
  speaker_label: string;
}

export enum ClipPublishedStatus {
  PUBLISHED = 'PUBLISHED',
  DRAFT = 'DRAFT',
  PROCESSING = 'PROCESSING',
  FAILED = 'FAILED'
}

export type ClipDeletes = Record<string, DeletedRange>;
export type ClipEdits = Record<string, EditedWord>;

export interface ClipMetadata {
  id: string;
  title: string;
  description: string;
  start: number;
  subtitle?: boolean;
  hide_borders?: boolean;
  captions: Array<Word>;
  config: AssetConfig | null;
  end: number;
  deletes: ClipDeletes;
  excludes: Array<[number, number]>;
  edits: ClipEdits;
  merges: EditedWord[];
  layout: LayoutType;
  size: SizeType;
  // intro and outro to clip
  intro?: string;
  outro?: string;
  // magic layout applied to clip
  sourceId?: number;
  duration?: number;
  source?: SourceType;
  font_location?: string;
  caption_positions?: CaptionsPosition;
  caption_styles?: CaptionStyles;
  word_highlight_color?: string;
  highlight_type?: HighlightType;
  text_highlight_color?: string;
  is_full_length: boolean;
  publish_status?: ClipPublishedStatus | null;
  published_at?: string;
  magicLayout?: {
    textColor: string;
    backgroundColor: string;
    showSpeakerLabels: boolean;
    backgroundImage?: string;
  };
  visible_speakers?: SpeakerWithDetails[];
  visible_face_ids?: number[];
  template_id?: string;
  is_edited: boolean;
  updated_at?: string;
  created_at?: string;
  isLayoutProcessing?: boolean;
  isAnalysisAccurate?: boolean;
  isAutoActiveMappingOverridden?: boolean;
  isAutoActiveSpeakerIdentified?: boolean;
  remotionConfig: RemotionConfig | null;
  video_library_id: string;
}

export type Clip = {
  id: string;
  event: string;
  broadcast: string;
  organization: string;
  title: string;
  content: MediaContentResponse;
  broadcast_data: BroadcastAsset;
  asset_metadata: ClipMetadata;
  isRemoved: boolean; // property for transition
  layout_status: {
    GRID: LayoutStatus;
    SPEAKER: LayoutStatus;
  };
  rating: AssetRating;
  updated_at: string;
  shared: boolean;
  locked?: boolean;
  first_frame_analysis: { [key: string]: any };
  saved_search: SavedSearches | null;
};
