import { PlayerRef, Player as RemotionPlayer, RenderPoster } from '@remotion/player';
import { preloadVideo } from '@remotion/preload';
import { useCallback, useEffect, useMemo, useRef, useSyncExternalStore } from 'react';
import { AbsoluteFill } from 'remotion';
import MainPlayer from './MainPlayer';
import { ResponsiveContainer } from '../../../components/atoms/ResponsiveContainer';
import { getClipPlayerElementId } from '../../../Pages/Clip/ClipPlayer/ClipPlayerUtils';
import { timelineLoader, timeline } from './timeline';
import Timeline from './Timeline/Timeline';
import Playground from './temp/Playground';
import ValuesChangeInScene from './temp/ValuesChangeInScene';
import { COMPOSITION_DIMENSIONS, FPS_24, VIDEO_QUALITY } from '@/App/remotion/constants';
import Loader from '@/components/atoms/Loader';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { classnames } from '@/libs/utils';
import ClipHeader from '@/components/molecules/ClipHeader';
import ClipCustomizerMenu from '@/Pages/Clip/ClipPlayer/ClipCustomizer/ClipCustomizerMenu';

export const durationInFrames = 1000 * FPS_24;

export default function ClipPlayerV2({ playerClasses = '' }: { playerClasses?: string }) {
  const remotionPlayerRef = useRef<PlayerRef>(null);
  const timelineStore = useSyncExternalStore(timeline.subscribe, timeline.getSnapshot);
  const loading = useSyncExternalStore(timelineLoader.subscribe, timelineLoader.getSnapshot);
  const { clipId, clipData } = useClipsContext();

  const { compositionHeight, compositionWidth } = useMemo(() => {
    const videoQuality = VIDEO_QUALITY.FULL_HD;
    const compositionHeight = COMPOSITION_DIMENSIONS[clipData.asset_metadata.size][videoQuality].HEIGHT;
    const compositionWidth = COMPOSITION_DIMENSIONS[clipData.asset_metadata.size][videoQuality].WIDTH;

    return {
      compositionHeight,
      compositionWidth
    };
  }, [clipData.asset_metadata.size]);

  useEffect(() => {
    const unpreload = preloadVideo(
      'https://staticassets.goldcast.io/content-lab/filestack/custom_assets/1efd4d08-f80b-6e40-a6fa-e5dcbdde4d14/1efd4d08-f80b-6e40-a43a-111714ad2889.mp4'
    );

    const unpreload1 = preloadVideo(
      'https://staticassets.goldcast.io/content-lab/filestack/custom_assets/1efcbe7a-61ba-6c10-bbb4-3f88bb86be99/1efcbe7a-61ba-6c10-9443-13348a45d90b.mp4'
    );

    return () => {
      unpreload();
      unpreload1();
    };
  }, []);

  const renderPoster: RenderPoster = useCallback(({ height, width, isBuffering }) => {
    if (isBuffering) {
      return (
        <AbsoluteFill style={{ justifyContent: 'center', alignItems: 'center', backgroundColor: '#ffcb85' }}>
          <Loader />
          <div style={{ fontSize: 72 }}>Buffering....</div>
        </AbsoluteFill>
      );
    }

    return (
      <AbsoluteFill style={{ backgroundColor: 'gray' }}>
        Click to play! ({height}x{width})
      </AbsoluteFill>
    );
  }, []);

  return (
    <div
      id={getClipPlayerElementId(clipId)}
      className={'flex h-full w-full grow flex-col items-center justify-center overflow-hidden px-3'}
    >
      <div className="flex h-full w-full flex-row items-center justify-between">
        <div className="relative h-full w-[calc(34rem-10px)] py-4">
          <div className="mb-2 h-full w-full flex-col items-center justify-start rounded-lg bg-red-400">
            <div className="shrink">
              Old Transcript to be added here
              <br />
              Sync between player time and captions to be handled <br />
              Also to account for frame changes when it comes to handling intro outro cases <br />
              Changes to be done in week of 17th Feb for this. All Transcript & Timeline Actions <br />
            </div>

            <div className="m-2 mt-4 bg-[#FFCB05] text-2xl font-bold">Till then focus is on player actions</div>
            <div className="m-2 mt-4 h-[60vh] overflow-y-scroll bg-white">
              <div className="text-lg font-bold">Bootstrap</div>
              <Playground clipStart={clipData.asset_metadata.start} clipEnd={clipData.asset_metadata.end} />
              <div className="text-base font-bold">Transcript - None for Week of 3rd</div>
              <div className="text-base font-bold">Header - None for Week of 3rd</div>
              <div className="text-base font-bold">Settings - None for Week of 3rd </div>
              <div className="text-lg font-bold">Player</div>
              <div>1. Interprets config for</div>
              <div> - Rendering Segments, elements inside Segments, Captions, Speaker Labels</div>
              <div>2. Rendering Videos in segments for intro/outro</div>
              <div>3. Moveable/Editable elements</div>
              <div className="text-lg font-bold">Controls</div>
              <div>1. Change modes</div>
              <div>2. Toggle borders</div>
              <div>3. Toggle captions</div>
              <div>4. Customise background</div>
              <div className="text-lg font-bold">Timeline</div>
              <ValuesChangeInScene clipStart={clipData.asset_metadata.start} clipEnd={clipData.asset_metadata.end} />
            </div>
          </div>
          {/* <FullTranscript
            playerStore={playerStore}
            videoAssetId={clipId}
            title="Recording"
            onCreateClip={onCreateClip}
            onTimeUpdate={playMainSectionFromTime}
          /> */}
        </div>
        <div className={'flex h-full w-[calc(100%-34rem)] flex-col'}>
          <ClipHeader />
          <div
            className={classnames(
              'flex h-full w-full items-center justify-between overflow-hidden rounded-md',
              playerClasses
            )}
          >
            <div />
            <ResponsiveContainer
              compositionHeight={compositionHeight}
              compositionWidth={compositionWidth}
              parentHeight={'100%'}
              parentWidth={'100%'}
              parentClassnames="m-4"
            >
              {/* ! TODO: @AshwinBhatkal to handle the state updates better */}
              {!loading && (
                <RemotionPlayer
                  component={MainPlayer}
                  durationInFrames={durationInFrames}
                  compositionWidth={compositionWidth}
                  compositionHeight={compositionHeight}
                  fps={FPS_24}
                  ref={remotionPlayerRef}
                  clickToPlay={false}
                  spaceKeyToPlayOrPause={false}
                  controls
                  inputProps={{ playerConfig: { tracks: timelineStore.tracks } } as any}
                  style={{
                    width: '100%'
                  }}
                  showVolumeControls
                  renderPoster={renderPoster}
                  showPosterWhenBuffering
                />
              )}
            </ResponsiveContainer>
            <ClipCustomizerMenu isFullRecordingEdit={true} onTimeUpdate={() => null} useNewRecordingsPlayer />
          </div>
        </div>
      </div>
      <Timeline />
    </div>
  );
}
