import React, { Fragment, memo, useEffect, useRef, useState } from 'react';
import { IconSearch } from '@tabler/icons-react';
import {
  HighlightedSearchParagraph,
  HighlightedSearchWord,
  SearchParagraph,
  SearchParagraphWord,
  SearchResult,
  TranscriptParagraph
} from './types';
import { TRANSCRIPT_SEARCH_DEBOUNCE } from './constants';
import { convertParagraphToSearchParagraph, getParagraphSearchResults } from './utils';
import { classnames, debounce } from '@/libs/utils';
import IconButton from '@/components/atoms/Button/IconButton';

function TranscriptSearch({
  paragraphs,
  onOccurrencesChange,
  setHighlightedParagraph,
  size = 'base'
}: {
  paragraphs: TranscriptParagraph[];
  onOccurrencesChange: (args: { index: number; words: SearchParagraphWord[] }) => void;
  setHighlightedParagraph: (_match: HighlightedSearchParagraph | null) => void;
  size?: 'small' | 'base';
}) {
  const [isOpen, setIsOpen] = useState(false);
  const [currentOccurrence, setCurrentOccurrence] = useState(-1);
  const [searchTerm, setSearchTerm] = useState('');
  const [occurrences, setOccurrences] = useState<SearchResult[]>([]);
  const [paragraphsContent, setParagraphsContent] = useState<SearchParagraph[]>([]);
  const searchInput = useRef<HTMLInputElement>(null);

  useEffect(() => {
    // Override native ctlr + F behavior
    window.addEventListener('keydown', handleBrowserSearch);
    return () => {
      window.removeEventListener('keydown', handleBrowserSearch);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const isTranscriptLoaded = paragraphs?.length > 0;
  useEffect(() => {
    if (isTranscriptLoaded && isOpen && !!searchTerm) {
      search(searchTerm);
    }
  }, [isTranscriptLoaded, isOpen]);

  useEffect(() => {
    if (isOpen && !!paragraphs.length) {
      setParagraphsContent(
        paragraphs.map((paragraph, index) => convertParagraphToSearchParagraph(paragraph.words, index))
      );
    }
    if (!isOpen) {
      setHighlightedParagraph(null);
      setCurrentOccurrence(-1);
      setOccurrences([]);
      setSearchTerm('');
    } else {
      focusSearchInput();
    }
  }, [isOpen, paragraphs]);

  /**
   * Check if CMD + F is clicked and overrides default behavior
   */
  function handleBrowserSearch(e: KeyboardEvent) {
    if (
      e.key === 'f3' ||
      (e.ctrlKey && e.key === 'f') ||
      e.key === 'f3' ||
      (e.ctrlKey && e.key === 'f') ||
      (e.metaKey && e.key === 'f')
    ) {
      setIsOpen(true);
      e.preventDefault();
      focusSearchInput();
    }
  }

  function focusSearchInput() {
    setTimeout(() => {
      searchInput.current?.focus();
    });
  }

  function toggleInput() {
    setIsOpen(current => !current);
  }

  const search = debounce((term: string) => {
    const results: SearchResult[] = term ? getSearchResults(term.trim()) : [];
    setSearchTerm(term.trim());
    setOccurrences(results);
    if (!results.length) {
      setCurrentOccurrence(-1);
      setHighlightedParagraph(null);
    } else {
      setCurrentOccurrence(0);
      triggerOccurrenceChange(results[0]);
    }
  }, TRANSCRIPT_SEARCH_DEBOUNCE);

  function getSearchResults(query: string): SearchResult[] {
    return paragraphsContent.reduce((acc: SearchResult[], el: SearchParagraph) => {
      return [...acc, ...getParagraphSearchResults(el, query)];
    }, []);
  }

  /**
   *
   * @param words all words in paragraph
   * @param resultStart start index of highlight in paragraph
   * @param resultEnd end index of highlight in paragraph
   * @returns map wordStartTime => wordData that is passed to TranscriptWord late
   */
  function getHighlightWords(
    words: SearchParagraphWord[],
    resultStart: number,
    resultEnd: number
  ): { [key: number]: HighlightedSearchWord } {
    const highlightWords = words.filter(w => {
      return w.endIndex >= resultStart && w.startIndex <= resultEnd;
    });
    return highlightWords.reduce((acc, el, index) => {
      acc[el.start_time] = {
        ...el,
        highlightStart: el.startIndex >= resultStart ? 0 : resultStart - el.startIndex,
        highlightEnd: el.endIndex <= resultEnd ? el.content.length : resultEnd - el.startIndex,
        highlightSpace: index < highlightWords.length - 1
      };
      return acc;
    }, {});
  }

  function goToNext() {
    if (!occurrences.length) return;
    const nextOccurence = (currentOccurrence + 1) % occurrences.length;
    setCurrentOccurrence(nextOccurence);
    triggerOccurrenceChange(occurrences[nextOccurence]);
  }

  function goToPrevious() {
    if (!occurrences.length) return;

    const nextOccurence =
      (currentOccurrence === 0 ? occurrences.length - 1 : currentOccurrence - 1) % occurrences.length;
    setCurrentOccurrence(nextOccurence);
    triggerOccurrenceChange(occurrences[nextOccurence]);
  }

  function triggerOccurrenceChange(searchResult: SearchResult) {
    const highlightedWords = getHighlightWords(searchResult.words, searchResult.start, searchResult.end);
    onOccurrencesChange({ index: searchResult.index, words: Object.values(highlightedWords) });
    setHighlightedParagraph({
      index: searchResult.index,
      words: highlightedWords
    });
  }

  function handleInputKeyDown(event: React.KeyboardEvent) {
    if (event.key === 'Enter') goToNext();
    else if (event.key === 'Escape') toggleInput();
  }
  return (
    <div>
      <div
        className={classnames(
          'flex h-8 flex-none rounded-lg transition-all duration-500',
          isOpen
            ? `overflow-hidden border bg-white focus-within:border-deep-orange-600 ${
                size === 'small' ? 'w-[200px]' : 'w-[250px]'
              }`
            : 'w-8 items-center justify-center border hover:border-deep-orange-50 hover:bg-deep-orange-50 hover:text-deep-orange-600'
        )}
      >
        <button
          className={`inline-flex h-8 w-8 flex-none items-center justify-center rounded-lg ${
            isOpen
              ? 'hover:text-deep-orange-600'
              : 'hover:border-deep-orange-50 hover:bg-deep-orange-50 hover:text-deep-orange-600'
          }`}
          onClick={toggleInput}
          data-testid="search-button"
        >
          <IconSearch className="mt-0 h-5 w-5" />
        </button>
        {isOpen && (
          <Fragment>
            <input
              type="text"
              ref={searchInput}
              onChange={e => search(e.target.value)}
              onKeyDown={handleInputKeyDown}
              className="h-8 w-full self-stretch overflow-visible rounded-full border-none bg-transparent px-1 pr-6 text-sm leading-4 focus:border-none focus:outline-none focus:ring-0"
              placeholder="Search..."
            />
            <span className="my-auto ml-[-20px] select-none text-xs">
              {currentOccurrence + 1}/{occurrences.length}
            </span>
            <IconButton
              icon="IconChevronUp"
              iconClassName={`mt-auto mb-auto p-1 cursor-pointer ${
                !occurrences.length ? 'text-slate-400 cursor-not-allowed' : ''
              }`}
              buttonClassName="self-center"
              size="base"
              variation="text"
              title="Next"
              disabled={!occurrences.length}
              trackingId="transcript-search-next-button"
              onClick={goToPrevious}
            ></IconButton>
            <IconButton
              icon="IconChevronDown"
              iconClassName={`mt-auto mb-auto p-1 cursor-pointer ${
                !occurrences.length ? 'text-slate-400 cursor-not-allowed' : ''
              }`}
              buttonClassName="self-center ml-[-8px]"
              size="base"
              variation="text"
              title="Previous"
              disabled={!occurrences.length}
              trackingId="transcript-search-previous-button"
              onClick={goToNext}
            ></IconButton>
            <IconButton
              icon="IconX"
              iconClassName="mt-auto mb-auto p-1 cursor-pointer"
              buttonClassName="self-center ml-[-8px]"
              size="base"
              title="Close"
              variation="text"
              trackingId="transcript-search-close-button"
              onClick={toggleInput}
            ></IconButton>
          </Fragment>
        )}
      </div>
    </div>
  );
}

export default memo(TranscriptSearch);
