import { AbsoluteFill } from 'remotion';
import ElementsTrackComponent from './ElementsTrackComponent';
import SegmentsTrackComponent from './SegmentsTrackComponent';
import CaptionsPages from '@/App/remotion/components/Captions/CaptionsPages';
import { Track } from '@/App/remotion/MainPlayer/types/Track/Track';
import { TrackType } from '@/App/remotion/MainPlayer/types/Track/Type';

const TrackComponent: React.FC<{
  track: Track;
}> = ({ track }) => {
  const { type } = track;

  if (type === TrackType.ELEMENTS) {
    return <ElementsTrackComponent elements={track.elements} source="TRACK" />;
  }

  if (type === TrackType.SEGMENTS) {
    return <SegmentsTrackComponent segments={track.segments} />;
  }

  if (type === TrackType.CAPTIONS) {
    return (
      <CaptionsPages
        pages={track.pages}
        onOutlineRelease={() => {}}
        canResizeCaptions={false}
        canEditCaptions={true}
        CaptionsMenu={<div>Hola</div>}
        drift={0}
        font={track.font}
      />
    );
  }

  return <AbsoluteFill>WhaatWhattttt</AbsoluteFill>;
};

export default TrackComponent;
