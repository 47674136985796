import { PlayerConfig } from './types/PlayerConfig';
import captionPages from './data/captionPages';
import { FPS_24 } from '../constants';
import { SegmentType } from './types/Segment/Type';
import { ElementType } from './types/Element/Type';
import { TrackType } from '@/App/remotion/MainPlayer/types/Track/Type';
import createStore from '@/stores/store';

const segmentId = 'e0006ca5-d03d-4db5-844c-821ed2b18cba';
const segmentTrackId = '611d029c-b9ab-437d-bd5a-53d1d19a8f25';
const captionsTrackId = '759f1d1f-e01c-4671-97c8-6148ec905c3e';
const sceneId = '15d7532c-f86d-4101-bc6f-5d776d298beb';

const video1Id = 'b205b72a-9080-4026-b4cf-126164797c89';
const video2Id = '5455766b-b933-4513-95c0-0797a14b4c22';
const video3Id = '55257783-9037-4773-8295-654459165452';

export const timelineLoader = createStore(false);

export const timeline = createStore<PlayerConfig>({
  tracks: [
    {
      id: segmentTrackId,
      name: 'Main Track',
      type: TrackType.SEGMENTS,
      hideTrack: {
        inTimeline: false,
        inPlayer: false
      },
      segments: [
        {
          id: segmentId,
          name: 'Main Segment',
          type: SegmentType.COMPOSITE,
          fromFrame: 0,
          toFrame: 3000 * FPS_24,
          scenes: [
            {
              id: sceneId,
              fromFrame: 0,
              toFrame: 3000 * FPS_24,
              name: 'Scene 1',
              details: {
                layout: 'GRID',
                visible_video_ids: ['video1', 'video2']
              }
            }
          ],
          elements: [
            {
              id: video1Id,
              name: 'Video 1',
              type: ElementType.VIDEO,
              fromFrame: 0,
              toFrame: 1500 * FPS_24,
              details: {
                src: 'https://d6d4ismr40iw.cloudfront.net/videos/individual_recording/a4e61a6e-51fb-4557-80ff-cf9a6b8c8c1f/8875c437-d989-437f-97df-3b43d9164978/1003297268_0_merge_av/stream.m3u8',
                altText: 'Speaker Video',
                volume: [
                  {
                    fromFrame: 0,
                    toFrame: 1500 * FPS_24,
                    value: 100
                  }
                ]
              },
              trim: [],
              style: {},
              containerStyle: {
                position: 'absolute',
                top: 0,
                left: 0,
                width: '50%',
                height: '50%'
              }
            },
            {
              id: video2Id,
              name: 'Video 2',
              type: ElementType.VIDEO,
              fromFrame: 0,
              toFrame: 1500 * FPS_24,
              details: {
                src: 'https://d6d4ismr40iw.cloudfront.net/videos/individual_recording/a4e61a6e-51fb-4557-80ff-cf9a6b8c8c1f/8875c437-d989-437f-97df-3b43d9164978/3307513_0_merge_av/stream.m3u8',
                altText: 'Speaker Video 2',
                volume: [
                  {
                    fromFrame: 0,
                    toFrame: 1500 * FPS_24,
                    value: 100
                  }
                ]
              },
              trim: [],
              style: {},
              containerStyle: {
                position: 'absolute',
                top: 0,
                left: '50%',
                width: '50%',
                height: '50%'
              }
            },
            {
              id: video3Id,
              name: 'Video 3',
              type: ElementType.VIDEO,
              fromFrame: 0,
              toFrame: 1500 * FPS_24,
              details: {
                src: 'https://d6d4ismr40iw.cloudfront.net/videos/individual_recording/a4e61a6e-51fb-4557-80ff-cf9a6b8c8c1f/8875c437-d989-437f-97df-3b43d9164978/3307506_0_merge_av/stream.m3u8',
                altText: 'Speaker Video 3',
                volume: [
                  {
                    fromFrame: 0,
                    toFrame: 3000 * FPS_24,
                    value: 100
                  }
                ]
              },
              trim: [],
              style: {},
              containerStyle: {
                position: 'absolute',
                top: '50%',
                left: '25%',
                width: '50%',
                height: '50%'
              }
            },
            {
              id: video2Id + '1',
              name: 'Video 2',
              type: ElementType.VIDEO,
              fromFrame: 1500 * FPS_24,
              toFrame: 3000 * FPS_24,
              details: {
                src: 'https://d6d4ismr40iw.cloudfront.net/videos/individual_recording/a4e61a6e-51fb-4557-80ff-cf9a6b8c8c1f/8875c437-d989-437f-97df-3b43d9164978/3307513_0_merge_av/stream.m3u8',
                altText: 'Speaker Video 2',
                volume: [
                  {
                    fromFrame: 0,
                    toFrame: 1500 * FPS_24,
                    value: 100
                  }
                ]
              },
              trim: [],
              style: {},
              containerStyle: {
                position: 'absolute',
                top: '25%',
                left: 0,
                width: '50%',
                height: '50%'
              }
            },
            {
              id: video3Id + '2',
              name: 'Video 3',
              type: ElementType.VIDEO,
              fromFrame: 1500 * FPS_24,
              toFrame: 3000 * FPS_24,
              details: {
                src: 'https://d6d4ismr40iw.cloudfront.net/videos/individual_recording/a4e61a6e-51fb-4557-80ff-cf9a6b8c8c1f/8875c437-d989-437f-97df-3b43d9164978/3307506_0_merge_av/stream.m3u8',
                altText: 'Speaker Video 3',
                volume: [
                  {
                    fromFrame: 0,
                    toFrame: 3000 * FPS_24,
                    value: 100
                  }
                ]
              },
              trim: [],
              style: {},
              containerStyle: {
                position: 'absolute',
                top: '25%',
                left: '50%',
                width: '50%',
                height: '50%'
              }
            }
          ]
        }
      ]
    },
    {
      id: captionsTrackId,
      name: 'Captions Track',
      type: TrackType.CAPTIONS,
      font: {
        name: 'Montserrat',
        location: 'https://d6d4ismr40iw.cloudfront.net/content-lab/fonts/Montserrat-ExtraBold.ttf'
      },
      pages: captionPages,
      hideTrack: {
        inTimeline: true,
        inPlayer: false
      },
      segmentId
    }
  ]
});
