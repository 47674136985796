import createStore from '@/stores/store';

interface PartSpeakerOverlap {
  fromFrame: number;
  toFrame: number;
  speakerIds: string[];
}

type SpeakerOverlaps = PartSpeakerOverlap[];

export const speakerOverlapsStore = createStore<SpeakerOverlaps>([]);
