import { Element } from '../../MainPlayer/types/Element/Element';
import { ElementType } from '../../MainPlayer/types/Element/Type';
import AudioElementComponent from '../AudioElementComponent/AudioElementComponent';
import ImageElementComponent from '../ElementComponent/ImageElementComponent/ImageElementComponent';
import TextElementComponent from '../ElementComponent/TextElementComponent/TextElementComponent';
import VideoElementComponent from '../VideoElementComponent/VideoElementComponent';

const ElementsTrackComponent: React.FC<{
  elements: Element[];
  source: 'SEGMENT' | 'TRACK';
}> = ({ elements, source }) => {
  return elements.map(element => {
    if (!element) return null;

    switch (element.type) {
      case ElementType.TEXT:
        return <TextElementComponent key={source + '-' + element.id} element={element} />;
      // case ElementType.SHAPE:
      //   return <ShapeElementComponent key={source + '-' + element.id} element={element} />;
      case ElementType.IMAGE:
        return <ImageElementComponent key={source + '-' + element.id} element={element} />;
      case ElementType.AUDIO:
        return <AudioElementComponent key={source + '-' + element.id} element={element} />;
      case ElementType.VIDEO:
        return <VideoElementComponent key={source + '-' + element.id} element={element} />;
      default:
        return null;
    }
  });
};

export default ElementsTrackComponent;
