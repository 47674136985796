import React, {
  Dispatch,
  memo,
  RefObject,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
  useSyncExternalStore
} from 'react';
import DragHandle from '../../ClipTimeline/DragHandle/DragHandle';
import { getDeletedSecondsBeforeTime } from '../../ClipPlayerControls/utils';
import { DRAG_HANDLE_WIDTH } from '../../ClipTimeline/DragHandle/DragHandleUtils';
import {
  getNewEndTimePosition,
  getNewStartTimePosition,
  getNewTrimFromEndDeletes,
  getNewTrimFromStartDeletes
} from './utlis';
import { useMainSectionRefReturn } from '../../ClipPlayerSections/ClipPlayerSectionsTypes';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { updateClipCaptionsLoadingState, updateDeletes, updateStartEndTime } from '@/stores/clip';
import useThrottle from '@/hooks/useThrottle';
import {
  editFullRecording,
  startDraggingFullRecoringTimeline,
  stopDraggingFullRecoringTimeline
} from '@/stores/editFullRecording';
import { ClipDeletes } from '@/domains/asset';

function ClipSpeakersTimelineTrim({
  timelineContainerRef,
  startDragHandleX,
  endDragHandleX,
  mainPlayerProps,
  setStartDragHandleX,
  setEndDragHandleX,
  onDrag,
  onDragStarted = () => null
}: {
  timelineContainerRef: RefObject<HTMLDivElement>;
  startDragHandleX: number;
  endDragHandleX: number;
  mainPlayerProps: useMainSectionRefReturn;
  setStartDragHandleX: Dispatch<SetStateAction<number>>;
  setEndDragHandleX: Dispatch<SetStateAction<number>>;
  onDrag: (e) => void;
  onDragStarted: () => void;
}) {
  const { clipId, clipData } = useClipsContext();
  const editFullRecordingStore = useSyncExternalStore(editFullRecording.subscribe, editFullRecording.getSnapshot);
  const isDragging = useMemo(() => editFullRecordingStore.isDragging, [editFullRecordingStore.isDragging]);

  const dragHandleType = useRef<'start' | 'end' | null>(null);

  const [startDragTime, setStartDragTime] = useState<number>(0);
  const [endDragTime, setEndDragTime] = useState<number>(0);

  const throttledOnDrag = useThrottle(onDrag, 500);

  const fullRecordingDuration = useMemo(() => {
    return mainPlayerProps.mainSectionRef.current?.duration || 0;
  }, [mainPlayerProps.mainSectionRef.current?.duration]);

  const onStartTimeDragHandleClick = useCallback(() => {
    dragHandleType.current = 'start';
    onDragStarted();
    startDraggingFullRecoringTimeline();
  }, []);

  const onEndTimeDragHandleClick = useCallback(() => {
    dragHandleType.current = 'end';
    onDragStarted();
    startDraggingFullRecoringTimeline();
  }, []);

  const getNewTime = useCallback(() => {
    if (dragHandleType.current === 'end') {
      return endDragTime + getDeletedSecondsBeforeTime(startDragTime, clipId, clipData);
    }
    return Math.max(startDragTime, 0);
  }, [clipData, clipId, endDragTime, startDragTime]);

  const onPointerUp = useCallback(() => {
    if (!!dragHandleType.current) {
      const time = getNewTime();
      let updatedDeletes: ClipDeletes | null =
        dragHandleType.current === 'start'
          ? getNewTrimFromStartDeletes(time, clipData)
          : getNewTrimFromEndDeletes(time, clipData, fullRecordingDuration);
      if (!updatedDeletes) return;
      updateClipCaptionsLoadingState(clipId, true);
      updateDeletes(clipId, updatedDeletes, { eventName: 'FULL_RECORDING_TRIM' });
      updateStartEndTime(clipId, dragHandleType.current, time);
    }
    dragHandleType.current = null;
    stopDraggingFullRecoringTimeline();
  }, [clipData, clipId, fullRecordingDuration, getNewTime]);

  const onPointerMove = useCallback(
    (e: MouseEvent) => {
      if (!isDragging || !timelineContainerRef.current) return;
      const framesRect = timelineContainerRef.current.getBoundingClientRect();
      let shouldUpdatePlayer = false;
      if (dragHandleType.current === 'start') {
        const newPosition = getNewStartTimePosition(framesRect, endDragHandleX, e);
        if (newPosition !== startDragHandleX) {
          shouldUpdatePlayer = true;
        }
        setStartDragHandleX(newPosition);
        const movedPerc = (newPosition / framesRect.width) * 100;
        setStartDragTime((fullRecordingDuration / 100) * movedPerc);
        setEndDragTime(clipData.asset_metadata.end);
      } else if (dragHandleType.current === 'end') {
        const newPosition = getNewEndTimePosition(framesRect, startDragHandleX, e);
        if (newPosition !== endDragHandleX) {
          shouldUpdatePlayer = true;
        }
        setEndDragHandleX(newPosition);
        const movedPerc = ((framesRect.width - newPosition) / framesRect.width) * 100;
        setEndDragTime((fullRecordingDuration / 100) * movedPerc);
        setStartDragTime(clipData.asset_metadata.start);
      }
      if (shouldUpdatePlayer) {
        throttledOnDrag(e, true);
      }
    },
    [
      clipData.asset_metadata.end,
      clipData.asset_metadata.start,
      fullRecordingDuration,
      endDragHandleX,
      isDragging,
      setEndDragHandleX,
      setStartDragHandleX,
      startDragHandleX,
      throttledOnDrag,
      timelineContainerRef
    ]
  );

  useEffect(() => {
    if (!isDragging) return;

    const handlePointerMove = e => onPointerMove(e);
    const handlePointerUp = () => onPointerUp();

    document.addEventListener('pointermove', handlePointerMove);
    document.addEventListener('pointerup', handlePointerUp);

    return () => {
      document.removeEventListener('pointermove', handlePointerMove);
      document.removeEventListener('pointerup', handlePointerUp);
    };
  }, [isDragging, onPointerUp, onPointerMove]);

  return (
    <>
      <DragHandle
        type="start"
        onPointerDown={onStartTimeDragHandleClick}
        positionX={Math.max(startDragHandleX - DRAG_HANDLE_WIDTH, 0)}
      />
      <DragHandle
        type="end"
        onPointerDown={onEndTimeDragHandleClick}
        positionX={Math.max(endDragHandleX - DRAG_HANDLE_WIDTH, 0)}
      />
    </>
  );
}
export default memo(ClipSpeakersTimelineTrim);
