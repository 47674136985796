import { SpeakerWithDetails } from '@/context/TranscriptContext/TranscriptContextTypes';

export const getActiveSpeakersWithinTimeRange = (
  start: number,
  end: number,
  speakersWithDetails: Record<string, SpeakerWithDetails>
) => {
  return Object.entries(speakersWithDetails)
    .flatMap(([speakerId, speakerDetails]) => {
      const speakingSlots = speakerDetails.speakingSlots;

      return Object.entries(speakingSlots).map(([slotStart, slotEnd]) => {
        let speakerStart = +slotStart;
        let speakerEnd = slotEnd;

        if (speakerStart < start) {
          if (speakerEnd <= start) {
            return null;
          }

          speakerStart = start;

          if (speakerEnd > end) {
            speakerEnd = end;
          } else if (speakerEnd <= end) {
            // this else if is only for readability. It would work with an else
            // do nothing
          }
        } else if (speakerStart > end) {
          return null;
        } else if (speakerStart >= start) {
          // this else if is also only for readability. It would work with an else

          if (speakerStart === end || speakerEnd === start) {
            // return null to avoid single speaker with same start and end times
            return null;
          }

          if (speakerEnd < end) {
            // do nothing
          } else if (speakerEnd > end) {
            // this else if is also only for readability. It would work with an else
            speakerEnd = end;
          }
        }

        return {
          speakerId,
          start: speakerStart,
          end: speakerEnd
        };
      });
    })
    .filter(a => a !== null)
    .sort((a, b) => (a && b ? a.start - b.start : 0));
};
