import { Word } from '@/domains/transcript';

export const CURRENT_SELECTION_DEFAULT = {
  x: 0,
  y: 0,
  bottom: 0,
  right: 0,
  words: [] as Word[],
  startIndex: 0,
  endIndex: 0
};

export const CLIP_MIN_LENGTH = 3;
export const VIRTUAL_CONTAINER_EXTEND_BY_SIZE = 15000;
export const VIRTUAL_VIEWPORT_EXTENDED_BY = 1500;
export const TRIM_POSITION_OFFSET = 60;
export const CLIP_MIN_DURATION = 5;
export const CLIP_MIN_DURATION_ERROR = 'Min. 5 seconds';
export const CLIP_MAX_DURATION = 10800;
export const CLIP_MAX_DURATION_ERROR = 'Max. 3 hours';

export const TRANSCRIPT_SEARCH_DEBOUNCE = 700;

export const TRANSCRIPT_MAX_WORD_EDIT_LENGTH = 5;

export enum ClipTranscriptMode {
  Full,
  EditDuration,
  EditCaptions
}
