import { SegmentsTrack } from '../../MainPlayer/types/Track/SegmentsTrack';
import { SegmentType } from '../../MainPlayer/types/Segment/Type';
import CompositeSegmentComponent from '../SegmentComponent/CompositeSegmentComponent';

const SegmentsTrackComponent: React.FC<{
  segments: SegmentsTrack['segments'];
}> = ({ segments }) => {
  return segments.map(segment => {
    if (!segment) return null;

    switch (segment.type) {
      case SegmentType.COMPOSITE:
        return <CompositeSegmentComponent key={segment.id} segment={segment} />;
      default:
        return null;
    }
  });
};

export default SegmentsTrackComponent;
