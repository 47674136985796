import { ReactNode, CSSProperties } from 'react';
import { DivProps } from '@/domains/global';
import { classnames } from '@/libs/utils';

/**
 * A container that will adjust to it's parent's size, maintaining the aspect ratio of the composition.
 * The parent container should have relative positioning and have a defined width and height.
 */
export const ResponsiveContainer = ({
  compositionHeight,
  compositionWidth,
  children,
  parentHeight,
  parentWidth,
  parentClassnames = ''
}: {
  compositionHeight: number;
  compositionWidth: number;
  parentHeight: CSSProperties['height'];
  parentWidth: CSSProperties['width'];
  children?: ReactNode;
  /**
   * Other optional properties
   */
  parentClassnames?: DivProps['className'];
}) => {
  return (
    <div
      className={classnames('relative', parentClassnames)}
      style={{
        height: parentHeight,
        width: parentWidth
      }}
    >
      <div
        className="absolute inset-0 m-auto max-h-full max-w-full"
        style={{
          aspectRatio: `${compositionWidth} / ${compositionHeight}`
        }}
      >
        {children}
      </div>
    </div>
  );
};
