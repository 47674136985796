import { Img, Sequence } from 'remotion';
import { ImageElement } from '@/App/remotion/MainPlayer/types/Element/ImageElement';

const ImageElementComponent: React.FC<{
  element: ImageElement;
}> = ({ element }) => {
  const { fromFrame, toFrame, details, style } = element;
  const durationInFrames = Math.round(toFrame - fromFrame);

  const transformedElementStyle = {
    ...style
    // boxShadow: (style.boxShadow || [])
    //   .map(shadow => `${shadow.x}px ${shadow.y}px ${shadow.blur}px ${shadow.spread}px ${shadow.color}`)
    //   .join(',')
  };

  return (
    <Sequence from={fromFrame} durationInFrames={durationInFrames} layout="none">
      <Img style={{ ...transformedElementStyle }} src={details.src} alt={details.altText} pauseWhenLoading />
    </Sequence>
  );
};

export default ImageElementComponent;
