import { SearchParagraphWord, SearchResult } from '../types';

/**
 * Finds and returns all word occurrences containing the specified editing word content
 * from an array of search results (paragraphs) in order to replace all occurrences
 *
 * @param {SearchResult[]} searchParagraphs - An array of search results where each result
 * contains words within a paragraph.
 * @param {string} editingWordContent - The new content to replace in all occurrences
 *
 * @returns {SearchParagraphWord[]} An array of `SearchParagraphWord` objects, where each
 * object corresponds to a word from the search results containing the `editingWordContent`.
 */
export function getAllEditingWordOccurrences(
  searchParagraphs: SearchResult[],
  editingWordContent: string
): SearchParagraphWord[] {
  const matchingWords = searchParagraphs.reduce((acc: SearchParagraphWord[], el) => {
    const words = el.words.filter(w => w.content.toLowerCase().includes(editingWordContent.toLowerCase()));
    if (words.length) acc = [...acc, ...words];
    return acc;
  }, []);
  // Return unique values
  return Array.from(new Set(matchingWords.map(item => JSON.stringify(item)))).map(item => JSON.parse(item));
}

export function getMergesTimeRanges(searchParagraphs: SearchResult[]): Array<{ startTime: number; endTime: number }> {
  return searchParagraphs.reduce((acc: any, el) => {
    const firstWord = el.words.find(w => w.startIndex === el.start);
    const lastWord = el.words.find(w => w.endIndex === el.end);
    if (firstWord && lastWord) {
      acc.push({
        startTime: firstWord.start_time,
        endTime: lastWord.end_time
      });
    }
    return acc;
  }, []);
}
