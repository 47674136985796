import { Fragment } from 'react';
import { AbsoluteFill, useCurrentFrame } from 'remotion';
import TrackComponent from '../components/TrackComponent/TrackComponent';
import { CompositionConfig } from './types/CompositionConfig';

const MainPlayer: React.FC<CompositionConfig> = ({ playerConfig: { tracks } }) => {
  const frame = useCurrentFrame();

  return (
    <Fragment>
      <AbsoluteFill className="bg-black">
        {tracks.map(track => {
          if (track.hideTrack.inPlayer) return null;

          return <TrackComponent track={track} key={track.id} />;
        })}
      </AbsoluteFill>
      <AbsoluteFill style={{ color: 'red', fontSize: 60 }}>{frame}</AbsoluteFill>
    </Fragment>
  );
};

export default MainPlayer;
