import createStore from '@/stores/store';

type StageTimeSpans = {
  [speakerId: string]: string; // Comma-separated ranges
};

const stageTimeSpans: StageTimeSpans = {
  spk_0:
    '0-650,800-1350,1800-2380,3000-3580,4100-4750,5000-5580,6200-6780,7000-7580,8100-8680,9000-9580,10200-10780,11000-11580,12100-12680,13000-13580,14200-14780,15000-15580,16200-16780,17000-17580,18200-18780,19000-19580,20200-20780,21000-21580,22200-22780,23000-24000',
  spk_1:
    '150-780,600-1180,1600-2150,2800-3380,3900-4480,4800-5380,6000-6580,6800-7380,7900-8480,8800-9380,10000-10580,10800-11380,11900-12480,12800-13380,14000-14580,14800-15380,16000-16580,16800-17380,18000-18580,18800-19380,20000-20580,20800-21380,22000-22580,22800-23380',
  spk_2:
    '400-880,900-1380,1400-1880,2600-3080,3700-4180,4600-5080,5800-6280,6600-7080,7700-8180,8600-9080,9800-10280,10600-11080,11700-12180,12600-13180,13800-14380,14600-15180,15800-16380,16600-17180,17800-18380,18600-19180,19800-20380,20600-21180,21800-22380,22600-23180'
};

export const stageTimeSpansStore = createStore<StageTimeSpans>(stageTimeSpans);
