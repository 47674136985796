import Hls from 'hls.js';
import { useEffect, useRef } from 'react';
import { RemotionMainVideoProps, RemotionVideoProps, Video } from 'remotion';

const HlsVideo: React.FC<RemotionVideoProps & RemotionMainVideoProps> = ({ src, ...props }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (!src) {
      throw new Error('src is required');
    }

    const startFrom = 0;

    const hls = new Hls({
      startLevel: 4,
      maxBufferLength: 5,
      maxMaxBufferLength: 5
    });

    hls.on(Hls.Events.MANIFEST_PARSED, () => {
      hls.startLoad(startFrom);
    });

    hls.loadSource(src);
    hls.attachMedia(videoRef.current!);

    return () => {
      hls.destroy();
    };
  }, [src]);

  return <Video src={src} {...props} ref={videoRef} />;
};

export default HlsVideo;
