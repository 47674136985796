import { Popover, Transition } from '@headlessui/react';
import Icon, { IconName } from '../Icon';
import { classnames } from '@/libs/utils';

export type ActionButton = {
  action: () => void | Promise<void>;
  label: string;
  icon: IconName;
  trackingId: string;
  disabled: boolean;
  isActive?: boolean;
  hidden?: boolean;
};

export default function ActionButtons({
  show,
  buttons,
  labelPosition,
  size
}: {
  show: boolean;
  buttons: ActionButton[];
  labelPosition: 'left' | 'right';
  size: 'base' | 'large';
}) {
  return (
    <div className="sticky top-0 flex w-12 shrink-0 items-center justify-center">
      <Transition
        show={show}
        enter="transition duration-150 ease-out"
        enterFrom="transform translate-x-2 scale-[0.98] opacity-0"
        enterTo="transform translate-x-0 scale-1 opacity-100"
        leave="transition duration-100 ease-out"
        leaveFrom="transform translate-x-0 scale-1 opacity-100"
        leaveTo="transform translate-x-2 scale-[0.98] opacity-0"
      >
        <div className="flex flex-col rounded-[0.75rem] border bg-white p-1.5 shadow">
          <Popover
            className={classnames('relative z-50 flex flex-col', {
              'space-y-4': size === 'large'
            })}
          >
            {({ open }) => (
              <>
                {buttons.map(({ action, label, icon, trackingId, disabled, isActive, hidden }) => {
                  if (hidden) return null;

                  return (
                    <button
                      key={trackingId}
                      data-testid={trackingId}
                      onClick={action}
                      disabled={disabled}
                      className={classnames('group flex flex-col items-center justify-center rounded-lg', {
                        'h-10 w-10': size === 'base',
                        'h-16 w-[4.75rem] pt-1': size === 'large',
                        'bg-slate-100 text-slate-950': open || isActive,
                        'text-slate-700 hover:bg-slate-100 hover:text-slate-950': !open,
                        'cursor-not-allowed !text-slate-500': disabled
                      })}
                    >
                      <Icon name={icon} className={size === 'large' ? 'h-6 w-6' : 'h-5 w-5'} />
                      {size === 'large' ? (
                        <div className="mt-2 text-xs">{label}</div>
                      ) : (
                        <div
                          className={`${
                            labelPosition === 'left' ? 'right-14' : 'left-14'
                          } pointer-events-none absolute flex w-max -translate-x-2 items-center justify-center rounded bg-slate-900 p-1 px-2 text-xs text-white opacity-0 transition-all delay-100 duration-150 ease-in-out group-hover:translate-x-0 group-hover:opacity-100`}
                        >
                          <div
                            className={`absolute h-2 w-2 rotate-45 ${
                              labelPosition === 'left' ? 'right-[-3px]' : 'left-[-3px]'
                            } bg-slate-900`}
                          ></div>
                          <span>{label}</span>
                        </div>
                      )}
                    </button>
                  );
                })}
              </>
            )}
          </Popover>
        </div>
      </Transition>
    </div>
  );
}
