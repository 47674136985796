import React from 'react';
import { Popover } from '@headlessui/react';
import { IconChevronDown, IconX } from '@tabler/icons-react';
import PopoverPanel from './PopoverPanel';
import { MultiSelectDropdownProps } from './types';

export default function MultiSelectDropdown({
  label,
  searchable = false,
  joinSelected,
  onClear,
  selected,
  onSelect,
  searchValue,
  setSearchValue,
  onPopoverOpen,
  options,
  loading,
  Loader,
  itemRenderer,
  disabled
}: MultiSelectDropdownProps) {
  const hasFilter = selected.length > 0;

  return (
    <Popover className="relative">
      <div className="flex">
        <Popover.Button
          disabled={disabled}
          className={`${
            hasFilter
              ? 'rounded-l-lg border-deep-orange-200/80 bg-deep-orange-50 pl-3 pr-2.5 text-deep-orange hover:bg-deep-orange-100'
              : 'rounded-lg border-slate-200 px-3 pr-2.5 hover:border-transparent hover:bg-deep-orange-50 hover:text-deep-orange'
          } group flex items-center justify-center border border-slate-200 py-2 text-xs font-medium focus:outline-none focus:ring-deep-orange-600 disabled:cursor-not-allowed disabled:border-slate-200 disabled:bg-slate-50 disabled:text-slate-400`}
        >
          {hasFilter ? (
            <span>
              {joinSelected
                ? options
                    .filter(s => selected.includes(s.value))
                    .map(t => t.label)
                    .join(', ')
                : `${selected?.length} ${label.toLowerCase()}${selected?.length > 1 ? 's' : ''}`}
            </span>
          ) : (
            <>
              {label}
              <IconChevronDown size={12} stroke={1.8} className="ml-1" />
            </>
          )}
        </Popover.Button>
        {hasFilter && (
          <button
            title="Clear"
            onClick={onClear}
            className="rounded-r-lg border border-deep-orange-200/80 !border-l-transparent bg-deep-orange-50 px-1.5 py-2 pr-2 text-deep-orange hover:bg-deep-orange-100"
          >
            <IconX size={14} stroke={1.9} />
          </button>
        )}
      </div>

      <Popover.Panel
        className={`${searchable ? 'w-72' : 'w-40'} absolute left-1 z-40 mt-1 min-w-40 rounded-md bg-white shadow-md`}
      >
        <PopoverPanel
          searchable={searchable}
          searchValue={searchValue}
          setSearchValue={setSearchValue}
          onPopoverOpen={onPopoverOpen}
          options={options}
          selected={selected}
          onSelect={onSelect}
          loading={loading}
          Loader={Loader}
          itemRenderer={itemRenderer}
        />
      </Popover.Panel>
    </Popover>
  );
}
