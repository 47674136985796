import { memo, useCallback, useState } from 'react';
import { IconArrowUpRight, IconLoader2 } from '@tabler/icons-react';
import { assetsClipPublishToLibraryCreate } from '@goldcast/api/content';
import { VideoAssetUsage } from '@goldcast/api/backend';
import Button from '@/components/atoms/Button/Button';
import { updateClipMetadataWithId } from '@/stores/clip';
import { useAppContext } from '@/context/AppContext/AppContext';
import { showErrorToast, showSuccessToast } from '@/libs/toast/toast';
import useAnalytics from '@/hooks/useAnalytics';

const NAVIGATION_ROUTES = {
  EVENT: {
    Recording: '/recording-event',
    Conference: '/events',
    Webinar: '/webinar'
  },
  HUB: '/pages',
  STUDIO_EVENT_TEMPLATE: '/templates'
} as const;

const VIDEO_USAGE_MESSAGE = {
  PUBLISH: {
    title: 'Video is in use',
    message:
      'This video is currently in use in the following destinations. Click publish to sync changes to Video Library and use the updated video in these destinations.'
  },
  DELETE: {
    title: 'Video is in use',
    message:
      'This video is currently in use on the following pages and cannot be deleted. Please remove the video from the following destinations to delete clip.'
  }
};

function ClipUsageDialog({
  onClose,
  type = 'PUBLISH',
  clipId,
  clipAssetUsage
}: {
  onClose: () => void;
  type: 'PUBLISH' | 'DELETE';
  clipId?: string;
  clipAssetUsage: VideoAssetUsage[];
}) {
  const { logger } = useAppContext();
  const { trackVideoPublished } = useAnalytics();
  const [isPublishing, setIsPublishing] = useState(false);

  const publish = useCallback(() => {
    if (isPublishing || !clipId) return;
    setIsPublishing(true);
    assetsClipPublishToLibraryCreate({ id: clipId })
      .then(res => {
        updateClipMetadataWithId(clipId, res.asset_metadata);
        showSuccessToast('Publishing started. This might take a few minutes.');
        trackVideoPublished({ id: clipId, isSuccess: true });
      })
      .catch(e => {
        logger.error('Failed to publish clip to library:', e);
        showErrorToast('Something went wrong while publishing recording. Please try again later.');
        trackVideoPublished({ id: clipId, isSuccess: false });
      })
      .finally(() => {
        onClose();
      });
  }, [clipId, logger, onClose, isPublishing]);

  const onUsageClick = useCallback(
    (clipUsage: VideoAssetUsage) => {
      const basePath =
        clipUsage.entity_type === 'EVENT'
          ? NAVIGATION_ROUTES.EVENT[clipUsage.entity_subtype as keyof typeof NAVIGATION_ROUTES.EVENT]
          : NAVIGATION_ROUTES[clipUsage.entity_type as keyof typeof NAVIGATION_ROUTES];

      if (!basePath) return;

      const url = `${basePath}/${clipUsage.entity_id}`;
      window.open(url, '_blank');
      onClose();
    },
    [onClose]
  );

  return (
    <div className="relative flex flex-col overflow-y-auto overflow-x-hidden">
      <div className="grow px-5 pb-5">
        <h1 className="font-medium">{VIDEO_USAGE_MESSAGE[type].title}</h1>
        <div className="mt-1.5 w-full text-sm leading-tight text-slate-600">
          {VIDEO_USAGE_MESSAGE[type].message}
          <div className="mt-5">
            {clipAssetUsage.map(clipUsage => (
              <div key={clipUsage?.entity_id} className="flex w-full items-center justify-between py-3">
                <div className="">
                  <div className="text-sm font-medium text-black">{clipUsage.entity_title}</div>
                  <div className="mt-0.5 text-xs text-slate-600">{clipUsage.entity_subtype}</div>
                </div>
                <div className="flex flex-col items-end">
                  <div
                    className="flex cursor-pointer items-center space-x-1 text-sm hover:underline"
                    onClick={() => onUsageClick(clipUsage)}
                  >
                    <span className="text-xs font-medium text-black">Visit</span>
                    <IconArrowUpRight className="h-4 w-4 text-black" />
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      {type === 'PUBLISH' ? (
        <div className="flex items-center justify-end gap-3 border-t border-t-slate-200 px-5 pb-3 pt-2.5">
          <Button variation="outline" trackingId="cancel-publishing" onClick={onClose}>
            Cancel
          </Button>
          <Button variation="filled" trackingId="publish-content" disabled={isPublishing} onClick={publish}>
            Publish and update
            {isPublishing && <IconLoader2 className="ml-2 animate-spin" size={16} />}
          </Button>
        </div>
      ) : (
        <div className="flex items-center justify-end gap-3 border-t border-t-slate-200 px-5 pb-3 pt-2.5">
          <Button variation="filled" trackingId="close-dialog" onClick={onClose}>
            Close
          </Button>
        </div>
      )}
    </div>
  );
}

export default memo(ClipUsageDialog);
