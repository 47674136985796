import { memo, useCallback, useMemo, useState } from 'react';
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { listVideoAssetUsage, VideoAssetUsage } from '@goldcast/api/backend';
import IconButton from '../../atoms/Button/IconButton';
import ClipPublishDialog from './ClipPublishDialog';
import ClipUsageDialog from './ClipUsageDialog';
import ContentDialog from '@/Pages/Sessions/uiComponents/ContentDialog/ContentDialog';
import useDialog from '@/components/organisms/useDialog';
import { ClipPublishedStatus } from '@/domains/asset';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import TooltipV2 from '@/components/TooltipV2';

function ClipPublishButton({ isPublishingAllowed }: { isPublishingAllowed: boolean }) {
  const { clipData } = useClipsContext();
  const [clipAssetUsage, setClipAssetUsage] = useState<VideoAssetUsage[]>([]);
  const { isOpen: isPublishDialogOpen, openDialog: openPublishDialog, closeDialog: closePublishDialog } = useDialog();
  const {
    isOpen: isClipUsageDialogOpen,
    openDialog: openClipUsageDialog,
    closeDialog: closeClipUsageDialog
  } = useDialog();

  const location = useLocation();

  const isClipListPage = useMemo(() => {
    return location.pathname.includes('/clips');
  }, [location.pathname]);

  const isPublishedWithLatestChanges = useMemo(() => {
    return (
      clipData.asset_metadata.publish_status === ClipPublishedStatus.PUBLISHED &&
      moment(clipData.updated_at).diff(moment(clipData.asset_metadata.published_at), 'second') < 10
    );
  }, [clipData.asset_metadata.publish_status, clipData.asset_metadata.published_at, clipData.updated_at]);

  const isPublishing = useMemo(() => {
    return clipData.asset_metadata.publish_status === ClipPublishedStatus.PROCESSING;
  }, [clipData.asset_metadata.publish_status]);

  const isClipInInitialState = useMemo(() => {
    return moment(clipData.updated_at).isSame(moment(clipData.asset_metadata.created_at), 'minute');
  }, [clipData.asset_metadata.created_at, clipData.updated_at]);

  const tooltipContent = useMemo(() => {
    if (!isPublishingAllowed) {
      return 'Coming soon for clips and uploads.';
    }

    if (isPublishedWithLatestChanges || isClipInInitialState) {
      return 'There are no unpublished changes';
    }
    if (isPublishing) {
      return 'Please wait for publishing to complete before publishing again';
    }
    return '';
  }, [isPublishedWithLatestChanges, isPublishing, isClipInInitialState]);

  const isPublishDisabled = useMemo(() => {
    return isPublishedWithLatestChanges || isPublishing || isClipInInitialState || !isPublishingAllowed;
  }, [isPublishedWithLatestChanges, isPublishing, isClipInInitialState]);

  const triggerPublish = useCallback(async () => {
    if (isClipListPage && clipData?.asset_metadata?.video_library_id) {
      const usageData = await listVideoAssetUsage({
        id: clipData?.asset_metadata?.video_library_id
      });
      if (usageData && usageData.length > 0) {
        setClipAssetUsage(usageData);
        openClipUsageDialog();
        return;
      }
    }

    openPublishDialog();
  }, [openPublishDialog]);

  return (
    <>
      <TooltipV2
        place="bottom"
        tooltipId="publish-tooltip"
        tooltipContent={tooltipContent}
        size="xsmall"
        tooltipElement={
          <IconButton
            icon="IconArrowBarToUp"
            content="Publish"
            variation="filled"
            trackingId="publish-recording-button"
            disabled={isPublishDisabled}
            onClick={triggerPublish}
            data-tooltip-id="publish-tooltip"
          />
        }
      />

      <ContentDialog
        isOpen={isPublishDialogOpen}
        hideCloseIcon={true}
        size="medium"
        setIsOpen={closePublishDialog}
        disableBackdropClose={false}
      >
        <ClipPublishDialog onClose={closePublishDialog} />
      </ContentDialog>
      <ContentDialog
        isOpen={isClipUsageDialogOpen}
        hideCloseIcon={true}
        size="medium"
        setIsOpen={closeClipUsageDialog}
        disableBackdropClose={false}
      >
        <ClipUsageDialog onClose={closeClipUsageDialog} clipAssetUsage={clipAssetUsage} type={'PUBLISH'} />
      </ContentDialog>
    </>
  );
}

export default memo(ClipPublishButton);
