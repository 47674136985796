import { memo } from 'react';
import ActiveSpeakerRanges from './ActiveSpeakerRanges';
import StageTimeSpans from './StageTimeSpans';
import SpeakersInTimeRange from './SpeakersInTimeRange';
import UpdateTimeline from './UpdateTimeline';

function Playground({ clipStart, clipEnd }: { clipStart: number; clipEnd: number }) {
  return (
    <div className="px-4 pb-4 pt-2 ">
      <ActiveSpeakerRanges clipStart={clipStart} clipEnd={clipEnd} />

      <StageTimeSpans />
      <SpeakersInTimeRange clipStart={clipStart} clipEnd={clipEnd} />
      <UpdateTimeline />
    </div>
  );
}

export default memo(Playground);
