import { memo, useEffect, useRef, useState } from 'react';
import { SearchParagraph, SearchResult, TranscriptParagraph, TranscriptSelection } from '../types';
import { convertParagraphToSearchParagraph, getParagraphSearchResults } from '../utils';
import { editClipWord, mergeClipWords, saveCorrectWordChanges } from '../EditTranscriptMenu/utils';
import { getAllEditingWordOccurrences, getMergesTimeRanges } from './utils';
import IconButton from '@/components/atoms/Button/IconButton';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { Clip } from '@/domains/asset';

const MAX_TRANSCRIPT_INLINE_WORD_EDIT_INPUT_LENGTH = 40;

function TranscriptInlineEdit({
  editingTranscriptRef,
  editingContent,
  paragraphs,
  transcriptSelection,
  onCancelEditing,
  transcriptContainer,
  onEditActionTriggered
}: {
  editingTranscriptRef: React.MutableRefObject<HTMLInputElement | null>;
  editingContent: string;
  paragraphs: TranscriptParagraph[];
  transcriptSelection: TranscriptSelection;
  onCancelEditing: () => void;
  transcriptContainer: React.MutableRefObject<HTMLDivElement | null>;
  onEditActionTriggered: () => void;
}) {
  const { clipData } = useClipsContext();
  const [correctInputValue, setCorrectInputValue] = useState(editingContent);
  const inlineEditPopoverRef = useRef<HTMLDivElement>(null);
  const inlineEditAnchorRef = useRef<HTMLDivElement>(null);
  const [popupCalculativeStyles, setPopupCalculativeStyles] = useState<{
    top: number;
    left: number;
  }>();

  useEffect(() => {
    requestAnimationFrame(() => {
      if (editingTranscriptRef.current)
        editingTranscriptRef.current.style.height = `${editingTranscriptRef.current?.scrollHeight}px`;
    });
  }, [editingContent]);

  function saveAndResetSelection(value: Clip) {
    saveCorrectWordChanges(value, clipData);
    onCancelEditing();
  }

  const correctTranscript = () => {
    onEditActionTriggered();
    const updatedValue =
      transcriptSelection.words.length > 1
        ? mergeClipWords(
            correctInputValue,
            clipData,
            transcriptSelection.words.at(0)!.start_time,
            transcriptSelection.words.at(-1)!.end_time
          )
        : editClipWord(transcriptSelection.words[0], correctInputValue, clipData, true);
    saveAndResetSelection(updatedValue);
  };

  const correctAll = () => {
    onEditActionTriggered();
    const content = paragraphs.map((paragraph, index) => convertParagraphToSearchParagraph(paragraph.words, index));
    const foundParagraphs: SearchResult[] = content.reduce((acc: SearchResult[], el: SearchParagraph) => {
      return [...acc, ...getParagraphSearchResults(el, editingContent)];
    }, []);
    let updatedClipValue = clipData;
    if (transcriptSelection.words.length > 1) {
      const ranges = getMergesTimeRanges(foundParagraphs);
      ranges.forEach(r => {
        updatedClipValue = mergeClipWords(correctInputValue, updatedClipValue, r.startTime, r.endTime);
      });
    } else {
      const editingWordOccurrences = getAllEditingWordOccurrences(foundParagraphs, editingContent);
      const regex = new RegExp(editingContent, 'gi');
      editingWordOccurrences.forEach(w => {
        updatedClipValue = editClipWord(w, w.content.replace(regex, correctInputValue), updatedClipValue, true);
      });
    }
    saveAndResetSelection(updatedClipValue);
    onCancelEditing();
  };
  const isNewInputValid = correctInputValue.replaceAll(' ', '').length > 0;

  useEffect(() => {
    if (!transcriptContainer.current || !inlineEditPopoverRef.current || !inlineEditAnchorRef.current) return;

    const parentRect = transcriptContainer.current.getBoundingClientRect();
    const anchorRect = inlineEditAnchorRef.current.getBoundingClientRect();
    const popupRect = inlineEditPopoverRef.current.getBoundingClientRect();

    let top = anchorRect.top - popupRect.height - 8;
    let left = anchorRect.left + anchorRect.width / 2 - popupRect.width / 2;

    if (left < parentRect.left + 8) {
      left = anchorRect.left;
    }

    if (left + popupRect.width > parentRect.right) {
      left = anchorRect.right - popupRect.width;
    }

    setPopupCalculativeStyles({ top, left });
  }, []);

  return (
    <div className="relative inline-flex px-2" ref={inlineEditAnchorRef}>
      <div
        className="fixed z-50 flex h-fit items-center space-x-0.5 rounded-lg border border-slate-300 bg-white pr-5 shadow-lg"
        style={popupCalculativeStyles || { visibility: 'hidden' }}
        ref={inlineEditPopoverRef}
      >
        <IconButton
          variation="text"
          icon="IconCheck"
          size="base"
          content="Correct"
          trackingId="correct-trascript-confirm"
          disabled={!isNewInputValid}
          onClick={correctTranscript}
        />
        <IconButton
          variation="text"
          icon="IconChecks"
          size="base"
          buttonClassName="whitespace-nowrap"
          content="Correct all"
          onClick={correctAll}
          disabled={!isNewInputValid}
          trackingId="correct-all-trascript-confirm"
        />
        <IconButton
          variation="text"
          icon="IconX"
          size="base"
          content=""
          trackingId="correct-transcript-cancel"
          onClick={onCancelEditing}
        />
      </div>
      <input
        ref={editingTranscriptRef}
        autoFocus
        id="editing-block"
        size={Math.min(correctInputValue.length, MAX_TRANSCRIPT_INLINE_WORD_EDIT_INPUT_LENGTH) || 1}
        value={correctInputValue}
        onChange={e => setCorrectInputValue(e.target.value)}
        onKeyDown={e => {
          if (e.key === 'Enter' && isNewInputValid) {
            e.preventDefault();
            correctTranscript();
          }
        }}
        className="block w-fit resize-none overflow-hidden rounded border border-deep-orange p-1 text-black selection:bg-deep-orange focus:border-none focus:outline-none focus:ring-deep-orange"
      />
    </div>
  );
}

export default memo(TranscriptInlineEdit);
