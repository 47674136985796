import { CompositeSegment } from '../../MainPlayer/types/Segment/CompositeSegment';
import ElementsTrackComponent from '../TrackComponent/ElementsTrackComponent';

const CompositeSegmentComponent: React.FC<{
  segment: CompositeSegment;
}> = ({ segment }) => {
  return (
    <div className="relative h-full w-full">
      <ElementsTrackComponent key={segment.id} elements={segment.elements} source="SEGMENT" />
    </div>
  );
};

export default CompositeSegmentComponent;
