import { memo, useEffect, useState, useSyncExternalStore } from 'react';
import { timelineLoader, timeline } from '../timeline';
import { speakerOverlapsStore } from '../stores/speakerOverlaps';
import speaker_stream_mapping from '../data/spk_stream_mapping';
import { ElementType } from '../types/Element/Type';
import { TrackType } from '../types/Track/Type';
import { SegmentType } from '../types/Segment/Type';
import { Element } from '../types/Element/Element';
import { getPositionForSpeaker } from '../utils/videoPositioning';
import Toggle from '@/components/atoms/Toggle';

function UpdateTimeline() {
  const [showTimelineValues, setShowTimelineValues] = useState(false);
  const timelineStore = useSyncExternalStore(timeline.subscribe, timeline.getSnapshot);
  const speakerOverlaps = useSyncExternalStore(speakerOverlapsStore.subscribe, speakerOverlapsStore.getSnapshot);

  useEffect(() => {
    if (!speakerOverlaps?.length) return;

    timelineLoader.set(() => true);

    setTimeout(() => {
      const updatedElements = speakerOverlaps.flatMap((overlap, index) =>
        overlap.speakerIds.map((speakerId, speakerIndex) => ({
          id: `video-${index}-${speakerId}`,
          name: `Speaker ${speakerId}`,
          type: ElementType.VIDEO,
          fromFrame: overlap.fromFrame,
          toFrame: overlap.toFrame,
          details: {
            src: speaker_stream_mapping[speakerId],
            altText: `Speaker ${speakerId} Video`,
            volume: [
              {
                fromFrame: overlap.fromFrame,
                toFrame: overlap.toFrame,
                value: 100
              }
            ]
          },
          trim: [],
          style: {},
          containerStyle: {
            position: 'absolute',
            ...getPositionForSpeaker(speakerIndex, overlap.speakerIds.length)
          }
        }))
      );

      timeline.update(data => {
        const mainTrack = data.tracks[0];
        if (mainTrack.type === TrackType.SEGMENTS && mainTrack.segments[0].type === SegmentType.COMPOSITE) {
          mainTrack.segments[0].elements = updatedElements as Element[];
        }
        return data;
      });

      timelineLoader.set(() => false);
    }, 3000);
  }, [speakerOverlaps, speaker_stream_mapping]);

  // eslint-disable-next-line no-console
  console.log('FINAL: ', timelineStore);

  return (
    <div className="mt-6 rounded bg-gray-100 p-4">
      <div className=" flex items-center gap-3 text-sm font-medium">
        <div>Timeline Store Values:</div>
        <Toggle on={showTimelineValues} onToggle={() => setShowTimelineValues(s => !s)} />
      </div>
      {showTimelineValues && <pre className="mt-2 text-xs">{JSON.stringify(timelineStore, null, 2)}</pre>}
    </div>
  );
}

export default memo(UpdateTimeline);
