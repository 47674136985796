// Helper function to calculate position based on speaker index and total count
export function getPositionForSpeaker(index: number, total: number) {
  if (total === 1) {
    return {
      top: 0,
      left: 0,
      width: '100%',
      height: '100%'
    };
  }
  if (total === 2) {
    return {
      top: 0,
      left: `${index * 50}%`,
      width: '50%',
      height: '100%'
    };
  }
  if (total === 3 || total === 4) {
    return {
      top: `${Math.floor(index / 2) * 50}%`,
      left: `${(index % 2) * 50}%`,
      width: '50%',
      height: '50%'
    };
  }
  // Add more layouts for higher speaker counts if needed
  return {
    top: 0,
    left: 0,
    width: '100%',
    height: '100%'
  };
}
