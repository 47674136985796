const captionPages = [
  {
    text: "You've wait. What? It's an IDT. Oh, I",
    tokens: [
      {
        text: "You've",
        fromFrame: 2337,
        toFrame: 2349
      },
      {
        text: 'wait.',
        fromFrame: 2420,
        toFrame: 2427
      },
      {
        text: 'What?',
        fromFrame: 2427,
        toFrame: 2439
      },
      {
        text: "It's",
        fromFrame: 2468,
        toFrame: 2480
      },
      {
        text: 'an',
        fromFrame: 2508,
        toFrame: 2516
      },
      {
        text: 'IDT.',
        fromFrame: 2516,
        toFrame: 2528
      },
      {
        text: 'Oh,',
        fromFrame: 2581,
        toFrame: 2593
      },
      {
        text: 'I',
        fromFrame: 2621,
        toFrame: 2625
      }
    ],
    fromFrame: 2337,
    toFrame: 2625,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "thought that is Pratik. Oh, Pratik's all. That's",
    tokens: [
      {
        text: 'thought',
        fromFrame: 2625,
        toFrame: 2631
      },
      {
        text: 'that',
        fromFrame: 2631,
        toFrame: 2635
      },
      {
        text: 'is',
        fromFrame: 2635,
        toFrame: 2641
      },
      {
        text: 'Pratik.',
        fromFrame: 2641,
        toFrame: 2653
      },
      {
        text: 'Oh,',
        fromFrame: 2689,
        toFrame: 2701
      },
      {
        text: "Pratik's",
        fromFrame: 2714,
        toFrame: 2726
      },
      {
        text: 'all.',
        fromFrame: 2727,
        toFrame: 2739
      },
      {
        text: "That's",
        fromFrame: 2746,
        toFrame: 2756
      }
    ],
    fromFrame: 2625,
    toFrame: 2764,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'I was also confused when I logged it.',
    tokens: [
      {
        text: 'I',
        fromFrame: 2764,
        toFrame: 2769
      },
      {
        text: 'was',
        fromFrame: 2769,
        toFrame: 2773
      },
      {
        text: 'also',
        fromFrame: 2773,
        toFrame: 2781
      },
      {
        text: 'confused',
        fromFrame: 2781,
        toFrame: 2793
      },
      {
        text: 'when',
        fromFrame: 2794,
        toFrame: 2802
      },
      {
        text: 'I',
        fromFrame: 2802,
        toFrame: 2804
      },
      {
        text: 'logged',
        fromFrame: 2804,
        toFrame: 2816
      },
      {
        text: 'it.',
        fromFrame: 2839,
        toFrame: 2851
      }
    ],
    fromFrame: 2764,
    toFrame: 2875,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "It, there is this bug, when when you're",
    tokens: [
      {
        text: 'It,',
        fromFrame: 2875,
        toFrame: 2883
      },
      {
        text: 'there',
        fromFrame: 2892,
        toFrame: 2898
      },
      {
        text: 'is',
        fromFrame: 2898,
        toFrame: 2902
      },
      {
        text: 'this',
        fromFrame: 2902,
        toFrame: 2910
      },
      {
        text: 'bug,',
        fromFrame: 2910,
        toFrame: 2922
      },
      {
        text: 'when',
        fromFrame: 2934,
        toFrame: 2942
      },
      {
        text: 'when',
        fromFrame: 2942,
        toFrame: 2948
      },
      {
        text: "you're",
        fromFrame: 2948,
        toFrame: 2952
      }
    ],
    fromFrame: 2875,
    toFrame: 2952,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'copying a magic links now, you need to',
    tokens: [
      {
        text: 'copying',
        fromFrame: 2952,
        toFrame: 2959
      },
      {
        text: 'a',
        fromFrame: 2959,
        toFrame: 2961
      },
      {
        text: 'magic',
        fromFrame: 2961,
        toFrame: 2971
      },
      {
        text: 'links',
        fromFrame: 2971,
        toFrame: 2977
      },
      {
        text: 'now,',
        fromFrame: 2977,
        toFrame: 2989
      },
      {
        text: 'you',
        fromFrame: 2991,
        toFrame: 2995
      },
      {
        text: 'need',
        fromFrame: 2995,
        toFrame: 2999
      },
      {
        text: 'to',
        fromFrame: 2999,
        toFrame: 3001
      }
    ],
    fromFrame: 2952,
    toFrame: 3001,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'wait for 2 seconds before it you paste',
    tokens: [
      {
        text: 'wait',
        fromFrame: 3001,
        toFrame: 3007
      },
      {
        text: 'for',
        fromFrame: 3007,
        toFrame: 3011
      },
      {
        text: '2',
        fromFrame: 3011,
        toFrame: 3016
      },
      {
        text: 'seconds',
        fromFrame: 3016,
        toFrame: 3026
      },
      {
        text: 'before',
        fromFrame: 3026,
        toFrame: 3036
      },
      {
        text: 'it',
        fromFrame: 3036,
        toFrame: 3039
      },
      {
        text: 'you',
        fromFrame: 3043,
        toFrame: 3053
      },
      {
        text: 'paste',
        fromFrame: 3053,
        toFrame: 3063
      }
    ],
    fromFrame: 3001,
    toFrame: 3063,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "it. Otherwise, it's years old. Since 2 years.",
    tokens: [
      {
        text: 'it.',
        fromFrame: 3063,
        toFrame: 3066
      },
      {
        text: 'Otherwise,',
        fromFrame: 3066,
        toFrame: 3076
      },
      {
        text: "it's",
        fromFrame: 3076,
        toFrame: 3082
      },
      {
        text: 'years',
        fromFrame: 3082,
        toFrame: 3089
      },
      {
        text: 'old.',
        fromFrame: 3089,
        toFrame: 3101
      },
      {
        text: 'Since',
        fromFrame: 3109,
        toFrame: 3118
      },
      {
        text: '2',
        fromFrame: 3118,
        toFrame: 3126
      },
      {
        text: 'years.',
        fromFrame: 3126,
        toFrame: 3138
      }
    ],
    fromFrame: 3063,
    toFrame: 3159,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Oh, is it? That's why she gave me",
    tokens: [
      {
        text: 'Oh,',
        fromFrame: 3159,
        toFrame: 3164
      },
      {
        text: 'is',
        fromFrame: 3164,
        toFrame: 3168
      },
      {
        text: 'it?',
        fromFrame: 3168,
        toFrame: 3176
      },
      {
        text: "That's",
        fromFrame: 3176,
        toFrame: 3185
      },
      {
        text: 'why',
        fromFrame: 3185,
        toFrame: 3193
      },
      {
        text: 'she',
        fromFrame: 3193,
        toFrame: 3197
      },
      {
        text: 'gave',
        fromFrame: 3197,
        toFrame: 3203
      },
      {
        text: 'me',
        fromFrame: 3203,
        toFrame: 3208
      }
    ],
    fromFrame: 3159,
    toFrame: 3208,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'again, yeah, pretty Uh-huh. It takes some time.',
    tokens: [
      {
        text: 'again,',
        fromFrame: 3208,
        toFrame: 3216
      },
      {
        text: 'yeah,',
        fromFrame: 3216,
        toFrame: 3222
      },
      {
        text: 'pretty',
        fromFrame: 3222,
        toFrame: 3230
      },
      {
        text: 'Uh-huh.',
        fromFrame: 3256,
        toFrame: 3268
      },
      {
        text: 'It',
        fromFrame: 3276,
        toFrame: 3281
      },
      {
        text: 'takes',
        fromFrame: 3281,
        toFrame: 3291
      },
      {
        text: 'some',
        fromFrame: 3291,
        toFrame: 3303
      },
      {
        text: 'time.',
        fromFrame: 3327,
        toFrame: 3339
      }
    ],
    fromFrame: 3208,
    toFrame: 3369,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'I always would like I thought I thought',
    tokens: [
      {
        text: 'I',
        fromFrame: 3369,
        toFrame: 3373
      },
      {
        text: 'always',
        fromFrame: 3373,
        toFrame: 3384
      },
      {
        text: 'would',
        fromFrame: 3384,
        toFrame: 3388
      },
      {
        text: 'like',
        fromFrame: 3388,
        toFrame: 3392
      },
      {
        text: 'I',
        fromFrame: 3396,
        toFrame: 3403
      },
      {
        text: 'thought',
        fromFrame: 3403,
        toFrame: 3407
      },
      {
        text: 'I',
        fromFrame: 3407,
        toFrame: 3417
      },
      {
        text: 'thought',
        fromFrame: 3417,
        toFrame: 3423
      }
    ],
    fromFrame: 3369,
    toFrame: 3423,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'they will not bring it up. But, anyways',
    tokens: [
      {
        text: 'they',
        fromFrame: 3423,
        toFrame: 3426
      },
      {
        text: 'will',
        fromFrame: 3426,
        toFrame: 3430
      },
      {
        text: 'not',
        fromFrame: 3430,
        toFrame: 3434
      },
      {
        text: 'bring',
        fromFrame: 3434,
        toFrame: 3440
      },
      {
        text: 'it',
        fromFrame: 3440,
        toFrame: 3446
      },
      {
        text: 'up.',
        fromFrame: 3446,
        toFrame: 3458
      },
      {
        text: 'But,',
        fromFrame: 3473,
        toFrame: 3478
      },
      {
        text: 'anyways',
        fromFrame: 3478,
        toFrame: 3490
      }
    ],
    fromFrame: 3423,
    toFrame: 3513,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'okay. No. I cofounded this when I was',
    tokens: [
      {
        text: 'okay.',
        fromFrame: 3513,
        toFrame: 3525
      },
      {
        text: 'No.',
        fromFrame: 3530,
        toFrame: 3536
      },
      {
        text: 'I',
        fromFrame: 3536,
        toFrame: 3544
      },
      {
        text: 'cofounded',
        fromFrame: 3544,
        toFrame: 3556
      },
      {
        text: 'this',
        fromFrame: 3561,
        toFrame: 3565
      },
      {
        text: 'when',
        fromFrame: 3565,
        toFrame: 3570
      },
      {
        text: 'I',
        fromFrame: 3570,
        toFrame: 3572
      },
      {
        text: 'was',
        fromFrame: 3572,
        toFrame: 3578
      }
    ],
    fromFrame: 3513,
    toFrame: 3578,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'actually testing on the hackathon thing. So I',
    tokens: [
      {
        text: 'actually',
        fromFrame: 3578,
        toFrame: 3586
      },
      {
        text: 'testing',
        fromFrame: 3586,
        toFrame: 3595
      },
      {
        text: 'on',
        fromFrame: 3595,
        toFrame: 3603
      },
      {
        text: 'the',
        fromFrame: 3603,
        toFrame: 3609
      },
      {
        text: 'hackathon',
        fromFrame: 3609,
        toFrame: 3621
      },
      {
        text: 'thing.',
        fromFrame: 3624,
        toFrame: 3636
      },
      {
        text: 'So',
        fromFrame: 3647,
        toFrame: 3650
      },
      {
        text: 'I',
        fromFrame: 3650,
        toFrame: 3653
      }
    ],
    fromFrame: 3578,
    toFrame: 3653,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'thought it was a reason. We worked on',
    tokens: [
      {
        text: 'thought',
        fromFrame: 3653,
        toFrame: 3665
      },
      {
        text: 'it',
        fromFrame: 3666,
        toFrame: 3668
      },
      {
        text: 'was',
        fromFrame: 3668,
        toFrame: 3672
      },
      {
        text: 'a',
        fromFrame: 3672,
        toFrame: 3678
      },
      {
        text: 'reason.',
        fromFrame: 3678,
        toFrame: 3687
      },
      {
        text: 'We',
        fromFrame: 3891,
        toFrame: 3895
      },
      {
        text: 'worked',
        fromFrame: 3895,
        toFrame: 3902
      },
      {
        text: 'on',
        fromFrame: 3902,
        toFrame: 3906
      }
    ],
    fromFrame: 3653,
    toFrame: 3906,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'on the new features. Not bug. Yeah. Cool.',
    tokens: [
      {
        text: 'on',
        fromFrame: 3906,
        toFrame: 3910
      },
      {
        text: 'the',
        fromFrame: 3910,
        toFrame: 3914
      },
      {
        text: 'new',
        fromFrame: 3914,
        toFrame: 3920
      },
      {
        text: 'features.',
        fromFrame: 3920,
        toFrame: 3932
      },
      {
        text: 'Not',
        fromFrame: 3971,
        toFrame: 3977
      },
      {
        text: 'bug.',
        fromFrame: 3977,
        toFrame: 3989
      },
      {
        text: 'Yeah.',
        fromFrame: 4085,
        toFrame: 4097
      },
      {
        text: 'Cool.',
        fromFrame: 4139,
        toFrame: 4151
      }
    ],
    fromFrame: 3906,
    toFrame: 4176,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Let's I have a call at 3:30. I",
    tokens: [
      {
        text: "Let's",
        fromFrame: 4176,
        toFrame: 4187
      },
      {
        text: 'I',
        fromFrame: 4210,
        toFrame: 4216
      },
      {
        text: 'have',
        fromFrame: 4216,
        toFrame: 4222
      },
      {
        text: 'a',
        fromFrame: 4222,
        toFrame: 4224
      },
      {
        text: 'call',
        fromFrame: 4224,
        toFrame: 4229
      },
      {
        text: 'at',
        fromFrame: 4229,
        toFrame: 4235
      },
      {
        text: '3:30.',
        fromFrame: 4235,
        toFrame: 4247
      },
      {
        text: 'I',
        fromFrame: 4252,
        toFrame: 4256
      }
    ],
    fromFrame: 4176,
    toFrame: 4256,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "don't know how much we'll get through, but",
    tokens: [
      {
        text: "don't",
        fromFrame: 4256,
        toFrame: 4264
      },
      {
        text: 'know',
        fromFrame: 4264,
        toFrame: 4268
      },
      {
        text: 'how',
        fromFrame: 4268,
        toFrame: 4273
      },
      {
        text: 'much',
        fromFrame: 4273,
        toFrame: 4285
      },
      {
        text: "we'll",
        fromFrame: 4289,
        toFrame: 4296
      },
      {
        text: 'get',
        fromFrame: 4296,
        toFrame: 4304
      },
      {
        text: 'through,',
        fromFrame: 4304,
        toFrame: 4312
      },
      {
        text: 'but',
        fromFrame: 4312,
        toFrame: 4318
      }
    ],
    fromFrame: 4256,
    toFrame: 4377,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'There is so much to do. There are',
    tokens: [
      {
        text: 'There',
        fromFrame: 4377,
        toFrame: 4387
      },
      {
        text: 'is',
        fromFrame: 4387,
        toFrame: 4391
      },
      {
        text: 'so',
        fromFrame: 4391,
        toFrame: 4399
      },
      {
        text: 'much',
        fromFrame: 4399,
        toFrame: 4404
      },
      {
        text: 'to',
        fromFrame: 4404,
        toFrame: 4408
      },
      {
        text: 'do.',
        fromFrame: 4408,
        toFrame: 4420
      },
      {
        text: 'There',
        fromFrame: 4470,
        toFrame: 4475
      },
      {
        text: 'are',
        fromFrame: 4475,
        toFrame: 4479
      }
    ],
    fromFrame: 4377,
    toFrame: 4479,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'so many tickets. All has been lower for',
    tokens: [
      {
        text: 'so',
        fromFrame: 4479,
        toFrame: 4483
      },
      {
        text: 'many',
        fromFrame: 4483,
        toFrame: 4491
      },
      {
        text: 'tickets.',
        fromFrame: 4491,
        toFrame: 4503
      },
      {
        text: 'All',
        fromFrame: 4512,
        toFrame: 4518
      },
      {
        text: 'has',
        fromFrame: 4518,
        toFrame: 4523
      },
      {
        text: 'been',
        fromFrame: 4523,
        toFrame: 4531
      },
      {
        text: 'lower',
        fromFrame: 4531,
        toFrame: 4543
      },
      {
        text: 'for',
        fromFrame: 4562,
        toFrame: 4568
      }
    ],
    fromFrame: 4479,
    toFrame: 4568,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'almost 3 weeks and not looked at anything.',
    tokens: [
      {
        text: 'almost',
        fromFrame: 4568,
        toFrame: 4575
      },
      {
        text: '3',
        fromFrame: 4575,
        toFrame: 4579
      },
      {
        text: 'weeks',
        fromFrame: 4579,
        toFrame: 4585
      },
      {
        text: 'and',
        fromFrame: 4585,
        toFrame: 4587
      },
      {
        text: 'not',
        fromFrame: 4587,
        toFrame: 4593
      },
      {
        text: 'looked',
        fromFrame: 4593,
        toFrame: 4598
      },
      {
        text: 'at',
        fromFrame: 4598,
        toFrame: 4600
      },
      {
        text: 'anything.',
        fromFrame: 4600,
        toFrame: 4612
      }
    ],
    fromFrame: 4568,
    toFrame: 4637,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Yeah. I'll share or you'll share. I'll share",
    tokens: [
      {
        text: 'Yeah.',
        fromFrame: 4637,
        toFrame: 4649
      },
      {
        text: "I'll",
        fromFrame: 4707,
        toFrame: 4718
      },
      {
        text: 'share',
        fromFrame: 4718,
        toFrame: 4728
      },
      {
        text: 'or',
        fromFrame: 4728,
        toFrame: 4734
      },
      {
        text: "you'll",
        fromFrame: 4734,
        toFrame: 4741
      },
      {
        text: 'share.',
        fromFrame: 4741,
        toFrame: 4749
      },
      {
        text: "I'll",
        fromFrame: 4749,
        toFrame: 4757
      },
      {
        text: 'share',
        fromFrame: 4757,
        toFrame: 4764
      }
    ],
    fromFrame: 4637,
    toFrame: 4764,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'screen. Where is share? And share button No.',
    tokens: [
      {
        text: 'screen.',
        fromFrame: 4764,
        toFrame: 4772
      },
      {
        text: 'Where',
        fromFrame: 4772,
        toFrame: 4784
      },
      {
        text: 'is',
        fromFrame: 4857,
        toFrame: 4862
      },
      {
        text: 'share?',
        fromFrame: 4862,
        toFrame: 4874
      },
      {
        text: 'And',
        fromFrame: 4916,
        toFrame: 4924
      },
      {
        text: 'share',
        fromFrame: 4924,
        toFrame: 4930
      },
      {
        text: 'button',
        fromFrame: 4930,
        toFrame: 4942
      },
      {
        text: 'No.',
        fromFrame: 5060,
        toFrame: 5064
      }
    ],
    fromFrame: 4764,
    toFrame: 5064,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'It shows. Left, miss. Upload screen. Not this.',
    tokens: [
      {
        text: 'It',
        fromFrame: 5064,
        toFrame: 5067
      },
      {
        text: 'shows.',
        fromFrame: 5067,
        toFrame: 5079
      },
      {
        text: 'Left,',
        fromFrame: 5106,
        toFrame: 5112
      },
      {
        text: 'miss.',
        fromFrame: 5112,
        toFrame: 5119
      },
      {
        text: 'Upload',
        fromFrame: 5119,
        toFrame: 5129
      },
      {
        text: 'screen.',
        fromFrame: 5129,
        toFrame: 5141
      },
      {
        text: 'Not',
        fromFrame: 5284,
        toFrame: 5292
      },
      {
        text: 'this.',
        fromFrame: 5292,
        toFrame: 5304
      }
    ],
    fromFrame: 5064,
    toFrame: 5309,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Wait. How do I go back? What? Is',
    tokens: [
      {
        text: 'Wait.',
        fromFrame: 5309,
        toFrame: 5321
      },
      {
        text: 'How',
        fromFrame: 5349,
        toFrame: 5355
      },
      {
        text: 'do',
        fromFrame: 5355,
        toFrame: 5359
      },
      {
        text: 'I',
        fromFrame: 5359,
        toFrame: 5371
      },
      {
        text: 'go',
        fromFrame: 5474,
        toFrame: 5486
      },
      {
        text: 'back?',
        fromFrame: 5595,
        toFrame: 5607
      },
      {
        text: 'What?',
        fromFrame: 5660,
        toFrame: 5672
      },
      {
        text: 'Is',
        fromFrame: 5691,
        toFrame: 5697
      }
    ],
    fromFrame: 5309,
    toFrame: 5697,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'this the bug list? Where is this thing?',
    tokens: [
      {
        text: 'this',
        fromFrame: 5697,
        toFrame: 5702
      },
      {
        text: 'the',
        fromFrame: 5702,
        toFrame: 5704
      },
      {
        text: 'bug',
        fromFrame: 5704,
        toFrame: 5712
      },
      {
        text: 'list?',
        fromFrame: 5712,
        toFrame: 5724
      },
      {
        text: 'Where',
        fromFrame: 5808,
        toFrame: 5819
      },
      {
        text: 'is',
        fromFrame: 5819,
        toFrame: 5821
      },
      {
        text: 'this',
        fromFrame: 5821,
        toFrame: 5827
      },
      {
        text: 'thing?',
        fromFrame: 5827,
        toFrame: 5839
      }
    ],
    fromFrame: 5697,
    toFrame: 5848,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'One sec. Yeah. Yeah. This is the bot',
    tokens: [
      {
        text: 'One',
        fromFrame: 5848,
        toFrame: 5854
      },
      {
        text: 'sec.',
        fromFrame: 5854,
        toFrame: 5866
      },
      {
        text: 'Yeah.',
        fromFrame: 5982,
        toFrame: 5990
      },
      {
        text: 'Yeah.',
        fromFrame: 5990,
        toFrame: 5996
      },
      {
        text: 'This',
        fromFrame: 5996,
        toFrame: 6000
      },
      {
        text: 'is',
        fromFrame: 6000,
        toFrame: 6004
      },
      {
        text: 'the',
        fromFrame: 6004,
        toFrame: 6007
      },
      {
        text: 'bot',
        fromFrame: 6007,
        toFrame: 6011
      }
    ],
    fromFrame: 5848,
    toFrame: 6011,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "list. We are in Sprint 36. Okay. We'll",
    tokens: [
      {
        text: 'list.',
        fromFrame: 6011,
        toFrame: 6023
      },
      {
        text: 'We',
        fromFrame: 6084,
        toFrame: 6088
      },
      {
        text: 'are',
        fromFrame: 6088,
        toFrame: 6092
      },
      {
        text: 'in',
        fromFrame: 6092,
        toFrame: 6098
      },
      {
        text: 'Sprint',
        fromFrame: 6098,
        toFrame: 6110
      },
      {
        text: '36.',
        fromFrame: 6130,
        toFrame: 6142
      },
      {
        text: 'Okay.',
        fromFrame: 6150,
        toFrame: 6162
      },
      {
        text: "We'll",
        fromFrame: 6276,
        toFrame: 6282
      }
    ],
    fromFrame: 6011,
    toFrame: 6282,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'go there. Yeah? Or should we just look',
    tokens: [
      {
        text: 'go',
        fromFrame: 6282,
        toFrame: 6290
      },
      {
        text: 'there.',
        fromFrame: 6290,
        toFrame: 6301
      },
      {
        text: 'Yeah?',
        fromFrame: 6301,
        toFrame: 6313
      },
      {
        text: 'Or',
        fromFrame: 6336,
        toFrame: 6348
      },
      {
        text: 'should',
        fromFrame: 6348,
        toFrame: 6354
      },
      {
        text: 'we',
        fromFrame: 6354,
        toFrame: 6357
      },
      {
        text: 'just',
        fromFrame: 6357,
        toFrame: 6363
      },
      {
        text: 'look',
        fromFrame: 6363,
        toFrame: 6369
      }
    ],
    fromFrame: 6282,
    toFrame: 6369,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'at the list? Oh, the device then we',
    tokens: [
      {
        text: 'at',
        fromFrame: 6369,
        toFrame: 6373
      },
      {
        text: 'the',
        fromFrame: 6373,
        toFrame: 6377
      },
      {
        text: 'list?',
        fromFrame: 6377,
        toFrame: 6389
      },
      {
        text: 'Oh,',
        fromFrame: 6401,
        toFrame: 6413
      },
      {
        text: 'the',
        fromFrame: 6425,
        toFrame: 6437
      },
      {
        text: 'device',
        fromFrame: 6449,
        toFrame: 6461
      },
      {
        text: 'then',
        fromFrame: 6473,
        toFrame: 6477
      },
      {
        text: 'we',
        fromFrame: 6477,
        toFrame: 6489
      }
    ],
    fromFrame: 6369,
    toFrame: 6498,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'go. Yeah. We can. Gold high priority first.',
    tokens: [
      {
        text: 'go.',
        fromFrame: 6498,
        toFrame: 6510
      },
      {
        text: 'Yeah.',
        fromFrame: 6519,
        toFrame: 6531
      },
      {
        text: 'We',
        fromFrame: 6549,
        toFrame: 6557
      },
      {
        text: 'can.',
        fromFrame: 6557,
        toFrame: 6569
      },
      {
        text: 'Gold',
        fromFrame: 6603,
        toFrame: 6611
      },
      {
        text: 'high',
        fromFrame: 6611,
        toFrame: 6617
      },
      {
        text: 'priority',
        fromFrame: 6617,
        toFrame: 6628
      },
      {
        text: 'first.',
        fromFrame: 6628,
        toFrame: 6640
      }
    ],
    fromFrame: 6498,
    toFrame: 6725,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Okay. Filters high tickets. Oh, nice. Mostly. Okay.',
    tokens: [
      {
        text: 'Okay.',
        fromFrame: 6725,
        toFrame: 6727
      },
      {
        text: 'Filters',
        fromFrame: 6729,
        toFrame: 6737
      },
      {
        text: 'high',
        fromFrame: 6737,
        toFrame: 6742
      },
      {
        text: 'tickets.',
        fromFrame: 6742,
        toFrame: 6754
      },
      {
        text: 'Oh,',
        fromFrame: 6854,
        toFrame: 6865
      },
      {
        text: 'nice.',
        fromFrame: 6865,
        toFrame: 6877
      },
      {
        text: 'Mostly.',
        fromFrame: 7003,
        toFrame: 7015
      },
      {
        text: 'Okay.',
        fromFrame: 7026,
        toFrame: 7038
      }
    ],
    fromFrame: 6725,
    toFrame: 7046,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Uh-huh. This will Actually, you only reported? Yeah.',
    tokens: [
      {
        text: 'Uh-huh.',
        fromFrame: 7046,
        toFrame: 7058
      },
      {
        text: 'This',
        fromFrame: 7201,
        toFrame: 7209
      },
      {
        text: 'will',
        fromFrame: 7209,
        toFrame: 7219
      },
      {
        text: 'Actually,',
        fromFrame: 7221,
        toFrame: 7228
      },
      {
        text: 'you',
        fromFrame: 7228,
        toFrame: 7234
      },
      {
        text: 'only',
        fromFrame: 7234,
        toFrame: 7240
      },
      {
        text: 'reported?',
        fromFrame: 7240,
        toFrame: 7252
      },
      {
        text: 'Yeah.',
        fromFrame: 7307,
        toFrame: 7319
      }
    ],
    fromFrame: 7046,
    toFrame: 7425,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Forgot. Can you pick up my call? Pick',
    tokens: [
      {
        text: 'Forgot.',
        fromFrame: 7425,
        toFrame: 7437
      },
      {
        text: 'Can',
        fromFrame: 7457,
        toFrame: 7465
      },
      {
        text: 'you',
        fromFrame: 7465,
        toFrame: 7469
      },
      {
        text: 'pick',
        fromFrame: 7469,
        toFrame: 7473
      },
      {
        text: 'up',
        fromFrame: 7473,
        toFrame: 7476
      },
      {
        text: 'my',
        fromFrame: 7476,
        toFrame: 7480
      },
      {
        text: 'call?',
        fromFrame: 7480,
        toFrame: 7486
      },
      {
        text: 'Pick',
        fromFrame: 7486,
        toFrame: 7492
      }
    ],
    fromFrame: 7425,
    toFrame: 7492,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'up will also talk. Wait. Once let me',
    tokens: [
      {
        text: 'up',
        fromFrame: 7492,
        toFrame: 7494
      },
      {
        text: 'will',
        fromFrame: 7494,
        toFrame: 7498
      },
      {
        text: 'also',
        fromFrame: 7498,
        toFrame: 7505
      },
      {
        text: 'talk.',
        fromFrame: 7505,
        toFrame: 7517
      },
      {
        text: 'Wait.',
        fromFrame: 7571,
        toFrame: 7583
      },
      {
        text: 'Once',
        fromFrame: 7620,
        toFrame: 7630
      },
      {
        text: 'let',
        fromFrame: 7634,
        toFrame: 7642
      },
      {
        text: 'me',
        fromFrame: 7642,
        toFrame: 7653
      }
    ],
    fromFrame: 7492,
    toFrame: 7653,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "tell him I'm in a call. Okay. Fine.",
    tokens: [
      {
        text: 'tell',
        fromFrame: 7653,
        toFrame: 7661
      },
      {
        text: 'him',
        fromFrame: 7661,
        toFrame: 7670
      },
      {
        text: "I'm",
        fromFrame: 7670,
        toFrame: 7678
      },
      {
        text: 'in',
        fromFrame: 7678,
        toFrame: 7684
      },
      {
        text: 'a',
        fromFrame: 7684,
        toFrame: 7690
      },
      {
        text: 'call.',
        fromFrame: 7690,
        toFrame: 7702
      },
      {
        text: 'Okay.',
        fromFrame: 7943,
        toFrame: 7955
      },
      {
        text: 'Fine.',
        fromFrame: 8256,
        toFrame: 8268
      }
    ],
    fromFrame: 7653,
    toFrame: 8276,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Can you see my screen? Yes. Yes. We',
    tokens: [
      {
        text: 'Can',
        fromFrame: 8276,
        toFrame: 8281
      },
      {
        text: 'you',
        fromFrame: 8281,
        toFrame: 8285
      },
      {
        text: 'see',
        fromFrame: 8285,
        toFrame: 8291
      },
      {
        text: 'my',
        fromFrame: 8291,
        toFrame: 8303
      },
      {
        text: 'screen?',
        fromFrame: 8329,
        toFrame: 8341
      },
      {
        text: 'Yes.',
        fromFrame: 8366,
        toFrame: 8378
      },
      {
        text: 'Yes.',
        fromFrame: 8381,
        toFrame: 8393
      },
      {
        text: 'We',
        fromFrame: 8485,
        toFrame: 8489
      }
    ],
    fromFrame: 8276,
    toFrame: 8489,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'will revisit this on prepared p a v',
    tokens: [
      {
        text: 'will',
        fromFrame: 8489,
        toFrame: 8495
      },
      {
        text: 'revisit',
        fromFrame: 8495,
        toFrame: 8504
      },
      {
        text: 'this',
        fromFrame: 8504,
        toFrame: 8510
      },
      {
        text: 'on',
        fromFrame: 8510,
        toFrame: 8512
      },
      {
        text: 'prepared',
        fromFrame: 8512,
        toFrame: 8524
      },
      {
        text: 'p',
        fromFrame: 8530,
        toFrame: 8534
      },
      {
        text: 'a',
        fromFrame: 8534,
        toFrame: 8536
      },
      {
        text: 'v',
        fromFrame: 8536,
        toFrame: 8538
      }
    ],
    fromFrame: 8489,
    toFrame: 8538,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "one, p one c results. Yeah. We'll reuse",
    tokens: [
      {
        text: 'one,',
        fromFrame: 8538,
        toFrame: 8544
      },
      {
        text: 'p',
        fromFrame: 8544,
        toFrame: 8548
      },
      {
        text: 'one',
        fromFrame: 8548,
        toFrame: 8552
      },
      {
        text: 'c',
        fromFrame: 8552,
        toFrame: 8555
      },
      {
        text: 'results.',
        fromFrame: 8555,
        toFrame: 8567
      },
      {
        text: 'Yeah.',
        fromFrame: 8580,
        toFrame: 8586
      },
      {
        text: "We'll",
        fromFrame: 8586,
        toFrame: 8590
      },
      {
        text: 'reuse',
        fromFrame: 8590,
        toFrame: 8598
      }
    ],
    fromFrame: 8538,
    toFrame: 8598,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'it next next sprint. So we should move',
    tokens: [
      {
        text: 'it',
        fromFrame: 8598,
        toFrame: 8601
      },
      {
        text: 'next',
        fromFrame: 8601,
        toFrame: 8609
      },
      {
        text: 'next',
        fromFrame: 8609,
        toFrame: 8619
      },
      {
        text: 'sprint.',
        fromFrame: 8619,
        toFrame: 8631
      },
      {
        text: 'So',
        fromFrame: 8655,
        toFrame: 8659
      },
      {
        text: 'we',
        fromFrame: 8659,
        toFrame: 8663
      },
      {
        text: 'should',
        fromFrame: 8663,
        toFrame: 8669
      },
      {
        text: 'move',
        fromFrame: 8669,
        toFrame: 8674
      }
    ],
    fromFrame: 8598,
    toFrame: 8674,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "this out now? Yeah. Yeah. Next sprint. I'll",
    tokens: [
      {
        text: 'this',
        fromFrame: 8674,
        toFrame: 8680
      },
      {
        text: 'out',
        fromFrame: 8680,
        toFrame: 8688
      },
      {
        text: 'now?',
        fromFrame: 8688,
        toFrame: 8700
      },
      {
        text: 'Yeah.',
        fromFrame: 8715,
        toFrame: 8719
      },
      {
        text: 'Yeah.',
        fromFrame: 8719,
        toFrame: 8731
      },
      {
        text: 'Next',
        fromFrame: 8744,
        toFrame: 8751
      },
      {
        text: 'sprint.',
        fromFrame: 8751,
        toFrame: 8759
      },
      {
        text: "I'll",
        fromFrame: 8759,
        toFrame: 8763
      }
    ],
    fromFrame: 8674,
    toFrame: 8763,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'move to the next sprint. 38. Oh, 37.',
    tokens: [
      {
        text: 'move',
        fromFrame: 8763,
        toFrame: 8767
      },
      {
        text: 'to',
        fromFrame: 8767,
        toFrame: 8770
      },
      {
        text: 'the',
        fromFrame: 8770,
        toFrame: 8772
      },
      {
        text: 'next',
        fromFrame: 8772,
        toFrame: 8780
      },
      {
        text: 'sprint.',
        fromFrame: 8780,
        toFrame: 8792
      },
      {
        text: '38.',
        fromFrame: 8855,
        toFrame: 8867
      },
      {
        text: 'Oh,',
        fromFrame: 8889,
        toFrame: 8891
      },
      {
        text: '37.',
        fromFrame: 8891,
        toFrame: 8903
      }
    ],
    fromFrame: 8763,
    toFrame: 8916,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: '37. 37 is the coming sprint. Yeah. Next',
    tokens: [
      {
        text: '37.',
        fromFrame: 8916,
        toFrame: 8928
      },
      {
        text: '37',
        fromFrame: 8958,
        toFrame: 8970
      },
      {
        text: 'is',
        fromFrame: 8971,
        toFrame: 8977
      },
      {
        text: 'the',
        fromFrame: 8977,
        toFrame: 8979
      },
      {
        text: 'coming',
        fromFrame: 8979,
        toFrame: 8989
      },
      {
        text: 'sprint.',
        fromFrame: 8989,
        toFrame: 9001
      },
      {
        text: 'Yeah.',
        fromFrame: 9019,
        toFrame: 9029
      },
      {
        text: 'Next',
        fromFrame: 9029,
        toFrame: 9037
      }
    ],
    fromFrame: 8916,
    toFrame: 9037,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'sprint. Okay. I need this is still not',
    tokens: [
      {
        text: 'sprint.',
        fromFrame: 9037,
        toFrame: 9049
      },
      {
        text: 'Okay.',
        fromFrame: 9060,
        toFrame: 9072
      },
      {
        text: 'I',
        fromFrame: 9146,
        toFrame: 9150
      },
      {
        text: 'need',
        fromFrame: 9150,
        toFrame: 9156
      },
      {
        text: 'this',
        fromFrame: 9158,
        toFrame: 9163
      },
      {
        text: 'is',
        fromFrame: 9163,
        toFrame: 9167
      },
      {
        text: 'still',
        fromFrame: 9167,
        toFrame: 9175
      },
      {
        text: 'not',
        fromFrame: 9175,
        toFrame: 9187
      }
    ],
    fromFrame: 9037,
    toFrame: 9204,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "resolved. Yeah. Oh, it's me. I think we",
    tokens: [
      {
        text: 'resolved.',
        fromFrame: 9204,
        toFrame: 9215
      },
      {
        text: 'Yeah.',
        fromFrame: 9215,
        toFrame: 9227
      },
      {
        text: 'Oh,',
        fromFrame: 9247,
        toFrame: 9249
      },
      {
        text: "it's",
        fromFrame: 9249,
        toFrame: 9253
      },
      {
        text: 'me.',
        fromFrame: 9253,
        toFrame: 9261
      },
      {
        text: 'I',
        fromFrame: 9261,
        toFrame: 9265
      },
      {
        text: 'think',
        fromFrame: 9265,
        toFrame: 9270
      },
      {
        text: 'we',
        fromFrame: 9270,
        toFrame: 9282
      }
    ],
    fromFrame: 9204,
    toFrame: 9322,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'discussion. Yeah. I think hold on to this.',
    tokens: [
      {
        text: 'discussion.',
        fromFrame: 9322,
        toFrame: 9334
      },
      {
        text: 'Yeah.',
        fromFrame: 9426,
        toFrame: 9438
      },
      {
        text: 'I',
        fromFrame: 9445,
        toFrame: 9449
      },
      {
        text: 'think',
        fromFrame: 9449,
        toFrame: 9457
      },
      {
        text: 'hold',
        fromFrame: 9457,
        toFrame: 9462
      },
      {
        text: 'on',
        fromFrame: 9462,
        toFrame: 9468
      },
      {
        text: 'to',
        fromFrame: 9468,
        toFrame: 9472
      },
      {
        text: 'this.',
        fromFrame: 9472,
        toFrame: 9484
      }
    ],
    fromFrame: 9322,
    toFrame: 9485,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Let's see how the New thing. Scroll works",
    tokens: [
      {
        text: "Let's",
        fromFrame: 9485,
        toFrame: 9493
      },
      {
        text: 'see',
        fromFrame: 9493,
        toFrame: 9499
      },
      {
        text: 'how',
        fromFrame: 9499,
        toFrame: 9505
      },
      {
        text: 'the',
        fromFrame: 9505,
        toFrame: 9517
      },
      {
        text: 'New',
        fromFrame: 9531,
        toFrame: 9537
      },
      {
        text: 'thing.',
        fromFrame: 9537,
        toFrame: 9547
      },
      {
        text: 'Scroll',
        fromFrame: 9549,
        toFrame: 9558
      },
      {
        text: 'works',
        fromFrame: 9558,
        toFrame: 9564
      }
    ],
    fromFrame: 9485,
    toFrame: 9564,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'in the new one. Yeah. Okay. We should',
    tokens: [
      {
        text: 'in',
        fromFrame: 9564,
        toFrame: 9568
      },
      {
        text: 'the',
        fromFrame: 9568,
        toFrame: 9574
      },
      {
        text: 'new',
        fromFrame: 9574,
        toFrame: 9578
      },
      {
        text: 'one.',
        fromFrame: 9578,
        toFrame: 9583
      },
      {
        text: 'Yeah.',
        fromFrame: 9583,
        toFrame: 9594
      },
      {
        text: 'Okay.',
        fromFrame: 9730,
        toFrame: 9742
      },
      {
        text: 'We',
        fromFrame: 9751,
        toFrame: 9759
      },
      {
        text: 'should',
        fromFrame: 9759,
        toFrame: 9765
      }
    ],
    fromFrame: 9564,
    toFrame: 9765,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'write. When are those changes expected? Expected? December',
    tokens: [
      {
        text: 'write.',
        fromFrame: 9765,
        toFrame: 9777
      },
      {
        text: 'When',
        fromFrame: 9841,
        toFrame: 9849
      },
      {
        text: 'are',
        fromFrame: 9849,
        toFrame: 9855
      },
      {
        text: 'those',
        fromFrame: 9855,
        toFrame: 9861
      },
      {
        text: 'changes',
        fromFrame: 9861,
        toFrame: 9868
      },
      {
        text: 'expected?',
        fromFrame: 9868,
        toFrame: 9880
      },
      {
        text: 'Expected?',
        fromFrame: 9905,
        toFrame: 9917
      },
      {
        text: 'December',
        fromFrame: 9953,
        toFrame: 9965
      }
    ],
    fromFrame: 9765,
    toFrame: 9967,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: '2nd. And this is what clicked. The motion',
    tokens: [
      {
        text: '2nd.',
        fromFrame: 9967,
        toFrame: 9979
      },
      {
        text: 'And',
        fromFrame: 10065,
        toFrame: 10077
      },
      {
        text: 'this',
        fromFrame: 10168,
        toFrame: 10172
      },
      {
        text: 'is',
        fromFrame: 10172,
        toFrame: 10176
      },
      {
        text: 'what',
        fromFrame: 10176,
        toFrame: 10182
      },
      {
        text: 'clicked.',
        fromFrame: 10182,
        toFrame: 10194
      },
      {
        text: 'The',
        fromFrame: 10214,
        toFrame: 10220
      },
      {
        text: 'motion',
        fromFrame: 10220,
        toFrame: 10230
      }
    ],
    fromFrame: 9967,
    toFrame: 10230,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'change. It is all recorded. Okay. Okay. I',
    tokens: [
      {
        text: 'change.',
        fromFrame: 10230,
        toFrame: 10242
      },
      {
        text: 'It',
        fromFrame: 10262,
        toFrame: 10264
      },
      {
        text: 'is',
        fromFrame: 10264,
        toFrame: 10269
      },
      {
        text: 'all',
        fromFrame: 10269,
        toFrame: 10281
      },
      {
        text: 'recorded.',
        fromFrame: 10362,
        toFrame: 10374
      },
      {
        text: 'Okay.',
        fromFrame: 10810,
        toFrame: 10822
      },
      {
        text: 'Okay.',
        fromFrame: 10980,
        toFrame: 10992
      },
      {
        text: 'I',
        fromFrame: 11094,
        toFrame: 11095
      }
    ],
    fromFrame: 10230,
    toFrame: 11095,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "don't know what is this. I'll check with",
    tokens: [
      {
        text: "don't",
        fromFrame: 11095,
        toFrame: 11103
      },
      {
        text: 'know',
        fromFrame: 11103,
        toFrame: 11107
      },
      {
        text: 'what',
        fromFrame: 11107,
        toFrame: 11111
      },
      {
        text: 'is',
        fromFrame: 11111,
        toFrame: 11117
      },
      {
        text: 'this.',
        fromFrame: 11117,
        toFrame: 11129
      },
      {
        text: "I'll",
        fromFrame: 11143,
        toFrame: 11151
      },
      {
        text: 'check',
        fromFrame: 11151,
        toFrame: 11159
      },
      {
        text: 'with',
        fromFrame: 11159,
        toFrame: 11165
      }
    ],
    fromFrame: 11095,
    toFrame: 11165,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Pratyush. Just move to the next sprint. This',
    tokens: [
      {
        text: 'Pratyush.',
        fromFrame: 11165,
        toFrame: 11177
      },
      {
        text: 'Just',
        fromFrame: 11190,
        toFrame: 11197
      },
      {
        text: 'move',
        fromFrame: 11197,
        toFrame: 11205
      },
      {
        text: 'to',
        fromFrame: 11205,
        toFrame: 11209
      },
      {
        text: 'the',
        fromFrame: 11209,
        toFrame: 11213
      },
      {
        text: 'next',
        fromFrame: 11213,
        toFrame: 11220
      },
      {
        text: 'sprint.',
        fromFrame: 11220,
        toFrame: 11232
      },
      {
        text: 'This',
        fromFrame: 11387,
        toFrame: 11393
      }
    ],
    fromFrame: 11165,
    toFrame: 11393,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'got pushed to this card, spin over. Everything',
    tokens: [
      {
        text: 'got',
        fromFrame: 11393,
        toFrame: 11401
      },
      {
        text: 'pushed',
        fromFrame: 11401,
        toFrame: 11410
      },
      {
        text: 'to',
        fromFrame: 11410,
        toFrame: 11416
      },
      {
        text: 'this',
        fromFrame: 11416,
        toFrame: 11420
      },
      {
        text: 'card,',
        fromFrame: 11420,
        toFrame: 11426
      },
      {
        text: 'spin',
        fromFrame: 11426,
        toFrame: 11435
      },
      {
        text: 'over.',
        fromFrame: 11435,
        toFrame: 11447
      },
      {
        text: 'Everything',
        fromFrame: 11537,
        toFrame: 11549
      }
    ],
    fromFrame: 11393,
    toFrame: 11574,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'going to spill over. Scroll up. Auto generate',
    tokens: [
      {
        text: 'going',
        fromFrame: 11574,
        toFrame: 11577
      },
      {
        text: 'to',
        fromFrame: 11577,
        toFrame: 11583
      },
      {
        text: 'spill',
        fromFrame: 11583,
        toFrame: 11589
      },
      {
        text: 'over.',
        fromFrame: 11589,
        toFrame: 11601
      },
      {
        text: 'Scroll',
        fromFrame: 11691,
        toFrame: 11700
      },
      {
        text: 'up.',
        fromFrame: 11700,
        toFrame: 11712
      },
      {
        text: 'Auto',
        fromFrame: 11731,
        toFrame: 11741
      },
      {
        text: 'generate',
        fromFrame: 11741,
        toFrame: 11750
      }
    ],
    fromFrame: 11574,
    toFrame: 11750,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "multi model as it's failing a lot. It's",
    tokens: [
      {
        text: 'multi',
        fromFrame: 11750,
        toFrame: 11758
      },
      {
        text: 'model',
        fromFrame: 11758,
        toFrame: 11766
      },
      {
        text: 'as',
        fromFrame: 11766,
        toFrame: 11769
      },
      {
        text: "it's",
        fromFrame: 11769,
        toFrame: 11775
      },
      {
        text: 'failing',
        fromFrame: 11775,
        toFrame: 11783
      },
      {
        text: 'a',
        fromFrame: 11783,
        toFrame: 11787
      },
      {
        text: 'lot.',
        fromFrame: 11787,
        toFrame: 11799
      },
      {
        text: "It's",
        fromFrame: 11910,
        toFrame: 11913
      }
    ],
    fromFrame: 11750,
    toFrame: 11913,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'in progress, Harid. Okay. Misinterpretation of space causing',
    tokens: [
      {
        text: 'in',
        fromFrame: 11913,
        toFrame: 11919
      },
      {
        text: 'progress,',
        fromFrame: 11919,
        toFrame: 11931
      },
      {
        text: 'Harid.',
        fromFrame: 11931,
        toFrame: 11943
      },
      {
        text: 'Okay.',
        fromFrame: 11971,
        toFrame: 11983
      },
      {
        text: 'Misinterpretation',
        fromFrame: 12057,
        toFrame: 12069
      },
      {
        text: 'of',
        fromFrame: 12107,
        toFrame: 12115
      },
      {
        text: 'space',
        fromFrame: 12115,
        toFrame: 12126
      },
      {
        text: 'causing',
        fromFrame: 12126,
        toFrame: 12138
      }
    ],
    fromFrame: 11913,
    toFrame: 12140,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'issue in location blah blah blah. Okay. I',
    tokens: [
      {
        text: 'issue',
        fromFrame: 12140,
        toFrame: 12151
      },
      {
        text: 'in',
        fromFrame: 12151,
        toFrame: 12157
      },
      {
        text: 'location',
        fromFrame: 12157,
        toFrame: 12169
      },
      {
        text: 'blah',
        fromFrame: 12176,
        toFrame: 12182
      },
      {
        text: 'blah',
        fromFrame: 12182,
        toFrame: 12188
      },
      {
        text: 'blah.',
        fromFrame: 12188,
        toFrame: 12197
      },
      {
        text: 'Okay.',
        fromFrame: 12197,
        toFrame: 12209
      },
      {
        text: 'I',
        fromFrame: 12246,
        toFrame: 12251
      }
    ],
    fromFrame: 12140,
    toFrame: 12251,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "don't know if Korang is looking at it,",
    tokens: [
      {
        text: "don't",
        fromFrame: 12251,
        toFrame: 12263
      },
      {
        text: 'know',
        fromFrame: 12263,
        toFrame: 12270
      },
      {
        text: 'if',
        fromFrame: 12270,
        toFrame: 12278
      },
      {
        text: 'Korang',
        fromFrame: 12278,
        toFrame: 12288
      },
      {
        text: 'is',
        fromFrame: 12288,
        toFrame: 12294
      },
      {
        text: 'looking',
        fromFrame: 12294,
        toFrame: 12301
      },
      {
        text: 'at',
        fromFrame: 12301,
        toFrame: 12305
      },
      {
        text: 'it,',
        fromFrame: 12305,
        toFrame: 12311
      }
    ],
    fromFrame: 12251,
    toFrame: 12311,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "but if it's high, we should. Right? This",
    tokens: [
      {
        text: 'but',
        fromFrame: 12311,
        toFrame: 12317
      },
      {
        text: 'if',
        fromFrame: 12317,
        toFrame: 12322
      },
      {
        text: "it's",
        fromFrame: 12322,
        toFrame: 12326
      },
      {
        text: 'high,',
        fromFrame: 12326,
        toFrame: 12332
      },
      {
        text: 'we',
        fromFrame: 12332,
        toFrame: 12338
      },
      {
        text: 'should.',
        fromFrame: 12338,
        toFrame: 12345
      },
      {
        text: 'Right?',
        fromFrame: 12345,
        toFrame: 12357
      },
      {
        text: 'This',
        fromFrame: 12401,
        toFrame: 12407
      }
    ],
    fromFrame: 12311,
    toFrame: 12407,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'was high, question, but can you confirm that',
    tokens: [
      {
        text: 'was',
        fromFrame: 12407,
        toFrame: 12418
      },
      {
        text: 'high,',
        fromFrame: 12418,
        toFrame: 12426
      },
      {
        text: 'question,',
        fromFrame: 12510,
        toFrame: 12520
      },
      {
        text: 'but',
        fromFrame: 12520,
        toFrame: 12524
      },
      {
        text: 'can',
        fromFrame: 12524,
        toFrame: 12528
      },
      {
        text: 'you',
        fromFrame: 12528,
        toFrame: 12534
      },
      {
        text: 'confirm',
        fromFrame: 12534,
        toFrame: 12545
      },
      {
        text: 'that',
        fromFrame: 12545,
        toFrame: 12553
      }
    ],
    fromFrame: 12407,
    toFrame: 12556,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'that is this the same issue which we',
    tokens: [
      {
        text: 'that',
        fromFrame: 12556,
        toFrame: 12562
      },
      {
        text: 'is',
        fromFrame: 12564,
        toFrame: 12574
      },
      {
        text: 'this',
        fromFrame: 12574,
        toFrame: 12578
      },
      {
        text: 'the',
        fromFrame: 12578,
        toFrame: 12581
      },
      {
        text: 'same',
        fromFrame: 12581,
        toFrame: 12587
      },
      {
        text: 'issue',
        fromFrame: 12587,
        toFrame: 12593
      },
      {
        text: 'which',
        fromFrame: 12593,
        toFrame: 12599
      },
      {
        text: 'we',
        fromFrame: 12599,
        toFrame: 12603
      }
    ],
    fromFrame: 12556,
    toFrame: 12603,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'fixed today? Today, we do not have any',
    tokens: [
      {
        text: 'fixed',
        fromFrame: 12603,
        toFrame: 12612
      },
      {
        text: 'today?',
        fromFrame: 12612,
        toFrame: 12624
      },
      {
        text: 'Today,',
        fromFrame: 12699,
        toFrame: 12706
      },
      {
        text: 'we',
        fromFrame: 12706,
        toFrame: 12710
      },
      {
        text: 'do',
        fromFrame: 12710,
        toFrame: 12712
      },
      {
        text: 'not',
        fromFrame: 12712,
        toFrame: 12716
      },
      {
        text: 'have',
        fromFrame: 12716,
        toFrame: 12720
      },
      {
        text: 'any',
        fromFrame: 12720,
        toFrame: 12723
      }
    ],
    fromFrame: 12603,
    toFrame: 12723,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'space to add issue. No? No. No font.',
    tokens: [
      {
        text: 'space',
        fromFrame: 12723,
        toFrame: 12731
      },
      {
        text: 'to',
        fromFrame: 12731,
        toFrame: 12733
      },
      {
        text: 'add',
        fromFrame: 12733,
        toFrame: 12735
      },
      {
        text: 'issue.',
        fromFrame: 12735,
        toFrame: 12743
      },
      {
        text: 'No?',
        fromFrame: 12743,
        toFrame: 12755
      },
      {
        text: 'No.',
        fromFrame: 12770,
        toFrame: 12773
      },
      {
        text: 'No',
        fromFrame: 12773,
        toFrame: 12777
      },
      {
        text: 'font.',
        fromFrame: 12777,
        toFrame: 12789
      }
    ],
    fromFrame: 12723,
    toFrame: 12804,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'In blank, it was fixed. No? From back',
    tokens: [
      {
        text: 'In',
        fromFrame: 12804,
        toFrame: 12808
      },
      {
        text: 'blank,',
        fromFrame: 12808,
        toFrame: 12816
      },
      {
        text: 'it',
        fromFrame: 12816,
        toFrame: 12819
      },
      {
        text: 'was',
        fromFrame: 12819,
        toFrame: 12823
      },
      {
        text: 'fixed.',
        fromFrame: 12823,
        toFrame: 12831
      },
      {
        text: 'No?',
        fromFrame: 12831,
        toFrame: 12837
      },
      {
        text: 'From',
        fromFrame: 12837,
        toFrame: 12841
      },
      {
        text: 'back',
        fromFrame: 12841,
        toFrame: 12848
      }
    ],
    fromFrame: 12804,
    toFrame: 12848,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'end. That was different. Okay. That was for',
    tokens: [
      {
        text: 'end.',
        fromFrame: 12848,
        toFrame: 12860
      },
      {
        text: 'That',
        fromFrame: 12879,
        toFrame: 12883
      },
      {
        text: 'was',
        fromFrame: 12883,
        toFrame: 12889
      },
      {
        text: 'different.',
        fromFrame: 12889,
        toFrame: 12901
      },
      {
        text: 'Okay.',
        fromFrame: 12906,
        toFrame: 12918
      },
      {
        text: 'That',
        fromFrame: 12954,
        toFrame: 12960
      },
      {
        text: 'was',
        fromFrame: 12960,
        toFrame: 12966
      },
      {
        text: 'for',
        fromFrame: 12966,
        toFrame: 12970
      }
    ],
    fromFrame: 12848,
    toFrame: 12970,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'separating between predefined and branded fonts. Mhmm. Let',
    tokens: [
      {
        text: 'separating',
        fromFrame: 12970,
        toFrame: 12981
      },
      {
        text: 'between',
        fromFrame: 12981,
        toFrame: 12989
      },
      {
        text: 'predefined',
        fromFrame: 12989,
        toFrame: 13001
      },
      {
        text: 'and',
        fromFrame: 13017,
        toFrame: 13029
      },
      {
        text: 'branded',
        fromFrame: 13045,
        toFrame: 13054
      },
      {
        text: 'fonts.',
        fromFrame: 13054,
        toFrame: 13066
      },
      {
        text: 'Mhmm.',
        fromFrame: 13077,
        toFrame: 13089
      },
      {
        text: 'Let',
        fromFrame: 13100,
        toFrame: 13106
      }
    ],
    fromFrame: 12970,
    toFrame: 13106,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "me move just to next point. But it's",
    tokens: [
      {
        text: 'me',
        fromFrame: 13106,
        toFrame: 13108
      },
      {
        text: 'move',
        fromFrame: 13108,
        toFrame: 13114
      },
      {
        text: 'just',
        fromFrame: 13114,
        toFrame: 13120
      },
      {
        text: 'to',
        fromFrame: 13120,
        toFrame: 13125
      },
      {
        text: 'next',
        fromFrame: 13125,
        toFrame: 13133
      },
      {
        text: 'point.',
        fromFrame: 13133,
        toFrame: 13145
      },
      {
        text: 'But',
        fromFrame: 13220,
        toFrame: 13228
      },
      {
        text: "it's",
        fromFrame: 13228,
        toFrame: 13232
      }
    ],
    fromFrame: 13106,
    toFrame: 13232,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'high. But it costs, downloads to fail. Yeah.',
    tokens: [
      {
        text: 'high.',
        fromFrame: 13232,
        toFrame: 13244
      },
      {
        text: 'But',
        fromFrame: 13287,
        toFrame: 13295
      },
      {
        text: 'it',
        fromFrame: 13295,
        toFrame: 13307
      },
      {
        text: 'costs,',
        fromFrame: 13308,
        toFrame: 13320
      },
      {
        text: 'downloads',
        fromFrame: 13333,
        toFrame: 13345
      },
      {
        text: 'to',
        fromFrame: 13345,
        toFrame: 13349
      },
      {
        text: 'fail.',
        fromFrame: 13349,
        toFrame: 13361
      },
      {
        text: 'Yeah.',
        fromFrame: 13399,
        toFrame: 13408
      }
    ],
    fromFrame: 13232,
    toFrame: 13408,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I'll check with Goran. I don't know what",
    tokens: [
      {
        text: "I'll",
        fromFrame: 13408,
        toFrame: 13416
      },
      {
        text: 'check',
        fromFrame: 13416,
        toFrame: 13424
      },
      {
        text: 'with',
        fromFrame: 13424,
        toFrame: 13431
      },
      {
        text: 'Goran.',
        fromFrame: 13431,
        toFrame: 13443
      },
      {
        text: 'I',
        fromFrame: 13574,
        toFrame: 13578
      },
      {
        text: "don't",
        fromFrame: 13578,
        toFrame: 13583
      },
      {
        text: 'know',
        fromFrame: 13583,
        toFrame: 13585
      },
      {
        text: 'what',
        fromFrame: 13585,
        toFrame: 13591
      }
    ],
    fromFrame: 13408,
    toFrame: 13591,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'should I write. Needs to be fixed. By',
    tokens: [
      {
        text: 'should',
        fromFrame: 13591,
        toFrame: 13593
      },
      {
        text: 'I',
        fromFrame: 13593,
        toFrame: 13597
      },
      {
        text: 'write.',
        fromFrame: 13597,
        toFrame: 13609
      },
      {
        text: 'Needs',
        fromFrame: 13675,
        toFrame: 13683
      },
      {
        text: 'to',
        fromFrame: 13683,
        toFrame: 13685
      },
      {
        text: 'be',
        fromFrame: 13685,
        toFrame: 13693
      },
      {
        text: 'fixed.',
        fromFrame: 13693,
        toFrame: 13705
      },
      {
        text: 'By',
        fromFrame: 13777,
        toFrame: 13783
      }
    ],
    fromFrame: 13591,
    toFrame: 13783,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'next release. Right? It this should actually be',
    tokens: [
      {
        text: 'next',
        fromFrame: 13783,
        toFrame: 13789
      },
      {
        text: 'release.',
        fromFrame: 13789,
        toFrame: 13800
      },
      {
        text: 'Right?',
        fromFrame: 13800,
        toFrame: 13812
      },
      {
        text: 'It',
        fromFrame: 13846,
        toFrame: 13850
      },
      {
        text: 'this',
        fromFrame: 13852,
        toFrame: 13856
      },
      {
        text: 'should',
        fromFrame: 13856,
        toFrame: 13862
      },
      {
        text: 'actually',
        fromFrame: 13862,
        toFrame: 13865
      },
      {
        text: 'be',
        fromFrame: 13865,
        toFrame: 13869
      }
    ],
    fromFrame: 13783,
    toFrame: 13869,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'a hard fix if it is causing download',
    tokens: [
      {
        text: 'a',
        fromFrame: 13869,
        toFrame: 13875
      },
      {
        text: 'hard',
        fromFrame: 13875,
        toFrame: 13883
      },
      {
        text: 'fix',
        fromFrame: 13883,
        toFrame: 13895
      },
      {
        text: 'if',
        fromFrame: 13944,
        toFrame: 13949
      },
      {
        text: 'it',
        fromFrame: 13949,
        toFrame: 13951
      },
      {
        text: 'is',
        fromFrame: 13951,
        toFrame: 13957
      },
      {
        text: 'causing',
        fromFrame: 13957,
        toFrame: 13969
      },
      {
        text: 'download',
        fromFrame: 13969,
        toFrame: 13976
      }
    ],
    fromFrame: 13869,
    toFrame: 13976,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'issues. How often is it causing the issue?',
    tokens: [
      {
        text: 'issues.',
        fromFrame: 13976,
        toFrame: 13988
      },
      {
        text: 'How',
        fromFrame: 14007,
        toFrame: 14015
      },
      {
        text: 'often',
        fromFrame: 14015,
        toFrame: 14027
      },
      {
        text: 'is',
        fromFrame: 14028,
        toFrame: 14032
      },
      {
        text: 'it',
        fromFrame: 14032,
        toFrame: 14038
      },
      {
        text: 'causing',
        fromFrame: 14038,
        toFrame: 14050
      },
      {
        text: 'the',
        fromFrame: 14076,
        toFrame: 14080
      },
      {
        text: 'issue?',
        fromFrame: 14080,
        toFrame: 14092
      }
    ],
    fromFrame: 13976,
    toFrame: 14178,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'I think we got a lot a lot',
    tokens: [
      {
        text: 'I',
        fromFrame: 14178,
        toFrame: 14180
      },
      {
        text: 'think',
        fromFrame: 14180,
        toFrame: 14187
      },
      {
        text: 'we',
        fromFrame: 14187,
        toFrame: 14191
      },
      {
        text: 'got',
        fromFrame: 14191,
        toFrame: 14201
      },
      {
        text: 'a',
        fromFrame: 14201,
        toFrame: 14203
      },
      {
        text: 'lot',
        fromFrame: 14203,
        toFrame: 14215
      },
      {
        text: 'a',
        fromFrame: 14220,
        toFrame: 14224
      },
      {
        text: 'lot',
        fromFrame: 14224,
        toFrame: 14232
      }
    ],
    fromFrame: 14178,
    toFrame: 14232,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'twice. Not sure. And and the coming week.',
    tokens: [
      {
        text: 'twice.',
        fromFrame: 14232,
        toFrame: 14244
      },
      {
        text: 'Not',
        fromFrame: 14273,
        toFrame: 14277
      },
      {
        text: 'sure.',
        fromFrame: 14277,
        toFrame: 14289
      },
      {
        text: 'And',
        fromFrame: 14430,
        toFrame: 14442
      },
      {
        text: 'and',
        fromFrame: 14444,
        toFrame: 14449
      },
      {
        text: 'the',
        fromFrame: 14449,
        toFrame: 14453
      },
      {
        text: 'coming',
        fromFrame: 14453,
        toFrame: 14465
      },
      {
        text: 'week.',
        fromFrame: 14467,
        toFrame: 14479
      }
    ],
    fromFrame: 14232,
    toFrame: 14513,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'We can check for how many customers, this',
    tokens: [
      {
        text: 'We',
        fromFrame: 14513,
        toFrame: 14517
      },
      {
        text: 'can',
        fromFrame: 14517,
        toFrame: 14522
      },
      {
        text: 'check',
        fromFrame: 14522,
        toFrame: 14528
      },
      {
        text: 'for',
        fromFrame: 14528,
        toFrame: 14534
      },
      {
        text: 'how',
        fromFrame: 14534,
        toFrame: 14538
      },
      {
        text: 'many',
        fromFrame: 14538,
        toFrame: 14544
      },
      {
        text: 'customers,',
        fromFrame: 14544,
        toFrame: 14556
      },
      {
        text: 'this',
        fromFrame: 14568,
        toFrame: 14572
      }
    ],
    fromFrame: 14513,
    toFrame: 14572,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'issue is occurring. Thank you. Montrium, they are',
    tokens: [
      {
        text: 'issue',
        fromFrame: 14572,
        toFrame: 14580
      },
      {
        text: 'is',
        fromFrame: 14580,
        toFrame: 14584
      },
      {
        text: 'occurring.',
        fromFrame: 14584,
        toFrame: 14596
      },
      {
        text: 'Thank',
        fromFrame: 15114,
        toFrame: 15121
      },
      {
        text: 'you.',
        fromFrame: 15121,
        toFrame: 15133
      },
      {
        text: 'Montrium,',
        fromFrame: 15221,
        toFrame: 15233
      },
      {
        text: 'they',
        fromFrame: 15242,
        toFrame: 15248
      },
      {
        text: 'are',
        fromFrame: 15248,
        toFrame: 15252
      }
    ],
    fromFrame: 14572,
    toFrame: 15252,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'looking. The the issue is 2 folds now.',
    tokens: [
      {
        text: 'looking.',
        fromFrame: 15252,
        toFrame: 15264
      },
      {
        text: 'The',
        fromFrame: 15279,
        toFrame: 15291
      },
      {
        text: 'the',
        fromFrame: 15297,
        toFrame: 15300
      },
      {
        text: 'issue',
        fromFrame: 15300,
        toFrame: 15310
      },
      {
        text: 'is',
        fromFrame: 15310,
        toFrame: 15318
      },
      {
        text: '2',
        fromFrame: 15318,
        toFrame: 15323
      },
      {
        text: 'folds',
        fromFrame: 15323,
        toFrame: 15333
      },
      {
        text: 'now.',
        fromFrame: 15333,
        toFrame: 15345
      }
    ],
    fromFrame: 15252,
    toFrame: 15348,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'So I leave it in this print. Yeah.',
    tokens: [
      {
        text: 'So',
        fromFrame: 15348,
        toFrame: 15356
      },
      {
        text: 'I',
        fromFrame: 15356,
        toFrame: 15358
      },
      {
        text: 'leave',
        fromFrame: 15358,
        toFrame: 15366
      },
      {
        text: 'it',
        fromFrame: 15366,
        toFrame: 15371
      },
      {
        text: 'in',
        fromFrame: 15371,
        toFrame: 15373
      },
      {
        text: 'this',
        fromFrame: 15373,
        toFrame: 15381
      },
      {
        text: 'print.',
        fromFrame: 15381,
        toFrame: 15393
      },
      {
        text: 'Yeah.',
        fromFrame: 15506,
        toFrame: 15512
      }
    ],
    fromFrame: 15348,
    toFrame: 15512,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'What is it? This I want to discuss',
    tokens: [
      {
        text: 'What',
        fromFrame: 15512,
        toFrame: 15517
      },
      {
        text: 'is',
        fromFrame: 15517,
        toFrame: 15523
      },
      {
        text: 'it?',
        fromFrame: 15523,
        toFrame: 15535
      },
      {
        text: 'This',
        fromFrame: 15540,
        toFrame: 15548
      },
      {
        text: 'I',
        fromFrame: 15548,
        toFrame: 15550
      },
      {
        text: 'want',
        fromFrame: 15550,
        toFrame: 15558
      },
      {
        text: 'to',
        fromFrame: 15558,
        toFrame: 15560
      },
      {
        text: 'discuss',
        fromFrame: 15560,
        toFrame: 15571
      }
    ],
    fromFrame: 15512,
    toFrame: 15571,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'with you. So what happen if you upload',
    tokens: [
      {
        text: 'with',
        fromFrame: 15571,
        toFrame: 15575
      },
      {
        text: 'you.',
        fromFrame: 15575,
        toFrame: 15587
      },
      {
        text: 'So',
        fromFrame: 15622,
        toFrame: 15626
      },
      {
        text: 'what',
        fromFrame: 15626,
        toFrame: 15630
      },
      {
        text: 'happen',
        fromFrame: 15630,
        toFrame: 15639
      },
      {
        text: 'if',
        fromFrame: 15639,
        toFrame: 15645
      },
      {
        text: 'you',
        fromFrame: 15645,
        toFrame: 15649
      },
      {
        text: 'upload',
        fromFrame: 15649,
        toFrame: 15661
      }
    ],
    fromFrame: 15571,
    toFrame: 15664,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'any video and then go back to dashboard',
    tokens: [
      {
        text: 'any',
        fromFrame: 15664,
        toFrame: 15670
      },
      {
        text: 'video',
        fromFrame: 15670,
        toFrame: 15680
      },
      {
        text: 'and',
        fromFrame: 15680,
        toFrame: 15692
      },
      {
        text: 'then',
        fromFrame: 15697,
        toFrame: 15705
      },
      {
        text: 'go',
        fromFrame: 15705,
        toFrame: 15708
      },
      {
        text: 'back',
        fromFrame: 15708,
        toFrame: 15714
      },
      {
        text: 'to',
        fromFrame: 15714,
        toFrame: 15718
      },
      {
        text: 'dashboard',
        fromFrame: 15718,
        toFrame: 15730
      }
    ],
    fromFrame: 15664,
    toFrame: 15732,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'and come again on the upload screen? Mhmm.',
    tokens: [
      {
        text: 'and',
        fromFrame: 15732,
        toFrame: 15733
      },
      {
        text: 'come',
        fromFrame: 15733,
        toFrame: 15737
      },
      {
        text: 'again',
        fromFrame: 15737,
        toFrame: 15747
      },
      {
        text: 'on',
        fromFrame: 15747,
        toFrame: 15751
      },
      {
        text: 'the',
        fromFrame: 15751,
        toFrame: 15760
      },
      {
        text: 'upload',
        fromFrame: 15760,
        toFrame: 15770
      },
      {
        text: 'screen?',
        fromFrame: 15770,
        toFrame: 15782
      },
      {
        text: 'Mhmm.',
        fromFrame: 15793,
        toFrame: 15801
      }
    ],
    fromFrame: 15732,
    toFrame: 15801,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Please it will not show the preset template.',
    tokens: [
      {
        text: 'Please',
        fromFrame: 15801,
        toFrame: 15810
      },
      {
        text: 'it',
        fromFrame: 15812,
        toFrame: 15818
      },
      {
        text: 'will',
        fromFrame: 15818,
        toFrame: 15822
      },
      {
        text: 'not',
        fromFrame: 15822,
        toFrame: 15828
      },
      {
        text: 'show',
        fromFrame: 15828,
        toFrame: 15831
      },
      {
        text: 'the',
        fromFrame: 15831,
        toFrame: 15835
      },
      {
        text: 'preset',
        fromFrame: 15835,
        toFrame: 15847
      },
      {
        text: 'template.',
        fromFrame: 15847,
        toFrame: 15859
      }
    ],
    fromFrame: 15801,
    toFrame: 15885,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "So it's not happening every time, but very",
    tokens: [
      {
        text: 'So',
        fromFrame: 15885,
        toFrame: 15889
      },
      {
        text: "it's",
        fromFrame: 15889,
        toFrame: 15897
      },
      {
        text: 'not',
        fromFrame: 15897,
        toFrame: 15906
      },
      {
        text: 'happening',
        fromFrame: 15906,
        toFrame: 15918
      },
      {
        text: 'every',
        fromFrame: 15918,
        toFrame: 15924
      },
      {
        text: 'time,',
        fromFrame: 15924,
        toFrame: 15929
      },
      {
        text: 'but',
        fromFrame: 15929,
        toFrame: 15941
      },
      {
        text: 'very',
        fromFrame: 15941,
        toFrame: 15948
      }
    ],
    fromFrame: 15885,
    toFrame: 15948,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'frequently I can do this. The gold cast',
    tokens: [
      {
        text: 'frequently',
        fromFrame: 15948,
        toFrame: 15960
      },
      {
        text: 'I',
        fromFrame: 15964,
        toFrame: 15966
      },
      {
        text: 'can',
        fromFrame: 15966,
        toFrame: 15975
      },
      {
        text: 'do',
        fromFrame: 15975,
        toFrame: 15983
      },
      {
        text: 'this.',
        fromFrame: 15983,
        toFrame: 15993
      },
      {
        text: 'The',
        fromFrame: 16034,
        toFrame: 16040
      },
      {
        text: 'gold',
        fromFrame: 16040,
        toFrame: 16047
      },
      {
        text: 'cast',
        fromFrame: 16047,
        toFrame: 16055
      }
    ],
    fromFrame: 15948,
    toFrame: 16055,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'presets? Yes. Can you see the saved templates,',
    tokens: [
      {
        text: 'presets?',
        fromFrame: 16055,
        toFrame: 16067
      },
      {
        text: 'Yes.',
        fromFrame: 16086,
        toFrame: 16098
      },
      {
        text: 'Can',
        fromFrame: 16130,
        toFrame: 16136
      },
      {
        text: 'you',
        fromFrame: 16136,
        toFrame: 16139
      },
      {
        text: 'see',
        fromFrame: 16139,
        toFrame: 16143
      },
      {
        text: 'the',
        fromFrame: 16143,
        toFrame: 16149
      },
      {
        text: 'saved',
        fromFrame: 16149,
        toFrame: 16159
      },
      {
        text: 'templates,',
        fromFrame: 16159,
        toFrame: 16171
      }
    ],
    fromFrame: 16055,
    toFrame: 16182,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "if any? See uh-huh. I'll check for saved",
    tokens: [
      {
        text: 'if',
        fromFrame: 16182,
        toFrame: 16187
      },
      {
        text: 'any?',
        fromFrame: 16187,
        toFrame: 16199
      },
      {
        text: 'See',
        fromFrame: 16230,
        toFrame: 16237
      },
      {
        text: 'uh-huh.',
        fromFrame: 16243,
        toFrame: 16249
      },
      {
        text: "I'll",
        fromFrame: 16249,
        toFrame: 16255
      },
      {
        text: 'check',
        fromFrame: 16255,
        toFrame: 16262
      },
      {
        text: 'for',
        fromFrame: 16262,
        toFrame: 16268
      },
      {
        text: 'saved',
        fromFrame: 16268,
        toFrame: 16276
      }
    ],
    fromFrame: 16182,
    toFrame: 16276,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "templates. Yeah. If it's only preset, I'm okay",
    tokens: [
      {
        text: 'templates.',
        fromFrame: 16276,
        toFrame: 16288
      },
      {
        text: 'Yeah.',
        fromFrame: 16320,
        toFrame: 16325
      },
      {
        text: 'If',
        fromFrame: 16325,
        toFrame: 16331
      },
      {
        text: "it's",
        fromFrame: 16331,
        toFrame: 16339
      },
      {
        text: 'only',
        fromFrame: 16339,
        toFrame: 16348
      },
      {
        text: 'preset,',
        fromFrame: 16348,
        toFrame: 16360
      },
      {
        text: "I'm",
        fromFrame: 16369,
        toFrame: 16379
      },
      {
        text: 'okay',
        fromFrame: 16379,
        toFrame: 16387
      }
    ],
    fromFrame: 16276,
    toFrame: 16387,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "to put it medium. I don't think anyone",
    tokens: [
      {
        text: 'to',
        fromFrame: 16387,
        toFrame: 16391
      },
      {
        text: 'put',
        fromFrame: 16391,
        toFrame: 16396
      },
      {
        text: 'it',
        fromFrame: 16396,
        toFrame: 16400
      },
      {
        text: 'medium.',
        fromFrame: 16400,
        toFrame: 16410
      },
      {
        text: 'I',
        fromFrame: 16410,
        toFrame: 16414
      },
      {
        text: "don't",
        fromFrame: 16414,
        toFrame: 16417
      },
      {
        text: 'think',
        fromFrame: 16417,
        toFrame: 16423
      },
      {
        text: 'anyone',
        fromFrame: 16423,
        toFrame: 16433
      }
    ],
    fromFrame: 16387,
    toFrame: 16433,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "wants to use our presets. But if it's",
    tokens: [
      {
        text: 'wants',
        fromFrame: 16433,
        toFrame: 16439
      },
      {
        text: 'to',
        fromFrame: 16439,
        toFrame: 16442
      },
      {
        text: 'use',
        fromFrame: 16442,
        toFrame: 16448
      },
      {
        text: 'our',
        fromFrame: 16448,
        toFrame: 16454
      },
      {
        text: 'presets.',
        fromFrame: 16454,
        toFrame: 16466
      },
      {
        text: 'But',
        fromFrame: 16533,
        toFrame: 16538
      },
      {
        text: 'if',
        fromFrame: 16538,
        toFrame: 16542
      },
      {
        text: "it's",
        fromFrame: 16542,
        toFrame: 16546
      }
    ],
    fromFrame: 16433,
    toFrame: 16546,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'for save template, it just remains as high.',
    tokens: [
      {
        text: 'for',
        fromFrame: 16546,
        toFrame: 16552
      },
      {
        text: 'save',
        fromFrame: 16552,
        toFrame: 16558
      },
      {
        text: 'template,',
        fromFrame: 16558,
        toFrame: 16570
      },
      {
        text: 'it',
        fromFrame: 16571,
        toFrame: 16573
      },
      {
        text: 'just',
        fromFrame: 16573,
        toFrame: 16581
      },
      {
        text: 'remains',
        fromFrame: 16581,
        toFrame: 16590
      },
      {
        text: 'as',
        fromFrame: 16590,
        toFrame: 16596
      },
      {
        text: 'high.',
        fromFrame: 16596,
        toFrame: 16608
      }
    ],
    fromFrame: 16546,
    toFrame: 16652,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Yeah. But even then Yeah. It looks odd.',
    tokens: [
      {
        text: 'Yeah.',
        fromFrame: 16652,
        toFrame: 16654
      },
      {
        text: 'But',
        fromFrame: 16654,
        toFrame: 16659
      },
      {
        text: 'even',
        fromFrame: 16659,
        toFrame: 16667
      },
      {
        text: 'then',
        fromFrame: 16667,
        toFrame: 16676
      },
      {
        text: 'Yeah.',
        fromFrame: 16684,
        toFrame: 16696
      },
      {
        text: 'It',
        fromFrame: 16723,
        toFrame: 16727
      },
      {
        text: 'looks',
        fromFrame: 16727,
        toFrame: 16734
      },
      {
        text: 'odd.',
        fromFrame: 16734,
        toFrame: 16742
      }
    ],
    fromFrame: 16652,
    toFrame: 16742,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'No? For the last time show the template.',
    tokens: [
      {
        text: 'No?',
        fromFrame: 16742,
        toFrame: 16752
      },
      {
        text: 'For',
        fromFrame: 16759,
        toFrame: 16765
      },
      {
        text: 'the',
        fromFrame: 16765,
        toFrame: 16767
      },
      {
        text: 'last',
        fromFrame: 16767,
        toFrame: 16773
      },
      {
        text: 'time',
        fromFrame: 16773,
        toFrame: 16779
      },
      {
        text: 'show',
        fromFrame: 16784,
        toFrame: 16792
      },
      {
        text: 'the',
        fromFrame: 16792,
        toFrame: 16794
      },
      {
        text: 'template.',
        fromFrame: 16794,
        toFrame: 16806
      }
    ],
    fromFrame: 16742,
    toFrame: 16875,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Yeah. But I need to know that I',
    tokens: [
      {
        text: 'Yeah.',
        fromFrame: 16875,
        toFrame: 16876
      },
      {
        text: 'But',
        fromFrame: 16876,
        toFrame: 16884
      },
      {
        text: 'I',
        fromFrame: 16888,
        toFrame: 16890
      },
      {
        text: 'need',
        fromFrame: 16890,
        toFrame: 16896
      },
      {
        text: 'to',
        fromFrame: 16896,
        toFrame: 16901
      },
      {
        text: 'know',
        fromFrame: 16901,
        toFrame: 16903
      },
      {
        text: 'that',
        fromFrame: 16903,
        toFrame: 16909
      },
      {
        text: 'I',
        fromFrame: 16917,
        toFrame: 16921
      }
    ],
    fromFrame: 16875,
    toFrame: 16921,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'discussed this with in morning, and he said',
    tokens: [
      {
        text: 'discussed',
        fromFrame: 16921,
        toFrame: 16932
      },
      {
        text: 'this',
        fromFrame: 16932,
        toFrame: 16936
      },
      {
        text: 'with',
        fromFrame: 16936,
        toFrame: 16944
      },
      {
        text: 'in',
        fromFrame: 16965,
        toFrame: 16967
      },
      {
        text: 'morning,',
        fromFrame: 16967,
        toFrame: 16976
      },
      {
        text: 'and',
        fromFrame: 16976,
        toFrame: 16978
      },
      {
        text: 'he',
        fromFrame: 16978,
        toFrame: 16982
      },
      {
        text: 'said',
        fromFrame: 16982,
        toFrame: 16988
      }
    ],
    fromFrame: 16921,
    toFrame: 16988,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "he don't have bandwidth to fix this. So,",
    tokens: [
      {
        text: 'he',
        fromFrame: 16988,
        toFrame: 16992
      },
      {
        text: "don't",
        fromFrame: 16992,
        toFrame: 16997
      },
      {
        text: 'have',
        fromFrame: 16997,
        toFrame: 17003
      },
      {
        text: 'bandwidth',
        fromFrame: 17003,
        toFrame: 17015
      },
      {
        text: 'to',
        fromFrame: 17015,
        toFrame: 17026
      },
      {
        text: 'fix',
        fromFrame: 17026,
        toFrame: 17034
      },
      {
        text: 'this.',
        fromFrame: 17034,
        toFrame: 17046
      },
      {
        text: 'So,',
        fromFrame: 17089,
        toFrame: 17099
      }
    ],
    fromFrame: 16988,
    toFrame: 17099,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'like yeah. This product will say they need',
    tokens: [
      {
        text: 'like',
        fromFrame: 17099,
        toFrame: 17105
      },
      {
        text: 'yeah.',
        fromFrame: 17109,
        toFrame: 17121
      },
      {
        text: 'This',
        fromFrame: 17134,
        toFrame: 17141
      },
      {
        text: 'product',
        fromFrame: 17141,
        toFrame: 17153
      },
      {
        text: 'will',
        fromFrame: 17153,
        toFrame: 17159
      },
      {
        text: 'say',
        fromFrame: 17159,
        toFrame: 17168
      },
      {
        text: 'they',
        fromFrame: 17168,
        toFrame: 17180
      },
      {
        text: 'need',
        fromFrame: 17193,
        toFrame: 17199
      }
    ],
    fromFrame: 17099,
    toFrame: 17199,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "to fix, so he'll pause the video library",
    tokens: [
      {
        text: 'to',
        fromFrame: 17199,
        toFrame: 17201
      },
      {
        text: 'fix,',
        fromFrame: 17201,
        toFrame: 17208
      },
      {
        text: 'so',
        fromFrame: 17208,
        toFrame: 17214
      },
      {
        text: "he'll",
        fromFrame: 17214,
        toFrame: 17222
      },
      {
        text: 'pause',
        fromFrame: 17222,
        toFrame: 17230
      },
      {
        text: 'the',
        fromFrame: 17230,
        toFrame: 17233
      },
      {
        text: 'video',
        fromFrame: 17233,
        toFrame: 17241
      },
      {
        text: 'library',
        fromFrame: 17241,
        toFrame: 17251
      }
    ],
    fromFrame: 17199,
    toFrame: 17251,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'work and then fix it. Oh, we can',
    tokens: [
      {
        text: 'work',
        fromFrame: 17251,
        toFrame: 17256
      },
      {
        text: 'and',
        fromFrame: 17256,
        toFrame: 17260
      },
      {
        text: 'then',
        fromFrame: 17260,
        toFrame: 17266
      },
      {
        text: 'fix',
        fromFrame: 17266,
        toFrame: 17272
      },
      {
        text: 'it.',
        fromFrame: 17272,
        toFrame: 17284
      },
      {
        text: 'Oh,',
        fromFrame: 17361,
        toFrame: 17373
      },
      {
        text: 'we',
        fromFrame: 17413,
        toFrame: 17419
      },
      {
        text: 'can',
        fromFrame: 17419,
        toFrame: 17427
      }
    ],
    fromFrame: 17251,
    toFrame: 17427,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "pause video library. But yeah. Okay. I'll check",
    tokens: [
      {
        text: 'pause',
        fromFrame: 17427,
        toFrame: 17434
      },
      {
        text: 'video',
        fromFrame: 17434,
        toFrame: 17444
      },
      {
        text: 'library.',
        fromFrame: 17444,
        toFrame: 17456
      },
      {
        text: 'But',
        fromFrame: 17477,
        toFrame: 17482
      },
      {
        text: 'yeah.',
        fromFrame: 17484,
        toFrame: 17492
      },
      {
        text: 'Okay.',
        fromFrame: 17492,
        toFrame: 17502
      },
      {
        text: "I'll",
        fromFrame: 17502,
        toFrame: 17507
      },
      {
        text: 'check',
        fromFrame: 17507,
        toFrame: 17515
      }
    ],
    fromFrame: 17427,
    toFrame: 17515,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "this. But, yeah, let's keep it high for",
    tokens: [
      {
        text: 'this.',
        fromFrame: 17515,
        toFrame: 17527
      },
      {
        text: 'But,',
        fromFrame: 17548,
        toFrame: 17553
      },
      {
        text: 'yeah,',
        fromFrame: 17553,
        toFrame: 17561
      },
      {
        text: "let's",
        fromFrame: 17561,
        toFrame: 17571
      },
      {
        text: 'keep',
        fromFrame: 17571,
        toFrame: 17575
      },
      {
        text: 'it',
        fromFrame: 17575,
        toFrame: 17580
      },
      {
        text: 'high',
        fromFrame: 17580,
        toFrame: 17586
      },
      {
        text: 'for',
        fromFrame: 17586,
        toFrame: 17592
      }
    ],
    fromFrame: 17515,
    toFrame: 17592,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'now. No. This will switch. The thing is',
    tokens: [
      {
        text: 'now.',
        fromFrame: 17592,
        toFrame: 17604
      },
      {
        text: 'No.',
        fromFrame: 17637,
        toFrame: 17641
      },
      {
        text: 'This',
        fromFrame: 17641,
        toFrame: 17647
      },
      {
        text: 'will',
        fromFrame: 17647,
        toFrame: 17653
      },
      {
        text: 'switch.',
        fromFrame: 17653,
        toFrame: 17665
      },
      {
        text: 'The',
        fromFrame: 17678,
        toFrame: 17680
      },
      {
        text: 'thing',
        fromFrame: 17680,
        toFrame: 17685
      },
      {
        text: 'is',
        fromFrame: 17685,
        toFrame: 17687
      }
    ],
    fromFrame: 17592,
    toFrame: 17687,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I don't think it needs to be high",
    tokens: [
      {
        text: 'I',
        fromFrame: 17687,
        toFrame: 17689
      },
      {
        text: "don't",
        fromFrame: 17689,
        toFrame: 17693
      },
      {
        text: 'think',
        fromFrame: 17693,
        toFrame: 17697
      },
      {
        text: 'it',
        fromFrame: 17697,
        toFrame: 17701
      },
      {
        text: 'needs',
        fromFrame: 17701,
        toFrame: 17705
      },
      {
        text: 'to',
        fromFrame: 17705,
        toFrame: 17706
      },
      {
        text: 'be',
        fromFrame: 17706,
        toFrame: 17710
      },
      {
        text: 'high',
        fromFrame: 17710,
        toFrame: 17716
      }
    ],
    fromFrame: 17687,
    toFrame: 17716,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "also because I don't think anybody will be",
    tokens: [
      {
        text: 'also',
        fromFrame: 17716,
        toFrame: 17722
      },
      {
        text: 'because',
        fromFrame: 17722,
        toFrame: 17734
      },
      {
        text: 'I',
        fromFrame: 17747,
        toFrame: 17751
      },
      {
        text: "don't",
        fromFrame: 17751,
        toFrame: 17754
      },
      {
        text: 'think',
        fromFrame: 17754,
        toFrame: 17758
      },
      {
        text: 'anybody',
        fromFrame: 17758,
        toFrame: 17768
      },
      {
        text: 'will',
        fromFrame: 17768,
        toFrame: 17772
      },
      {
        text: 'be',
        fromFrame: 17772,
        toFrame: 17774
      }
    ],
    fromFrame: 17716,
    toFrame: 17774,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'switching to 2 seconds. So the thing is',
    tokens: [
      {
        text: 'switching',
        fromFrame: 17774,
        toFrame: 17781
      },
      {
        text: 'to',
        fromFrame: 17781,
        toFrame: 17785
      },
      {
        text: '2',
        fromFrame: 17785,
        toFrame: 17797
      },
      {
        text: 'seconds.',
        fromFrame: 17799,
        toFrame: 17811
      },
      {
        text: 'So',
        fromFrame: 17826,
        toFrame: 17833
      },
      {
        text: 'the',
        fromFrame: 17833,
        toFrame: 17837
      },
      {
        text: 'thing',
        fromFrame: 17837,
        toFrame: 17843
      },
      {
        text: 'is',
        fromFrame: 17843,
        toFrame: 17847
      }
    ],
    fromFrame: 17774,
    toFrame: 17847,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "if you go back and come, that's what",
    tokens: [
      {
        text: 'if',
        fromFrame: 17847,
        toFrame: 17850
      },
      {
        text: 'you',
        fromFrame: 17850,
        toFrame: 17852
      },
      {
        text: 'go',
        fromFrame: 17852,
        toFrame: 17860
      },
      {
        text: 'back',
        fromFrame: 17860,
        toFrame: 17866
      },
      {
        text: 'and',
        fromFrame: 17866,
        toFrame: 17872
      },
      {
        text: 'come,',
        fromFrame: 17872,
        toFrame: 17879
      },
      {
        text: "that's",
        fromFrame: 17879,
        toFrame: 17887
      },
      {
        text: 'what',
        fromFrame: 17887,
        toFrame: 17893
      }
    ],
    fromFrame: 17847,
    toFrame: 17893,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I'm not very worried. Yeah. Like, Go back",
    tokens: [
      {
        text: "I'm",
        fromFrame: 17893,
        toFrame: 17900
      },
      {
        text: 'not',
        fromFrame: 17900,
        toFrame: 17910
      },
      {
        text: 'very',
        fromFrame: 17910,
        toFrame: 17918
      },
      {
        text: 'worried.',
        fromFrame: 17918,
        toFrame: 17930
      },
      {
        text: 'Yeah.',
        fromFrame: 17931,
        toFrame: 17939
      },
      {
        text: 'Like,',
        fromFrame: 17939,
        toFrame: 17951
      },
      {
        text: 'Go',
        fromFrame: 17964,
        toFrame: 17971
      },
      {
        text: 'back',
        fromFrame: 17971,
        toFrame: 17975
      }
    ],
    fromFrame: 17893,
    toFrame: 17975,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'and then come fix to upload. But, like,',
    tokens: [
      {
        text: 'and',
        fromFrame: 17975,
        toFrame: 17979
      },
      {
        text: 'then',
        fromFrame: 17979,
        toFrame: 17983
      },
      {
        text: 'come',
        fromFrame: 17983,
        toFrame: 17991
      },
      {
        text: 'fix',
        fromFrame: 17991,
        toFrame: 17999
      },
      {
        text: 'to',
        fromFrame: 17999,
        toFrame: 18011
      },
      {
        text: 'upload.',
        fromFrame: 18011,
        toFrame: 18021
      },
      {
        text: 'But,',
        fromFrame: 18021,
        toFrame: 18033
      },
      {
        text: 'like,',
        fromFrame: 18063,
        toFrame: 18070
      }
    ],
    fromFrame: 17975,
    toFrame: 18070,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'in in 2, 3 time, definitely, it will',
    tokens: [
      {
        text: 'in',
        fromFrame: 18070,
        toFrame: 18082
      },
      {
        text: 'in',
        fromFrame: 18082,
        toFrame: 18084
      },
      {
        text: '2,',
        fromFrame: 18084,
        toFrame: 18092
      },
      {
        text: '3',
        fromFrame: 18092,
        toFrame: 18095
      },
      {
        text: 'time,',
        fromFrame: 18095,
        toFrame: 18105
      },
      {
        text: 'definitely,',
        fromFrame: 18105,
        toFrame: 18117
      },
      {
        text: 'it',
        fromFrame: 18118,
        toFrame: 18120
      },
      {
        text: 'will',
        fromFrame: 18120,
        toFrame: 18126
      }
    ],
    fromFrame: 18070,
    toFrame: 18126,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'repro. But sometime, it can repro, like, if',
    tokens: [
      {
        text: 'repro.',
        fromFrame: 18126,
        toFrame: 18138
      },
      {
        text: 'But',
        fromFrame: 18147,
        toFrame: 18155
      },
      {
        text: 'sometime,',
        fromFrame: 18155,
        toFrame: 18167
      },
      {
        text: 'it',
        fromFrame: 18168,
        toFrame: 18170
      },
      {
        text: 'can',
        fromFrame: 18170,
        toFrame: 18178
      },
      {
        text: 'repro,',
        fromFrame: 18178,
        toFrame: 18190
      },
      {
        text: 'like,',
        fromFrame: 18191,
        toFrame: 18203
      },
      {
        text: 'if',
        fromFrame: 18203,
        toFrame: 18209
      }
    ],
    fromFrame: 18126,
    toFrame: 18209,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "you go once and I'll dive in and",
    tokens: [
      {
        text: 'you',
        fromFrame: 18209,
        toFrame: 18213
      },
      {
        text: 'go',
        fromFrame: 18213,
        toFrame: 18218
      },
      {
        text: 'once',
        fromFrame: 18218,
        toFrame: 18224
      },
      {
        text: 'and',
        fromFrame: 18224,
        toFrame: 18228
      },
      {
        text: "I'll",
        fromFrame: 18228,
        toFrame: 18232
      },
      {
        text: 'dive',
        fromFrame: 18232,
        toFrame: 18237
      },
      {
        text: 'in',
        fromFrame: 18237,
        toFrame: 18241
      },
      {
        text: 'and',
        fromFrame: 18241,
        toFrame: 18247
      }
    ],
    fromFrame: 18209,
    toFrame: 18247,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'then come back to the upload screen. You',
    tokens: [
      {
        text: 'then',
        fromFrame: 18247,
        toFrame: 18253
      },
      {
        text: 'come',
        fromFrame: 18253,
        toFrame: 18257
      },
      {
        text: 'back',
        fromFrame: 18257,
        toFrame: 18262
      },
      {
        text: 'to',
        fromFrame: 18262,
        toFrame: 18266
      },
      {
        text: 'the',
        fromFrame: 18266,
        toFrame: 18278
      },
      {
        text: 'upload',
        fromFrame: 18299,
        toFrame: 18311
      },
      {
        text: 'screen.',
        fromFrame: 18311,
        toFrame: 18322
      },
      {
        text: 'You',
        fromFrame: 18322,
        toFrame: 18326
      }
    ],
    fromFrame: 18247,
    toFrame: 18326,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "don't need to repeat the steps. We can",
    tokens: [
      {
        text: "don't",
        fromFrame: 18326,
        toFrame: 18332
      },
      {
        text: 'need',
        fromFrame: 18332,
        toFrame: 18338
      },
      {
        text: 'to',
        fromFrame: 18338,
        toFrame: 18342
      },
      {
        text: 'repeat',
        fromFrame: 18342,
        toFrame: 18351
      },
      {
        text: 'the',
        fromFrame: 18351,
        toFrame: 18355
      },
      {
        text: 'steps.',
        fromFrame: 18355,
        toFrame: 18367
      },
      {
        text: 'We',
        fromFrame: 18474,
        toFrame: 18480
      },
      {
        text: 'can',
        fromFrame: 18480,
        toFrame: 18486
      }
    ],
    fromFrame: 18326,
    toFrame: 18486,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "keep it as medium. I don't know how",
    tokens: [
      {
        text: 'keep',
        fromFrame: 18486,
        toFrame: 18491
      },
      {
        text: 'it',
        fromFrame: 18491,
        toFrame: 18497
      },
      {
        text: 'as',
        fromFrame: 18497,
        toFrame: 18501
      },
      {
        text: 'medium.',
        fromFrame: 18501,
        toFrame: 18513
      },
      {
        text: 'I',
        fromFrame: 18526,
        toFrame: 18532
      },
      {
        text: "don't",
        fromFrame: 18532,
        toFrame: 18537
      },
      {
        text: 'know',
        fromFrame: 18537,
        toFrame: 18541
      },
      {
        text: 'how',
        fromFrame: 18541,
        toFrame: 18547
      }
    ],
    fromFrame: 18486,
    toFrame: 18547,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'many people will go back. But, yeah, we',
    tokens: [
      {
        text: 'many',
        fromFrame: 18547,
        toFrame: 18553
      },
      {
        text: 'people',
        fromFrame: 18553,
        toFrame: 18562
      },
      {
        text: 'will',
        fromFrame: 18562,
        toFrame: 18568
      },
      {
        text: 'go',
        fromFrame: 18568,
        toFrame: 18574
      },
      {
        text: 'back.',
        fromFrame: 18574,
        toFrame: 18585
      },
      {
        text: 'But,',
        fromFrame: 18585,
        toFrame: 18597
      },
      {
        text: 'yeah,',
        fromFrame: 18607,
        toFrame: 18619
      },
      {
        text: 'we',
        fromFrame: 18635,
        toFrame: 18641
      }
    ],
    fromFrame: 18547,
    toFrame: 18641,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "can pick after video library maybe. I don't",
    tokens: [
      {
        text: 'can',
        fromFrame: 18641,
        toFrame: 18647
      },
      {
        text: 'pick',
        fromFrame: 18647,
        toFrame: 18653
      },
      {
        text: 'after',
        fromFrame: 18653,
        toFrame: 18662
      },
      {
        text: 'video',
        fromFrame: 18662,
        toFrame: 18672
      },
      {
        text: 'library',
        fromFrame: 18672,
        toFrame: 18681
      },
      {
        text: 'maybe.',
        fromFrame: 18681,
        toFrame: 18693
      },
      {
        text: 'I',
        fromFrame: 18726,
        toFrame: 18728
      },
      {
        text: "don't",
        fromFrame: 18728,
        toFrame: 18733
      }
    ],
    fromFrame: 18641,
    toFrame: 18733,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "know. Does it? Yeah. Of course. She's busy.",
    tokens: [
      {
        text: 'know.',
        fromFrame: 18733,
        toFrame: 18736
      },
      {
        text: 'Does',
        fromFrame: 18739,
        toFrame: 18745
      },
      {
        text: 'it?',
        fromFrame: 18745,
        toFrame: 18751
      },
      {
        text: 'Yeah.',
        fromFrame: 18751,
        toFrame: 18763
      },
      {
        text: 'Of',
        fromFrame: 19031,
        toFrame: 19037
      },
      {
        text: 'course.',
        fromFrame: 19037,
        toFrame: 19047
      },
      {
        text: "She's",
        fromFrame: 19047,
        toFrame: 19054
      },
      {
        text: 'busy.',
        fromFrame: 19054,
        toFrame: 19062
      }
    ],
    fromFrame: 18733,
    toFrame: 19062,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Can anyone else in next to sprint? One',
    tokens: [
      {
        text: 'Can',
        fromFrame: 19062,
        toFrame: 19070
      },
      {
        text: 'anyone',
        fromFrame: 19070,
        toFrame: 19079
      },
      {
        text: 'else',
        fromFrame: 19079,
        toFrame: 19091
      },
      {
        text: 'in',
        fromFrame: 19097,
        toFrame: 19104
      },
      {
        text: 'next',
        fromFrame: 19104,
        toFrame: 19110
      },
      {
        text: 'to',
        fromFrame: 19110,
        toFrame: 19114
      },
      {
        text: 'sprint?',
        fromFrame: 19114,
        toFrame: 19126
      },
      {
        text: 'One',
        fromFrame: 19214,
        toFrame: 19219
      }
    ],
    fromFrame: 19062,
    toFrame: 19219,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "second. If Kashish is busy, I don't know.",
    tokens: [
      {
        text: 'second.',
        fromFrame: 19219,
        toFrame: 19231
      },
      {
        text: 'If',
        fromFrame: 19570,
        toFrame: 19579
      },
      {
        text: 'Kashish',
        fromFrame: 19579,
        toFrame: 19587
      },
      {
        text: 'is',
        fromFrame: 19587,
        toFrame: 19593
      },
      {
        text: 'busy,',
        fromFrame: 19593,
        toFrame: 19601
      },
      {
        text: 'I',
        fromFrame: 19601,
        toFrame: 19605
      },
      {
        text: "don't",
        fromFrame: 19605,
        toFrame: 19610
      },
      {
        text: 'know.',
        fromFrame: 19610,
        toFrame: 19612
      }
    ],
    fromFrame: 19219,
    toFrame: 19612,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I'll check, but I can I put can",
    tokens: [
      {
        text: "I'll",
        fromFrame: 19612,
        toFrame: 19616
      },
      {
        text: 'check,',
        fromFrame: 19616,
        toFrame: 19622
      },
      {
        text: 'but',
        fromFrame: 19622,
        toFrame: 19625
      },
      {
        text: 'I',
        fromFrame: 19625,
        toFrame: 19628
      },
      {
        text: 'can',
        fromFrame: 19629,
        toFrame: 19635
      },
      {
        text: 'I',
        fromFrame: 19635,
        toFrame: 19637
      },
      {
        text: 'put',
        fromFrame: 19637,
        toFrame: 19646
      },
      {
        text: 'can',
        fromFrame: 19646,
        toFrame: 19650
      }
    ],
    fromFrame: 19612,
    toFrame: 19650,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'we put it as medium? Yeah. No. Okay.',
    tokens: [
      {
        text: 'we',
        fromFrame: 19650,
        toFrame: 19655
      },
      {
        text: 'put',
        fromFrame: 19655,
        toFrame: 19660
      },
      {
        text: 'it',
        fromFrame: 19660,
        toFrame: 19666
      },
      {
        text: 'as',
        fromFrame: 19666,
        toFrame: 19671
      },
      {
        text: 'medium?',
        fromFrame: 19671,
        toFrame: 19683
      },
      {
        text: 'Yeah.',
        fromFrame: 19753,
        toFrame: 19765
      },
      {
        text: 'No.',
        fromFrame: 19922,
        toFrame: 19934
      },
      {
        text: 'Okay.',
        fromFrame: 20007,
        toFrame: 20019
      }
    ],
    fromFrame: 19650,
    toFrame: 20226,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Okay. One second. One more ticket link with',
    tokens: [
      {
        text: 'Okay.',
        fromFrame: 20226,
        toFrame: 20238
      },
      {
        text: 'One',
        fromFrame: 20344,
        toFrame: 20351
      },
      {
        text: 'second.',
        fromFrame: 20351,
        toFrame: 20363
      },
      {
        text: 'One',
        fromFrame: 20367,
        toFrame: 20372
      },
      {
        text: 'more',
        fromFrame: 20372,
        toFrame: 20378
      },
      {
        text: 'ticket',
        fromFrame: 20378,
        toFrame: 20388
      },
      {
        text: 'link',
        fromFrame: 20388,
        toFrame: 20397
      },
      {
        text: 'with',
        fromFrame: 20397,
        toFrame: 20401
      }
    ],
    fromFrame: 20226,
    toFrame: 20401,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'the same Mhmm. The below 1737. Also, for',
    tokens: [
      {
        text: 'the',
        fromFrame: 20401,
        toFrame: 20407
      },
      {
        text: 'same',
        fromFrame: 20407,
        toFrame: 20419
      },
      {
        text: 'Mhmm.',
        fromFrame: 20472,
        toFrame: 20484
      },
      {
        text: 'The',
        fromFrame: 20511,
        toFrame: 20516
      },
      {
        text: 'below',
        fromFrame: 20516,
        toFrame: 20522
      },
      {
        text: '1737.',
        fromFrame: 20522,
        toFrame: 20534
      },
      {
        text: 'Also,',
        fromFrame: 20582,
        toFrame: 20591
      },
      {
        text: 'for',
        fromFrame: 20591,
        toFrame: 20595
      }
    ],
    fromFrame: 20401,
    toFrame: 20595,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "this, currently, we're not showing the last uploaded,",
    tokens: [
      {
        text: 'this,',
        fromFrame: 20595,
        toFrame: 20607
      },
      {
        text: 'currently,',
        fromFrame: 20652,
        toFrame: 20663
      },
      {
        text: "we're",
        fromFrame: 20663,
        toFrame: 20671
      },
      {
        text: 'not',
        fromFrame: 20671,
        toFrame: 20679
      },
      {
        text: 'showing',
        fromFrame: 20679,
        toFrame: 20684
      },
      {
        text: 'the',
        fromFrame: 20684,
        toFrame: 20688
      },
      {
        text: 'last',
        fromFrame: 20688,
        toFrame: 20698
      },
      {
        text: 'uploaded,',
        fromFrame: 20698,
        toFrame: 20710
      }
    ],
    fromFrame: 20595,
    toFrame: 20727,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'font and color as a selected index upload.',
    tokens: [
      {
        text: 'font',
        fromFrame: 20727,
        toFrame: 20738
      },
      {
        text: 'and',
        fromFrame: 20738,
        toFrame: 20750
      },
      {
        text: 'color',
        fromFrame: 20750,
        toFrame: 20761
      },
      {
        text: 'as',
        fromFrame: 20761,
        toFrame: 20767
      },
      {
        text: 'a',
        fromFrame: 20767,
        toFrame: 20769
      },
      {
        text: 'selected',
        fromFrame: 20769,
        toFrame: 20781
      },
      {
        text: 'index',
        fromFrame: 20784,
        toFrame: 20794
      },
      {
        text: 'upload.',
        fromFrame: 20794,
        toFrame: 20806
      }
    ],
    fromFrame: 20727,
    toFrame: 20884,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Yeah. So this one this is a queue.',
    tokens: [
      {
        text: 'Yeah.',
        fromFrame: 20884,
        toFrame: 20895
      },
      {
        text: 'So',
        fromFrame: 20895,
        toFrame: 20903
      },
      {
        text: 'this',
        fromFrame: 20903,
        toFrame: 20913
      },
      {
        text: 'one',
        fromFrame: 20913,
        toFrame: 20920
      },
      {
        text: 'this',
        fromFrame: 20954,
        toFrame: 20960
      },
      {
        text: 'is',
        fromFrame: 20960,
        toFrame: 20965
      },
      {
        text: 'a',
        fromFrame: 20965,
        toFrame: 20971
      },
      {
        text: 'queue.',
        fromFrame: 20971,
        toFrame: 20983
      }
    ],
    fromFrame: 20884,
    toFrame: 20985,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Ajahn, he was not able to reach me.',
    tokens: [
      {
        text: 'Ajahn,',
        fromFrame: 20985,
        toFrame: 20996
      },
      {
        text: 'he',
        fromFrame: 20996,
        toFrame: 21000
      },
      {
        text: 'was',
        fromFrame: 21000,
        toFrame: 21004
      },
      {
        text: 'not',
        fromFrame: 21004,
        toFrame: 21010
      },
      {
        text: 'able',
        fromFrame: 21010,
        toFrame: 21015
      },
      {
        text: 'to',
        fromFrame: 21015,
        toFrame: 21021
      },
      {
        text: 'reach',
        fromFrame: 21021,
        toFrame: 21025
      },
      {
        text: 'me.',
        fromFrame: 21025,
        toFrame: 21037
      }
    ],
    fromFrame: 20985,
    toFrame: 21063,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "So what what content, what they're doing, they're,",
    tokens: [
      {
        text: 'So',
        fromFrame: 21063,
        toFrame: 21075
      },
      {
        text: 'what',
        fromFrame: 21077,
        toFrame: 21083
      },
      {
        text: 'what',
        fromFrame: 21083,
        toFrame: 21090
      },
      {
        text: 'content,',
        fromFrame: 21090,
        toFrame: 21102
      },
      {
        text: 'what',
        fromFrame: 21106,
        toFrame: 21113
      },
      {
        text: "they're",
        fromFrame: 21113,
        toFrame: 21121
      },
      {
        text: 'doing,',
        fromFrame: 21121,
        toFrame: 21129
      },
      {
        text: "they're,",
        fromFrame: 21129,
        toFrame: 21141
      }
    ],
    fromFrame: 21063,
    toFrame: 21150,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'taking the first listed font from blanket and',
    tokens: [
      {
        text: 'taking',
        fromFrame: 21150,
        toFrame: 21157
      },
      {
        text: 'the',
        fromFrame: 21157,
        toFrame: 21169
      },
      {
        text: 'first',
        fromFrame: 21173,
        toFrame: 21185
      },
      {
        text: 'listed',
        fromFrame: 21194,
        toFrame: 21206
      },
      {
        text: 'font',
        fromFrame: 21211,
        toFrame: 21219
      },
      {
        text: 'from',
        fromFrame: 21219,
        toFrame: 21227
      },
      {
        text: 'blanket',
        fromFrame: 21227,
        toFrame: 21238
      },
      {
        text: 'and',
        fromFrame: 21238,
        toFrame: 21250
      }
    ],
    fromFrame: 21150,
    toFrame: 21287,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'and then showing on the after you explain.',
    tokens: [
      {
        text: 'and',
        fromFrame: 21287,
        toFrame: 21291
      },
      {
        text: 'then',
        fromFrame: 21291,
        toFrame: 21299
      },
      {
        text: 'showing',
        fromFrame: 21299,
        toFrame: 21307
      },
      {
        text: 'on',
        fromFrame: 21307,
        toFrame: 21319
      },
      {
        text: 'the',
        fromFrame: 21322,
        toFrame: 21324
      },
      {
        text: 'after',
        fromFrame: 21326,
        toFrame: 21333
      },
      {
        text: 'you',
        fromFrame: 21333,
        toFrame: 21335
      },
      {
        text: 'explain.',
        fromFrame: 21335,
        toFrame: 21347
      }
    ],
    fromFrame: 21287,
    toFrame: 21368,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'So I think we can change the order',
    tokens: [
      {
        text: 'So',
        fromFrame: 21368,
        toFrame: 21376
      },
      {
        text: 'I',
        fromFrame: 21376,
        toFrame: 21378
      },
      {
        text: 'think',
        fromFrame: 21378,
        toFrame: 21385
      },
      {
        text: 'we',
        fromFrame: 21385,
        toFrame: 21393
      },
      {
        text: 'can',
        fromFrame: 21393,
        toFrame: 21401
      },
      {
        text: 'change',
        fromFrame: 21401,
        toFrame: 21412
      },
      {
        text: 'the',
        fromFrame: 21412,
        toFrame: 21418
      },
      {
        text: 'order',
        fromFrame: 21418,
        toFrame: 21429
      }
    ],
    fromFrame: 21368,
    toFrame: 21429,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'in blanket itself. Whatever the last selected color',
    tokens: [
      {
        text: 'in',
        fromFrame: 21429,
        toFrame: 21435
      },
      {
        text: 'blanket',
        fromFrame: 21435,
        toFrame: 21445
      },
      {
        text: 'itself.',
        fromFrame: 21445,
        toFrame: 21457
      },
      {
        text: 'Whatever',
        fromFrame: 21468,
        toFrame: 21479
      },
      {
        text: 'the',
        fromFrame: 21479,
        toFrame: 21483
      },
      {
        text: 'last',
        fromFrame: 21483,
        toFrame: 21495
      },
      {
        text: 'selected',
        fromFrame: 21499,
        toFrame: 21510
      },
      {
        text: 'color',
        fromFrame: 21510,
        toFrame: 21520
      }
    ],
    fromFrame: 21429,
    toFrame: 21520,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'should be come on first place. Yeah. So',
    tokens: [
      {
        text: 'should',
        fromFrame: 21520,
        toFrame: 21525
      },
      {
        text: 'be',
        fromFrame: 21525,
        toFrame: 21537
      },
      {
        text: 'come',
        fromFrame: 21543,
        toFrame: 21549
      },
      {
        text: 'on',
        fromFrame: 21549,
        toFrame: 21560
      },
      {
        text: 'first',
        fromFrame: 21560,
        toFrame: 21570
      },
      {
        text: 'place.',
        fromFrame: 21570,
        toFrame: 21582
      },
      {
        text: 'Yeah.',
        fromFrame: 21611,
        toFrame: 21617
      },
      {
        text: 'So',
        fromFrame: 21617,
        toFrame: 21623
      }
    ],
    fromFrame: 21520,
    toFrame: 21623,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'this, we are going to prioritize, actually not',
    tokens: [
      {
        text: 'this,',
        fromFrame: 21623,
        toFrame: 21630
      },
      {
        text: 'we',
        fromFrame: 21630,
        toFrame: 21634
      },
      {
        text: 'are',
        fromFrame: 21634,
        toFrame: 21640
      },
      {
        text: 'going',
        fromFrame: 21640,
        toFrame: 21646
      },
      {
        text: 'to',
        fromFrame: 21646,
        toFrame: 21658
      },
      {
        text: 'prioritize,',
        fromFrame: 21663,
        toFrame: 21675
      },
      {
        text: 'actually',
        fromFrame: 21776,
        toFrame: 21786
      },
      {
        text: 'not',
        fromFrame: 21786,
        toFrame: 21792
      }
    ],
    fromFrame: 21623,
    toFrame: 21792,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'discussed yet, but we do have some front',
    tokens: [
      {
        text: 'discussed',
        fromFrame: 21792,
        toFrame: 21801
      },
      {
        text: 'yet,',
        fromFrame: 21801,
        toFrame: 21807
      },
      {
        text: 'but',
        fromFrame: 21807,
        toFrame: 21811
      },
      {
        text: 'we',
        fromFrame: 21811,
        toFrame: 21816
      },
      {
        text: 'do',
        fromFrame: 21816,
        toFrame: 21822
      },
      {
        text: 'have',
        fromFrame: 21822,
        toFrame: 21828
      },
      {
        text: 'some',
        fromFrame: 21828,
        toFrame: 21832
      },
      {
        text: 'front',
        fromFrame: 21832,
        toFrame: 21840
      }
    ],
    fromFrame: 21792,
    toFrame: 21840,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'end bandwidth. Ashwin, do you remember which team',
    tokens: [
      {
        text: 'end',
        fromFrame: 21840,
        toFrame: 21843
      },
      {
        text: 'bandwidth.',
        fromFrame: 21843,
        toFrame: 21855
      },
      {
        text: 'Ashwin,',
        fromFrame: 21855,
        toFrame: 21864
      },
      {
        text: 'do',
        fromFrame: 21864,
        toFrame: 21868
      },
      {
        text: 'you',
        fromFrame: 21868,
        toFrame: 21872
      },
      {
        text: 'remember',
        fromFrame: 21872,
        toFrame: 21884
      },
      {
        text: 'which',
        fromFrame: 21889,
        toFrame: 21895
      },
      {
        text: 'team',
        fromFrame: 21895,
        toFrame: 21901
      }
    ],
    fromFrame: 21840,
    toFrame: 21901,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'has front end bandwidth? I think Mavericks only',
    tokens: [
      {
        text: 'has',
        fromFrame: 21901,
        toFrame: 21907
      },
      {
        text: 'front',
        fromFrame: 21907,
        toFrame: 21912
      },
      {
        text: 'end',
        fromFrame: 21912,
        toFrame: 21916
      },
      {
        text: 'bandwidth?',
        fromFrame: 21916,
        toFrame: 21928
      },
      {
        text: 'I',
        fromFrame: 21980,
        toFrame: 21986
      },
      {
        text: 'think',
        fromFrame: 21986,
        toFrame: 21998
      },
      {
        text: 'Mavericks',
        fromFrame: 21998,
        toFrame: 22009
      },
      {
        text: 'only',
        fromFrame: 22009,
        toFrame: 22017
      }
    ],
    fromFrame: 21901,
    toFrame: 22017,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'has front end bandwidth. You are not now,',
    tokens: [
      {
        text: 'has',
        fromFrame: 22017,
        toFrame: 22023
      },
      {
        text: 'front',
        fromFrame: 22023,
        toFrame: 22030
      },
      {
        text: 'end',
        fromFrame: 22030,
        toFrame: 22034
      },
      {
        text: 'bandwidth.',
        fromFrame: 22034,
        toFrame: 22046
      },
      {
        text: 'You',
        fromFrame: 22063,
        toFrame: 22069
      },
      {
        text: 'are',
        fromFrame: 22069,
        toFrame: 22072
      },
      {
        text: 'not',
        fromFrame: 22072,
        toFrame: 22082
      },
      {
        text: 'now,',
        fromFrame: 22082,
        toFrame: 22090
      }
    ],
    fromFrame: 22017,
    toFrame: 22090,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'but later probably. Yes. Yeah. So we but,',
    tokens: [
      {
        text: 'but',
        fromFrame: 22090,
        toFrame: 22099
      },
      {
        text: 'later',
        fromFrame: 22099,
        toFrame: 22111
      },
      {
        text: 'probably.',
        fromFrame: 22111,
        toFrame: 22120
      },
      {
        text: 'Yes.',
        fromFrame: 22120,
        toFrame: 22132
      },
      {
        text: 'Yeah.',
        fromFrame: 22136,
        toFrame: 22143
      },
      {
        text: 'So',
        fromFrame: 22143,
        toFrame: 22149
      },
      {
        text: 'we',
        fromFrame: 22149,
        toFrame: 22153
      },
      {
        text: 'but,',
        fromFrame: 22161,
        toFrame: 22168
      }
    ],
    fromFrame: 22090,
    toFrame: 22168,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'anyways, with this the black form teams, we',
    tokens: [
      {
        text: 'anyways,',
        fromFrame: 22168,
        toFrame: 22180
      },
      {
        text: 'with',
        fromFrame: 22184,
        toFrame: 22191
      },
      {
        text: 'this',
        fromFrame: 22191,
        toFrame: 22203
      },
      {
        text: 'the',
        fromFrame: 22224,
        toFrame: 22228
      },
      {
        text: 'black',
        fromFrame: 22228,
        toFrame: 22238
      },
      {
        text: 'form',
        fromFrame: 22238,
        toFrame: 22250
      },
      {
        text: 'teams,',
        fromFrame: 22261,
        toFrame: 22265
      },
      {
        text: 'we',
        fromFrame: 22265,
        toFrame: 22270
      }
    ],
    fromFrame: 22168,
    toFrame: 22270,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "have to pick this up anyways. So I'll",
    tokens: [
      {
        text: 'have',
        fromFrame: 22270,
        toFrame: 22276
      },
      {
        text: 'to',
        fromFrame: 22276,
        toFrame: 22282
      },
      {
        text: 'pick',
        fromFrame: 22282,
        toFrame: 22286
      },
      {
        text: 'this',
        fromFrame: 22286,
        toFrame: 22291
      },
      {
        text: 'up',
        fromFrame: 22291,
        toFrame: 22297
      },
      {
        text: 'anyways.',
        fromFrame: 22297,
        toFrame: 22309
      },
      {
        text: 'So',
        fromFrame: 22309,
        toFrame: 22316
      },
      {
        text: "I'll",
        fromFrame: 22316,
        toFrame: 22318
      }
    ],
    fromFrame: 22270,
    toFrame: 22318,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'club it there. But pretty far, this trend',
    tokens: [
      {
        text: 'club',
        fromFrame: 22318,
        toFrame: 22326
      },
      {
        text: 'it',
        fromFrame: 22326,
        toFrame: 22334
      },
      {
        text: 'there.',
        fromFrame: 22334,
        toFrame: 22346
      },
      {
        text: 'But',
        fromFrame: 22437,
        toFrame: 22445
      },
      {
        text: 'pretty',
        fromFrame: 22445,
        toFrame: 22453
      },
      {
        text: 'far,',
        fromFrame: 22453,
        toFrame: 22460
      },
      {
        text: 'this',
        fromFrame: 22460,
        toFrame: 22470
      },
      {
        text: 'trend',
        fromFrame: 22470,
        toFrame: 22478
      }
    ],
    fromFrame: 22318,
    toFrame: 22478,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "maybe we'll might not Because, what I want",
    tokens: [
      {
        text: 'maybe',
        fromFrame: 22478,
        toFrame: 22487
      },
      {
        text: "we'll",
        fromFrame: 22487,
        toFrame: 22499
      },
      {
        text: 'might',
        fromFrame: 22543,
        toFrame: 22549
      },
      {
        text: 'not',
        fromFrame: 22549,
        toFrame: 22560
      },
      {
        text: 'Because,',
        fromFrame: 22573,
        toFrame: 22583
      },
      {
        text: 'what',
        fromFrame: 22589,
        toFrame: 22601
      },
      {
        text: 'I',
        fromFrame: 22602,
        toFrame: 22608
      },
      {
        text: 'want',
        fromFrame: 22608,
        toFrame: 22614
      }
    ],
    fromFrame: 22478,
    toFrame: 22614,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'to, like, fix the entire experience. The entire',
    tokens: [
      {
        text: 'to,',
        fromFrame: 22614,
        toFrame: 22617
      },
      {
        text: 'like,',
        fromFrame: 22617,
        toFrame: 22623
      },
      {
        text: 'fix',
        fromFrame: 22623,
        toFrame: 22631
      },
      {
        text: 'the',
        fromFrame: 22631,
        toFrame: 22635
      },
      {
        text: 'entire',
        fromFrame: 22635,
        toFrame: 22644
      },
      {
        text: 'experience.',
        fromFrame: 22644,
        toFrame: 22656
      },
      {
        text: 'The',
        fromFrame: 22660,
        toFrame: 22667
      },
      {
        text: 'entire',
        fromFrame: 22667,
        toFrame: 22675
      }
    ],
    fromFrame: 22614,
    toFrame: 22675,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'experience for platform is bad now. Like, for',
    tokens: [
      {
        text: 'experience',
        fromFrame: 22675,
        toFrame: 22685
      },
      {
        text: 'for',
        fromFrame: 22685,
        toFrame: 22690
      },
      {
        text: 'platform',
        fromFrame: 22690,
        toFrame: 22702
      },
      {
        text: 'is',
        fromFrame: 22702,
        toFrame: 22708
      },
      {
        text: 'bad',
        fromFrame: 22708,
        toFrame: 22713
      },
      {
        text: 'now.',
        fromFrame: 22713,
        toFrame: 22725
      },
      {
        text: 'Like,',
        fromFrame: 22798,
        toFrame: 22804
      },
      {
        text: 'for',
        fromFrame: 22804,
        toFrame: 22809
      }
    ],
    fromFrame: 22675,
    toFrame: 22809,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'upload, I know it is We have these',
    tokens: [
      {
        text: 'upload,',
        fromFrame: 22809,
        toFrame: 22819
      },
      {
        text: 'I',
        fromFrame: 22819,
        toFrame: 22823
      },
      {
        text: 'know',
        fromFrame: 22823,
        toFrame: 22829
      },
      {
        text: 'it',
        fromFrame: 22829,
        toFrame: 22834
      },
      {
        text: 'is',
        fromFrame: 22834,
        toFrame: 22846
      },
      {
        text: 'We',
        fromFrame: 22857,
        toFrame: 22861
      },
      {
        text: 'have',
        fromFrame: 22861,
        toFrame: 22867
      },
      {
        text: 'these',
        fromFrame: 22867,
        toFrame: 22875
      }
    ],
    fromFrame: 22809,
    toFrame: 22875,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'issues, but maybe we can club these with',
    tokens: [
      {
        text: 'issues,',
        fromFrame: 22875,
        toFrame: 22884
      },
      {
        text: 'but',
        fromFrame: 22884,
        toFrame: 22890
      },
      {
        text: 'maybe',
        fromFrame: 22890,
        toFrame: 22898
      },
      {
        text: 'we',
        fromFrame: 22898,
        toFrame: 22902
      },
      {
        text: 'can',
        fromFrame: 22902,
        toFrame: 22907
      },
      {
        text: 'club',
        fromFrame: 22907,
        toFrame: 22917
      },
      {
        text: 'these',
        fromFrame: 22917,
        toFrame: 22923
      },
      {
        text: 'with',
        fromFrame: 22923,
        toFrame: 22935
      }
    ],
    fromFrame: 22875,
    toFrame: 22940,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "that change. We didn't get any complaints. Right?",
    tokens: [
      {
        text: 'that',
        fromFrame: 22940,
        toFrame: 22946
      },
      {
        text: 'change.',
        fromFrame: 22946,
        toFrame: 22958
      },
      {
        text: 'We',
        fromFrame: 23003,
        toFrame: 23009
      },
      {
        text: "didn't",
        fromFrame: 23009,
        toFrame: 23015
      },
      {
        text: 'get',
        fromFrame: 23015,
        toFrame: 23021
      },
      {
        text: 'any',
        fromFrame: 23021,
        toFrame: 23026
      },
      {
        text: 'complaints.',
        fromFrame: 23026,
        toFrame: 23038
      },
      {
        text: 'Right?',
        fromFrame: 23040,
        toFrame: 23052
      }
    ],
    fromFrame: 22940,
    toFrame: 23080,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Like, it's not look like a bug. Like,",
    tokens: [
      {
        text: 'Like,',
        fromFrame: 23080,
        toFrame: 23086
      },
      {
        text: "it's",
        fromFrame: 23086,
        toFrame: 23092
      },
      {
        text: 'not',
        fromFrame: 23092,
        toFrame: 23104
      },
      {
        text: 'look',
        fromFrame: 23132,
        toFrame: 23138
      },
      {
        text: 'like',
        fromFrame: 23138,
        toFrame: 23143
      },
      {
        text: 'a',
        fromFrame: 23143,
        toFrame: 23147
      },
      {
        text: 'bug.',
        fromFrame: 23147,
        toFrame: 23159
      },
      {
        text: 'Like,',
        fromFrame: 23163,
        toFrame: 23171
      }
    ],
    fromFrame: 23080,
    toFrame: 23171,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'no one knows that the last selected font',
    tokens: [
      {
        text: 'no',
        fromFrame: 23171,
        toFrame: 23173
      },
      {
        text: 'one',
        fromFrame: 23173,
        toFrame: 23178
      },
      {
        text: 'knows',
        fromFrame: 23178,
        toFrame: 23184
      },
      {
        text: 'that',
        fromFrame: 23184,
        toFrame: 23194
      },
      {
        text: 'the',
        fromFrame: 23194,
        toFrame: 23201
      },
      {
        text: 'last',
        fromFrame: 23201,
        toFrame: 23209
      },
      {
        text: 'selected',
        fromFrame: 23209,
        toFrame: 23219
      },
      {
        text: 'font',
        fromFrame: 23219,
        toFrame: 23226
      }
    ],
    fromFrame: 23171,
    toFrame: 23226,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'will be selected in next upload. Okay. Fine.',
    tokens: [
      {
        text: 'will',
        fromFrame: 23226,
        toFrame: 23230
      },
      {
        text: 'be',
        fromFrame: 23230,
        toFrame: 23234
      },
      {
        text: 'selected',
        fromFrame: 23234,
        toFrame: 23242
      },
      {
        text: 'in',
        fromFrame: 23242,
        toFrame: 23246
      },
      {
        text: 'next',
        fromFrame: 23246,
        toFrame: 23253
      },
      {
        text: 'upload.',
        fromFrame: 23253,
        toFrame: 23265
      },
      {
        text: 'Okay.',
        fromFrame: 23274,
        toFrame: 23286
      },
      {
        text: 'Fine.',
        fromFrame: 23470,
        toFrame: 23482
      }
    ],
    fromFrame: 23226,
    toFrame: 23544,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Yeah. This I have asked if you're able",
    tokens: [
      {
        text: 'Yeah.',
        fromFrame: 23544,
        toFrame: 23550
      },
      {
        text: 'This',
        fromFrame: 23550,
        toFrame: 23556
      },
      {
        text: 'I',
        fromFrame: 23556,
        toFrame: 23562
      },
      {
        text: 'have',
        fromFrame: 23562,
        toFrame: 23566
      },
      {
        text: 'asked',
        fromFrame: 23566,
        toFrame: 23573
      },
      {
        text: 'if',
        fromFrame: 23573,
        toFrame: 23577
      },
      {
        text: "you're",
        fromFrame: 23577,
        toFrame: 23581
      },
      {
        text: 'able',
        fromFrame: 23581,
        toFrame: 23587
      }
    ],
    fromFrame: 23544,
    toFrame: 23587,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "to check. She I can't write today. K.",
    tokens: [
      {
        text: 'to',
        fromFrame: 23587,
        toFrame: 23594
      },
      {
        text: 'check.',
        fromFrame: 23594,
        toFrame: 23604
      },
      {
        text: 'She',
        fromFrame: 23604,
        toFrame: 23616
      },
      {
        text: 'I',
        fromFrame: 23938,
        toFrame: 23940
      },
      {
        text: "can't",
        fromFrame: 23940,
        toFrame: 23950
      },
      {
        text: 'write',
        fromFrame: 23950,
        toFrame: 23955
      },
      {
        text: 'today.',
        fromFrame: 23955,
        toFrame: 23967
      },
      {
        text: 'K.',
        fromFrame: 24508,
        toFrame: 24520
      }
    ],
    fromFrame: 23587,
    toFrame: 24591,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Anything else? No. I think these are fine.',
    tokens: [
      {
        text: 'Anything',
        fromFrame: 24591,
        toFrame: 24603
      },
      {
        text: 'else?',
        fromFrame: 24606,
        toFrame: 24618
      },
      {
        text: 'No.',
        fromFrame: 24618,
        toFrame: 24627
      },
      {
        text: 'I',
        fromFrame: 24627,
        toFrame: 24637
      },
      {
        text: 'think',
        fromFrame: 24637,
        toFrame: 24649
      },
      {
        text: 'these',
        fromFrame: 24656,
        toFrame: 24658
      },
      {
        text: 'are',
        fromFrame: 24658,
        toFrame: 24662
      },
      {
        text: 'fine.',
        fromFrame: 24662,
        toFrame: 24674
      }
    ],
    fromFrame: 24591,
    toFrame: 24708,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'This one, I will leave it. Okay. I',
    tokens: [
      {
        text: 'This',
        fromFrame: 24708,
        toFrame: 24714
      },
      {
        text: 'one,',
        fromFrame: 24714,
        toFrame: 24726
      },
      {
        text: 'I',
        fromFrame: 24735,
        toFrame: 24747
      },
      {
        text: 'will',
        fromFrame: 24747,
        toFrame: 24752
      },
      {
        text: 'leave',
        fromFrame: 24752,
        toFrame: 24758
      },
      {
        text: 'it.',
        fromFrame: 24758,
        toFrame: 24770
      },
      {
        text: 'Okay.',
        fromFrame: 24770,
        toFrame: 24779
      },
      {
        text: 'I',
        fromFrame: 24779,
        toFrame: 24785
      }
    ],
    fromFrame: 24708,
    toFrame: 24785,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'have to check with Patricia and Goran. Okay.',
    tokens: [
      {
        text: 'have',
        fromFrame: 24785,
        toFrame: 24789
      },
      {
        text: 'to',
        fromFrame: 24789,
        toFrame: 24793
      },
      {
        text: 'check',
        fromFrame: 24793,
        toFrame: 24798
      },
      {
        text: 'with',
        fromFrame: 24798,
        toFrame: 24810
      },
      {
        text: 'Patricia',
        fromFrame: 24825,
        toFrame: 24837
      },
      {
        text: 'and',
        fromFrame: 24841,
        toFrame: 24844
      },
      {
        text: 'Goran.',
        fromFrame: 24844,
        toFrame: 24856
      },
      {
        text: 'Okay.',
        fromFrame: 24919,
        toFrame: 24931
      }
    ],
    fromFrame: 24785,
    toFrame: 25000,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Moving. Let's look at Ashwin's ticket. Yeah, Ashwin.",
    tokens: [
      {
        text: 'Moving.',
        fromFrame: 25000,
        toFrame: 25012
      },
      {
        text: "Let's",
        fromFrame: 25102,
        toFrame: 25110
      },
      {
        text: 'look',
        fromFrame: 25110,
        toFrame: 25115
      },
      {
        text: 'at',
        fromFrame: 25115,
        toFrame: 25119
      },
      {
        text: "Ashwin's",
        fromFrame: 25119,
        toFrame: 25131
      },
      {
        text: 'ticket.',
        fromFrame: 25133,
        toFrame: 25145
      },
      {
        text: 'Yeah,',
        fromFrame: 25209,
        toFrame: 25217
      },
      {
        text: 'Ashwin.',
        fromFrame: 25217,
        toFrame: 25229
      }
    ],
    fromFrame: 25000,
    toFrame: 25263,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I have 8 minutes only. Okay. Something I'll",
    tokens: [
      {
        text: 'I',
        fromFrame: 25263,
        toFrame: 25269
      },
      {
        text: 'have',
        fromFrame: 25269,
        toFrame: 25280
      },
      {
        text: '8',
        fromFrame: 25280,
        toFrame: 25288
      },
      {
        text: 'minutes',
        fromFrame: 25288,
        toFrame: 25298
      },
      {
        text: 'only.',
        fromFrame: 25298,
        toFrame: 25307
      },
      {
        text: 'Okay.',
        fromFrame: 25307,
        toFrame: 25319
      },
      {
        text: 'Something',
        fromFrame: 25353,
        toFrame: 25365
      },
      {
        text: "I'll",
        fromFrame: 25365,
        toFrame: 25371
      }
    ],
    fromFrame: 25263,
    toFrame: 25371,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'talk about this. I have text clip templates',
    tokens: [
      {
        text: 'talk',
        fromFrame: 25371,
        toFrame: 25380
      },
      {
        text: 'about',
        fromFrame: 25380,
        toFrame: 25382
      },
      {
        text: 'this.',
        fromFrame: 25382,
        toFrame: 25394
      },
      {
        text: 'I',
        fromFrame: 25430,
        toFrame: 25434
      },
      {
        text: 'have',
        fromFrame: 25434,
        toFrame: 25440
      },
      {
        text: 'text',
        fromFrame: 25440,
        toFrame: 25447
      },
      {
        text: 'clip',
        fromFrame: 25447,
        toFrame: 25455
      },
      {
        text: 'templates',
        fromFrame: 25455,
        toFrame: 25463
      }
    ],
    fromFrame: 25371,
    toFrame: 25463,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'assigned to me. When time clip from what',
    tokens: [
      {
        text: 'assigned',
        fromFrame: 25463,
        toFrame: 25472
      },
      {
        text: 'to',
        fromFrame: 25472,
        toFrame: 25476
      },
      {
        text: 'me.',
        fromFrame: 25476,
        toFrame: 25488
      },
      {
        text: 'When',
        fromFrame: 25497,
        toFrame: 25503
      },
      {
        text: 'time',
        fromFrame: 25503,
        toFrame: 25513
      },
      {
        text: 'clip',
        fromFrame: 25513,
        toFrame: 25518
      },
      {
        text: 'from',
        fromFrame: 25518,
        toFrame: 25530
      },
      {
        text: 'what',
        fromFrame: 25569,
        toFrame: 25573
      }
    ],
    fromFrame: 25463,
    toFrame: 25609,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'this is all ticket only. And this one,',
    tokens: [
      {
        text: 'this',
        fromFrame: 25609,
        toFrame: 25617
      },
      {
        text: 'is',
        fromFrame: 25617,
        toFrame: 25621
      },
      {
        text: 'all',
        fromFrame: 25621,
        toFrame: 25625
      },
      {
        text: 'ticket',
        fromFrame: 25625,
        toFrame: 25634
      },
      {
        text: 'only.',
        fromFrame: 25634,
        toFrame: 25646
      },
      {
        text: 'And',
        fromFrame: 25676,
        toFrame: 25682
      },
      {
        text: 'this',
        fromFrame: 25682,
        toFrame: 25688
      },
      {
        text: 'one,',
        fromFrame: 25688,
        toFrame: 25692
      }
    ],
    fromFrame: 25609,
    toFrame: 25692,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "move to the next one. I can let's",
    tokens: [
      {
        text: 'move',
        fromFrame: 25692,
        toFrame: 25696
      },
      {
        text: 'to',
        fromFrame: 25696,
        toFrame: 25699
      },
      {
        text: 'the',
        fromFrame: 25699,
        toFrame: 25701
      },
      {
        text: 'next',
        fromFrame: 25701,
        toFrame: 25709
      },
      {
        text: 'one.',
        fromFrame: 25709,
        toFrame: 25713
      },
      {
        text: 'I',
        fromFrame: 25713,
        toFrame: 25717
      },
      {
        text: 'can',
        fromFrame: 25717,
        toFrame: 25721
      },
      {
        text: "let's",
        fromFrame: 25724,
        toFrame: 25730
      }
    ],
    fromFrame: 25692,
    toFrame: 25730,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "see. I've added up a timeline. Next sprint,",
    tokens: [
      {
        text: 'see.',
        fromFrame: 25730,
        toFrame: 25738
      },
      {
        text: "I've",
        fromFrame: 25738,
        toFrame: 25742
      },
      {
        text: 'added',
        fromFrame: 25742,
        toFrame: 25747
      },
      {
        text: 'up',
        fromFrame: 25747,
        toFrame: 25749
      },
      {
        text: 'a',
        fromFrame: 25749,
        toFrame: 25753
      },
      {
        text: 'timeline.',
        fromFrame: 25753,
        toFrame: 25765
      },
      {
        text: 'Next',
        fromFrame: 25824,
        toFrame: 25830
      },
      {
        text: 'sprint,',
        fromFrame: 25830,
        toFrame: 25842
      }
    ],
    fromFrame: 25730,
    toFrame: 25845,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'you have a 38. 30 Dushan 38. 38.',
    tokens: [
      {
        text: 'you',
        fromFrame: 25845,
        toFrame: 25851
      },
      {
        text: 'have',
        fromFrame: 25851,
        toFrame: 25857
      },
      {
        text: 'a',
        fromFrame: 25857,
        toFrame: 25859
      },
      {
        text: '38.',
        fromFrame: 25859,
        toFrame: 25871
      },
      {
        text: '30',
        fromFrame: 25897,
        toFrame: 25909
      },
      {
        text: 'Dushan',
        fromFrame: 25947,
        toFrame: 25959
      },
      {
        text: '38.',
        fromFrame: 25962,
        toFrame: 25974
      },
      {
        text: '38.',
        fromFrame: 25978,
        toFrame: 25990
      }
    ],
    fromFrame: 25845,
    toFrame: 26030,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Okay. Now what is this? You only raised?',
    tokens: [
      {
        text: 'Okay.',
        fromFrame: 26030,
        toFrame: 26042
      },
      {
        text: 'Now',
        fromFrame: 26108,
        toFrame: 26114
      },
      {
        text: 'what',
        fromFrame: 26114,
        toFrame: 26120
      },
      {
        text: 'is',
        fromFrame: 26120,
        toFrame: 26124
      },
      {
        text: 'this?',
        fromFrame: 26124,
        toFrame: 26131
      },
      {
        text: 'You',
        fromFrame: 26131,
        toFrame: 26137
      },
      {
        text: 'only',
        fromFrame: 26137,
        toFrame: 26145
      },
      {
        text: 'raised?',
        fromFrame: 26145,
        toFrame: 26156
      }
    ],
    fromFrame: 26030,
    toFrame: 26156,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Okay. On this sticky raised. This one, I'll",
    tokens: [
      {
        text: 'Okay.',
        fromFrame: 26156,
        toFrame: 26168
      },
      {
        text: 'On',
        fromFrame: 26189,
        toFrame: 26193
      },
      {
        text: 'this',
        fromFrame: 26193,
        toFrame: 26199
      },
      {
        text: 'sticky',
        fromFrame: 26199,
        toFrame: 26210
      },
      {
        text: 'raised.',
        fromFrame: 26210,
        toFrame: 26222
      },
      {
        text: 'This',
        fromFrame: 26249,
        toFrame: 26255
      },
      {
        text: 'one,',
        fromFrame: 26255,
        toFrame: 26267
      },
      {
        text: "I'll",
        fromFrame: 26286,
        toFrame: 26298
      }
    ],
    fromFrame: 26156,
    toFrame: 26303,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'discuss this with you for how do we',
    tokens: [
      {
        text: 'discuss',
        fromFrame: 26303,
        toFrame: 26314
      },
      {
        text: 'this',
        fromFrame: 26314,
        toFrame: 26320
      },
      {
        text: 'with',
        fromFrame: 26320,
        toFrame: 26332
      },
      {
        text: 'you',
        fromFrame: 26341,
        toFrame: 26349
      },
      {
        text: 'for',
        fromFrame: 26349,
        toFrame: 26361
      },
      {
        text: 'how',
        fromFrame: 26366,
        toFrame: 26372
      },
      {
        text: 'do',
        fromFrame: 26372,
        toFrame: 26376
      },
      {
        text: 'we',
        fromFrame: 26376,
        toFrame: 26379
      }
    ],
    fromFrame: 26303,
    toFrame: 26379,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'end up showing the loader in those cases.',
    tokens: [
      {
        text: 'end',
        fromFrame: 26379,
        toFrame: 26383
      },
      {
        text: 'up',
        fromFrame: 26383,
        toFrame: 26385
      },
      {
        text: 'showing',
        fromFrame: 26385,
        toFrame: 26393
      },
      {
        text: 'the',
        fromFrame: 26393,
        toFrame: 26395
      },
      {
        text: 'loader',
        fromFrame: 26395,
        toFrame: 26404
      },
      {
        text: 'in',
        fromFrame: 26404,
        toFrame: 26406
      },
      {
        text: 'those',
        fromFrame: 26406,
        toFrame: 26410
      },
      {
        text: 'cases.',
        fromFrame: 26410,
        toFrame: 26422
      }
    ],
    fromFrame: 26379,
    toFrame: 26441,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "We don't know in which case that we",
    tokens: [
      {
        text: 'We',
        fromFrame: 26441,
        toFrame: 26445
      },
      {
        text: "don't",
        fromFrame: 26445,
        toFrame: 26448
      },
      {
        text: 'know',
        fromFrame: 26448,
        toFrame: 26452
      },
      {
        text: 'in',
        fromFrame: 26452,
        toFrame: 26454
      },
      {
        text: 'which',
        fromFrame: 26454,
        toFrame: 26460
      },
      {
        text: 'case',
        fromFrame: 26460,
        toFrame: 26466
      },
      {
        text: 'that',
        fromFrame: 26466,
        toFrame: 26470
      },
      {
        text: 'we',
        fromFrame: 26470,
        toFrame: 26473
      }
    ],
    fromFrame: 26441,
    toFrame: 26473,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'want. We can show the loader, which case',
    tokens: [
      {
        text: 'want.',
        fromFrame: 26473,
        toFrame: 26479
      },
      {
        text: 'We',
        fromFrame: 26479,
        toFrame: 26483
      },
      {
        text: 'can',
        fromFrame: 26483,
        toFrame: 26489
      },
      {
        text: 'show',
        fromFrame: 26489,
        toFrame: 26493
      },
      {
        text: 'the',
        fromFrame: 26493,
        toFrame: 26496
      },
      {
        text: 'loader,',
        fromFrame: 26496,
        toFrame: 26502
      },
      {
        text: 'which',
        fromFrame: 26502,
        toFrame: 26508
      },
      {
        text: 'case',
        fromFrame: 26508,
        toFrame: 26512
      }
    ],
    fromFrame: 26473,
    toFrame: 26512,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'we cannot show the loader. And even if',
    tokens: [
      {
        text: 'we',
        fromFrame: 26512,
        toFrame: 26516
      },
      {
        text: 'cannot',
        fromFrame: 26516,
        toFrame: 26521
      },
      {
        text: 'show',
        fromFrame: 26521,
        toFrame: 26525
      },
      {
        text: 'the',
        fromFrame: 26525,
        toFrame: 26529
      },
      {
        text: 'loader.',
        fromFrame: 26529,
        toFrame: 26538
      },
      {
        text: 'And',
        fromFrame: 26555,
        toFrame: 26561
      },
      {
        text: 'even',
        fromFrame: 26561,
        toFrame: 26569
      },
      {
        text: 'if',
        fromFrame: 26569,
        toFrame: 26571
      }
    ],
    fromFrame: 26512,
    toFrame: 26571,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "you show the phone to be always? It's",
    tokens: [
      {
        text: 'you',
        fromFrame: 26571,
        toFrame: 26572
      },
      {
        text: 'show',
        fromFrame: 26572,
        toFrame: 26578
      },
      {
        text: 'the',
        fromFrame: 26578,
        toFrame: 26582
      },
      {
        text: 'phone',
        fromFrame: 26582,
        toFrame: 26584
      },
      {
        text: 'to',
        fromFrame: 26584,
        toFrame: 26590
      },
      {
        text: 'be',
        fromFrame: 26590,
        toFrame: 26595
      },
      {
        text: 'always?',
        fromFrame: 26611,
        toFrame: 26623
      },
      {
        text: "It's",
        fromFrame: 26653,
        toFrame: 26661
      }
    ],
    fromFrame: 26571,
    toFrame: 26661,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'gonna be for a fraction of a second',
    tokens: [
      {
        text: 'gonna',
        fromFrame: 26661,
        toFrame: 26667
      },
      {
        text: 'be',
        fromFrame: 26667,
        toFrame: 26670
      },
      {
        text: 'for',
        fromFrame: 26670,
        toFrame: 26678
      },
      {
        text: 'a',
        fromFrame: 26678,
        toFrame: 26682
      },
      {
        text: 'fraction',
        fromFrame: 26682,
        toFrame: 26693
      },
      {
        text: 'of',
        fromFrame: 26693,
        toFrame: 26695
      },
      {
        text: 'a',
        fromFrame: 26695,
        toFrame: 26699
      },
      {
        text: 'second',
        fromFrame: 26699,
        toFrame: 26707
      }
    ],
    fromFrame: 26661,
    toFrame: 26707,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'probably. For 300 milliseconds, 500 milliseconds. Okay. But',
    tokens: [
      {
        text: 'probably.',
        fromFrame: 26707,
        toFrame: 26719
      },
      {
        text: 'For',
        fromFrame: 26730,
        toFrame: 26738
      },
      {
        text: '300',
        fromFrame: 26738,
        toFrame: 26749
      },
      {
        text: 'milliseconds,',
        fromFrame: 26749,
        toFrame: 26761
      },
      {
        text: '500',
        fromFrame: 26761,
        toFrame: 26768
      },
      {
        text: 'milliseconds.',
        fromFrame: 26768,
        toFrame: 26780
      },
      {
        text: 'Okay.',
        fromFrame: 26835,
        toFrame: 26847
      },
      {
        text: 'But',
        fromFrame: 26847,
        toFrame: 26851
      }
    ],
    fromFrame: 26707,
    toFrame: 26851,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'we can show the loader. Right? We can.',
    tokens: [
      {
        text: 'we',
        fromFrame: 26851,
        toFrame: 26855
      },
      {
        text: 'can',
        fromFrame: 26855,
        toFrame: 26859
      },
      {
        text: 'show',
        fromFrame: 26859,
        toFrame: 26864
      },
      {
        text: 'the',
        fromFrame: 26864,
        toFrame: 26868
      },
      {
        text: 'loader.',
        fromFrame: 26868,
        toFrame: 26878
      },
      {
        text: 'Right?',
        fromFrame: 26878,
        toFrame: 26890
      },
      {
        text: 'We',
        fromFrame: 26915,
        toFrame: 26924
      },
      {
        text: 'can.',
        fromFrame: 26924,
        toFrame: 26936
      }
    ],
    fromFrame: 26851,
    toFrame: 26995,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Instead, what we are showing blue screen. Instead,',
    tokens: [
      {
        text: 'Instead,',
        fromFrame: 26995,
        toFrame: 27005
      },
      {
        text: 'what',
        fromFrame: 27005,
        toFrame: 27009
      },
      {
        text: 'we',
        fromFrame: 27009,
        toFrame: 27011
      },
      {
        text: 'are',
        fromFrame: 27011,
        toFrame: 27015
      },
      {
        text: 'showing',
        fromFrame: 27015,
        toFrame: 27022
      },
      {
        text: 'blue',
        fromFrame: 27022,
        toFrame: 27030
      },
      {
        text: 'screen.',
        fromFrame: 27030,
        toFrame: 27042
      },
      {
        text: 'Instead,',
        fromFrame: 27049,
        toFrame: 27061
      }
    ],
    fromFrame: 26995,
    toFrame: 27078,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'we get a blue screen. Yes. Oh, yeah.',
    tokens: [
      {
        text: 'we',
        fromFrame: 27078,
        toFrame: 27082
      },
      {
        text: 'get',
        fromFrame: 27082,
        toFrame: 27086
      },
      {
        text: 'a',
        fromFrame: 27086,
        toFrame: 27088
      },
      {
        text: 'blue',
        fromFrame: 27088,
        toFrame: 27093
      },
      {
        text: 'screen.',
        fromFrame: 27093,
        toFrame: 27101
      },
      {
        text: 'Yes.',
        fromFrame: 27101,
        toFrame: 27113
      },
      {
        text: 'Oh,',
        fromFrame: 27113,
        toFrame: 27116
      },
      {
        text: 'yeah.',
        fromFrame: 27116,
        toFrame: 27124
      }
    ],
    fromFrame: 27078,
    toFrame: 27124,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Let's show the loader. We need to analyzing",
    tokens: [
      {
        text: "Let's",
        fromFrame: 27124,
        toFrame: 27134
      },
      {
        text: 'show',
        fromFrame: 27134,
        toFrame: 27139
      },
      {
        text: 'the',
        fromFrame: 27139,
        toFrame: 27143
      },
      {
        text: 'loader.',
        fromFrame: 27143,
        toFrame: 27155
      },
      {
        text: 'We',
        fromFrame: 27228,
        toFrame: 27232
      },
      {
        text: 'need',
        fromFrame: 27232,
        toFrame: 27235
      },
      {
        text: 'to',
        fromFrame: 27235,
        toFrame: 27239
      },
      {
        text: 'analyzing',
        fromFrame: 27239,
        toFrame: 27251
      }
    ],
    fromFrame: 27124,
    toFrame: 27255,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'our loader. The But the thing is that',
    tokens: [
      {
        text: 'our',
        fromFrame: 27255,
        toFrame: 27260
      },
      {
        text: 'loader.',
        fromFrame: 27260,
        toFrame: 27271
      },
      {
        text: 'The',
        fromFrame: 27330,
        toFrame: 27332
      },
      {
        text: 'But',
        fromFrame: 27344,
        toFrame: 27350
      },
      {
        text: 'the',
        fromFrame: 27350,
        toFrame: 27353
      },
      {
        text: 'thing',
        fromFrame: 27353,
        toFrame: 27359
      },
      {
        text: 'is',
        fromFrame: 27359,
        toFrame: 27363
      },
      {
        text: 'that',
        fromFrame: 27363,
        toFrame: 27375
      }
    ],
    fromFrame: 27255,
    toFrame: 27386,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'the analyzing great page. Wow. I only remember',
    tokens: [
      {
        text: 'the',
        fromFrame: 27386,
        toFrame: 27390
      },
      {
        text: 'analyzing',
        fromFrame: 27390,
        toFrame: 27402
      },
      {
        text: 'great',
        fromFrame: 27444,
        toFrame: 27453
      },
      {
        text: 'page.',
        fromFrame: 27453,
        toFrame: 27465
      },
      {
        text: 'Wow.',
        fromFrame: 27467,
        toFrame: 27479
      },
      {
        text: 'I',
        fromFrame: 27507,
        toFrame: 27511
      },
      {
        text: 'only',
        fromFrame: 27511,
        toFrame: 27519
      },
      {
        text: 'remember',
        fromFrame: 27519,
        toFrame: 27528
      }
    ],
    fromFrame: 27386,
    toFrame: 27528,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "it's great. No. But the thing is that",
    tokens: [
      {
        text: "it's",
        fromFrame: 27528,
        toFrame: 27536
      },
      {
        text: 'great.',
        fromFrame: 27536,
        toFrame: 27548
      },
      {
        text: 'No.',
        fromFrame: 27557,
        toFrame: 27559
      },
      {
        text: 'But',
        fromFrame: 27563,
        toFrame: 27567
      },
      {
        text: 'the',
        fromFrame: 27567,
        toFrame: 27569
      },
      {
        text: 'thing',
        fromFrame: 27569,
        toFrame: 27570
      },
      {
        text: 'is',
        fromFrame: 27570,
        toFrame: 27571
      },
      {
        text: 'that',
        fromFrame: 27571,
        toFrame: 27576
      }
    ],
    fromFrame: 27528,
    toFrame: 27576,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "it's not a completely accurate if you're analyzing",
    tokens: [
      {
        text: "it's",
        fromFrame: 27576,
        toFrame: 27582
      },
      {
        text: 'not',
        fromFrame: 27582,
        toFrame: 27588
      },
      {
        text: 'a',
        fromFrame: 27588,
        toFrame: 27590
      },
      {
        text: 'completely',
        fromFrame: 27590,
        toFrame: 27600
      },
      {
        text: 'accurate',
        fromFrame: 27600,
        toFrame: 27607
      },
      {
        text: 'if',
        fromFrame: 27607,
        toFrame: 27611
      },
      {
        text: "you're",
        fromFrame: 27611,
        toFrame: 27615
      },
      {
        text: 'analyzing',
        fromFrame: 27615,
        toFrame: 27626
      }
    ],
    fromFrame: 27576,
    toFrame: 27626,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "because it's not it may not be analyzing.",
    tokens: [
      {
        text: 'because',
        fromFrame: 27626,
        toFrame: 27632
      },
      {
        text: "it's",
        fromFrame: 27632,
        toFrame: 27636
      },
      {
        text: 'not',
        fromFrame: 27636,
        toFrame: 27640
      },
      {
        text: 'it',
        fromFrame: 27642,
        toFrame: 27646
      },
      {
        text: 'may',
        fromFrame: 27646,
        toFrame: 27651
      },
      {
        text: 'not',
        fromFrame: 27651,
        toFrame: 27657
      },
      {
        text: 'be',
        fromFrame: 27657,
        toFrame: 27661
      },
      {
        text: 'analyzing.',
        fromFrame: 27661,
        toFrame: 27673
      }
    ],
    fromFrame: 27626,
    toFrame: 27682,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'But okay. You can show it. Fine. Oh,',
    tokens: [
      {
        text: 'But',
        fromFrame: 27682,
        toFrame: 27685
      },
      {
        text: 'okay.',
        fromFrame: 27688,
        toFrame: 27696
      },
      {
        text: 'You',
        fromFrame: 27696,
        toFrame: 27699
      },
      {
        text: 'can',
        fromFrame: 27699,
        toFrame: 27705
      },
      {
        text: 'show',
        fromFrame: 27705,
        toFrame: 27709
      },
      {
        text: 'it.',
        fromFrame: 27709,
        toFrame: 27719
      },
      {
        text: 'Fine.',
        fromFrame: 27719,
        toFrame: 27731
      },
      {
        text: 'Oh,',
        fromFrame: 27742,
        toFrame: 27747
      }
    ],
    fromFrame: 27682,
    toFrame: 27747,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "show a loader also screen if you don't",
    tokens: [
      {
        text: 'show',
        fromFrame: 27747,
        toFrame: 27759
      },
      {
        text: 'a',
        fromFrame: 27763,
        toFrame: 27767
      },
      {
        text: 'loader',
        fromFrame: 27767,
        toFrame: 27778
      },
      {
        text: 'also',
        fromFrame: 27778,
        toFrame: 27790
      },
      {
        text: 'screen',
        fromFrame: 27790,
        toFrame: 27799
      },
      {
        text: 'if',
        fromFrame: 27799,
        toFrame: 27803
      },
      {
        text: 'you',
        fromFrame: 27803,
        toFrame: 27807
      },
      {
        text: "don't",
        fromFrame: 27807,
        toFrame: 27813
      }
    ],
    fromFrame: 27747,
    toFrame: 27813,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "want to. Then after load, we'll show analyzing",
    tokens: [
      {
        text: 'want',
        fromFrame: 27813,
        toFrame: 27816
      },
      {
        text: 'to.',
        fromFrame: 27816,
        toFrame: 27828
      },
      {
        text: 'Then',
        fromFrame: 27847,
        toFrame: 27855
      },
      {
        text: 'after',
        fromFrame: 27855,
        toFrame: 27861
      },
      {
        text: 'load,',
        fromFrame: 27861,
        toFrame: 27864
      },
      {
        text: "we'll",
        fromFrame: 27864,
        toFrame: 27870
      },
      {
        text: 'show',
        fromFrame: 27870,
        toFrame: 27874
      },
      {
        text: 'analyzing',
        fromFrame: 27874,
        toFrame: 27886
      }
    ],
    fromFrame: 27813,
    toFrame: 27886,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "if you're actually analyzing. Like, it will be",
    tokens: [
      {
        text: 'if',
        fromFrame: 27886,
        toFrame: 27888
      },
      {
        text: "you're",
        fromFrame: 27888,
        toFrame: 27891
      },
      {
        text: 'actually',
        fromFrame: 27891,
        toFrame: 27897
      },
      {
        text: 'analyzing.',
        fromFrame: 27897,
        toFrame: 27909
      },
      {
        text: 'Like,',
        fromFrame: 28001,
        toFrame: 28007
      },
      {
        text: 'it',
        fromFrame: 28007,
        toFrame: 28009
      },
      {
        text: 'will',
        fromFrame: 28009,
        toFrame: 28014
      },
      {
        text: 'be',
        fromFrame: 28014,
        toFrame: 28016
      }
    ],
    fromFrame: 27886,
    toFrame: 28016,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'analyzing on this. So after this state, you',
    tokens: [
      {
        text: 'analyzing',
        fromFrame: 28016,
        toFrame: 28024
      },
      {
        text: 'on',
        fromFrame: 28024,
        toFrame: 28026
      },
      {
        text: 'this.',
        fromFrame: 28026,
        toFrame: 28034
      },
      {
        text: 'So',
        fromFrame: 28034,
        toFrame: 28037
      },
      {
        text: 'after',
        fromFrame: 28037,
        toFrame: 28043
      },
      {
        text: 'this',
        fromFrame: 28043,
        toFrame: 28047
      },
      {
        text: 'state,',
        fromFrame: 28047,
        toFrame: 28053
      },
      {
        text: 'you',
        fromFrame: 28053,
        toFrame: 28059
      }
    ],
    fromFrame: 28016,
    toFrame: 28059,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'will show that, mapping pop up. This should',
    tokens: [
      {
        text: 'will',
        fromFrame: 28059,
        toFrame: 28062
      },
      {
        text: 'show',
        fromFrame: 28062,
        toFrame: 28068
      },
      {
        text: 'that,',
        fromFrame: 28068,
        toFrame: 28078
      },
      {
        text: 'mapping',
        fromFrame: 28087,
        toFrame: 28099
      },
      {
        text: 'pop',
        fromFrame: 28149,
        toFrame: 28156
      },
      {
        text: 'up.',
        fromFrame: 28156,
        toFrame: 28168
      },
      {
        text: 'This',
        fromFrame: 28187,
        toFrame: 28195
      },
      {
        text: 'should',
        fromFrame: 28195,
        toFrame: 28201
      }
    ],
    fromFrame: 28059,
    toFrame: 28201,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'also not come up now. No? Because we',
    tokens: [
      {
        text: 'also',
        fromFrame: 28201,
        toFrame: 28209
      },
      {
        text: 'not',
        fromFrame: 28216,
        toFrame: 28220
      },
      {
        text: 'come',
        fromFrame: 28220,
        toFrame: 28226
      },
      {
        text: 'up',
        fromFrame: 28226,
        toFrame: 28232
      },
      {
        text: 'now.',
        fromFrame: 28232,
        toFrame: 28237
      },
      {
        text: 'No?',
        fromFrame: 28237,
        toFrame: 28239
      },
      {
        text: 'Because',
        fromFrame: 28239,
        toFrame: 28251
      },
      {
        text: 'we',
        fromFrame: 28251,
        toFrame: 28260
      }
    ],
    fromFrame: 28201,
    toFrame: 28260,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "auto It's all already mapped. Yeah. How do",
    tokens: [
      {
        text: 'auto',
        fromFrame: 28260,
        toFrame: 28268
      },
      {
        text: "It's",
        fromFrame: 28274,
        toFrame: 28280
      },
      {
        text: 'all',
        fromFrame: 28280,
        toFrame: 28285
      },
      {
        text: 'already',
        fromFrame: 28293,
        toFrame: 28301
      },
      {
        text: 'mapped.',
        fromFrame: 28301,
        toFrame: 28313
      },
      {
        text: 'Yeah.',
        fromFrame: 28328,
        toFrame: 28339
      },
      {
        text: 'How',
        fromFrame: 28339,
        toFrame: 28343
      },
      {
        text: 'do',
        fromFrame: 28343,
        toFrame: 28347
      }
    ],
    fromFrame: 28260,
    toFrame: 28347,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'I do it? If there is no map',
    tokens: [
      {
        text: 'I',
        fromFrame: 28347,
        toFrame: 28351
      },
      {
        text: 'do',
        fromFrame: 28351,
        toFrame: 28355
      },
      {
        text: 'it?',
        fromFrame: 28355,
        toFrame: 28367
      },
      {
        text: 'If',
        fromFrame: 28389,
        toFrame: 28395
      },
      {
        text: 'there',
        fromFrame: 28395,
        toFrame: 28401
      },
      {
        text: 'is',
        fromFrame: 28401,
        toFrame: 28408
      },
      {
        text: 'no',
        fromFrame: 28408,
        toFrame: 28416
      },
      {
        text: 'map',
        fromFrame: 28416,
        toFrame: 28428
      }
    ],
    fromFrame: 28347,
    toFrame: 28429,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'existing, then only it will show. But this,',
    tokens: [
      {
        text: 'existing,',
        fromFrame: 28429,
        toFrame: 28441
      },
      {
        text: 'then',
        fromFrame: 28441,
        toFrame: 28447
      },
      {
        text: 'only',
        fromFrame: 28447,
        toFrame: 28452
      },
      {
        text: 'it',
        fromFrame: 28452,
        toFrame: 28454
      },
      {
        text: 'will',
        fromFrame: 28454,
        toFrame: 28460
      },
      {
        text: 'show.',
        fromFrame: 28460,
        toFrame: 28472
      },
      {
        text: 'But',
        fromFrame: 28489,
        toFrame: 28493
      },
      {
        text: 'this,',
        fromFrame: 28493,
        toFrame: 28504
      }
    ],
    fromFrame: 28429,
    toFrame: 28504,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Ashwin, do you have bandwidth for the coming',
    tokens: [
      {
        text: 'Ashwin,',
        fromFrame: 28504,
        toFrame: 28516
      },
      {
        text: 'do',
        fromFrame: 28518,
        toFrame: 28522
      },
      {
        text: 'you',
        fromFrame: 28522,
        toFrame: 28525
      },
      {
        text: 'have',
        fromFrame: 28525,
        toFrame: 28531
      },
      {
        text: 'bandwidth',
        fromFrame: 28531,
        toFrame: 28543
      },
      {
        text: 'for',
        fromFrame: 28569,
        toFrame: 28573
      },
      {
        text: 'the',
        fromFrame: 28573,
        toFrame: 28579
      },
      {
        text: 'coming',
        fromFrame: 28579,
        toFrame: 28591
      }
    ],
    fromFrame: 28504,
    toFrame: 28591,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'sprint? You have you have already something to',
    tokens: [
      {
        text: 'sprint?',
        fromFrame: 28591,
        toFrame: 28603
      },
      {
        text: 'You',
        fromFrame: 28673,
        toFrame: 28675
      },
      {
        text: 'have',
        fromFrame: 28675,
        toFrame: 28687
      },
      {
        text: 'you',
        fromFrame: 28712,
        toFrame: 28717
      },
      {
        text: 'have',
        fromFrame: 28717,
        toFrame: 28723
      },
      {
        text: 'already',
        fromFrame: 28723,
        toFrame: 28733
      },
      {
        text: 'something',
        fromFrame: 28733,
        toFrame: 28742
      },
      {
        text: 'to',
        fromFrame: 28742,
        toFrame: 28746
      }
    ],
    fromFrame: 28591,
    toFrame: 28746,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'deliver. Right? The motion. Yes. Okay. Can you',
    tokens: [
      {
        text: 'deliver.',
        fromFrame: 28746,
        toFrame: 28758
      },
      {
        text: 'Right?',
        fromFrame: 28758,
        toFrame: 28769
      },
      {
        text: 'The',
        fromFrame: 28769,
        toFrame: 28775
      },
      {
        text: 'motion.',
        fromFrame: 28775,
        toFrame: 28787
      },
      {
        text: 'Yes.',
        fromFrame: 28813,
        toFrame: 28825
      },
      {
        text: 'Okay.',
        fromFrame: 28871,
        toFrame: 28883
      },
      {
        text: 'Can',
        fromFrame: 28905,
        toFrame: 28911
      },
      {
        text: 'you',
        fromFrame: 28911,
        toFrame: 28917
      }
    ],
    fromFrame: 28746,
    toFrame: 28917,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'take any bugs? For now, at least medium',
    tokens: [
      {
        text: 'take',
        fromFrame: 28917,
        toFrame: 28923
      },
      {
        text: 'any',
        fromFrame: 28923,
        toFrame: 28929
      },
      {
        text: 'bugs?',
        fromFrame: 28929,
        toFrame: 28941
      },
      {
        text: 'For',
        fromFrame: 28956,
        toFrame: 28963
      },
      {
        text: 'now,',
        fromFrame: 28963,
        toFrame: 28967
      },
      {
        text: 'at',
        fromFrame: 28967,
        toFrame: 28971
      },
      {
        text: 'least',
        fromFrame: 28971,
        toFrame: 28981
      },
      {
        text: 'medium',
        fromFrame: 28981,
        toFrame: 28990
      }
    ],
    fromFrame: 28917,
    toFrame: 28990,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "ones, I'm This one, take 2508, I'll take.",
    tokens: [
      {
        text: 'ones,',
        fromFrame: 28990,
        toFrame: 29000
      },
      {
        text: "I'm",
        fromFrame: 29000,
        toFrame: 29004
      },
      {
        text: 'This',
        fromFrame: 29013,
        toFrame: 29019
      },
      {
        text: 'one,',
        fromFrame: 29019,
        toFrame: 29025
      },
      {
        text: 'take',
        fromFrame: 29025,
        toFrame: 29033
      },
      {
        text: '2508,',
        fromFrame: 29033,
        toFrame: 29045
      },
      {
        text: "I'll",
        fromFrame: 29050,
        toFrame: 29054
      },
      {
        text: 'take.',
        fromFrame: 29054,
        toFrame: 29066
      }
    ],
    fromFrame: 28990,
    toFrame: 29121,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "2508 or 2899? No. You'll take. Okay. Yeah.",
    tokens: [
      {
        text: '2508',
        fromFrame: 29121,
        toFrame: 29133
      },
      {
        text: 'or',
        fromFrame: 29136,
        toFrame: 29142
      },
      {
        text: '2899?',
        fromFrame: 29142,
        toFrame: 29154
      },
      {
        text: 'No.',
        fromFrame: 29211,
        toFrame: 29223
      },
      {
        text: "You'll",
        fromFrame: 29228,
        toFrame: 29234
      },
      {
        text: 'take.',
        fromFrame: 29234,
        toFrame: 29242
      },
      {
        text: 'Okay.',
        fromFrame: 29242,
        toFrame: 29254
      },
      {
        text: 'Yeah.',
        fromFrame: 29263,
        toFrame: 29275
      }
    ],
    fromFrame: 29121,
    toFrame: 29343,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Other things. Yeah. That after 3222zeroslow priority. So',
    tokens: [
      {
        text: 'Other',
        fromFrame: 29343,
        toFrame: 29353
      },
      {
        text: 'things.',
        fromFrame: 29353,
        toFrame: 29365
      },
      {
        text: 'Yeah.',
        fromFrame: 29424,
        toFrame: 29436
      },
      {
        text: 'That',
        fromFrame: 29457,
        toFrame: 29464
      },
      {
        text: 'after',
        fromFrame: 29464,
        toFrame: 29470
      },
      {
        text: '3222zeroslow',
        fromFrame: 29470,
        toFrame: 29482
      },
      {
        text: 'priority.',
        fromFrame: 29526,
        toFrame: 29538
      },
      {
        text: 'So',
        fromFrame: 29947,
        toFrame: 29954
      }
    ],
    fromFrame: 29343,
    toFrame: 29954,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'what is the conclusion? It is not happening',
    tokens: [
      {
        text: 'what',
        fromFrame: 29954,
        toFrame: 29960
      },
      {
        text: 'is',
        fromFrame: 29960,
        toFrame: 29964
      },
      {
        text: 'the',
        fromFrame: 29964,
        toFrame: 29970
      },
      {
        text: 'conclusion?',
        fromFrame: 29970,
        toFrame: 29982
      },
      {
        text: 'It',
        fromFrame: 29995,
        toFrame: 29998
      },
      {
        text: 'is',
        fromFrame: 29998,
        toFrame: 30006
      },
      {
        text: 'not',
        fromFrame: 30006,
        toFrame: 30014
      },
      {
        text: 'happening',
        fromFrame: 30014,
        toFrame: 30023
      }
    ],
    fromFrame: 29954,
    toFrame: 30023,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "anymore. Yeah. Yeah. I'll should I close this,",
    tokens: [
      {
        text: 'anymore.',
        fromFrame: 30023,
        toFrame: 30035
      },
      {
        text: 'Yeah.',
        fromFrame: 30035,
        toFrame: 30047
      },
      {
        text: 'Yeah.',
        fromFrame: 30093,
        toFrame: 30105
      },
      {
        text: "I'll",
        fromFrame: 30123,
        toFrame: 30129
      },
      {
        text: 'should',
        fromFrame: 30133,
        toFrame: 30137
      },
      {
        text: 'I',
        fromFrame: 30137,
        toFrame: 30141
      },
      {
        text: 'close',
        fromFrame: 30141,
        toFrame: 30148
      },
      {
        text: 'this,',
        fromFrame: 30148,
        toFrame: 30154
      }
    ],
    fromFrame: 30023,
    toFrame: 30154,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Priti? I don't remember. What does the issue?",
    tokens: [
      {
        text: 'Priti?',
        fromFrame: 30154,
        toFrame: 30166
      },
      {
        text: 'I',
        fromFrame: 30244,
        toFrame: 30252
      },
      {
        text: "don't",
        fromFrame: 30252,
        toFrame: 30259
      },
      {
        text: 'remember.',
        fromFrame: 30259,
        toFrame: 30271
      },
      {
        text: 'What',
        fromFrame: 30284,
        toFrame: 30288
      },
      {
        text: 'does',
        fromFrame: 30288,
        toFrame: 30292
      },
      {
        text: 'the',
        fromFrame: 30292,
        toFrame: 30296
      },
      {
        text: 'issue?',
        fromFrame: 30296,
        toFrame: 30308
      }
    ],
    fromFrame: 30154,
    toFrame: 30330,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Basically, you see, something went wrong. Okay. I'll",
    tokens: [
      {
        text: 'Basically,',
        fromFrame: 30330,
        toFrame: 30342
      },
      {
        text: 'you',
        fromFrame: 30348,
        toFrame: 30352
      },
      {
        text: 'see,',
        fromFrame: 30352,
        toFrame: 30361
      },
      {
        text: 'something',
        fromFrame: 30365,
        toFrame: 30377
      },
      {
        text: 'went',
        fromFrame: 30378,
        toFrame: 30384
      },
      {
        text: 'wrong.',
        fromFrame: 30384,
        toFrame: 30396
      },
      {
        text: 'Okay.',
        fromFrame: 30498,
        toFrame: 30510
      },
      {
        text: "I'll",
        fromFrame: 30529,
        toFrame: 30532
      }
    ],
    fromFrame: 30330,
    toFrame: 30532,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'keep it open. I think I observed this,',
    tokens: [
      {
        text: 'keep',
        fromFrame: 30532,
        toFrame: 30538
      },
      {
        text: 'it',
        fromFrame: 30538,
        toFrame: 30542
      },
      {
        text: 'open.',
        fromFrame: 30542,
        toFrame: 30552
      },
      {
        text: 'I',
        fromFrame: 30552,
        toFrame: 30554
      },
      {
        text: 'think',
        fromFrame: 30554,
        toFrame: 30559
      },
      {
        text: 'I',
        fromFrame: 30559,
        toFrame: 30563
      },
      {
        text: 'observed',
        fromFrame: 30563,
        toFrame: 30575
      },
      {
        text: 'this,',
        fromFrame: 30575,
        toFrame: 30587
      }
    ],
    fromFrame: 30532,
    toFrame: 30650,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "in last situation. The one thing, I'll send",
    tokens: [
      {
        text: 'in',
        fromFrame: 30650,
        toFrame: 30655
      },
      {
        text: 'last',
        fromFrame: 30655,
        toFrame: 30663
      },
      {
        text: 'situation.',
        fromFrame: 30663,
        toFrame: 30675
      },
      {
        text: 'The',
        fromFrame: 30675,
        toFrame: 30676
      },
      {
        text: 'one',
        fromFrame: 30676,
        toFrame: 30680
      },
      {
        text: 'thing,',
        fromFrame: 30680,
        toFrame: 30684
      },
      {
        text: "I'll",
        fromFrame: 30684,
        toFrame: 30690
      },
      {
        text: 'send',
        fromFrame: 30690,
        toFrame: 30694
      }
    ],
    fromFrame: 30650,
    toFrame: 30694,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'it to Preeti so that she can keep',
    tokens: [
      {
        text: 'it',
        fromFrame: 30694,
        toFrame: 30698
      },
      {
        text: 'to',
        fromFrame: 30698,
        toFrame: 30699
      },
      {
        text: 'Preeti',
        fromFrame: 30699,
        toFrame: 30711
      },
      {
        text: 'so',
        fromFrame: 30751,
        toFrame: 30757
      },
      {
        text: 'that',
        fromFrame: 30757,
        toFrame: 30763
      },
      {
        text: 'she',
        fromFrame: 30763,
        toFrame: 30767
      },
      {
        text: 'can',
        fromFrame: 30767,
        toFrame: 30771
      },
      {
        text: 'keep',
        fromFrame: 30771,
        toFrame: 30776
      }
    ],
    fromFrame: 30694,
    toFrame: 30776,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "track of her ticket forms again. I don't",
    tokens: [
      {
        text: 'track',
        fromFrame: 30776,
        toFrame: 30782
      },
      {
        text: 'of',
        fromFrame: 30782,
        toFrame: 30786
      },
      {
        text: 'her',
        fromFrame: 30786,
        toFrame: 30790
      },
      {
        text: 'ticket',
        fromFrame: 30790,
        toFrame: 30797
      },
      {
        text: 'forms',
        fromFrame: 30797,
        toFrame: 30805
      },
      {
        text: 'again.',
        fromFrame: 30805,
        toFrame: 30817
      },
      {
        text: 'I',
        fromFrame: 31393,
        toFrame: 31405
      },
      {
        text: "don't",
        fromFrame: 31410,
        toFrame: 31422
      }
    ],
    fromFrame: 30776,
    toFrame: 31427,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'think so, but we can this is medium',
    tokens: [
      {
        text: 'think',
        fromFrame: 31427,
        toFrame: 31439
      },
      {
        text: 'so,',
        fromFrame: 31443,
        toFrame: 31451
      },
      {
        text: 'but',
        fromFrame: 31451,
        toFrame: 31463
      },
      {
        text: 'we',
        fromFrame: 31468,
        toFrame: 31480
      },
      {
        text: 'can',
        fromFrame: 31487,
        toFrame: 31495
      },
      {
        text: 'this',
        fromFrame: 31497,
        toFrame: 31503
      },
      {
        text: 'is',
        fromFrame: 31503,
        toFrame: 31509
      },
      {
        text: 'medium',
        fromFrame: 31509,
        toFrame: 31516
      }
    ],
    fromFrame: 31427,
    toFrame: 31516,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "only. Right? I'll move it to where did",
    tokens: [
      {
        text: 'only.',
        fromFrame: 31516,
        toFrame: 31524
      },
      {
        text: 'Right?',
        fromFrame: 31524,
        toFrame: 31532
      },
      {
        text: "I'll",
        fromFrame: 31532,
        toFrame: 31537
      },
      {
        text: 'move',
        fromFrame: 31537,
        toFrame: 31543
      },
      {
        text: 'it',
        fromFrame: 31543,
        toFrame: 31547
      },
      {
        text: 'to',
        fromFrame: 31547,
        toFrame: 31559
      },
      {
        text: 'where',
        fromFrame: 31634,
        toFrame: 31638
      },
      {
        text: 'did',
        fromFrame: 31638,
        toFrame: 31644
      }
    ],
    fromFrame: 31516,
    toFrame: 31644,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'it go? Oh, I signed it to Preeti.',
    tokens: [
      {
        text: 'it',
        fromFrame: 31644,
        toFrame: 31648
      },
      {
        text: 'go?',
        fromFrame: 31648,
        toFrame: 31660
      },
      {
        text: 'Oh,',
        fromFrame: 31678,
        toFrame: 31684
      },
      {
        text: 'I',
        fromFrame: 31684,
        toFrame: 31690
      },
      {
        text: 'signed',
        fromFrame: 31690,
        toFrame: 31696
      },
      {
        text: 'it',
        fromFrame: 31696,
        toFrame: 31699
      },
      {
        text: 'to',
        fromFrame: 31699,
        toFrame: 31703
      },
      {
        text: 'Preeti.',
        fromFrame: 31703,
        toFrame: 31715
      }
    ],
    fromFrame: 31644,
    toFrame: 31794,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Yeah. Ashwin, you can fix this. No? But',
    tokens: [
      {
        text: 'Yeah.',
        fromFrame: 31794,
        toFrame: 31806
      },
      {
        text: 'Ashwin,',
        fromFrame: 31809,
        toFrame: 31821
      },
      {
        text: 'you',
        fromFrame: 31822,
        toFrame: 31824
      },
      {
        text: 'can',
        fromFrame: 31824,
        toFrame: 31830
      },
      {
        text: 'fix',
        fromFrame: 31830,
        toFrame: 31838
      },
      {
        text: 'this.',
        fromFrame: 31838,
        toFrame: 31845
      },
      {
        text: 'No?',
        fromFrame: 31845,
        toFrame: 31857
      },
      {
        text: 'But',
        fromFrame: 31892,
        toFrame: 31896
      }
    ],
    fromFrame: 31794,
    toFrame: 31896,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'time line is being overwritten anyway. So why',
    tokens: [
      {
        text: 'time',
        fromFrame: 31896,
        toFrame: 31902
      },
      {
        text: 'line',
        fromFrame: 31902,
        toFrame: 31906
      },
      {
        text: 'is',
        fromFrame: 31906,
        toFrame: 31910
      },
      {
        text: 'being',
        fromFrame: 31910,
        toFrame: 31915
      },
      {
        text: 'overwritten',
        fromFrame: 31915,
        toFrame: 31925
      },
      {
        text: 'anyway.',
        fromFrame: 31925,
        toFrame: 31935
      },
      {
        text: 'So',
        fromFrame: 31935,
        toFrame: 31936
      },
      {
        text: 'why',
        fromFrame: 31936,
        toFrame: 31940
      }
    ],
    fromFrame: 31896,
    toFrame: 31940,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "you can't delete your paste? And this is",
    tokens: [
      {
        text: 'you',
        fromFrame: 31940,
        toFrame: 31942
      },
      {
        text: "can't",
        fromFrame: 31942,
        toFrame: 31948
      },
      {
        text: 'delete',
        fromFrame: 31948,
        toFrame: 31954
      },
      {
        text: 'your',
        fromFrame: 31954,
        toFrame: 31956
      },
      {
        text: 'paste?',
        fromFrame: 31956,
        toFrame: 31968
      },
      {
        text: 'And',
        fromFrame: 32031,
        toFrame: 32034
      },
      {
        text: 'this',
        fromFrame: 32034,
        toFrame: 32040
      },
      {
        text: 'is',
        fromFrame: 32040,
        toFrame: 32044
      }
    ],
    fromFrame: 31940,
    toFrame: 32044,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "not going to come. I don't know how",
    tokens: [
      {
        text: 'not',
        fromFrame: 32044,
        toFrame: 32048
      },
      {
        text: 'going',
        fromFrame: 32048,
        toFrame: 32054
      },
      {
        text: 'to',
        fromFrame: 32054,
        toFrame: 32055
      },
      {
        text: 'come.',
        fromFrame: 32055,
        toFrame: 32061
      },
      {
        text: 'I',
        fromFrame: 32061,
        toFrame: 32063
      },
      {
        text: "don't",
        fromFrame: 32063,
        toFrame: 32067
      },
      {
        text: 'know',
        fromFrame: 32067,
        toFrame: 32069
      },
      {
        text: 'how',
        fromFrame: 32069,
        toFrame: 32075
      }
    ],
    fromFrame: 32044,
    toFrame: 32075,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'we reproduce this also. If you have those',
    tokens: [
      {
        text: 'we',
        fromFrame: 32075,
        toFrame: 32087
      },
      {
        text: 'reproduce',
        fromFrame: 32088,
        toFrame: 32100
      },
      {
        text: 'this',
        fromFrame: 32100,
        toFrame: 32103
      },
      {
        text: 'also.',
        fromFrame: 32103,
        toFrame: 32115
      },
      {
        text: 'If',
        fromFrame: 32127,
        toFrame: 32132
      },
      {
        text: 'you',
        fromFrame: 32132,
        toFrame: 32134
      },
      {
        text: 'have',
        fromFrame: 32134,
        toFrame: 32140
      },
      {
        text: 'those',
        fromFrame: 32140,
        toFrame: 32146
      }
    ],
    fromFrame: 32075,
    toFrame: 32146,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'many backgrounds. No. But you only can hover',
    tokens: [
      {
        text: 'many',
        fromFrame: 32146,
        toFrame: 32153
      },
      {
        text: 'backgrounds.',
        fromFrame: 32153,
        toFrame: 32165
      },
      {
        text: 'No.',
        fromFrame: 32215,
        toFrame: 32220
      },
      {
        text: 'But',
        fromFrame: 32220,
        toFrame: 32232
      },
      {
        text: 'you',
        fromFrame: 32240,
        toFrame: 32245
      },
      {
        text: 'only',
        fromFrame: 32245,
        toFrame: 32253
      },
      {
        text: 'can',
        fromFrame: 32253,
        toFrame: 32265
      },
      {
        text: 'hover',
        fromFrame: 32274,
        toFrame: 32280
      }
    ],
    fromFrame: 32146,
    toFrame: 32280,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'on the timeline for it to work, for',
    tokens: [
      {
        text: 'on',
        fromFrame: 32280,
        toFrame: 32282
      },
      {
        text: 'the',
        fromFrame: 32282,
        toFrame: 32286
      },
      {
        text: 'timeline',
        fromFrame: 32286,
        toFrame: 32295
      },
      {
        text: 'for',
        fromFrame: 32295,
        toFrame: 32301
      },
      {
        text: 'it',
        fromFrame: 32301,
        toFrame: 32305
      },
      {
        text: 'to',
        fromFrame: 32305,
        toFrame: 32307
      },
      {
        text: 'work,',
        fromFrame: 32307,
        toFrame: 32319
      },
      {
        text: 'for',
        fromFrame: 32334,
        toFrame: 32340
      }
    ],
    fromFrame: 32280,
    toFrame: 32340,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'it to actually come like that. Oh, I',
    tokens: [
      {
        text: 'it',
        fromFrame: 32340,
        toFrame: 32340
      },
      {
        text: 'to',
        fromFrame: 32340,
        toFrame: 32341
      },
      {
        text: 'actually',
        fromFrame: 32341,
        toFrame: 32349
      },
      {
        text: 'come',
        fromFrame: 32349,
        toFrame: 32355
      },
      {
        text: 'like',
        fromFrame: 32355,
        toFrame: 32359
      },
      {
        text: 'that.',
        fromFrame: 32359,
        toFrame: 32371
      },
      {
        text: 'Oh,',
        fromFrame: 32437,
        toFrame: 32449
      },
      {
        text: 'I',
        fromFrame: 32523,
        toFrame: 32526
      }
    ],
    fromFrame: 32340,
    toFrame: 32526,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "see. Okay. Might produce it. You said it's",
    tokens: [
      {
        text: 'see.',
        fromFrame: 32526,
        toFrame: 32538
      },
      {
        text: 'Okay.',
        fromFrame: 32680,
        toFrame: 32688
      },
      {
        text: 'Might',
        fromFrame: 32688,
        toFrame: 32697
      },
      {
        text: 'produce',
        fromFrame: 32697,
        toFrame: 32707
      },
      {
        text: 'it.',
        fromFrame: 32707,
        toFrame: 32716
      },
      {
        text: 'You',
        fromFrame: 32716,
        toFrame: 32722
      },
      {
        text: 'said',
        fromFrame: 32722,
        toFrame: 32730
      },
      {
        text: "it's",
        fromFrame: 32730,
        toFrame: 32739
      }
    ],
    fromFrame: 32526,
    toFrame: 32739,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "looking fine, Katie? Oh, yeah. I'm That's fine.",
    tokens: [
      {
        text: 'looking',
        fromFrame: 32739,
        toFrame: 32749
      },
      {
        text: 'fine,',
        fromFrame: 32749,
        toFrame: 32761
      },
      {
        text: 'Katie?',
        fromFrame: 32764,
        toFrame: 32776
      },
      {
        text: 'Oh,',
        fromFrame: 32784,
        toFrame: 32789
      },
      {
        text: 'yeah.',
        fromFrame: 32789,
        toFrame: 32797
      },
      {
        text: "I'm",
        fromFrame: 32797,
        toFrame: 32809
      },
      {
        text: "That's",
        fromFrame: 32878,
        toFrame: 32885
      },
      {
        text: 'fine.',
        fromFrame: 32885,
        toFrame: 32897
      }
    ],
    fromFrame: 32739,
    toFrame: 32910,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Yeah. That's what I'm saying. No. It comes",
    tokens: [
      {
        text: 'Yeah.',
        fromFrame: 32910,
        toFrame: 32914
      },
      {
        text: "That's",
        fromFrame: 32914,
        toFrame: 32920
      },
      {
        text: 'what',
        fromFrame: 32920,
        toFrame: 32924
      },
      {
        text: "I'm",
        fromFrame: 32924,
        toFrame: 32926
      },
      {
        text: 'saying.',
        fromFrame: 32926,
        toFrame: 32931
      },
      {
        text: 'No.',
        fromFrame: 32931,
        toFrame: 32937
      },
      {
        text: 'It',
        fromFrame: 32937,
        toFrame: 32939
      },
      {
        text: 'comes',
        fromFrame: 32939,
        toFrame: 32947
      }
    ],
    fromFrame: 32910,
    toFrame: 32947,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'only when you hover on it. Yeah. Timeline.',
    tokens: [
      {
        text: 'only',
        fromFrame: 32947,
        toFrame: 32952
      },
      {
        text: 'when',
        fromFrame: 32952,
        toFrame: 32958
      },
      {
        text: 'you',
        fromFrame: 32958,
        toFrame: 32960
      },
      {
        text: 'hover',
        fromFrame: 32960,
        toFrame: 32966
      },
      {
        text: 'on',
        fromFrame: 32966,
        toFrame: 32970
      },
      {
        text: 'it.',
        fromFrame: 32970,
        toFrame: 32982
      },
      {
        text: 'Yeah.',
        fromFrame: 33008,
        toFrame: 33018
      },
      {
        text: 'Timeline.',
        fromFrame: 33018,
        toFrame: 33030
      }
    ],
    fromFrame: 32947,
    toFrame: 33100,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Something is coming up if you hover on',
    tokens: [
      {
        text: 'Something',
        fromFrame: 33100,
        toFrame: 33112
      },
      {
        text: 'is',
        fromFrame: 33116,
        toFrame: 33123
      },
      {
        text: 'coming',
        fromFrame: 33123,
        toFrame: 33131
      },
      {
        text: 'up',
        fromFrame: 33131,
        toFrame: 33135
      },
      {
        text: 'if',
        fromFrame: 33135,
        toFrame: 33139
      },
      {
        text: 'you',
        fromFrame: 33139,
        toFrame: 33143
      },
      {
        text: 'hover',
        fromFrame: 33143,
        toFrame: 33150
      },
      {
        text: 'on',
        fromFrame: 33150,
        toFrame: 33156
      }
    ],
    fromFrame: 33100,
    toFrame: 33156,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'it. Check. Just assign it to me. Not',
    tokens: [
      {
        text: 'it.',
        fromFrame: 33156,
        toFrame: 33165
      },
      {
        text: 'Check.',
        fromFrame: 33200,
        toFrame: 33207
      },
      {
        text: 'Just',
        fromFrame: 33207,
        toFrame: 33211
      },
      {
        text: 'assign',
        fromFrame: 33211,
        toFrame: 33219
      },
      {
        text: 'it',
        fromFrame: 33219,
        toFrame: 33220
      },
      {
        text: 'to',
        fromFrame: 33220,
        toFrame: 33224
      },
      {
        text: 'me.',
        fromFrame: 33224,
        toFrame: 33236
      },
      {
        text: 'Not',
        fromFrame: 33513,
        toFrame: 33519
      }
    ],
    fromFrame: 33156,
    toFrame: 33519,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'quite an answer. Mhmm. Clicked on there. No.',
    tokens: [
      {
        text: 'quite',
        fromFrame: 33519,
        toFrame: 33526
      },
      {
        text: 'an',
        fromFrame: 33526,
        toFrame: 33530
      },
      {
        text: 'answer.',
        fromFrame: 33530,
        toFrame: 33542
      },
      {
        text: 'Mhmm.',
        fromFrame: 33778,
        toFrame: 33784
      },
      {
        text: 'Clicked',
        fromFrame: 33784,
        toFrame: 33789
      },
      {
        text: 'on',
        fromFrame: 33789,
        toFrame: 33793
      },
      {
        text: 'there.',
        fromFrame: 33793,
        toFrame: 33797
      },
      {
        text: 'No.',
        fromFrame: 33797,
        toFrame: 33805
      }
    ],
    fromFrame: 33519,
    toFrame: 33805,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Not getting updated until you play. Yeah. This',
    tokens: [
      {
        text: 'Not',
        fromFrame: 33805,
        toFrame: 33810
      },
      {
        text: 'getting',
        fromFrame: 33810,
        toFrame: 33818
      },
      {
        text: 'updated',
        fromFrame: 33818,
        toFrame: 33828
      },
      {
        text: 'until',
        fromFrame: 33828,
        toFrame: 33835
      },
      {
        text: 'you',
        fromFrame: 33835,
        toFrame: 33839
      },
      {
        text: 'play.',
        fromFrame: 33839,
        toFrame: 33851
      },
      {
        text: 'Yeah.',
        fromFrame: 33855,
        toFrame: 33862
      },
      {
        text: 'This',
        fromFrame: 33862,
        toFrame: 33874
      }
    ],
    fromFrame: 33805,
    toFrame: 33876,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "is there. You are still oh. Nice. I'm",
    tokens: [
      {
        text: 'is',
        fromFrame: 33876,
        toFrame: 33878
      },
      {
        text: 'there.',
        fromFrame: 33878,
        toFrame: 33884
      },
      {
        text: 'You',
        fromFrame: 33884,
        toFrame: 33887
      },
      {
        text: 'are',
        fromFrame: 33887,
        toFrame: 33891
      },
      {
        text: 'still',
        fromFrame: 33891,
        toFrame: 33903
      },
      {
        text: 'oh.',
        fromFrame: 33999,
        toFrame: 34011
      },
      {
        text: 'Nice.',
        fromFrame: 34022,
        toFrame: 34034
      },
      {
        text: "I'm",
        fromFrame: 34043,
        toFrame: 34054
      }
    ],
    fromFrame: 33876,
    toFrame: 34054,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'really to yourself. What? What are you talking',
    tokens: [
      {
        text: 'really',
        fromFrame: 34054,
        toFrame: 34056
      },
      {
        text: 'to',
        fromFrame: 34064,
        toFrame: 34072
      },
      {
        text: 'yourself.',
        fromFrame: 34072,
        toFrame: 34084
      },
      {
        text: 'What?',
        fromFrame: 34190,
        toFrame: 34200
      },
      {
        text: 'What',
        fromFrame: 34200,
        toFrame: 34204
      },
      {
        text: 'are',
        fromFrame: 34204,
        toFrame: 34208
      },
      {
        text: 'you',
        fromFrame: 34208,
        toFrame: 34212
      },
      {
        text: 'talking',
        fromFrame: 34212,
        toFrame: 34219
      }
    ],
    fromFrame: 34054,
    toFrame: 34219,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'about? There was nothing. Click on history? No.',
    tokens: [
      {
        text: 'about?',
        fromFrame: 34219,
        toFrame: 34227
      },
      {
        text: 'There',
        fromFrame: 34227,
        toFrame: 34231
      },
      {
        text: 'was',
        fromFrame: 34231,
        toFrame: 34236
      },
      {
        text: 'nothing.',
        fromFrame: 34236,
        toFrame: 34248
      },
      {
        text: 'Click',
        fromFrame: 34296,
        toFrame: 34300
      },
      {
        text: 'on',
        fromFrame: 34300,
        toFrame: 34304
      },
      {
        text: 'history?',
        fromFrame: 34304,
        toFrame: 34316
      },
      {
        text: 'No.',
        fromFrame: 34375,
        toFrame: 34387
      }
    ],
    fromFrame: 34219,
    toFrame: 34425,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I think it will delete. Yes. But it'll",
    tokens: [
      {
        text: 'I',
        fromFrame: 34425,
        toFrame: 34428
      },
      {
        text: 'think',
        fromFrame: 34428,
        toFrame: 34434
      },
      {
        text: 'it',
        fromFrame: 34434,
        toFrame: 34440
      },
      {
        text: 'will',
        fromFrame: 34440,
        toFrame: 34444
      },
      {
        text: 'delete.',
        fromFrame: 34444,
        toFrame: 34456
      },
      {
        text: 'Yes.',
        fromFrame: 34475,
        toFrame: 34487
      },
      {
        text: 'But',
        fromFrame: 34533,
        toFrame: 34539
      },
      {
        text: "it'll",
        fromFrame: 34539,
        toFrame: 34550
      }
    ],
    fromFrame: 34425,
    toFrame: 34567,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'okay. Not important. But sorry. Clip thumbnail. Yeah.',
    tokens: [
      {
        text: 'okay.',
        fromFrame: 34567,
        toFrame: 34579
      },
      {
        text: 'Not',
        fromFrame: 34581,
        toFrame: 34588
      },
      {
        text: 'important.',
        fromFrame: 34588,
        toFrame: 34600
      },
      {
        text: 'But',
        fromFrame: 34602,
        toFrame: 34614
      },
      {
        text: 'sorry.',
        fromFrame: 34802,
        toFrame: 34814
      },
      {
        text: 'Clip',
        fromFrame: 34957,
        toFrame: 34965
      },
      {
        text: 'thumbnail.',
        fromFrame: 34965,
        toFrame: 34977
      },
      {
        text: 'Yeah.',
        fromFrame: 35003,
        toFrame: 35015
      }
    ],
    fromFrame: 34567,
    toFrame: 35111,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'And why is this assigned to you? I',
    tokens: [
      {
        text: 'And',
        fromFrame: 35111,
        toFrame: 35118
      },
      {
        text: 'why',
        fromFrame: 35118,
        toFrame: 35122
      },
      {
        text: 'is',
        fromFrame: 35122,
        toFrame: 35124
      },
      {
        text: 'this',
        fromFrame: 35124,
        toFrame: 35130
      },
      {
        text: 'assigned',
        fromFrame: 35130,
        toFrame: 35139
      },
      {
        text: 'to',
        fromFrame: 35139,
        toFrame: 35143
      },
      {
        text: 'you?',
        fromFrame: 35143,
        toFrame: 35155
      },
      {
        text: 'I',
        fromFrame: 35211,
        toFrame: 35218
      }
    ],
    fromFrame: 35111,
    toFrame: 35218,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "think it'll assign long part. Yeah. I don't",
    tokens: [
      {
        text: 'think',
        fromFrame: 35218,
        toFrame: 35220
      },
      {
        text: "it'll",
        fromFrame: 35220,
        toFrame: 35226
      },
      {
        text: 'assign',
        fromFrame: 35226,
        toFrame: 35236
      },
      {
        text: 'long',
        fromFrame: 35236,
        toFrame: 35241
      },
      {
        text: 'part.',
        fromFrame: 35241,
        toFrame: 35253
      },
      {
        text: 'Yeah.',
        fromFrame: 35401,
        toFrame: 35409
      },
      {
        text: 'I',
        fromFrame: 35409,
        toFrame: 35412
      },
      {
        text: "don't",
        fromFrame: 35412,
        toFrame: 35422
      }
    ],
    fromFrame: 35218,
    toFrame: 35422,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "know what I'll I'll keep it, but not",
    tokens: [
      {
        text: 'know',
        fromFrame: 35422,
        toFrame: 35426
      },
      {
        text: 'what',
        fromFrame: 35426,
        toFrame: 35438
      },
      {
        text: "I'll",
        fromFrame: 35449,
        toFrame: 35461
      },
      {
        text: "I'll",
        fromFrame: 35492,
        toFrame: 35499
      },
      {
        text: 'keep',
        fromFrame: 35499,
        toFrame: 35505
      },
      {
        text: 'it,',
        fromFrame: 35505,
        toFrame: 35513
      },
      {
        text: 'but',
        fromFrame: 35513,
        toFrame: 35520
      },
      {
        text: 'not',
        fromFrame: 35520,
        toFrame: 35528
      }
    ],
    fromFrame: 35422,
    toFrame: 35528,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'important to fix. More to cut it. Yeah.',
    tokens: [
      {
        text: 'important',
        fromFrame: 35528,
        toFrame: 35540
      },
      {
        text: 'to',
        fromFrame: 35544,
        toFrame: 35549
      },
      {
        text: 'fix.',
        fromFrame: 35549,
        toFrame: 35561
      },
      {
        text: 'More',
        fromFrame: 35580,
        toFrame: 35586
      },
      {
        text: 'to',
        fromFrame: 35586,
        toFrame: 35588
      },
      {
        text: 'cut',
        fromFrame: 35588,
        toFrame: 35592
      },
      {
        text: 'it.',
        fromFrame: 35592,
        toFrame: 35604
      },
      {
        text: 'Yeah.',
        fromFrame: 35630,
        toFrame: 35642
      }
    ],
    fromFrame: 35528,
    toFrame: 35799,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Negative speaker grid timeline. Not able to close.',
    tokens: [
      {
        text: 'Negative',
        fromFrame: 35799,
        toFrame: 35811
      },
      {
        text: 'speaker',
        fromFrame: 35811,
        toFrame: 35821
      },
      {
        text: 'grid',
        fromFrame: 35821,
        toFrame: 35826
      },
      {
        text: 'timeline.',
        fromFrame: 35826,
        toFrame: 35838
      },
      {
        text: 'Not',
        fromFrame: 35861,
        toFrame: 35867
      },
      {
        text: 'able',
        fromFrame: 35867,
        toFrame: 35874
      },
      {
        text: 'to',
        fromFrame: 35874,
        toFrame: 35876
      },
      {
        text: 'close.',
        fromFrame: 35876,
        toFrame: 35888
      }
    ],
    fromFrame: 35799,
    toFrame: 35926,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'So can I again close this? How do',
    tokens: [
      {
        text: 'So',
        fromFrame: 35926,
        toFrame: 35930
      },
      {
        text: 'can',
        fromFrame: 35930,
        toFrame: 35934
      },
      {
        text: 'I',
        fromFrame: 35934,
        toFrame: 35938
      },
      {
        text: 'again',
        fromFrame: 35938,
        toFrame: 35943
      },
      {
        text: 'close',
        fromFrame: 35943,
        toFrame: 35951
      },
      {
        text: 'this?',
        fromFrame: 35951,
        toFrame: 35963
      },
      {
        text: 'How',
        fromFrame: 36078,
        toFrame: 36084
      },
      {
        text: 'do',
        fromFrame: 36084,
        toFrame: 36087
      }
    ],
    fromFrame: 35926,
    toFrame: 36087,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I close? I'll close and close. Okay. So",
    tokens: [
      {
        text: 'I',
        fromFrame: 36087,
        toFrame: 36091
      },
      {
        text: 'close?',
        fromFrame: 36091,
        toFrame: 36101
      },
      {
        text: "I'll",
        fromFrame: 36101,
        toFrame: 36105
      },
      {
        text: 'close',
        fromFrame: 36105,
        toFrame: 36111
      },
      {
        text: 'and',
        fromFrame: 36111,
        toFrame: 36116
      },
      {
        text: 'close.',
        fromFrame: 36116,
        toFrame: 36128
      },
      {
        text: 'Okay.',
        fromFrame: 36152,
        toFrame: 36164
      },
      {
        text: 'So',
        fromFrame: 36212,
        toFrame: 36218
      }
    ],
    fromFrame: 36087,
    toFrame: 36218,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'you have 3 tickets already. Oh. No. Wait.',
    tokens: [
      {
        text: 'you',
        fromFrame: 36218,
        toFrame: 36223
      },
      {
        text: 'have',
        fromFrame: 36223,
        toFrame: 36231
      },
      {
        text: '3',
        fromFrame: 36231,
        toFrame: 36237
      },
      {
        text: 'tickets',
        fromFrame: 36237,
        toFrame: 36248
      },
      {
        text: 'already.',
        fromFrame: 36248,
        toFrame: 36260
      },
      {
        text: 'Oh.',
        fromFrame: 36273,
        toFrame: 36283
      },
      {
        text: 'No.',
        fromFrame: 36283,
        toFrame: 36295
      },
      {
        text: 'Wait.',
        fromFrame: 36296,
        toFrame: 36308
      }
    ],
    fromFrame: 36218,
    toFrame: 36323,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "37. Oh, sorry. You don't have any tickets?",
    tokens: [
      {
        text: '37.',
        fromFrame: 36323,
        toFrame: 36335
      },
      {
        text: 'Oh,',
        fromFrame: 36366,
        toFrame: 36375
      },
      {
        text: 'sorry.',
        fromFrame: 36375,
        toFrame: 36387
      },
      {
        text: 'You',
        fromFrame: 36440,
        toFrame: 36446
      },
      {
        text: "don't",
        fromFrame: 36446,
        toFrame: 36450
      },
      {
        text: 'have',
        fromFrame: 36450,
        toFrame: 36456
      },
      {
        text: 'any',
        fromFrame: 36456,
        toFrame: 36462
      },
      {
        text: 'tickets?',
        fromFrame: 36462,
        toFrame: 36474
      }
    ],
    fromFrame: 36323,
    toFrame: 36479,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Only 1? Yeah. Only enough. I'll get a",
    tokens: [
      {
        text: 'Only',
        fromFrame: 36479,
        toFrame: 36486
      },
      {
        text: '1?',
        fromFrame: 36486,
        toFrame: 36498
      },
      {
        text: 'Yeah.',
        fromFrame: 36509,
        toFrame: 36514
      },
      {
        text: 'Only',
        fromFrame: 36514,
        toFrame: 36524
      },
      {
        text: 'enough.',
        fromFrame: 36524,
        toFrame: 36534
      },
      {
        text: "I'll",
        fromFrame: 36534,
        toFrame: 36539
      },
      {
        text: 'get',
        fromFrame: 36539,
        toFrame: 36542
      },
      {
        text: 'a',
        fromFrame: 36542,
        toFrame: 36545
      }
    ],
    fromFrame: 36479,
    toFrame: 36545,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'lot of bugs. Preeti will create a lot',
    tokens: [
      {
        text: 'lot',
        fromFrame: 36545,
        toFrame: 36551
      },
      {
        text: 'of',
        fromFrame: 36551,
        toFrame: 36553
      },
      {
        text: 'bugs.',
        fromFrame: 36553,
        toFrame: 36561
      },
      {
        text: 'Preeti',
        fromFrame: 36561,
        toFrame: 36570
      },
      {
        text: 'will',
        fromFrame: 36570,
        toFrame: 36572
      },
      {
        text: 'create',
        fromFrame: 36572,
        toFrame: 36578
      },
      {
        text: 'a',
        fromFrame: 36578,
        toFrame: 36580
      },
      {
        text: 'lot',
        fromFrame: 36580,
        toFrame: 36584
      }
    ],
    fromFrame: 36545,
    toFrame: 36584,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "of bugs. Let's check, Dushan, actually. Will Dushan",
    tokens: [
      {
        text: 'of',
        fromFrame: 36584,
        toFrame: 36596
      },
      {
        text: 'bugs.',
        fromFrame: 36599,
        toFrame: 36611
      },
      {
        text: "Let's",
        fromFrame: 36687,
        toFrame: 36695
      },
      {
        text: 'check,',
        fromFrame: 36695,
        toFrame: 36707
      },
      {
        text: 'Dushan,',
        fromFrame: 36735,
        toFrame: 36747
      },
      {
        text: 'actually.',
        fromFrame: 36751,
        toFrame: 36763
      },
      {
        text: 'Will',
        fromFrame: 36772,
        toFrame: 36779
      },
      {
        text: 'Dushan',
        fromFrame: 36779,
        toFrame: 36791
      }
    ],
    fromFrame: 36584,
    toFrame: 36793,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'be there in this team? Dushan will come',
    tokens: [
      {
        text: 'be',
        fromFrame: 36793,
        toFrame: 36797
      },
      {
        text: 'there',
        fromFrame: 36797,
        toFrame: 36804
      },
      {
        text: 'in',
        fromFrame: 36804,
        toFrame: 36808
      },
      {
        text: 'this',
        fromFrame: 36808,
        toFrame: 36814
      },
      {
        text: 'team?',
        fromFrame: 36814,
        toFrame: 36825
      },
      {
        text: 'Dushan',
        fromFrame: 36839,
        toFrame: 36847
      },
      {
        text: 'will',
        fromFrame: 36847,
        toFrame: 36849
      },
      {
        text: 'come',
        fromFrame: 36849,
        toFrame: 36856
      }
    ],
    fromFrame: 36793,
    toFrame: 36856,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'over. No ticket. Oh. No high tickets or',
    tokens: [
      {
        text: 'over.',
        fromFrame: 36856,
        toFrame: 36864
      },
      {
        text: 'No',
        fromFrame: 36864,
        toFrame: 36870
      },
      {
        text: 'ticket.',
        fromFrame: 36870,
        toFrame: 36882
      },
      {
        text: 'Oh.',
        fromFrame: 36927,
        toFrame: 36932
      },
      {
        text: 'No',
        fromFrame: 36932,
        toFrame: 36938
      },
      {
        text: 'high',
        fromFrame: 36938,
        toFrame: 36944
      },
      {
        text: 'tickets',
        fromFrame: 36944,
        toFrame: 36956
      },
      {
        text: 'or',
        fromFrame: 36963,
        toFrame: 36967
      }
    ],
    fromFrame: 36856,
    toFrame: 36967,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'no condensed. No tickets. See. Amazing. But they',
    tokens: [
      {
        text: 'no',
        fromFrame: 36967,
        toFrame: 36973
      },
      {
        text: 'condensed.',
        fromFrame: 36973,
        toFrame: 36985
      },
      {
        text: 'No',
        fromFrame: 36990,
        toFrame: 37002
      },
      {
        text: 'tickets.',
        fromFrame: 37032,
        toFrame: 37040
      },
      {
        text: 'See.',
        fromFrame: 37040,
        toFrame: 37052
      },
      {
        text: 'Amazing.',
        fromFrame: 37053,
        toFrame: 37065
      },
      {
        text: 'But',
        fromFrame: 37074,
        toFrame: 37082
      },
      {
        text: 'they',
        fromFrame: 37082,
        toFrame: 37084
      }
    ],
    fromFrame: 36967,
    toFrame: 37084,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "had tickets today. That's feature tickets then. Not",
    tokens: [
      {
        text: 'had',
        fromFrame: 37084,
        toFrame: 37090
      },
      {
        text: 'tickets',
        fromFrame: 37090,
        toFrame: 37099
      },
      {
        text: 'today.',
        fromFrame: 37099,
        toFrame: 37111
      },
      {
        text: "That's",
        fromFrame: 37133,
        toFrame: 37145
      },
      {
        text: 'feature',
        fromFrame: 37178,
        toFrame: 37184
      },
      {
        text: 'tickets',
        fromFrame: 37184,
        toFrame: 37194
      },
      {
        text: 'then.',
        fromFrame: 37194,
        toFrame: 37206
      },
      {
        text: 'Not',
        fromFrame: 37244,
        toFrame: 37248
      }
    ],
    fromFrame: 37084,
    toFrame: 37248,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'in development. Also created, 2 bugs. Click on',
    tokens: [
      {
        text: 'in',
        fromFrame: 37248,
        toFrame: 37252
      },
      {
        text: 'development.',
        fromFrame: 37252,
        toFrame: 37263
      },
      {
        text: 'Also',
        fromFrame: 37265,
        toFrame: 37271
      },
      {
        text: 'created,',
        fromFrame: 37271,
        toFrame: 37283
      },
      {
        text: '2',
        fromFrame: 37304,
        toFrame: 37309
      },
      {
        text: 'bugs.',
        fromFrame: 37309,
        toFrame: 37321
      },
      {
        text: 'Click',
        fromFrame: 37332,
        toFrame: 37338
      },
      {
        text: 'on',
        fromFrame: 37338,
        toFrame: 37342
      }
    ],
    fromFrame: 37248,
    toFrame: 37342,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "auto development. Click. What's it? An order in",
    tokens: [
      {
        text: 'auto',
        fromFrame: 37342,
        toFrame: 37352
      },
      {
        text: 'development.',
        fromFrame: 37352,
        toFrame: 37364
      },
      {
        text: 'Click.',
        fromFrame: 37507,
        toFrame: 37515
      },
      {
        text: "What's",
        fromFrame: 37515,
        toFrame: 37519
      },
      {
        text: 'it?',
        fromFrame: 37519,
        toFrame: 37531
      },
      {
        text: 'An',
        fromFrame: 37600,
        toFrame: 37604
      },
      {
        text: 'order',
        fromFrame: 37604,
        toFrame: 37610
      },
      {
        text: 'in',
        fromFrame: 37610,
        toFrame: 37614
      }
    ],
    fromFrame: 37342,
    toFrame: 37614,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "development. We closed it. It's everything in in",
    tokens: [
      {
        text: 'development.',
        fromFrame: 37614,
        toFrame: 37626
      },
      {
        text: 'We',
        fromFrame: 37681,
        toFrame: 37686
      },
      {
        text: 'closed',
        fromFrame: 37686,
        toFrame: 37696
      },
      {
        text: 'it.',
        fromFrame: 37696,
        toFrame: 37708
      },
      {
        text: "It's",
        fromFrame: 37734,
        toFrame: 37742
      },
      {
        text: 'everything',
        fromFrame: 37742,
        toFrame: 37752
      },
      {
        text: 'in',
        fromFrame: 37752,
        toFrame: 37758
      },
      {
        text: 'in',
        fromFrame: 37758,
        toFrame: 37759
      }
    ],
    fromFrame: 37614,
    toFrame: 37759,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "QA. I think I'll send it to somebody",
    tokens: [
      {
        text: 'QA.',
        fromFrame: 37759,
        toFrame: 37771
      },
      {
        text: 'I',
        fromFrame: 37779,
        toFrame: 37781
      },
      {
        text: 'think',
        fromFrame: 37781,
        toFrame: 37786
      },
      {
        text: "I'll",
        fromFrame: 37786,
        toFrame: 37790
      },
      {
        text: 'send',
        fromFrame: 37790,
        toFrame: 37794
      },
      {
        text: 'it',
        fromFrame: 37794,
        toFrame: 37798
      },
      {
        text: 'to',
        fromFrame: 37798,
        toFrame: 37802
      },
      {
        text: 'somebody',
        fromFrame: 37802,
        toFrame: 37809
      }
    ],
    fromFrame: 37759,
    toFrame: 37809,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "else. Leave it. Okay, ma'am. Fine. Okay. Take",
    tokens: [
      {
        text: 'else.',
        fromFrame: 37809,
        toFrame: 37813
      },
      {
        text: 'Leave',
        fromFrame: 37813,
        toFrame: 37817
      },
      {
        text: 'it.',
        fromFrame: 37817,
        toFrame: 37821
      },
      {
        text: 'Okay,',
        fromFrame: 37821,
        toFrame: 37827
      },
      {
        text: "ma'am.",
        fromFrame: 37827,
        toFrame: 37834
      },
      {
        text: 'Fine.',
        fromFrame: 37834,
        toFrame: 37846
      },
      {
        text: 'Okay.',
        fromFrame: 37867,
        toFrame: 37879
      },
      {
        text: 'Take',
        fromFrame: 37911,
        toFrame: 37917
      }
    ],
    fromFrame: 37809,
    toFrame: 37917,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'an order development also. Who else? Abinit is',
    tokens: [
      {
        text: 'an',
        fromFrame: 37917,
        toFrame: 37921
      },
      {
        text: 'order',
        fromFrame: 37921,
        toFrame: 37926
      },
      {
        text: 'development',
        fromFrame: 37926,
        toFrame: 37936
      },
      {
        text: 'also.',
        fromFrame: 37936,
        toFrame: 37948
      },
      {
        text: 'Who',
        fromFrame: 38180,
        toFrame: 38186
      },
      {
        text: 'else?',
        fromFrame: 38186,
        toFrame: 38195
      },
      {
        text: 'Abinit',
        fromFrame: 38195,
        toFrame: 38207
      },
      {
        text: 'is',
        fromFrame: 38211,
        toFrame: 38216
      }
    ],
    fromFrame: 37917,
    toFrame: 38216,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'the one who has lot of ticket. Abinit,',
    tokens: [
      {
        text: 'the',
        fromFrame: 38216,
        toFrame: 38220
      },
      {
        text: 'one',
        fromFrame: 38220,
        toFrame: 38226
      },
      {
        text: 'who',
        fromFrame: 38226,
        toFrame: 38230
      },
      {
        text: 'has',
        fromFrame: 38230,
        toFrame: 38234
      },
      {
        text: 'lot',
        fromFrame: 38234,
        toFrame: 38237
      },
      {
        text: 'of',
        fromFrame: 38237,
        toFrame: 38241
      },
      {
        text: 'ticket.',
        fromFrame: 38241,
        toFrame: 38249
      },
      {
        text: 'Abinit,',
        fromFrame: 38249,
        toFrame: 38261
      }
    ],
    fromFrame: 38216,
    toFrame: 38262,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'can you? Every single time I put it',
    tokens: [
      {
        text: 'can',
        fromFrame: 38262,
        toFrame: 38266
      },
      {
        text: 'you?',
        fromFrame: 38266,
        toFrame: 38276
      },
      {
        text: 'Every',
        fromFrame: 38322,
        toFrame: 38329
      },
      {
        text: 'single',
        fromFrame: 38329,
        toFrame: 38339
      },
      {
        text: 'time',
        fromFrame: 38339,
        toFrame: 38345
      },
      {
        text: 'I',
        fromFrame: 38345,
        toFrame: 38349
      },
      {
        text: 'put',
        fromFrame: 38349,
        toFrame: 38351
      },
      {
        text: 'it',
        fromFrame: 38351,
        toFrame: 38352
      }
    ],
    fromFrame: 38262,
    toFrame: 38352,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'on the top, there are, like, files. Yes.',
    tokens: [
      {
        text: 'on',
        fromFrame: 38352,
        toFrame: 38358
      },
      {
        text: 'the',
        fromFrame: 38358,
        toFrame: 38360
      },
      {
        text: 'top,',
        fromFrame: 38360,
        toFrame: 38366
      },
      {
        text: 'there',
        fromFrame: 38366,
        toFrame: 38370
      },
      {
        text: 'are,',
        fromFrame: 38370,
        toFrame: 38376
      },
      {
        text: 'like,',
        fromFrame: 38376,
        toFrame: 38379
      },
      {
        text: 'files.',
        fromFrame: 38379,
        toFrame: 38389
      },
      {
        text: 'Yes.',
        fromFrame: 38389,
        toFrame: 38401
      }
    ],
    fromFrame: 38352,
    toFrame: 38435,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Remembered. The not remembers. Okay. How come Abinit',
    tokens: [
      {
        text: 'Remembered.',
        fromFrame: 38435,
        toFrame: 38447
      },
      {
        text: 'The',
        fromFrame: 38475,
        toFrame: 38483
      },
      {
        text: 'not',
        fromFrame: 38483,
        toFrame: 38491
      },
      {
        text: 'remembers.',
        fromFrame: 38491,
        toFrame: 38503
      },
      {
        text: 'Okay.',
        fromFrame: 38510,
        toFrame: 38522
      },
      {
        text: 'How',
        fromFrame: 38531,
        toFrame: 38533
      },
      {
        text: 'come',
        fromFrame: 38533,
        toFrame: 38541
      },
      {
        text: 'Abinit',
        fromFrame: 38541,
        toFrame: 38550
      }
    ],
    fromFrame: 38435,
    toFrame: 38550,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'has no tickets? Amazing. What is this? Oh.',
    tokens: [
      {
        text: 'has',
        fromFrame: 38550,
        toFrame: 38556
      },
      {
        text: 'no',
        fromFrame: 38556,
        toFrame: 38560
      },
      {
        text: 'tickets?',
        fromFrame: 38560,
        toFrame: 38572
      },
      {
        text: 'Amazing.',
        fromFrame: 38619,
        toFrame: 38631
      },
      {
        text: 'What',
        fromFrame: 38790,
        toFrame: 38793
      },
      {
        text: 'is',
        fromFrame: 38793,
        toFrame: 38801
      },
      {
        text: 'this?',
        fromFrame: 38801,
        toFrame: 38813
      },
      {
        text: 'Oh.',
        fromFrame: 38868,
        toFrame: 38880
      }
    ],
    fromFrame: 38550,
    toFrame: 38897,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "That's fine. Go to the next one. It's",
    tokens: [
      {
        text: "That's",
        fromFrame: 38897,
        toFrame: 38905
      },
      {
        text: 'fine.',
        fromFrame: 38905,
        toFrame: 38913
      },
      {
        text: 'Go',
        fromFrame: 38913,
        toFrame: 38916
      },
      {
        text: 'to',
        fromFrame: 38916,
        toFrame: 38918
      },
      {
        text: 'the',
        fromFrame: 38918,
        toFrame: 38922
      },
      {
        text: 'next',
        fromFrame: 38922,
        toFrame: 38928
      },
      {
        text: 'one.',
        fromFrame: 38928,
        toFrame: 38934
      },
      {
        text: "It's",
        fromFrame: 38934,
        toFrame: 38939
      }
    ],
    fromFrame: 38897,
    toFrame: 38939,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'okay. Yeah. What am I supposed to do',
    tokens: [
      {
        text: 'okay.',
        fromFrame: 38939,
        toFrame: 38951
      },
      {
        text: 'Yeah.',
        fromFrame: 38966,
        toFrame: 38978
      },
      {
        text: 'What',
        fromFrame: 39038,
        toFrame: 39044
      },
      {
        text: 'am',
        fromFrame: 39044,
        toFrame: 39049
      },
      {
        text: 'I',
        fromFrame: 39049,
        toFrame: 39051
      },
      {
        text: 'supposed',
        fromFrame: 39051,
        toFrame: 39059
      },
      {
        text: 'to',
        fromFrame: 39059,
        toFrame: 39063
      },
      {
        text: 'do',
        fromFrame: 39063,
        toFrame: 39067
      }
    ],
    fromFrame: 38939,
    toFrame: 39067,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "with this? I think I'm gonna close this.",
    tokens: [
      {
        text: 'with',
        fromFrame: 39067,
        toFrame: 39072
      },
      {
        text: 'this?',
        fromFrame: 39072,
        toFrame: 39084
      },
      {
        text: 'I',
        fromFrame: 39094,
        toFrame: 39096
      },
      {
        text: 'think',
        fromFrame: 39096,
        toFrame: 39101
      },
      {
        text: "I'm",
        fromFrame: 39101,
        toFrame: 39103
      },
      {
        text: 'gonna',
        fromFrame: 39103,
        toFrame: 39111
      },
      {
        text: 'close',
        fromFrame: 39111,
        toFrame: 39119
      },
      {
        text: 'this.',
        fromFrame: 39119,
        toFrame: 39131
      }
    ],
    fromFrame: 39067,
    toFrame: 39138,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'See, if you see the report has something,',
    tokens: [
      {
        text: 'See,',
        fromFrame: 39138,
        toFrame: 39144
      },
      {
        text: 'if',
        fromFrame: 39144,
        toFrame: 39147
      },
      {
        text: 'you',
        fromFrame: 39147,
        toFrame: 39151
      },
      {
        text: 'see',
        fromFrame: 39151,
        toFrame: 39159
      },
      {
        text: 'the',
        fromFrame: 39159,
        toFrame: 39163
      },
      {
        text: 'report',
        fromFrame: 39163,
        toFrame: 39170
      },
      {
        text: 'has',
        fromFrame: 39170,
        toFrame: 39172
      },
      {
        text: 'something,',
        fromFrame: 39172,
        toFrame: 39182
      }
    ],
    fromFrame: 39138,
    toFrame: 39182,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "just close it. Preeti? See. I don't think.",
    tokens: [
      {
        text: 'just',
        fromFrame: 39182,
        toFrame: 39188
      },
      {
        text: 'close',
        fromFrame: 39188,
        toFrame: 39193
      },
      {
        text: 'it.',
        fromFrame: 39193,
        toFrame: 39205
      },
      {
        text: 'Preeti?',
        fromFrame: 39255,
        toFrame: 39267
      },
      {
        text: 'See.',
        fromFrame: 39309,
        toFrame: 39316
      },
      {
        text: 'I',
        fromFrame: 39316,
        toFrame: 39324
      },
      {
        text: "don't",
        fromFrame: 39324,
        toFrame: 39336
      },
      {
        text: 'think.',
        fromFrame: 39338,
        toFrame: 39350
      }
    ],
    fromFrame: 39182,
    toFrame: 39359,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Preeti will try to observe this call. This',
    tokens: [
      {
        text: 'Preeti',
        fromFrame: 39359,
        toFrame: 39368
      },
      {
        text: 'will',
        fromFrame: 39368,
        toFrame: 39372
      },
      {
        text: 'try',
        fromFrame: 39372,
        toFrame: 39375
      },
      {
        text: 'to',
        fromFrame: 39375,
        toFrame: 39378
      },
      {
        text: 'observe',
        fromFrame: 39378,
        toFrame: 39384
      },
      {
        text: 'this',
        fromFrame: 39384,
        toFrame: 39387
      },
      {
        text: 'call.',
        fromFrame: 39387,
        toFrame: 39391
      },
      {
        text: 'This',
        fromFrame: 39391,
        toFrame: 39395
      }
    ],
    fromFrame: 39359,
    toFrame: 39395,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "call, she won't do. Listen, Deepstick it now.",
    tokens: [
      {
        text: 'call,',
        fromFrame: 39395,
        toFrame: 39399
      },
      {
        text: 'she',
        fromFrame: 39399,
        toFrame: 39405
      },
      {
        text: "won't",
        fromFrame: 39405,
        toFrame: 39411
      },
      {
        text: 'do.',
        fromFrame: 39411,
        toFrame: 39423
      },
      {
        text: 'Listen,',
        fromFrame: 39460,
        toFrame: 39470
      },
      {
        text: 'Deepstick',
        fromFrame: 39470,
        toFrame: 39482
      },
      {
        text: 'it',
        fromFrame: 39482,
        toFrame: 39485
      },
      {
        text: 'now.',
        fromFrame: 39485,
        toFrame: 39491
      }
    ],
    fromFrame: 39395,
    toFrame: 39491,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'You are not taking seriously. Nothing description. You',
    tokens: [
      {
        text: 'You',
        fromFrame: 39491,
        toFrame: 39493
      },
      {
        text: 'are',
        fromFrame: 39493,
        toFrame: 39499
      },
      {
        text: 'not',
        fromFrame: 39499,
        toFrame: 39505
      },
      {
        text: 'taking',
        fromFrame: 39505,
        toFrame: 39514
      },
      {
        text: 'seriously.',
        fromFrame: 39514,
        toFrame: 39526
      },
      {
        text: 'Nothing',
        fromFrame: 39597,
        toFrame: 39608
      },
      {
        text: 'description.',
        fromFrame: 39608,
        toFrame: 39620
      },
      {
        text: 'You',
        fromFrame: 39764,
        toFrame: 39770
      }
    ],
    fromFrame: 39491,
    toFrame: 39770,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'want to take it and create it properly?',
    tokens: [
      {
        text: 'want',
        fromFrame: 39770,
        toFrame: 39778
      },
      {
        text: 'to',
        fromFrame: 39778,
        toFrame: 39781
      },
      {
        text: 'take',
        fromFrame: 39781,
        toFrame: 39787
      },
      {
        text: 'it',
        fromFrame: 39787,
        toFrame: 39791
      },
      {
        text: 'and',
        fromFrame: 39791,
        toFrame: 39803
      },
      {
        text: 'create',
        fromFrame: 39814,
        toFrame: 39826
      },
      {
        text: 'it',
        fromFrame: 39826,
        toFrame: 39833
      },
      {
        text: 'properly?',
        fromFrame: 39833,
        toFrame: 39845
      }
    ],
    fromFrame: 39770,
    toFrame: 39885,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I don't know. Okay. Assign it to me.",
    tokens: [
      {
        text: 'I',
        fromFrame: 39885,
        toFrame: 39889
      },
      {
        text: "don't",
        fromFrame: 39889,
        toFrame: 39895
      },
      {
        text: 'know.',
        fromFrame: 39895,
        toFrame: 39907
      },
      {
        text: 'Okay.',
        fromFrame: 39948,
        toFrame: 39956
      },
      {
        text: 'Assign',
        fromFrame: 39956,
        toFrame: 39966
      },
      {
        text: 'it',
        fromFrame: 39966,
        toFrame: 39970
      },
      {
        text: 'to',
        fromFrame: 39970,
        toFrame: 39973
      },
      {
        text: 'me.',
        fromFrame: 39973,
        toFrame: 39984
      }
    ],
    fromFrame: 39885,
    toFrame: 39988,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I'll check how frequent it is happening. To",
    tokens: [
      {
        text: "I'll",
        fromFrame: 39988,
        toFrame: 39995
      },
      {
        text: 'check',
        fromFrame: 39995,
        toFrame: 40001
      },
      {
        text: 'how',
        fromFrame: 40001,
        toFrame: 40013
      },
      {
        text: 'frequent',
        fromFrame: 40015,
        toFrame: 40027
      },
      {
        text: 'it',
        fromFrame: 40074,
        toFrame: 40086
      },
      {
        text: 'is',
        fromFrame: 40133,
        toFrame: 40145
      },
      {
        text: 'happening.',
        fromFrame: 40192,
        toFrame: 40204
      },
      {
        text: 'To',
        fromFrame: 40251,
        toFrame: 40255
      }
    ],
    fromFrame: 39988,
    toFrame: 40255,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'be honest on the work. Uh-huh. So it',
    tokens: [
      {
        text: 'be',
        fromFrame: 40255,
        toFrame: 40260
      },
      {
        text: 'honest',
        fromFrame: 40260,
        toFrame: 40270
      },
      {
        text: 'on',
        fromFrame: 40270,
        toFrame: 40276
      },
      {
        text: 'the',
        fromFrame: 40276,
        toFrame: 40282
      },
      {
        text: 'work.',
        fromFrame: 40282,
        toFrame: 40294
      },
      {
        text: 'Uh-huh.',
        fromFrame: 40398,
        toFrame: 40410
      },
      {
        text: 'So',
        fromFrame: 40469,
        toFrame: 40474
      },
      {
        text: 'it',
        fromFrame: 40474,
        toFrame: 40476
      }
    ],
    fromFrame: 40255,
    toFrame: 40476,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "is not hardware. I can't hear you. It",
    tokens: [
      {
        text: 'is',
        fromFrame: 40476,
        toFrame: 40480
      },
      {
        text: 'not',
        fromFrame: 40480,
        toFrame: 40488
      },
      {
        text: 'hardware.',
        fromFrame: 40488,
        toFrame: 40500
      },
      {
        text: 'I',
        fromFrame: 40545,
        toFrame: 40549
      },
      {
        text: "can't",
        fromFrame: 40549,
        toFrame: 40555
      },
      {
        text: 'hear',
        fromFrame: 40555,
        toFrame: 40561
      },
      {
        text: 'you.',
        fromFrame: 40561,
        toFrame: 40564
      },
      {
        text: 'It',
        fromFrame: 40564,
        toFrame: 40566
      }
    ],
    fromFrame: 40476,
    toFrame: 40566,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "looks like, you know, it's can't hear me?",
    tokens: [
      {
        text: 'looks',
        fromFrame: 40566,
        toFrame: 40572
      },
      {
        text: 'like,',
        fromFrame: 40572,
        toFrame: 40582
      },
      {
        text: 'you',
        fromFrame: 40593,
        toFrame: 40597
      },
      {
        text: 'know,',
        fromFrame: 40597,
        toFrame: 40600
      },
      {
        text: "it's",
        fromFrame: 40600,
        toFrame: 40603
      },
      {
        text: "can't",
        fromFrame: 40639,
        toFrame: 40645
      },
      {
        text: 'hear',
        fromFrame: 40645,
        toFrame: 40651
      },
      {
        text: 'me?',
        fromFrame: 40651,
        toFrame: 40663
      }
    ],
    fromFrame: 40566,
    toFrame: 40763,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "No. I've been told her he fronted give",
    tokens: [
      {
        text: 'No.',
        fromFrame: 40763,
        toFrame: 40775
      },
      {
        text: "I've",
        fromFrame: 40809,
        toFrame: 40813
      },
      {
        text: 'been',
        fromFrame: 40813,
        toFrame: 40819
      },
      {
        text: 'told',
        fromFrame: 40819,
        toFrame: 40829
      },
      {
        text: 'her',
        fromFrame: 40829,
        toFrame: 40832
      },
      {
        text: 'he',
        fromFrame: 40834,
        toFrame: 40840
      },
      {
        text: 'fronted',
        fromFrame: 40840,
        toFrame: 40852
      },
      {
        text: 'give',
        fromFrame: 40852,
        toFrame: 40856
      }
    ],
    fromFrame: 40763,
    toFrame: 40856,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'us tickets Preeti, create tickets. Already created. There',
    tokens: [
      {
        text: 'us',
        fromFrame: 40856,
        toFrame: 40859
      },
      {
        text: 'tickets',
        fromFrame: 40859,
        toFrame: 40871
      },
      {
        text: 'Preeti,',
        fromFrame: 40879,
        toFrame: 40891
      },
      {
        text: 'create',
        fromFrame: 40896,
        toFrame: 40904
      },
      {
        text: 'tickets.',
        fromFrame: 40904,
        toFrame: 40916
      },
      {
        text: 'Already',
        fromFrame: 40946,
        toFrame: 40957
      },
      {
        text: 'created.',
        fromFrame: 40957,
        toFrame: 40969
      },
      {
        text: 'There',
        fromFrame: 41491,
        toFrame: 41497
      }
    ],
    fromFrame: 40856,
    toFrame: 41497,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'are so many tickets for AI search, but',
    tokens: [
      {
        text: 'are',
        fromFrame: 41497,
        toFrame: 41499
      },
      {
        text: 'so',
        fromFrame: 41499,
        toFrame: 41503
      },
      {
        text: 'many',
        fromFrame: 41503,
        toFrame: 41511
      },
      {
        text: 'tickets',
        fromFrame: 41511,
        toFrame: 41520
      },
      {
        text: 'for',
        fromFrame: 41520,
        toFrame: 41528
      },
      {
        text: 'AI',
        fromFrame: 41528,
        toFrame: 41540
      },
      {
        text: 'search,',
        fromFrame: 41549,
        toFrame: 41561
      },
      {
        text: 'but',
        fromFrame: 41574,
        toFrame: 41579
      }
    ],
    fromFrame: 41497,
    toFrame: 41579,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'I think I posted in the channel for',
    tokens: [
      {
        text: 'I',
        fromFrame: 41579,
        toFrame: 41583
      },
      {
        text: 'think',
        fromFrame: 41583,
        toFrame: 41589
      },
      {
        text: 'I',
        fromFrame: 41589,
        toFrame: 41591
      },
      {
        text: 'posted',
        fromFrame: 41591,
        toFrame: 41603
      },
      {
        text: 'in',
        fromFrame: 41603,
        toFrame: 41607
      },
      {
        text: 'the',
        fromFrame: 41607,
        toFrame: 41610
      },
      {
        text: 'channel',
        fromFrame: 41610,
        toFrame: 41622
      },
      {
        text: 'for',
        fromFrame: 41662,
        toFrame: 41668
      }
    ],
    fromFrame: 41579,
    toFrame: 41668,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'medium priority. Get relevance to feeling for gender.',
    tokens: [
      {
        text: 'medium',
        fromFrame: 41668,
        toFrame: 41676
      },
      {
        text: 'priority.',
        fromFrame: 41676,
        toFrame: 41688
      },
      {
        text: 'Get',
        fromFrame: 41723,
        toFrame: 41727
      },
      {
        text: 'relevance',
        fromFrame: 41727,
        toFrame: 41739
      },
      {
        text: 'to',
        fromFrame: 41744,
        toFrame: 41746
      },
      {
        text: 'feeling',
        fromFrame: 41746,
        toFrame: 41755
      },
      {
        text: 'for',
        fromFrame: 41755,
        toFrame: 41759
      },
      {
        text: 'gender.',
        fromFrame: 41759,
        toFrame: 41769
      }
    ],
    fromFrame: 41668,
    toFrame: 41769,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Which is a feature bug. This is a',
    tokens: [
      {
        text: 'Which',
        fromFrame: 41769,
        toFrame: 41773
      },
      {
        text: 'is',
        fromFrame: 41773,
        toFrame: 41775
      },
      {
        text: 'a',
        fromFrame: 41775,
        toFrame: 41777
      },
      {
        text: 'feature',
        fromFrame: 41777,
        toFrame: 41786
      },
      {
        text: 'bug.',
        fromFrame: 41786,
        toFrame: 41798
      },
      {
        text: 'This',
        fromFrame: 41846,
        toFrame: 41850
      },
      {
        text: 'is',
        fromFrame: 41850,
        toFrame: 41853
      },
      {
        text: 'a',
        fromFrame: 41853,
        toFrame: 41857
      }
    ],
    fromFrame: 41769,
    toFrame: 41857,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'feature bug. No. This is live in prod',
    tokens: [
      {
        text: 'feature',
        fromFrame: 41857,
        toFrame: 41865
      },
      {
        text: 'bug.',
        fromFrame: 41865,
        toFrame: 41874
      },
      {
        text: 'No.',
        fromFrame: 41874,
        toFrame: 41880
      },
      {
        text: 'This',
        fromFrame: 41880,
        toFrame: 41886
      },
      {
        text: 'is',
        fromFrame: 41886,
        toFrame: 41890
      },
      {
        text: 'live',
        fromFrame: 41890,
        toFrame: 41898
      },
      {
        text: 'in',
        fromFrame: 41898,
        toFrame: 41901
      },
      {
        text: 'prod',
        fromFrame: 41901,
        toFrame: 41909
      }
    ],
    fromFrame: 41857,
    toFrame: 41909,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "now. No. It's talking to. AI is also",
    tokens: [
      {
        text: 'now.',
        fromFrame: 41909,
        toFrame: 41921
      },
      {
        text: 'No.',
        fromFrame: 41934,
        toFrame: 41936
      },
      {
        text: "It's",
        fromFrame: 41936,
        toFrame: 41940
      },
      {
        text: 'talking',
        fromFrame: 41940,
        toFrame: 41949
      },
      {
        text: 'to.',
        fromFrame: 41949,
        toFrame: 41961
      },
      {
        text: 'AI',
        fromFrame: 42007,
        toFrame: 42011
      },
      {
        text: 'is',
        fromFrame: 42011,
        toFrame: 42017
      },
      {
        text: 'also',
        fromFrame: 42017,
        toFrame: 42024
      }
    ],
    fromFrame: 41909,
    toFrame: 42024,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'live now. You should have to contain the',
    tokens: [
      {
        text: 'live',
        fromFrame: 42024,
        toFrame: 42028
      },
      {
        text: 'now.',
        fromFrame: 42028,
        toFrame: 42040
      },
      {
        text: 'You',
        fromFrame: 42066,
        toFrame: 42070
      },
      {
        text: 'should',
        fromFrame: 42070,
        toFrame: 42075
      },
      {
        text: 'have',
        fromFrame: 42075,
        toFrame: 42080
      },
      {
        text: 'to',
        fromFrame: 42080,
        toFrame: 42081
      },
      {
        text: 'contain',
        fromFrame: 42081,
        toFrame: 42093
      },
      {
        text: 'the',
        fromFrame: 42093,
        toFrame: 42097
      }
    ],
    fromFrame: 42024,
    toFrame: 42097,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'labels. Might be that that label is still',
    tokens: [
      {
        text: 'labels.',
        fromFrame: 42097,
        toFrame: 42109
      },
      {
        text: 'Might',
        fromFrame: 42124,
        toFrame: 42128
      },
      {
        text: 'be',
        fromFrame: 42128,
        toFrame: 42131
      },
      {
        text: 'that',
        fromFrame: 42131,
        toFrame: 42139
      },
      {
        text: 'that',
        fromFrame: 42139,
        toFrame: 42149
      },
      {
        text: 'label',
        fromFrame: 42149,
        toFrame: 42156
      },
      {
        text: 'is',
        fromFrame: 42156,
        toFrame: 42164
      },
      {
        text: 'still',
        fromFrame: 42164,
        toFrame: 42170
      }
    ],
    fromFrame: 42097,
    toFrame: 42170,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'there. We need to convert the label. Yeah.',
    tokens: [
      {
        text: 'there.',
        fromFrame: 42170,
        toFrame: 42182
      },
      {
        text: 'We',
        fromFrame: 42245,
        toFrame: 42250
      },
      {
        text: 'need',
        fromFrame: 42250,
        toFrame: 42256
      },
      {
        text: 'to',
        fromFrame: 42256,
        toFrame: 42258
      },
      {
        text: 'convert',
        fromFrame: 42258,
        toFrame: 42268
      },
      {
        text: 'the',
        fromFrame: 42268,
        toFrame: 42272
      },
      {
        text: 'label.',
        fromFrame: 42272,
        toFrame: 42284
      },
      {
        text: 'Yeah.',
        fromFrame: 42421,
        toFrame: 42425
      }
    ],
    fromFrame: 42170,
    toFrame: 42425,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "We didn't still check this. Nice. Very nice.",
    tokens: [
      {
        text: 'We',
        fromFrame: 42425,
        toFrame: 42432
      },
      {
        text: "didn't",
        fromFrame: 42432,
        toFrame: 42442
      },
      {
        text: 'still',
        fromFrame: 42442,
        toFrame: 42450
      },
      {
        text: 'check',
        fromFrame: 42450,
        toFrame: 42455
      },
      {
        text: 'this.',
        fromFrame: 42455,
        toFrame: 42467
      },
      {
        text: 'Nice.',
        fromFrame: 42476,
        toFrame: 42488
      },
      {
        text: 'Very',
        fromFrame: 42528,
        toFrame: 42534
      },
      {
        text: 'nice.',
        fromFrame: 42534,
        toFrame: 42546
      }
    ],
    fromFrame: 42425,
    toFrame: 42636,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Should I send this to Mikkel? Label. And',
    tokens: [
      {
        text: 'Should',
        fromFrame: 42636,
        toFrame: 42640
      },
      {
        text: 'I',
        fromFrame: 42640,
        toFrame: 42645
      },
      {
        text: 'send',
        fromFrame: 42645,
        toFrame: 42651
      },
      {
        text: 'this',
        fromFrame: 42651,
        toFrame: 42655
      },
      {
        text: 'to',
        fromFrame: 42655,
        toFrame: 42661
      },
      {
        text: 'Mikkel?',
        fromFrame: 42661,
        toFrame: 42673
      },
      {
        text: 'Label.',
        fromFrame: 42718,
        toFrame: 42730
      },
      {
        text: 'And',
        fromFrame: 42828,
        toFrame: 42831
      }
    ],
    fromFrame: 42636,
    toFrame: 42831,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "labels you don't do. It's okay. Oh, quick",
    tokens: [
      {
        text: 'labels',
        fromFrame: 42831,
        toFrame: 42839
      },
      {
        text: 'you',
        fromFrame: 42839,
        toFrame: 42841
      },
      {
        text: "don't",
        fromFrame: 42841,
        toFrame: 42847
      },
      {
        text: 'do.',
        fromFrame: 42847,
        toFrame: 42855
      },
      {
        text: "It's",
        fromFrame: 42855,
        toFrame: 42856
      },
      {
        text: 'okay.',
        fromFrame: 42856,
        toFrame: 42868
      },
      {
        text: 'Oh,',
        fromFrame: 42897,
        toFrame: 42906
      },
      {
        text: 'quick',
        fromFrame: 42906,
        toFrame: 42912
      }
    ],
    fromFrame: 42831,
    toFrame: 42912,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'filters you want to remove not in development.',
    tokens: [
      {
        text: 'filters',
        fromFrame: 42912,
        toFrame: 42924
      },
      {
        text: 'you',
        fromFrame: 42924,
        toFrame: 42926
      },
      {
        text: 'want',
        fromFrame: 42926,
        toFrame: 42929
      },
      {
        text: 'to',
        fromFrame: 42929,
        toFrame: 42931
      },
      {
        text: 'remove',
        fromFrame: 42931,
        toFrame: 42937
      },
      {
        text: 'not',
        fromFrame: 42937,
        toFrame: 42941
      },
      {
        text: 'in',
        fromFrame: 42941,
        toFrame: 42945
      },
      {
        text: 'development.',
        fromFrame: 42945,
        toFrame: 42957
      }
    ],
    fromFrame: 42912,
    toFrame: 43086,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Oh, this yeah. Why is this medium priority?',
    tokens: [
      {
        text: 'Oh,',
        fromFrame: 43086,
        toFrame: 43092
      },
      {
        text: 'this',
        fromFrame: 43092,
        toFrame: 43104
      },
      {
        text: 'yeah.',
        fromFrame: 43117,
        toFrame: 43125
      },
      {
        text: 'Why',
        fromFrame: 43125,
        toFrame: 43130
      },
      {
        text: 'is',
        fromFrame: 43130,
        toFrame: 43136
      },
      {
        text: 'this',
        fromFrame: 43136,
        toFrame: 43142
      },
      {
        text: 'medium',
        fromFrame: 43142,
        toFrame: 43154
      },
      {
        text: 'priority?',
        fromFrame: 43155,
        toFrame: 43167
      }
    ],
    fromFrame: 43086,
    toFrame: 43211,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Because, that time we, this decided key will,',
    tokens: [
      {
        text: 'Because,',
        fromFrame: 43211,
        toFrame: 43223
      },
      {
        text: 'that',
        fromFrame: 43232,
        toFrame: 43238
      },
      {
        text: 'time',
        fromFrame: 43238,
        toFrame: 43246
      },
      {
        text: 'we,',
        fromFrame: 43246,
        toFrame: 43255
      },
      {
        text: 'this',
        fromFrame: 43261,
        toFrame: 43272
      },
      {
        text: 'decided',
        fromFrame: 43274,
        toFrame: 43286
      },
      {
        text: 'key',
        fromFrame: 43290,
        toFrame: 43299
      },
      {
        text: 'will,',
        fromFrame: 43299,
        toFrame: 43309
      }
    ],
    fromFrame: 43211,
    toFrame: 43317,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'do release without fixing this. Mhmm. We kept',
    tokens: [
      {
        text: 'do',
        fromFrame: 43317,
        toFrame: 43320
      },
      {
        text: 'release',
        fromFrame: 43320,
        toFrame: 43330
      },
      {
        text: 'without',
        fromFrame: 43330,
        toFrame: 43342
      },
      {
        text: 'fixing',
        fromFrame: 43342,
        toFrame: 43353
      },
      {
        text: 'this.',
        fromFrame: 43353,
        toFrame: 43365
      },
      {
        text: 'Mhmm.',
        fromFrame: 43386,
        toFrame: 43398
      },
      {
        text: 'We',
        fromFrame: 43401,
        toFrame: 43405
      },
      {
        text: 'kept',
        fromFrame: 43405,
        toFrame: 43413
      }
    ],
    fromFrame: 43317,
    toFrame: 43413,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'it as a medium priority. Okay. But this',
    tokens: [
      {
        text: 'it',
        fromFrame: 43413,
        toFrame: 43415
      },
      {
        text: 'as',
        fromFrame: 43415,
        toFrame: 43418
      },
      {
        text: 'a',
        fromFrame: 43418,
        toFrame: 43422
      },
      {
        text: 'medium',
        fromFrame: 43422,
        toFrame: 43432
      },
      {
        text: 'priority.',
        fromFrame: 43432,
        toFrame: 43444
      },
      {
        text: 'Okay.',
        fromFrame: 43496,
        toFrame: 43508
      },
      {
        text: 'But',
        fromFrame: 43527,
        toFrame: 43533
      },
      {
        text: 'this',
        fromFrame: 43533,
        toFrame: 43545
      }
    ],
    fromFrame: 43413,
    toFrame: 43550,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'thing, I think This should be high, I',
    tokens: [
      {
        text: 'thing,',
        fromFrame: 43550,
        toFrame: 43557
      },
      {
        text: 'I',
        fromFrame: 43557,
        toFrame: 43561
      },
      {
        text: 'think',
        fromFrame: 43561,
        toFrame: 43567
      },
      {
        text: 'This',
        fromFrame: 43577,
        toFrame: 43584
      },
      {
        text: 'should',
        fromFrame: 43584,
        toFrame: 43586
      },
      {
        text: 'be',
        fromFrame: 43586,
        toFrame: 43592
      },
      {
        text: 'high,',
        fromFrame: 43592,
        toFrame: 43604
      },
      {
        text: 'I',
        fromFrame: 43604,
        toFrame: 43605
      }
    ],
    fromFrame: 43550,
    toFrame: 43605,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'think, now. Coming spring. Where is the coming',
    tokens: [
      {
        text: 'think,',
        fromFrame: 43605,
        toFrame: 43611
      },
      {
        text: 'now.',
        fromFrame: 43611,
        toFrame: 43623
      },
      {
        text: 'Coming',
        fromFrame: 43861,
        toFrame: 43873
      },
      {
        text: 'spring.',
        fromFrame: 43984,
        toFrame: 43996
      },
      {
        text: 'Where',
        fromFrame: 44005,
        toFrame: 44009
      },
      {
        text: 'is',
        fromFrame: 44009,
        toFrame: 44013
      },
      {
        text: 'the',
        fromFrame: 44013,
        toFrame: 44019
      },
      {
        text: 'coming',
        fromFrame: 44019,
        toFrame: 44028
      }
    ],
    fromFrame: 43605,
    toFrame: 44028,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'spring? Spring, 37. Right? Oh, my call with',
    tokens: [
      {
        text: 'spring?',
        fromFrame: 44028,
        toFrame: 44040
      },
      {
        text: 'Spring,',
        fromFrame: 44067,
        toFrame: 44079
      },
      {
        text: '37.',
        fromFrame: 44092,
        toFrame: 44104
      },
      {
        text: 'Right?',
        fromFrame: 44111,
        toFrame: 44123
      },
      {
        text: 'Oh,',
        fromFrame: 44397,
        toFrame: 44409
      },
      {
        text: 'my',
        fromFrame: 44416,
        toFrame: 44421
      },
      {
        text: 'call',
        fromFrame: 44421,
        toFrame: 44427
      },
      {
        text: 'with',
        fromFrame: 44427,
        toFrame: 44433
      }
    ],
    fromFrame: 44028,
    toFrame: 44433,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Ankur. Sure. It's fine then. Okay. This is",
    tokens: [
      {
        text: 'Ankur.',
        fromFrame: 44433,
        toFrame: 44445
      },
      {
        text: 'Sure.',
        fromFrame: 45271,
        toFrame: 45283
      },
      {
        text: "It's",
        fromFrame: 45288,
        toFrame: 45292
      },
      {
        text: 'fine',
        fromFrame: 45292,
        toFrame: 45298
      },
      {
        text: 'then.',
        fromFrame: 45298,
        toFrame: 45310
      },
      {
        text: 'Okay.',
        fromFrame: 45430,
        toFrame: 45442
      },
      {
        text: 'This',
        fromFrame: 45445,
        toFrame: 45457
      },
      {
        text: 'is',
        fromFrame: 45457,
        toFrame: 45465
      }
    ],
    fromFrame: 44433,
    toFrame: 45465,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'fine. The setting city does not do anything.',
    tokens: [
      {
        text: 'fine.',
        fromFrame: 45465,
        toFrame: 45477
      },
      {
        text: 'The',
        fromFrame: 45538,
        toFrame: 45542
      },
      {
        text: 'setting',
        fromFrame: 45542,
        toFrame: 45554
      },
      {
        text: 'city',
        fromFrame: 45555,
        toFrame: 45565
      },
      {
        text: 'does',
        fromFrame: 45565,
        toFrame: 45571
      },
      {
        text: 'not',
        fromFrame: 45571,
        toFrame: 45574
      },
      {
        text: 'do',
        fromFrame: 45574,
        toFrame: 45578
      },
      {
        text: 'anything.',
        fromFrame: 45578,
        toFrame: 45588
      }
    ],
    fromFrame: 45465,
    toFrame: 45588,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Yeah. Okay. We can hide. Can we? But',
    tokens: [
      {
        text: 'Yeah.',
        fromFrame: 45588,
        toFrame: 45600
      },
      {
        text: 'Okay.',
        fromFrame: 45645,
        toFrame: 45657
      },
      {
        text: 'We',
        fromFrame: 45991,
        toFrame: 45993
      },
      {
        text: 'can',
        fromFrame: 45993,
        toFrame: 45999
      },
      {
        text: 'hide.',
        fromFrame: 45999,
        toFrame: 46011
      },
      {
        text: 'Can',
        fromFrame: 46041,
        toFrame: 46047
      },
      {
        text: 'we?',
        fromFrame: 46047,
        toFrame: 46059
      },
      {
        text: 'But',
        fromFrame: 46294,
        toFrame: 46306
      }
    ],
    fromFrame: 45588,
    toFrame: 46306,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "AI search might don't I'll check. Most probably,",
    tokens: [
      {
        text: 'AI',
        fromFrame: 46306,
        toFrame: 46316
      },
      {
        text: 'search',
        fromFrame: 46316,
        toFrame: 46325
      },
      {
        text: 'might',
        fromFrame: 46325,
        toFrame: 46331
      },
      {
        text: "don't",
        fromFrame: 46331,
        toFrame: 46337
      },
      {
        text: "I'll",
        fromFrame: 46373,
        toFrame: 46381
      },
      {
        text: 'check.',
        fromFrame: 46381,
        toFrame: 46390
      },
      {
        text: 'Most',
        fromFrame: 46390,
        toFrame: 46400
      },
      {
        text: 'probably,',
        fromFrame: 46400,
        toFrame: 46412
      }
    ],
    fromFrame: 46306,
    toFrame: 46412,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "we don't need it. This will happen in",
    tokens: [
      {
        text: 'we',
        fromFrame: 46412,
        toFrame: 46415
      },
      {
        text: "don't",
        fromFrame: 46415,
        toFrame: 46423
      },
      {
        text: 'need',
        fromFrame: 46423,
        toFrame: 46429
      },
      {
        text: 'it.',
        fromFrame: 46429,
        toFrame: 46438
      },
      {
        text: 'This',
        fromFrame: 46448,
        toFrame: 46458
      },
      {
        text: 'will',
        fromFrame: 46458,
        toFrame: 46465
      },
      {
        text: 'happen',
        fromFrame: 46465,
        toFrame: 46477
      },
      {
        text: 'in',
        fromFrame: 46481,
        toFrame: 46484
      }
    ],
    fromFrame: 46412,
    toFrame: 46484,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'the AI search, Andrew. Because if in AI',
    tokens: [
      {
        text: 'the',
        fromFrame: 46484,
        toFrame: 46492
      },
      {
        text: 'AI',
        fromFrame: 46492,
        toFrame: 46496
      },
      {
        text: 'search,',
        fromFrame: 46496,
        toFrame: 46502
      },
      {
        text: 'Andrew.',
        fromFrame: 46502,
        toFrame: 46514
      },
      {
        text: 'Because',
        fromFrame: 46542,
        toFrame: 46554
      },
      {
        text: 'if',
        fromFrame: 46554,
        toFrame: 46557
      },
      {
        text: 'in',
        fromFrame: 46557,
        toFrame: 46567
      },
      {
        text: 'AI',
        fromFrame: 46567,
        toFrame: 46571
      }
    ],
    fromFrame: 46484,
    toFrame: 46571,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'search, we have video clips from different source.',
    tokens: [
      {
        text: 'search,',
        fromFrame: 46571,
        toFrame: 46580
      },
      {
        text: 'we',
        fromFrame: 46580,
        toFrame: 46584
      },
      {
        text: 'have',
        fromFrame: 46584,
        toFrame: 46596
      },
      {
        text: 'video',
        fromFrame: 46628,
        toFrame: 46636
      },
      {
        text: 'clips',
        fromFrame: 46636,
        toFrame: 46644
      },
      {
        text: 'from',
        fromFrame: 46644,
        toFrame: 46650
      },
      {
        text: 'different',
        fromFrame: 46650,
        toFrame: 46659
      },
      {
        text: 'source.',
        fromFrame: 46659,
        toFrame: 46671
      }
    ],
    fromFrame: 46571,
    toFrame: 46686,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'So both speaker will show if we open',
    tokens: [
      {
        text: 'So',
        fromFrame: 46686,
        toFrame: 46694
      },
      {
        text: 'both',
        fromFrame: 46694,
        toFrame: 46706
      },
      {
        text: 'speaker',
        fromFrame: 46707,
        toFrame: 46719
      },
      {
        text: 'will',
        fromFrame: 46719,
        toFrame: 46726
      },
      {
        text: 'show',
        fromFrame: 46726,
        toFrame: 46734
      },
      {
        text: 'if',
        fromFrame: 46734,
        toFrame: 46740
      },
      {
        text: 'we',
        fromFrame: 46740,
        toFrame: 46747
      },
      {
        text: 'open',
        fromFrame: 46747,
        toFrame: 46757
      }
    ],
    fromFrame: 46686,
    toFrame: 46757,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'the setting proper. Mhmm. Which speaker? We can',
    tokens: [
      {
        text: 'the',
        fromFrame: 46757,
        toFrame: 46761
      },
      {
        text: 'setting',
        fromFrame: 46761,
        toFrame: 46770
      },
      {
        text: 'proper.',
        fromFrame: 46770,
        toFrame: 46782
      },
      {
        text: 'Mhmm.',
        fromFrame: 46976,
        toFrame: 46981
      },
      {
        text: 'Which',
        fromFrame: 46981,
        toFrame: 46993
      },
      {
        text: 'speaker?',
        fromFrame: 46993,
        toFrame: 47005
      },
      {
        text: 'We',
        fromFrame: 47107,
        toFrame: 47115
      },
      {
        text: 'can',
        fromFrame: 47115,
        toFrame: 47124
      }
    ],
    fromFrame: 46757,
    toFrame: 47124,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'hide, but I just want to check if',
    tokens: [
      {
        text: 'hide,',
        fromFrame: 47124,
        toFrame: 47132
      },
      {
        text: 'but',
        fromFrame: 47132,
        toFrame: 47140
      },
      {
        text: 'I',
        fromFrame: 47140,
        toFrame: 47142
      },
      {
        text: 'just',
        fromFrame: 47142,
        toFrame: 47149
      },
      {
        text: 'want',
        fromFrame: 47149,
        toFrame: 47157
      },
      {
        text: 'to',
        fromFrame: 47157,
        toFrame: 47169
      },
      {
        text: 'check',
        fromFrame: 47196,
        toFrame: 47201
      },
      {
        text: 'if',
        fromFrame: 47201,
        toFrame: 47213
      }
    ],
    fromFrame: 47124,
    toFrame: 47253,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "hiding is the correct way. I'll move this.",
    tokens: [
      {
        text: 'hiding',
        fromFrame: 47253,
        toFrame: 47263
      },
      {
        text: 'is',
        fromFrame: 47263,
        toFrame: 47267
      },
      {
        text: 'the',
        fromFrame: 47267,
        toFrame: 47270
      },
      {
        text: 'correct',
        fromFrame: 47270,
        toFrame: 47282
      },
      {
        text: 'way.',
        fromFrame: 47307,
        toFrame: 47318
      },
      {
        text: "I'll",
        fromFrame: 47318,
        toFrame: 47330
      },
      {
        text: 'move',
        fromFrame: 47343,
        toFrame: 47355
      },
      {
        text: 'this.',
        fromFrame: 47366,
        toFrame: 47378
      }
    ],
    fromFrame: 47253,
    toFrame: 47417,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Okay. It's got moved. Why is the ship",
    tokens: [
      {
        text: 'Okay.',
        fromFrame: 47417,
        toFrame: 47423
      },
      {
        text: "It's",
        fromFrame: 47423,
        toFrame: 47429
      },
      {
        text: 'got',
        fromFrame: 47429,
        toFrame: 47441
      },
      {
        text: 'moved.',
        fromFrame: 47508,
        toFrame: 47520
      },
      {
        text: 'Why',
        fromFrame: 47594,
        toFrame: 47602
      },
      {
        text: 'is',
        fromFrame: 47602,
        toFrame: 47606
      },
      {
        text: 'the',
        fromFrame: 47606,
        toFrame: 47609
      },
      {
        text: 'ship',
        fromFrame: 47609,
        toFrame: 47615
      }
    ],
    fromFrame: 47417,
    toFrame: 47615,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'here? No idea. You must know how to',
    tokens: [
      {
        text: 'here?',
        fromFrame: 47615,
        toFrame: 47627
      },
      {
        text: 'No',
        fromFrame: 47694,
        toFrame: 47698
      },
      {
        text: 'idea.',
        fromFrame: 47698,
        toFrame: 47710
      },
      {
        text: 'You',
        fromFrame: 47795,
        toFrame: 47799
      },
      {
        text: 'must',
        fromFrame: 47799,
        toFrame: 47804
      },
      {
        text: 'know',
        fromFrame: 47804,
        toFrame: 47808
      },
      {
        text: 'how',
        fromFrame: 47808,
        toFrame: 47812
      },
      {
        text: 'to',
        fromFrame: 47812,
        toFrame: 47814
      }
    ],
    fromFrame: 47615,
    toFrame: 47814,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "one ticket. From support. Oh, didn't see. Okay.",
    tokens: [
      {
        text: 'one',
        fromFrame: 47814,
        toFrame: 47818
      },
      {
        text: 'ticket.',
        fromFrame: 47818,
        toFrame: 47830
      },
      {
        text: 'From',
        fromFrame: 47843,
        toFrame: 47849
      },
      {
        text: 'support.',
        fromFrame: 47849,
        toFrame: 47861
      },
      {
        text: 'Oh,',
        fromFrame: 47994,
        toFrame: 47996
      },
      {
        text: "didn't",
        fromFrame: 47996,
        toFrame: 48006
      },
      {
        text: 'see.',
        fromFrame: 48006,
        toFrame: 48012
      },
      {
        text: 'Okay.',
        fromFrame: 48012,
        toFrame: 48024
      }
    ],
    fromFrame: 47814,
    toFrame: 48033,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Kashish has duplicate. Click thumbnail not matching with',
    tokens: [
      {
        text: 'Kashish',
        fromFrame: 48033,
        toFrame: 48045
      },
      {
        text: 'has',
        fromFrame: 48054,
        toFrame: 48066
      },
      {
        text: 'duplicate.',
        fromFrame: 48088,
        toFrame: 48100
      },
      {
        text: 'Click',
        fromFrame: 48102,
        toFrame: 48109
      },
      {
        text: 'thumbnail',
        fromFrame: 48109,
        toFrame: 48121
      },
      {
        text: 'not',
        fromFrame: 48123,
        toFrame: 48130
      },
      {
        text: 'matching',
        fromFrame: 48130,
        toFrame: 48140
      },
      {
        text: 'with',
        fromFrame: 48140,
        toFrame: 48146
      }
    ],
    fromFrame: 48033,
    toFrame: 48146,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'the actual clip. This Ignore ignore 2 split',
    tokens: [
      {
        text: 'the',
        fromFrame: 48146,
        toFrame: 48148
      },
      {
        text: 'actual',
        fromFrame: 48148,
        toFrame: 48159
      },
      {
        text: 'clip.',
        fromFrame: 48159,
        toFrame: 48171
      },
      {
        text: 'This',
        fromFrame: 48242,
        toFrame: 48254
      },
      {
        text: 'Ignore',
        fromFrame: 48284,
        toFrame: 48294
      },
      {
        text: 'ignore',
        fromFrame: 48294,
        toFrame: 48303
      },
      {
        text: '2',
        fromFrame: 48303,
        toFrame: 48307
      },
      {
        text: 'split',
        fromFrame: 48307,
        toFrame: 48311
      }
    ],
    fromFrame: 48146,
    toFrame: 48311,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "split. It's 10:38. What will happen by that",
    tokens: [
      {
        text: 'split.',
        fromFrame: 48311,
        toFrame: 48321
      },
      {
        text: "It's",
        fromFrame: 48321,
        toFrame: 48326
      },
      {
        text: '10:38.',
        fromFrame: 48326,
        toFrame: 48338
      },
      {
        text: 'What',
        fromFrame: 48399,
        toFrame: 48405
      },
      {
        text: 'will',
        fromFrame: 48405,
        toFrame: 48411
      },
      {
        text: 'happen',
        fromFrame: 48411,
        toFrame: 48420
      },
      {
        text: 'by',
        fromFrame: 48420,
        toFrame: 48426
      },
      {
        text: 'that',
        fromFrame: 48426,
        toFrame: 48432
      }
    ],
    fromFrame: 48311,
    toFrame: 48432,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "time? We'll figure out something. We'll at least",
    tokens: [
      {
        text: 'time?',
        fromFrame: 48432,
        toFrame: 48444
      },
      {
        text: "We'll",
        fromFrame: 48458,
        toFrame: 48466
      },
      {
        text: 'figure',
        fromFrame: 48466,
        toFrame: 48474
      },
      {
        text: 'out',
        fromFrame: 48474,
        toFrame: 48478
      },
      {
        text: 'something.',
        fromFrame: 48478,
        toFrame: 48490
      },
      {
        text: "We'll",
        fromFrame: 48491,
        toFrame: 48503
      },
      {
        text: 'at',
        fromFrame: 48504,
        toFrame: 48508
      },
      {
        text: 'least',
        fromFrame: 48508,
        toFrame: 48520
      }
    ],
    fromFrame: 48432,
    toFrame: 48522,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'work towards it. The heading text. This I',
    tokens: [
      {
        text: 'work',
        fromFrame: 48522,
        toFrame: 48528
      },
      {
        text: 'towards',
        fromFrame: 48528,
        toFrame: 48535
      },
      {
        text: 'it.',
        fromFrame: 48535,
        toFrame: 48547
      },
      {
        text: 'The',
        fromFrame: 48650,
        toFrame: 48654
      },
      {
        text: 'heading',
        fromFrame: 48654,
        toFrame: 48666
      },
      {
        text: 'text.',
        fromFrame: 48666,
        toFrame: 48678
      },
      {
        text: 'This',
        fromFrame: 48859,
        toFrame: 48865
      },
      {
        text: 'I',
        fromFrame: 48870,
        toFrame: 48882
      }
    ],
    fromFrame: 48522,
    toFrame: 48888,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'think you referred in last the version. And',
    tokens: [
      {
        text: 'think',
        fromFrame: 48888,
        toFrame: 48891
      },
      {
        text: 'you',
        fromFrame: 48891,
        toFrame: 48893
      },
      {
        text: 'referred',
        fromFrame: 48893,
        toFrame: 48905
      },
      {
        text: 'in',
        fromFrame: 48905,
        toFrame: 48909
      },
      {
        text: 'last',
        fromFrame: 48909,
        toFrame: 48918
      },
      {
        text: 'the',
        fromFrame: 48920,
        toFrame: 48924
      },
      {
        text: 'version.',
        fromFrame: 48924,
        toFrame: 48936
      },
      {
        text: 'And',
        fromFrame: 48976,
        toFrame: 48987
      }
    ],
    fromFrame: 48888,
    toFrame: 48987,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'this second. This one to I can move',
    tokens: [
      {
        text: 'this',
        fromFrame: 48987,
        toFrame: 48995
      },
      {
        text: 'second.',
        fromFrame: 48995,
        toFrame: 49007
      },
      {
        text: 'This',
        fromFrame: 49117,
        toFrame: 49129
      },
      {
        text: 'one',
        fromFrame: 49138,
        toFrame: 49150
      },
      {
        text: 'to',
        fromFrame: 49159,
        toFrame: 49171
      },
      {
        text: 'I',
        fromFrame: 49249,
        toFrame: 49253
      },
      {
        text: 'can',
        fromFrame: 49253,
        toFrame: 49257
      },
      {
        text: 'move',
        fromFrame: 49257,
        toFrame: 49265
      }
    ],
    fromFrame: 48987,
    toFrame: 49265,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'to 37. I will ask him. Gender transcript',
    tokens: [
      {
        text: 'to',
        fromFrame: 49265,
        toFrame: 49270
      },
      {
        text: '37.',
        fromFrame: 49270,
        toFrame: 49282
      },
      {
        text: 'I',
        fromFrame: 49299,
        toFrame: 49303
      },
      {
        text: 'will',
        fromFrame: 49303,
        toFrame: 49309
      },
      {
        text: 'ask',
        fromFrame: 49309,
        toFrame: 49318
      },
      {
        text: 'him.',
        fromFrame: 49318,
        toFrame: 49330
      },
      {
        text: 'Gender',
        fromFrame: 49389,
        toFrame: 49401
      },
      {
        text: 'transcript',
        fromFrame: 49401,
        toFrame: 49413
      }
    ],
    fromFrame: 49265,
    toFrame: 49414,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'for the edited clip. This is customer work.',
    tokens: [
      {
        text: 'for',
        fromFrame: 49414,
        toFrame: 49418
      },
      {
        text: 'the',
        fromFrame: 49418,
        toFrame: 49420
      },
      {
        text: 'edited',
        fromFrame: 49420,
        toFrame: 49432
      },
      {
        text: 'clip.',
        fromFrame: 49432,
        toFrame: 49444
      },
      {
        text: 'This',
        fromFrame: 49450,
        toFrame: 49454
      },
      {
        text: 'is',
        fromFrame: 49454,
        toFrame: 49458
      },
      {
        text: 'customer',
        fromFrame: 49458,
        toFrame: 49470
      },
      {
        text: 'work.',
        fromFrame: 49479,
        toFrame: 49483
      }
    ],
    fromFrame: 49414,
    toFrame: 49483,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Give me a second. Why is this medium?',
    tokens: [
      {
        text: 'Give',
        fromFrame: 49483,
        toFrame: 49487
      },
      {
        text: 'me',
        fromFrame: 49487,
        toFrame: 49489
      },
      {
        text: 'a',
        fromFrame: 49489,
        toFrame: 49493
      },
      {
        text: 'second.',
        fromFrame: 49493,
        toFrame: 49505
      },
      {
        text: 'Why',
        fromFrame: 49548,
        toFrame: 49554
      },
      {
        text: 'is',
        fromFrame: 49554,
        toFrame: 49558
      },
      {
        text: 'this',
        fromFrame: 49558,
        toFrame: 49564
      },
      {
        text: 'medium?',
        fromFrame: 49564,
        toFrame: 49576
      }
    ],
    fromFrame: 49483,
    toFrame: 49650,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Because it's customer work. It's not Yeah. But",
    tokens: [
      {
        text: 'Because',
        fromFrame: 49650,
        toFrame: 49658
      },
      {
        text: "it's",
        fromFrame: 49658,
        toFrame: 49662
      },
      {
        text: 'customer',
        fromFrame: 49662,
        toFrame: 49674
      },
      {
        text: 'work.',
        fromFrame: 49675,
        toFrame: 49687
      },
      {
        text: "It's",
        fromFrame: 49692,
        toFrame: 49698
      },
      {
        text: 'not',
        fromFrame: 49698,
        toFrame: 49700
      },
      {
        text: 'Yeah.',
        fromFrame: 49702,
        toFrame: 49708
      },
      {
        text: 'But',
        fromFrame: 49708,
        toFrame: 49710
      }
    ],
    fromFrame: 49650,
    toFrame: 49712,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'I think it was she was talking about',
    tokens: [
      {
        text: 'I',
        fromFrame: 49712,
        toFrame: 49713
      },
      {
        text: 'think',
        fromFrame: 49713,
        toFrame: 49719
      },
      {
        text: 'it',
        fromFrame: 49719,
        toFrame: 49721
      },
      {
        text: 'was',
        fromFrame: 49721,
        toFrame: 49729
      },
      {
        text: 'she',
        fromFrame: 49735,
        toFrame: 49740
      },
      {
        text: 'was',
        fromFrame: 49740,
        toFrame: 49746
      },
      {
        text: 'talking',
        fromFrame: 49746,
        toFrame: 49754
      },
      {
        text: 'about',
        fromFrame: 49754,
        toFrame: 49760
      }
    ],
    fromFrame: 49712,
    toFrame: 49760,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'something copy paste. Do we allow to copy',
    tokens: [
      {
        text: 'something',
        fromFrame: 49760,
        toFrame: 49772
      },
      {
        text: 'copy',
        fromFrame: 49775,
        toFrame: 49787
      },
      {
        text: 'paste.',
        fromFrame: 49790,
        toFrame: 49802
      },
      {
        text: 'Do',
        fromFrame: 49827,
        toFrame: 49831
      },
      {
        text: 'we',
        fromFrame: 49831,
        toFrame: 49835
      },
      {
        text: 'allow',
        fromFrame: 49835,
        toFrame: 49847
      },
      {
        text: 'to',
        fromFrame: 49848,
        toFrame: 49854
      },
      {
        text: 'copy',
        fromFrame: 49854,
        toFrame: 49862
      }
    ],
    fromFrame: 49760,
    toFrame: 49862,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "paste transcript? Click on clickable? No. No. Don't",
    tokens: [
      {
        text: 'paste',
        fromFrame: 49862,
        toFrame: 49874
      },
      {
        text: 'transcript?',
        fromFrame: 49877,
        toFrame: 49889
      },
      {
        text: 'Click',
        fromFrame: 49900,
        toFrame: 49908
      },
      {
        text: 'on',
        fromFrame: 49908,
        toFrame: 49914
      },
      {
        text: 'clickable?',
        fromFrame: 49914,
        toFrame: 49926
      },
      {
        text: 'No.',
        fromFrame: 49952,
        toFrame: 49960
      },
      {
        text: 'No.',
        fromFrame: 49960,
        toFrame: 49967
      },
      {
        text: "Don't",
        fromFrame: 49967,
        toFrame: 49975
      }
    ],
    fromFrame: 49862,
    toFrame: 49975,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "close this. We don't close. But we need",
    tokens: [
      {
        text: 'close',
        fromFrame: 49975,
        toFrame: 49983
      },
      {
        text: 'this.',
        fromFrame: 49983,
        toFrame: 49990
      },
      {
        text: 'We',
        fromFrame: 49990,
        toFrame: 49994
      },
      {
        text: "don't",
        fromFrame: 49994,
        toFrame: 50006
      },
      {
        text: 'close.',
        fromFrame: 50008,
        toFrame: 50020
      },
      {
        text: 'But',
        fromFrame: 50025,
        toFrame: 50029
      },
      {
        text: 'we',
        fromFrame: 50029,
        toFrame: 50033
      },
      {
        text: 'need',
        fromFrame: 50033,
        toFrame: 50040
      }
    ],
    fromFrame: 49975,
    toFrame: 50040,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "to. Allow. They copy. Right? Yeah. It's a",
    tokens: [
      {
        text: 'to.',
        fromFrame: 50040,
        toFrame: 50043
      },
      {
        text: 'Allow.',
        fromFrame: 50046,
        toFrame: 50049
      },
      {
        text: 'They',
        fromFrame: 50052,
        toFrame: 50064
      },
      {
        text: 'copy.',
        fromFrame: 50117,
        toFrame: 50129
      },
      {
        text: 'Right?',
        fromFrame: 50134,
        toFrame: 50146
      },
      {
        text: 'Yeah.',
        fromFrame: 50174,
        toFrame: 50175
      },
      {
        text: "It's",
        fromFrame: 50175,
        toFrame: 50183
      },
      {
        text: 'a',
        fromFrame: 50183,
        toFrame: 50191
      }
    ],
    fromFrame: 50040,
    toFrame: 50191,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'product cap also. Need to supply transcript? Like,',
    tokens: [
      {
        text: 'product',
        fromFrame: 50191,
        toFrame: 50203
      },
      {
        text: 'cap',
        fromFrame: 50204,
        toFrame: 50208
      },
      {
        text: 'also.',
        fromFrame: 50208,
        toFrame: 50220
      },
      {
        text: 'Need',
        fromFrame: 50343,
        toFrame: 50346
      },
      {
        text: 'to',
        fromFrame: 50346,
        toFrame: 50350
      },
      {
        text: 'supply',
        fromFrame: 50350,
        toFrame: 50362
      },
      {
        text: 'transcript?',
        fromFrame: 50362,
        toFrame: 50374
      },
      {
        text: 'Like,',
        fromFrame: 50389,
        toFrame: 50396
      }
    ],
    fromFrame: 50191,
    toFrame: 50396,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'how yeah. We just need to send them.',
    tokens: [
      {
        text: 'how',
        fromFrame: 50396,
        toFrame: 50400
      },
      {
        text: 'yeah.',
        fromFrame: 50408,
        toFrame: 50415
      },
      {
        text: 'We',
        fromFrame: 50415,
        toFrame: 50421
      },
      {
        text: 'just',
        fromFrame: 50421,
        toFrame: 50427
      },
      {
        text: 'need',
        fromFrame: 50427,
        toFrame: 50431
      },
      {
        text: 'to',
        fromFrame: 50431,
        toFrame: 50437
      },
      {
        text: 'send',
        fromFrame: 50437,
        toFrame: 50444
      },
      {
        text: 'them.',
        fromFrame: 50444,
        toFrame: 50456
      }
    ],
    fromFrame: 50396,
    toFrame: 50494,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'I think we should just give download transcript',
    tokens: [
      {
        text: 'I',
        fromFrame: 50494,
        toFrame: 50500
      },
      {
        text: 'think',
        fromFrame: 50500,
        toFrame: 50504
      },
      {
        text: 'we',
        fromFrame: 50504,
        toFrame: 50509
      },
      {
        text: 'should',
        fromFrame: 50509,
        toFrame: 50513
      },
      {
        text: 'just',
        fromFrame: 50513,
        toFrame: 50519
      },
      {
        text: 'give',
        fromFrame: 50519,
        toFrame: 50525
      },
      {
        text: 'download',
        fromFrame: 50525,
        toFrame: 50536
      },
      {
        text: 'transcript',
        fromFrame: 50536,
        toFrame: 50548
      }
    ],
    fromFrame: 50494,
    toFrame: 50548,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "option for clips also, Ashwin. Yeah. It'll come.",
    tokens: [
      {
        text: 'option',
        fromFrame: 50548,
        toFrame: 50557
      },
      {
        text: 'for',
        fromFrame: 50557,
        toFrame: 50563
      },
      {
        text: 'clips',
        fromFrame: 50563,
        toFrame: 50573
      },
      {
        text: 'also,',
        fromFrame: 50573,
        toFrame: 50585
      },
      {
        text: 'Ashwin.',
        fromFrame: 50592,
        toFrame: 50604
      },
      {
        text: 'Yeah.',
        fromFrame: 50640,
        toFrame: 50646
      },
      {
        text: "It'll",
        fromFrame: 50646,
        toFrame: 50652
      },
      {
        text: 'come.',
        fromFrame: 50652,
        toFrame: 50664
      }
    ],
    fromFrame: 50548,
    toFrame: 50703,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Okay. I'll credit for recording. Alright. Create a",
    tokens: [
      {
        text: 'Okay.',
        fromFrame: 50703,
        toFrame: 50715
      },
      {
        text: "I'll",
        fromFrame: 50715,
        toFrame: 50717
      },
      {
        text: 'credit',
        fromFrame: 50717,
        toFrame: 50724
      },
      {
        text: 'for',
        fromFrame: 50724,
        toFrame: 50730
      },
      {
        text: 'recording.',
        fromFrame: 50730,
        toFrame: 50742
      },
      {
        text: 'Alright.',
        fromFrame: 50782,
        toFrame: 50794
      },
      {
        text: 'Create',
        fromFrame: 50809,
        toFrame: 50814
      },
      {
        text: 'a',
        fromFrame: 50814,
        toFrame: 50819
      }
    ],
    fromFrame: 50703,
    toFrame: 50819,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'ticket, though. No. You create a ticket later.',
    tokens: [
      {
        text: 'ticket,',
        fromFrame: 50819,
        toFrame: 50831
      },
      {
        text: 'though.',
        fromFrame: 50877,
        toFrame: 50882
      },
      {
        text: 'No.',
        fromFrame: 50882,
        toFrame: 50888
      },
      {
        text: 'You',
        fromFrame: 50888,
        toFrame: 50894
      },
      {
        text: 'create',
        fromFrame: 50894,
        toFrame: 50900
      },
      {
        text: 'a',
        fromFrame: 50900,
        toFrame: 50901
      },
      {
        text: 'ticket',
        fromFrame: 50901,
        toFrame: 50907
      },
      {
        text: 'later.',
        fromFrame: 50907,
        toFrame: 50919
      }
    ],
    fromFrame: 50819,
    toFrame: 50976,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Where? Are you create a ticket? No? I',
    tokens: [
      {
        text: 'Where?',
        fromFrame: 50976,
        toFrame: 50988
      },
      {
        text: 'Are',
        fromFrame: 51015,
        toFrame: 51021
      },
      {
        text: 'you',
        fromFrame: 51021,
        toFrame: 51026
      },
      {
        text: 'create',
        fromFrame: 51026,
        toFrame: 51034
      },
      {
        text: 'a',
        fromFrame: 51034,
        toFrame: 51036
      },
      {
        text: 'ticket?',
        fromFrame: 51036,
        toFrame: 51044
      },
      {
        text: 'No?',
        fromFrame: 51044,
        toFrame: 51047
      },
      {
        text: 'I',
        fromFrame: 51047,
        toFrame: 51051
      }
    ],
    fromFrame: 50976,
    toFrame: 51051,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'said still No. No. I will, I will',
    tokens: [
      {
        text: 'said',
        fromFrame: 51051,
        toFrame: 51057
      },
      {
        text: 'still',
        fromFrame: 51063,
        toFrame: 51069
      },
      {
        text: 'No.',
        fromFrame: 51074,
        toFrame: 51078
      },
      {
        text: 'No.',
        fromFrame: 51078,
        toFrame: 51082
      },
      {
        text: 'I',
        fromFrame: 51082,
        toFrame: 51086
      },
      {
        text: 'will,',
        fromFrame: 51086,
        toFrame: 51097
      },
      {
        text: 'I',
        fromFrame: 51103,
        toFrame: 51109
      },
      {
        text: 'will',
        fromFrame: 51109,
        toFrame: 51117
      }
    ],
    fromFrame: 51051,
    toFrame: 51117,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'create. Ticket. But he still has 4 tickets,',
    tokens: [
      {
        text: 'create.',
        fromFrame: 51117,
        toFrame: 51129
      },
      {
        text: 'Ticket.',
        fromFrame: 51217,
        toFrame: 51229
      },
      {
        text: 'But',
        fromFrame: 51259,
        toFrame: 51263
      },
      {
        text: 'he',
        fromFrame: 51263,
        toFrame: 51269
      },
      {
        text: 'still',
        fromFrame: 51269,
        toFrame: 51275
      },
      {
        text: 'has',
        fromFrame: 51275,
        toFrame: 51280
      },
      {
        text: '4',
        fromFrame: 51280,
        toFrame: 51286
      },
      {
        text: 'tickets,',
        fromFrame: 51286,
        toFrame: 51296
      }
    ],
    fromFrame: 51117,
    toFrame: 51296,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "and I don't think he'll have I'll move",
    tokens: [
      {
        text: 'and',
        fromFrame: 51296,
        toFrame: 51301
      },
      {
        text: 'I',
        fromFrame: 51301,
        toFrame: 51303
      },
      {
        text: "don't",
        fromFrame: 51303,
        toFrame: 51309
      },
      {
        text: 'think',
        fromFrame: 51309,
        toFrame: 51315
      },
      {
        text: "he'll",
        fromFrame: 51315,
        toFrame: 51323
      },
      {
        text: 'have',
        fromFrame: 51323,
        toFrame: 51335
      },
      {
        text: "I'll",
        fromFrame: 51348,
        toFrame: 51360
      },
      {
        text: 'move',
        fromFrame: 51360,
        toFrame: 51372
      }
    ],
    fromFrame: 51296,
    toFrame: 51372,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'this one. Move the trim also and expect.',
    tokens: [
      {
        text: 'this',
        fromFrame: 51372,
        toFrame: 51380
      },
      {
        text: 'one.',
        fromFrame: 51380,
        toFrame: 51392
      },
      {
        text: 'Move',
        fromFrame: 51399,
        toFrame: 51403
      },
      {
        text: 'the',
        fromFrame: 51403,
        toFrame: 51415
      },
      {
        text: 'trim',
        fromFrame: 51442,
        toFrame: 51447
      },
      {
        text: 'also',
        fromFrame: 51447,
        toFrame: 51457
      },
      {
        text: 'and',
        fromFrame: 51457,
        toFrame: 51459
      },
      {
        text: 'expect.',
        fromFrame: 51459,
        toFrame: 51471
      }
    ],
    fromFrame: 51372,
    toFrame: 51507,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Next next. This is coming on December 2nd.',
    tokens: [
      {
        text: 'Next',
        fromFrame: 51507,
        toFrame: 51513
      },
      {
        text: 'next.',
        fromFrame: 51513,
        toFrame: 51525
      },
      {
        text: 'This',
        fromFrame: 51578,
        toFrame: 51584
      },
      {
        text: 'is',
        fromFrame: 51584,
        toFrame: 51587
      },
      {
        text: 'coming',
        fromFrame: 51587,
        toFrame: 51595
      },
      {
        text: 'on',
        fromFrame: 51595,
        toFrame: 51599
      },
      {
        text: 'December',
        fromFrame: 51599,
        toFrame: 51610
      },
      {
        text: '2nd.',
        fromFrame: 51610,
        toFrame: 51618
      }
    ],
    fromFrame: 51507,
    toFrame: 51618,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Right? Yeah. But those are all we're doing",
    tokens: [
      {
        text: 'Right?',
        fromFrame: 51618,
        toFrame: 51630
      },
      {
        text: 'Yeah.',
        fromFrame: 51658,
        toFrame: 51664
      },
      {
        text: 'But',
        fromFrame: 51664,
        toFrame: 51670
      },
      {
        text: 'those',
        fromFrame: 51670,
        toFrame: 51674
      },
      {
        text: 'are',
        fromFrame: 51674,
        toFrame: 51678
      },
      {
        text: 'all',
        fromFrame: 51678,
        toFrame: 51679
      },
      {
        text: "we're",
        fromFrame: 51680,
        toFrame: 51683
      },
      {
        text: 'doing',
        fromFrame: 51683,
        toFrame: 51691
      }
    ],
    fromFrame: 51618,
    toFrame: 51691,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'it. So we just need to still have',
    tokens: [
      {
        text: 'it.',
        fromFrame: 51691,
        toFrame: 51693
      },
      {
        text: 'So',
        fromFrame: 51693,
        toFrame: 51701
      },
      {
        text: 'we',
        fromFrame: 51701,
        toFrame: 51705
      },
      {
        text: 'just',
        fromFrame: 51705,
        toFrame: 51708
      },
      {
        text: 'need',
        fromFrame: 51708,
        toFrame: 51714
      },
      {
        text: 'to',
        fromFrame: 51714,
        toFrame: 51716
      },
      {
        text: 'still',
        fromFrame: 51716,
        toFrame: 51722
      },
      {
        text: 'have',
        fromFrame: 51722,
        toFrame: 51726
      }
    ],
    fromFrame: 51691,
    toFrame: 51726,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'earlier after that how things got about it.',
    tokens: [
      {
        text: 'earlier',
        fromFrame: 51726,
        toFrame: 51733
      },
      {
        text: 'after',
        fromFrame: 51733,
        toFrame: 51739
      },
      {
        text: 'that',
        fromFrame: 51739,
        toFrame: 51745
      },
      {
        text: 'how',
        fromFrame: 51745,
        toFrame: 51749
      },
      {
        text: 'things',
        fromFrame: 51749,
        toFrame: 51754
      },
      {
        text: 'got',
        fromFrame: 51754,
        toFrame: 51760
      },
      {
        text: 'about',
        fromFrame: 51760,
        toFrame: 51766
      },
      {
        text: 'it.',
        fromFrame: 51766,
        toFrame: 51778
      }
    ],
    fromFrame: 51726,
    toFrame: 51858,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Yeah. But at least we'll queue it. I'll",
    tokens: [
      {
        text: 'Yeah.',
        fromFrame: 51858,
        toFrame: 51867
      },
      {
        text: 'But',
        fromFrame: 51867,
        toFrame: 51873
      },
      {
        text: 'at',
        fromFrame: 51873,
        toFrame: 51877
      },
      {
        text: 'least',
        fromFrame: 51877,
        toFrame: 51882
      },
      {
        text: "we'll",
        fromFrame: 51882,
        toFrame: 51888
      },
      {
        text: 'queue',
        fromFrame: 51888,
        toFrame: 51896
      },
      {
        text: 'it.',
        fromFrame: 51896,
        toFrame: 51908
      },
      {
        text: "I'll",
        fromFrame: 51927,
        toFrame: 51930
      }
    ],
    fromFrame: 51858,
    toFrame: 51930,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "put it on Preeti's name. Preeti, you just",
    tokens: [
      {
        text: 'put',
        fromFrame: 51930,
        toFrame: 51936
      },
      {
        text: 'it',
        fromFrame: 51936,
        toFrame: 51940
      },
      {
        text: 'on',
        fromFrame: 51940,
        toFrame: 51944
      },
      {
        text: "Preeti's",
        fromFrame: 51944,
        toFrame: 51955
      },
      {
        text: 'name.',
        fromFrame: 51955,
        toFrame: 51967
      },
      {
        text: 'Preeti,',
        fromFrame: 51984,
        toFrame: 51996
      },
      {
        text: 'you',
        fromFrame: 52051,
        toFrame: 52057
      },
      {
        text: 'just',
        fromFrame: 52057,
        toFrame: 52065
      }
    ],
    fromFrame: 51930,
    toFrame: 52065,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "need to wait and reopen. If it's should",
    tokens: [
      {
        text: 'need',
        fromFrame: 52065,
        toFrame: 52076
      },
      {
        text: 'to',
        fromFrame: 52076,
        toFrame: 52078
      },
      {
        text: 'wait',
        fromFrame: 52078,
        toFrame: 52086
      },
      {
        text: 'and',
        fromFrame: 52086,
        toFrame: 52092
      },
      {
        text: 'reopen.',
        fromFrame: 52092,
        toFrame: 52104
      },
      {
        text: 'If',
        fromFrame: 52144,
        toFrame: 52146
      },
      {
        text: "it's",
        fromFrame: 52146,
        toFrame: 52151
      },
      {
        text: 'should',
        fromFrame: 52155,
        toFrame: 52159
      }
    ],
    fromFrame: 52065,
    toFrame: 52159,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'be on your name. 3 b You have',
    tokens: [
      {
        text: 'be',
        fromFrame: 52159,
        toFrame: 52163
      },
      {
        text: 'on',
        fromFrame: 52163,
        toFrame: 52167
      },
      {
        text: 'your',
        fromFrame: 52167,
        toFrame: 52172
      },
      {
        text: 'name.',
        fromFrame: 52172,
        toFrame: 52184
      },
      {
        text: '3',
        fromFrame: 52201,
        toFrame: 52206
      },
      {
        text: 'b',
        fromFrame: 52206,
        toFrame: 52210
      },
      {
        text: 'You',
        fromFrame: 52223,
        toFrame: 52235
      },
      {
        text: 'have',
        fromFrame: 52235,
        toFrame: 52241
      }
    ],
    fromFrame: 52159,
    toFrame: 52241,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "to tell if it's correct or not. Okay.",
    tokens: [
      {
        text: 'to',
        fromFrame: 52241,
        toFrame: 52243
      },
      {
        text: 'tell',
        fromFrame: 52243,
        toFrame: 52247
      },
      {
        text: 'if',
        fromFrame: 52247,
        toFrame: 52251
      },
      {
        text: "it's",
        fromFrame: 52251,
        toFrame: 52253
      },
      {
        text: 'correct',
        fromFrame: 52253,
        toFrame: 52260
      },
      {
        text: 'or',
        fromFrame: 52260,
        toFrame: 52266
      },
      {
        text: 'not.',
        fromFrame: 52266,
        toFrame: 52278
      },
      {
        text: 'Okay.',
        fromFrame: 52299,
        toFrame: 52308
      }
    ],
    fromFrame: 52241,
    toFrame: 52308,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "It's on my name. Thank you, sir. I",
    tokens: [
      {
        text: "It's",
        fromFrame: 52308,
        toFrame: 52312
      },
      {
        text: 'on',
        fromFrame: 52312,
        toFrame: 52318
      },
      {
        text: 'my',
        fromFrame: 52318,
        toFrame: 52324
      },
      {
        text: 'name.',
        fromFrame: 52324,
        toFrame: 52336
      },
      {
        text: 'Thank',
        fromFrame: 52368,
        toFrame: 52374
      },
      {
        text: 'you,',
        fromFrame: 52374,
        toFrame: 52377
      },
      {
        text: 'sir.',
        fromFrame: 52377,
        toFrame: 52389
      },
      {
        text: 'I',
        fromFrame: 52447,
        toFrame: 52449
      }
    ],
    fromFrame: 52308,
    toFrame: 52449,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "am telling it's not correct. We need to",
    tokens: [
      {
        text: 'am',
        fromFrame: 52449,
        toFrame: 52454
      },
      {
        text: 'telling',
        fromFrame: 52454,
        toFrame: 52466
      },
      {
        text: "it's",
        fromFrame: 52466,
        toFrame: 52472
      },
      {
        text: 'not',
        fromFrame: 52472,
        toFrame: 52477
      },
      {
        text: 'correct.',
        fromFrame: 52477,
        toFrame: 52489
      },
      {
        text: 'We',
        fromFrame: 52494,
        toFrame: 52500
      },
      {
        text: 'need',
        fromFrame: 52500,
        toFrame: 52501
      },
      {
        text: 'to',
        fromFrame: 52501,
        toFrame: 52513
      }
    ],
    fromFrame: 52449,
    toFrame: 52521,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "change. After it comes as event. Fine. I'm",
    tokens: [
      {
        text: 'change.',
        fromFrame: 52521,
        toFrame: 52533
      },
      {
        text: 'After',
        fromFrame: 52542,
        toFrame: 52549
      },
      {
        text: 'it',
        fromFrame: 52549,
        toFrame: 52553
      },
      {
        text: 'comes',
        fromFrame: 52553,
        toFrame: 52561
      },
      {
        text: 'as',
        fromFrame: 52561,
        toFrame: 52565
      },
      {
        text: 'event.',
        fromFrame: 52565,
        toFrame: 52577
      },
      {
        text: 'Fine.',
        fromFrame: 52636,
        toFrame: 52648
      },
      {
        text: "I'm",
        fromFrame: 52761,
        toFrame: 52764
      }
    ],
    fromFrame: 52521,
    toFrame: 52764,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'able to to close. You know what? This',
    tokens: [
      {
        text: 'able',
        fromFrame: 52764,
        toFrame: 52772
      },
      {
        text: 'to',
        fromFrame: 52772,
        toFrame: 52778
      },
      {
        text: 'to',
        fromFrame: 52789,
        toFrame: 52795
      },
      {
        text: 'close.',
        fromFrame: 52795,
        toFrame: 52807
      },
      {
        text: 'You',
        fromFrame: 52834,
        toFrame: 52839
      },
      {
        text: 'know',
        fromFrame: 52839,
        toFrame: 52845
      },
      {
        text: 'what?',
        fromFrame: 52845,
        toFrame: 52851
      },
      {
        text: 'This',
        fromFrame: 52851,
        toFrame: 52857
      }
    ],
    fromFrame: 52764,
    toFrame: 52857,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'needs to be there in video library, so',
    tokens: [
      {
        text: 'needs',
        fromFrame: 52857,
        toFrame: 52864
      },
      {
        text: 'to',
        fromFrame: 52864,
        toFrame: 52868
      },
      {
        text: 'be',
        fromFrame: 52868,
        toFrame: 52874
      },
      {
        text: 'there',
        fromFrame: 52874,
        toFrame: 52880
      },
      {
        text: 'in',
        fromFrame: 52880,
        toFrame: 52886
      },
      {
        text: 'video',
        fromFrame: 52886,
        toFrame: 52895
      },
      {
        text: 'library,',
        fromFrame: 52895,
        toFrame: 52907
      },
      {
        text: 'so',
        fromFrame: 52909,
        toFrame: 52914
      }
    ],
    fromFrame: 52857,
    toFrame: 52914,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I'm going to close this. Oh, wait. Amazing.",
    tokens: [
      {
        text: "I'm",
        fromFrame: 52914,
        toFrame: 52918
      },
      {
        text: 'going',
        fromFrame: 52918,
        toFrame: 52924
      },
      {
        text: 'to',
        fromFrame: 52924,
        toFrame: 52932
      },
      {
        text: 'close',
        fromFrame: 52932,
        toFrame: 52939
      },
      {
        text: 'this.',
        fromFrame: 52939,
        toFrame: 52951
      },
      {
        text: 'Oh,',
        fromFrame: 53020,
        toFrame: 53024
      },
      {
        text: 'wait.',
        fromFrame: 53024,
        toFrame: 53036
      },
      {
        text: 'Amazing.',
        fromFrame: 53043,
        toFrame: 53055
      }
    ],
    fromFrame: 52914,
    toFrame: 53068,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'How do I close this? What is this',
    tokens: [
      {
        text: 'How',
        fromFrame: 53068,
        toFrame: 53072
      },
      {
        text: 'do',
        fromFrame: 53072,
        toFrame: 53078
      },
      {
        text: 'I',
        fromFrame: 53078,
        toFrame: 53079
      },
      {
        text: 'close',
        fromFrame: 53079,
        toFrame: 53089
      },
      {
        text: 'this?',
        fromFrame: 53089,
        toFrame: 53101
      },
      {
        text: 'What',
        fromFrame: 53819,
        toFrame: 53823
      },
      {
        text: 'is',
        fromFrame: 53823,
        toFrame: 53829
      },
      {
        text: 'this',
        fromFrame: 53829,
        toFrame: 53835
      }
    ],
    fromFrame: 53068,
    toFrame: 53835,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'ticket? Why is this on impact area? You',
    tokens: [
      {
        text: 'ticket?',
        fromFrame: 53835,
        toFrame: 53847
      },
      {
        text: 'Why',
        fromFrame: 53911,
        toFrame: 53917
      },
      {
        text: 'is',
        fromFrame: 53917,
        toFrame: 53922
      },
      {
        text: 'this',
        fromFrame: 53922,
        toFrame: 53926
      },
      {
        text: 'on',
        fromFrame: 53926,
        toFrame: 53934
      },
      {
        text: 'impact',
        fromFrame: 53934,
        toFrame: 53942
      },
      {
        text: 'area?',
        fromFrame: 53942,
        toFrame: 53954
      },
      {
        text: 'You',
        fromFrame: 54162,
        toFrame: 54164
      }
    ],
    fromFrame: 53835,
    toFrame: 54164,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "cannot answer that. Let's see the ticket. No.",
    tokens: [
      {
        text: 'cannot',
        fromFrame: 54164,
        toFrame: 54176
      },
      {
        text: 'answer',
        fromFrame: 54178,
        toFrame: 54186
      },
      {
        text: 'that.',
        fromFrame: 54186,
        toFrame: 54193
      },
      {
        text: "Let's",
        fromFrame: 54193,
        toFrame: 54199
      },
      {
        text: 'see',
        fromFrame: 54199,
        toFrame: 54203
      },
      {
        text: 'the',
        fromFrame: 54203,
        toFrame: 54207
      },
      {
        text: 'ticket.',
        fromFrame: 54207,
        toFrame: 54219
      },
      {
        text: 'No.',
        fromFrame: 54219,
        toFrame: 54231
      }
    ],
    fromFrame: 54164,
    toFrame: 54507,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'I the ticket is something unrelated to clip.',
    tokens: [
      {
        text: 'I',
        fromFrame: 54507,
        toFrame: 54515
      },
      {
        text: 'the',
        fromFrame: 54522,
        toFrame: 54524
      },
      {
        text: 'ticket',
        fromFrame: 54524,
        toFrame: 54536
      },
      {
        text: 'is',
        fromFrame: 54536,
        toFrame: 54548
      },
      {
        text: 'something',
        fromFrame: 54608,
        toFrame: 54612
      },
      {
        text: 'unrelated',
        fromFrame: 54612,
        toFrame: 54624
      },
      {
        text: 'to',
        fromFrame: 54641,
        toFrame: 54653
      },
      {
        text: 'clip.',
        fromFrame: 54672,
        toFrame: 54684
      }
    ],
    fromFrame: 54507,
    toFrame: 54704,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Yeah. This to click and then How far',
    tokens: [
      {
        text: 'Yeah.',
        fromFrame: 54704,
        toFrame: 54710
      },
      {
        text: 'This',
        fromFrame: 54710,
        toFrame: 54716
      },
      {
        text: 'to',
        fromFrame: 54721,
        toFrame: 54733
      },
      {
        text: 'click',
        fromFrame: 54735,
        toFrame: 54746
      },
      {
        text: 'and',
        fromFrame: 54746,
        toFrame: 54749
      },
      {
        text: 'then',
        fromFrame: 54749,
        toFrame: 54752
      },
      {
        text: 'How',
        fromFrame: 54781,
        toFrame: 54787
      },
      {
        text: 'far',
        fromFrame: 54787,
        toFrame: 54791
      }
    ],
    fromFrame: 54704,
    toFrame: 54794,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Is it happening now still or is it',
    tokens: [
      {
        text: 'Is',
        fromFrame: 54794,
        toFrame: 54798
      },
      {
        text: 'it',
        fromFrame: 54798,
        toFrame: 54800
      },
      {
        text: 'happening',
        fromFrame: 54800,
        toFrame: 54812
      },
      {
        text: 'now',
        fromFrame: 54812,
        toFrame: 54817
      },
      {
        text: 'still',
        fromFrame: 54817,
        toFrame: 54825
      },
      {
        text: 'or',
        fromFrame: 54825,
        toFrame: 54831
      },
      {
        text: 'is',
        fromFrame: 54831,
        toFrame: 54835
      },
      {
        text: 'it',
        fromFrame: 54835,
        toFrame: 54837
      }
    ],
    fromFrame: 54794,
    toFrame: 54873,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "No. I don't observe this. Should I close",
    tokens: [
      {
        text: 'No.',
        fromFrame: 54873,
        toFrame: 54883
      },
      {
        text: 'I',
        fromFrame: 54883,
        toFrame: 54888
      },
      {
        text: "don't",
        fromFrame: 54888,
        toFrame: 54896
      },
      {
        text: 'observe',
        fromFrame: 54896,
        toFrame: 54906
      },
      {
        text: 'this.',
        fromFrame: 54906,
        toFrame: 54918
      },
      {
        text: 'Should',
        fromFrame: 54988,
        toFrame: 54996
      },
      {
        text: 'I',
        fromFrame: 54996,
        toFrame: 55004
      },
      {
        text: 'close',
        fromFrame: 55004,
        toFrame: 55010
      }
    ],
    fromFrame: 54873,
    toFrame: 55010,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'it, sir, September? Yeah. We can use this.',
    tokens: [
      {
        text: 'it,',
        fromFrame: 55010,
        toFrame: 55017
      },
      {
        text: 'sir,',
        fromFrame: 55017,
        toFrame: 55027
      },
      {
        text: 'September?',
        fromFrame: 55027,
        toFrame: 55039
      },
      {
        text: 'Yeah.',
        fromFrame: 55044,
        toFrame: 55048
      },
      {
        text: 'We',
        fromFrame: 55048,
        toFrame: 55052
      },
      {
        text: 'can',
        fromFrame: 55052,
        toFrame: 55059
      },
      {
        text: 'use',
        fromFrame: 55059,
        toFrame: 55067
      },
      {
        text: 'this.',
        fromFrame: 55067,
        toFrame: 55079
      }
    ],
    fromFrame: 55010,
    toFrame: 55511,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Okay. Highlight new generated post like we new',
    tokens: [
      {
        text: 'Okay.',
        fromFrame: 55511,
        toFrame: 55523
      },
      {
        text: 'Highlight',
        fromFrame: 55948,
        toFrame: 55959
      },
      {
        text: 'new',
        fromFrame: 55959,
        toFrame: 55963
      },
      {
        text: 'generated',
        fromFrame: 55963,
        toFrame: 55975
      },
      {
        text: 'post',
        fromFrame: 55977,
        toFrame: 55986
      },
      {
        text: 'like',
        fromFrame: 55986,
        toFrame: 55996
      },
      {
        text: 'we',
        fromFrame: 55996,
        toFrame: 56008
      },
      {
        text: 'new',
        fromFrame: 56094,
        toFrame: 56100
      }
    ],
    fromFrame: 55511,
    toFrame: 56100,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "generated post don't come below or on top?",
    tokens: [
      {
        text: 'generated',
        fromFrame: 56100,
        toFrame: 56111
      },
      {
        text: 'post',
        fromFrame: 56111,
        toFrame: 56119
      },
      {
        text: "don't",
        fromFrame: 56119,
        toFrame: 56124
      },
      {
        text: 'come',
        fromFrame: 56124,
        toFrame: 56136
      },
      {
        text: 'below',
        fromFrame: 56138,
        toFrame: 56150
      },
      {
        text: 'or',
        fromFrame: 56172,
        toFrame: 56174
      },
      {
        text: 'on',
        fromFrame: 56174,
        toFrame: 56180
      },
      {
        text: 'top?',
        fromFrame: 56180,
        toFrame: 56190
      }
    ],
    fromFrame: 56100,
    toFrame: 56211,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'It will come on top, but, the selected',
    tokens: [
      {
        text: 'It',
        fromFrame: 56211,
        toFrame: 56213
      },
      {
        text: 'will',
        fromFrame: 56213,
        toFrame: 56217
      },
      {
        text: 'come',
        fromFrame: 56217,
        toFrame: 56223
      },
      {
        text: 'on',
        fromFrame: 56223,
        toFrame: 56229
      },
      {
        text: 'top,',
        fromFrame: 56229,
        toFrame: 56238
      },
      {
        text: 'but,',
        fromFrame: 56238,
        toFrame: 56246
      },
      {
        text: 'the',
        fromFrame: 56254,
        toFrame: 56259
      },
      {
        text: 'selected',
        fromFrame: 56259,
        toFrame: 56271
      }
    ],
    fromFrame: 56211,
    toFrame: 56290,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "post is the last post. Mhmm. It's not",
    tokens: [
      {
        text: 'post',
        fromFrame: 56290,
        toFrame: 56300
      },
      {
        text: 'is',
        fromFrame: 56300,
        toFrame: 56312
      },
      {
        text: 'the',
        fromFrame: 56332,
        toFrame: 56338
      },
      {
        text: 'last',
        fromFrame: 56338,
        toFrame: 56350
      },
      {
        text: 'post.',
        fromFrame: 56350,
        toFrame: 56362
      },
      {
        text: 'Mhmm.',
        fromFrame: 56380,
        toFrame: 56392
      },
      {
        text: "It's",
        fromFrame: 56394,
        toFrame: 56403
      },
      {
        text: 'not',
        fromFrame: 56403,
        toFrame: 56415
      }
    ],
    fromFrame: 56290,
    toFrame: 56417,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'the selected one. It will create it will',
    tokens: [
      {
        text: 'the',
        fromFrame: 56417,
        toFrame: 56421
      },
      {
        text: 'selected',
        fromFrame: 56421,
        toFrame: 56433
      },
      {
        text: 'one.',
        fromFrame: 56434,
        toFrame: 56446
      },
      {
        text: 'It',
        fromFrame: 56467,
        toFrame: 56471
      },
      {
        text: 'will',
        fromFrame: 56471,
        toFrame: 56476
      },
      {
        text: 'create',
        fromFrame: 56476,
        toFrame: 56482
      },
      {
        text: 'it',
        fromFrame: 56484,
        toFrame: 56488
      },
      {
        text: 'will',
        fromFrame: 56488,
        toFrame: 56492
      }
    ],
    fromFrame: 56417,
    toFrame: 56492,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'just create the post and, listed on the',
    tokens: [
      {
        text: 'just',
        fromFrame: 56492,
        toFrame: 56498
      },
      {
        text: 'create',
        fromFrame: 56498,
        toFrame: 56505
      },
      {
        text: 'the',
        fromFrame: 56505,
        toFrame: 56509
      },
      {
        text: 'post',
        fromFrame: 56509,
        toFrame: 56519
      },
      {
        text: 'and,',
        fromFrame: 56519,
        toFrame: 56524
      },
      {
        text: 'listed',
        fromFrame: 56530,
        toFrame: 56540
      },
      {
        text: 'on',
        fromFrame: 56540,
        toFrame: 56544
      },
      {
        text: 'the',
        fromFrame: 56544,
        toFrame: 56547
      }
    ],
    fromFrame: 56492,
    toFrame: 56547,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'top, but not the preview of that new',
    tokens: [
      {
        text: 'top,',
        fromFrame: 56547,
        toFrame: 56558
      },
      {
        text: 'but',
        fromFrame: 56600,
        toFrame: 56604
      },
      {
        text: 'not',
        fromFrame: 56604,
        toFrame: 56614
      },
      {
        text: 'the',
        fromFrame: 56614,
        toFrame: 56621
      },
      {
        text: 'preview',
        fromFrame: 56621,
        toFrame: 56633
      },
      {
        text: 'of',
        fromFrame: 56640,
        toFrame: 56644
      },
      {
        text: 'that',
        fromFrame: 56644,
        toFrame: 56654
      },
      {
        text: 'new',
        fromFrame: 56654,
        toFrame: 56660
      }
    ],
    fromFrame: 56547,
    toFrame: 56660,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'post. Previous for you. And the posts are',
    tokens: [
      {
        text: 'post.',
        fromFrame: 56660,
        toFrame: 56672
      },
      {
        text: 'Previous',
        fromFrame: 56673,
        toFrame: 56685
      },
      {
        text: 'for',
        fromFrame: 56690,
        toFrame: 56698
      },
      {
        text: 'you.',
        fromFrame: 56698,
        toFrame: 56710
      },
      {
        text: 'And',
        fromFrame: 56736,
        toFrame: 56744
      },
      {
        text: 'the',
        fromFrame: 56744,
        toFrame: 56748
      },
      {
        text: 'posts',
        fromFrame: 56748,
        toFrame: 56758
      },
      {
        text: 'are',
        fromFrame: 56758,
        toFrame: 56763
      }
    ],
    fromFrame: 56660,
    toFrame: 56763,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'on top. Mhmm. It should redirect you to',
    tokens: [
      {
        text: 'on',
        fromFrame: 56763,
        toFrame: 56767
      },
      {
        text: 'top.',
        fromFrame: 56767,
        toFrame: 56779
      },
      {
        text: 'Mhmm.',
        fromFrame: 56796,
        toFrame: 56808
      },
      {
        text: 'It',
        fromFrame: 56817,
        toFrame: 56821
      },
      {
        text: 'should',
        fromFrame: 56821,
        toFrame: 56829
      },
      {
        text: 'redirect',
        fromFrame: 56829,
        toFrame: 56841
      },
      {
        text: 'you',
        fromFrame: 56842,
        toFrame: 56846
      },
      {
        text: 'to',
        fromFrame: 56846,
        toFrame: 56850
      }
    ],
    fromFrame: 56763,
    toFrame: 56850,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "the new post. Yeah. It's not happening. How",
    tokens: [
      {
        text: 'the',
        fromFrame: 56850,
        toFrame: 56862
      },
      {
        text: 'new',
        fromFrame: 56873,
        toFrame: 56877
      },
      {
        text: 'post.',
        fromFrame: 56877,
        toFrame: 56889
      },
      {
        text: 'Yeah.',
        fromFrame: 56913,
        toFrame: 56925
      },
      {
        text: "It's",
        fromFrame: 56929,
        toFrame: 56934
      },
      {
        text: 'not',
        fromFrame: 56934,
        toFrame: 56940
      },
      {
        text: 'happening.',
        fromFrame: 56940,
        toFrame: 56952
      },
      {
        text: 'How',
        fromFrame: 57096,
        toFrame: 57101
      }
    ],
    fromFrame: 56850,
    toFrame: 57101,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'many post do we generate? That would depend',
    tokens: [
      {
        text: 'many',
        fromFrame: 57101,
        toFrame: 57107
      },
      {
        text: 'post',
        fromFrame: 57107,
        toFrame: 57115
      },
      {
        text: 'do',
        fromFrame: 57115,
        toFrame: 57117
      },
      {
        text: 'we',
        fromFrame: 57117,
        toFrame: 57119
      },
      {
        text: 'generate?',
        fromFrame: 57119,
        toFrame: 57131
      },
      {
        text: 'That',
        fromFrame: 57195,
        toFrame: 57203
      },
      {
        text: 'would',
        fromFrame: 57203,
        toFrame: 57210
      },
      {
        text: 'depend',
        fromFrame: 57210,
        toFrame: 57218
      }
    ],
    fromFrame: 57101,
    toFrame: 57218,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'if 2 or 3 videos are selected. Then,',
    tokens: [
      {
        text: 'if',
        fromFrame: 57218,
        toFrame: 57226
      },
      {
        text: '2',
        fromFrame: 57226,
        toFrame: 57232
      },
      {
        text: 'or',
        fromFrame: 57232,
        toFrame: 57235
      },
      {
        text: '3',
        fromFrame: 57235,
        toFrame: 57243
      },
      {
        text: 'videos',
        fromFrame: 57243,
        toFrame: 57255
      },
      {
        text: 'are',
        fromFrame: 57255,
        toFrame: 57262
      },
      {
        text: 'selected.',
        fromFrame: 57262,
        toFrame: 57274
      },
      {
        text: 'Then,',
        fromFrame: 57306,
        toFrame: 57314
      }
    ],
    fromFrame: 57218,
    toFrame: 57326,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'it will generate 1 post. But there are',
    tokens: [
      {
        text: 'it',
        fromFrame: 57326,
        toFrame: 57330
      },
      {
        text: 'will',
        fromFrame: 57330,
        toFrame: 57333
      },
      {
        text: 'generate',
        fromFrame: 57333,
        toFrame: 57343
      },
      {
        text: '1',
        fromFrame: 57343,
        toFrame: 57351
      },
      {
        text: 'post.',
        fromFrame: 57351,
        toFrame: 57363
      },
      {
        text: 'But',
        fromFrame: 57383,
        toFrame: 57389
      },
      {
        text: 'there',
        fromFrame: 57389,
        toFrame: 57395
      },
      {
        text: 'are',
        fromFrame: 57395,
        toFrame: 57397
      }
    ],
    fromFrame: 57326,
    toFrame: 57397,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'more videos than it may generate max 3.',
    tokens: [
      {
        text: 'more',
        fromFrame: 57397,
        toFrame: 57401
      },
      {
        text: 'videos',
        fromFrame: 57401,
        toFrame: 57410
      },
      {
        text: 'than',
        fromFrame: 57410,
        toFrame: 57416
      },
      {
        text: 'it',
        fromFrame: 57416,
        toFrame: 57420
      },
      {
        text: 'may',
        fromFrame: 57420,
        toFrame: 57424
      },
      {
        text: 'generate',
        fromFrame: 57424,
        toFrame: 57436
      },
      {
        text: 'max',
        fromFrame: 57447,
        toFrame: 57458
      },
      {
        text: '3.',
        fromFrame: 57458,
        toFrame: 57470
      }
    ],
    fromFrame: 57397,
    toFrame: 57823,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "If we do most recent, they won't understand",
    tokens: [
      {
        text: 'If',
        fromFrame: 57823,
        toFrame: 57831
      },
      {
        text: 'we',
        fromFrame: 57831,
        toFrame: 57835
      },
      {
        text: 'do',
        fromFrame: 57835,
        toFrame: 57840
      },
      {
        text: 'most',
        fromFrame: 57840,
        toFrame: 57848
      },
      {
        text: 'recent,',
        fromFrame: 57848,
        toFrame: 57856
      },
      {
        text: 'they',
        fromFrame: 57856,
        toFrame: 57862
      },
      {
        text: "won't",
        fromFrame: 57862,
        toFrame: 57869
      },
      {
        text: 'understand',
        fromFrame: 57869,
        toFrame: 57881
      }
    ],
    fromFrame: 57823,
    toFrame: 57887,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'others, actually. Yeah. We should highlight the Okay.',
    tokens: [
      {
        text: 'others,',
        fromFrame: 57887,
        toFrame: 57896
      },
      {
        text: 'actually.',
        fromFrame: 57896,
        toFrame: 57908
      },
      {
        text: 'Yeah.',
        fromFrame: 57940,
        toFrame: 57952
      },
      {
        text: 'We',
        fromFrame: 57984,
        toFrame: 57988
      },
      {
        text: 'should',
        fromFrame: 57988,
        toFrame: 57996
      },
      {
        text: 'highlight',
        fromFrame: 57996,
        toFrame: 58008
      },
      {
        text: 'the',
        fromFrame: 58008,
        toFrame: 58020
      },
      {
        text: 'Okay.',
        fromFrame: 58626,
        toFrame: 58638
      }
    ],
    fromFrame: 57887,
    toFrame: 59248,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Okay. Just send move to 38. What is',
    tokens: [
      {
        text: 'Okay.',
        fromFrame: 59248,
        toFrame: 59260
      },
      {
        text: 'Just',
        fromFrame: 59263,
        toFrame: 59273
      },
      {
        text: 'send',
        fromFrame: 59273,
        toFrame: 59281
      },
      {
        text: 'move',
        fromFrame: 59281,
        toFrame: 59288
      },
      {
        text: 'to',
        fromFrame: 59288,
        toFrame: 59294
      },
      {
        text: '38.',
        fromFrame: 59294,
        toFrame: 59306
      },
      {
        text: 'What',
        fromFrame: 59377,
        toFrame: 59382
      },
      {
        text: 'is',
        fromFrame: 59382,
        toFrame: 59388
      }
    ],
    fromFrame: 59248,
    toFrame: 59388,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'this? Ashwin, do you know? Duplicate media content',
    tokens: [
      {
        text: 'this?',
        fromFrame: 59388,
        toFrame: 59400
      },
      {
        text: 'Ashwin,',
        fromFrame: 59449,
        toFrame: 59460
      },
      {
        text: 'do',
        fromFrame: 59460,
        toFrame: 59466
      },
      {
        text: 'you',
        fromFrame: 59466,
        toFrame: 59468
      },
      {
        text: 'know?',
        fromFrame: 59468,
        toFrame: 59480
      },
      {
        text: 'Duplicate',
        fromFrame: 59576,
        toFrame: 59588
      },
      {
        text: 'media',
        fromFrame: 59591,
        toFrame: 59599
      },
      {
        text: 'content',
        fromFrame: 59599,
        toFrame: 59611
      }
    ],
    fromFrame: 59388,
    toFrame: 59616,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "config. No. I don't know. Okay. Last. It",
    tokens: [
      {
        text: 'config.',
        fromFrame: 59616,
        toFrame: 59628
      },
      {
        text: 'No.',
        fromFrame: 59693,
        toFrame: 59697
      },
      {
        text: 'I',
        fromFrame: 59697,
        toFrame: 59700
      },
      {
        text: "don't",
        fromFrame: 59700,
        toFrame: 59706
      },
      {
        text: 'know.',
        fromFrame: 59706,
        toFrame: 59712
      },
      {
        text: 'Okay.',
        fromFrame: 59826,
        toFrame: 59835
      },
      {
        text: 'Last.',
        fromFrame: 59835,
        toFrame: 59847
      },
      {
        text: 'It',
        fromFrame: 59898,
        toFrame: 59904
      }
    ],
    fromFrame: 59616,
    toFrame: 59904,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "should be fine. It's smooth. It should be",
    tokens: [
      {
        text: 'should',
        fromFrame: 59904,
        toFrame: 59910
      },
      {
        text: 'be',
        fromFrame: 59910,
        toFrame: 59914
      },
      {
        text: 'fine.',
        fromFrame: 59914,
        toFrame: 59922
      },
      {
        text: "It's",
        fromFrame: 59922,
        toFrame: 59925
      },
      {
        text: 'smooth.',
        fromFrame: 59925,
        toFrame: 59931
      },
      {
        text: 'It',
        fromFrame: 59931,
        toFrame: 59933
      },
      {
        text: 'should',
        fromFrame: 59933,
        toFrame: 59935
      },
      {
        text: 'be',
        fromFrame: 59935,
        toFrame: 59941
      }
    ],
    fromFrame: 59904,
    toFrame: 59941,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'media one thing, I think. Add it to',
    tokens: [
      {
        text: 'media',
        fromFrame: 59941,
        toFrame: 59946
      },
      {
        text: 'one',
        fromFrame: 59946,
        toFrame: 59950
      },
      {
        text: 'thing,',
        fromFrame: 59950,
        toFrame: 59956
      },
      {
        text: 'I',
        fromFrame: 59956,
        toFrame: 59958
      },
      {
        text: 'think.',
        fromFrame: 59958,
        toFrame: 59970
      },
      {
        text: 'Add',
        fromFrame: 59985,
        toFrame: 59989
      },
      {
        text: 'it',
        fromFrame: 59989,
        toFrame: 59993
      },
      {
        text: 'to',
        fromFrame: 59993,
        toFrame: 59996
      }
    ],
    fromFrame: 59941,
    toFrame: 59996,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'next print request. Next 38. 38? Mhmm. 38',
    tokens: [
      {
        text: 'next',
        fromFrame: 59996,
        toFrame: 60002
      },
      {
        text: 'print',
        fromFrame: 60002,
        toFrame: 60014
      },
      {
        text: 'request.',
        fromFrame: 60014,
        toFrame: 60026
      },
      {
        text: 'Next',
        fromFrame: 60063,
        toFrame: 60075
      },
      {
        text: '38.',
        fromFrame: 60086,
        toFrame: 60098
      },
      {
        text: '38?',
        fromFrame: 60107,
        toFrame: 60119
      },
      {
        text: 'Mhmm.',
        fromFrame: 60144,
        toFrame: 60156
      },
      {
        text: '38',
        fromFrame: 60184,
        toFrame: 60196
      }
    ],
    fromFrame: 59996,
    toFrame: 60201,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'already has lot of things. Wait. Sometimes yeah.',
    tokens: [
      {
        text: 'already',
        fromFrame: 60201,
        toFrame: 60209
      },
      {
        text: 'has',
        fromFrame: 60209,
        toFrame: 60221
      },
      {
        text: 'lot',
        fromFrame: 60257,
        toFrame: 60269
      },
      {
        text: 'of',
        fromFrame: 60280,
        toFrame: 60284
      },
      {
        text: 'things.',
        fromFrame: 60284,
        toFrame: 60296
      },
      {
        text: 'Wait.',
        fromFrame: 60297,
        toFrame: 60309
      },
      {
        text: 'Sometimes',
        fromFrame: 60547,
        toFrame: 60559
      },
      {
        text: 'yeah.',
        fromFrame: 60614,
        toFrame: 60626
      }
    ],
    fromFrame: 60201,
    toFrame: 60628,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Okay. I'm assigning this to myself. This will",
    tokens: [
      {
        text: 'Okay.',
        fromFrame: 60628,
        toFrame: 60640
      },
      {
        text: "I'm",
        fromFrame: 60651,
        toFrame: 60659
      },
      {
        text: 'assigning',
        fromFrame: 60659,
        toFrame: 60671
      },
      {
        text: 'this',
        fromFrame: 60674,
        toFrame: 60678
      },
      {
        text: 'to',
        fromFrame: 60678,
        toFrame: 60684
      },
      {
        text: 'myself.',
        fromFrame: 60684,
        toFrame: 60696
      },
      {
        text: 'This',
        fromFrame: 60759,
        toFrame: 60766
      },
      {
        text: 'will',
        fromFrame: 60766,
        toFrame: 60772
      }
    ],
    fromFrame: 60628,
    toFrame: 60772,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "also fix with, full editing. Yeah? I'm probably",
    tokens: [
      {
        text: 'also',
        fromFrame: 60772,
        toFrame: 60778
      },
      {
        text: 'fix',
        fromFrame: 60778,
        toFrame: 60786
      },
      {
        text: 'with,',
        fromFrame: 60786,
        toFrame: 60789
      },
      {
        text: 'full',
        fromFrame: 60795,
        toFrame: 60803
      },
      {
        text: 'editing.',
        fromFrame: 60803,
        toFrame: 60814
      },
      {
        text: 'Yeah?',
        fromFrame: 60814,
        toFrame: 60826
      },
      {
        text: "I'm",
        fromFrame: 60939,
        toFrame: 60945
      },
      {
        text: 'probably',
        fromFrame: 60945,
        toFrame: 60955
      }
    ],
    fromFrame: 60772,
    toFrame: 60957,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'can you read it out? Can you read',
    tokens: [
      {
        text: 'can',
        fromFrame: 60957,
        toFrame: 60960
      },
      {
        text: 'you',
        fromFrame: 60960,
        toFrame: 60962
      },
      {
        text: 'read',
        fromFrame: 60962,
        toFrame: 60968
      },
      {
        text: 'it',
        fromFrame: 60968,
        toFrame: 60970
      },
      {
        text: 'out?',
        fromFrame: 60970,
        toFrame: 60982
      },
      {
        text: 'Can',
        fromFrame: 61033,
        toFrame: 61041
      },
      {
        text: 'you',
        fromFrame: 61041,
        toFrame: 61045
      },
      {
        text: 'read',
        fromFrame: 61045,
        toFrame: 61051
      }
    ],
    fromFrame: 60957,
    toFrame: 61051,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "that out? I'll I'll come back. Hold on.",
    tokens: [
      {
        text: 'that',
        fromFrame: 61051,
        toFrame: 61056
      },
      {
        text: 'out?',
        fromFrame: 61056,
        toFrame: 61060
      },
      {
        text: "I'll",
        fromFrame: 61060,
        toFrame: 61066
      },
      {
        text: "I'll",
        fromFrame: 61066,
        toFrame: 61070
      },
      {
        text: 'come',
        fromFrame: 61070,
        toFrame: 61076
      },
      {
        text: 'back.',
        fromFrame: 61076,
        toFrame: 61081
      },
      {
        text: 'Hold',
        fromFrame: 61081,
        toFrame: 61085
      },
      {
        text: 'on.',
        fromFrame: 61085,
        toFrame: 61093
      }
    ],
    fromFrame: 61051,
    toFrame: 61093,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I'll come back. I'm working on this. Basically,",
    tokens: [
      {
        text: "I'll",
        fromFrame: 61093,
        toFrame: 61097
      },
      {
        text: 'come',
        fromFrame: 61097,
        toFrame: 61101
      },
      {
        text: 'back.',
        fromFrame: 61101,
        toFrame: 61104
      },
      {
        text: "I'm",
        fromFrame: 61104,
        toFrame: 61108
      },
      {
        text: 'working',
        fromFrame: 61108,
        toFrame: 61118
      },
      {
        text: 'on',
        fromFrame: 61118,
        toFrame: 61120
      },
      {
        text: 'this.',
        fromFrame: 61120,
        toFrame: 61129
      },
      {
        text: 'Basically,',
        fromFrame: 61129,
        toFrame: 61141
      }
    ],
    fromFrame: 61093,
    toFrame: 61143,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'the transcript does not auto scroll if you',
    tokens: [
      {
        text: 'the',
        fromFrame: 61143,
        toFrame: 61149
      },
      {
        text: 'transcript',
        fromFrame: 61149,
        toFrame: 61160
      },
      {
        text: 'does',
        fromFrame: 61160,
        toFrame: 61166
      },
      {
        text: 'not',
        fromFrame: 61166,
        toFrame: 61174
      },
      {
        text: 'auto',
        fromFrame: 61174,
        toFrame: 61181
      },
      {
        text: 'scroll',
        fromFrame: 61181,
        toFrame: 61193
      },
      {
        text: 'if',
        fromFrame: 61193,
        toFrame: 61199
      },
      {
        text: 'you',
        fromFrame: 61199,
        toFrame: 61202
      }
    ],
    fromFrame: 61143,
    toFrame: 61202,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'move the video if you play the video.',
    tokens: [
      {
        text: 'move',
        fromFrame: 61202,
        toFrame: 61208
      },
      {
        text: 'the',
        fromFrame: 61208,
        toFrame: 61220
      },
      {
        text: 'video',
        fromFrame: 61270,
        toFrame: 61282
      },
      {
        text: 'if',
        fromFrame: 61331,
        toFrame: 61335
      },
      {
        text: 'you',
        fromFrame: 61335,
        toFrame: 61341
      },
      {
        text: 'play',
        fromFrame: 61341,
        toFrame: 61346
      },
      {
        text: 'the',
        fromFrame: 61346,
        toFrame: 61348
      },
      {
        text: 'video.',
        fromFrame: 61348,
        toFrame: 61358
      }
    ],
    fromFrame: 61202,
    toFrame: 61358,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Right? It should I think it should be',
    tokens: [
      {
        text: 'Right?',
        fromFrame: 61358,
        toFrame: 61370
      },
      {
        text: 'It',
        fromFrame: 61397,
        toFrame: 61403
      },
      {
        text: 'should',
        fromFrame: 61403,
        toFrame: 61415
      },
      {
        text: 'I',
        fromFrame: 61451,
        toFrame: 61455
      },
      {
        text: 'think',
        fromFrame: 61455,
        toFrame: 61461
      },
      {
        text: 'it',
        fromFrame: 61461,
        toFrame: 61467
      },
      {
        text: 'should',
        fromFrame: 61467,
        toFrame: 61472
      },
      {
        text: 'be',
        fromFrame: 61472,
        toFrame: 61474
      }
    ],
    fromFrame: 61358,
    toFrame: 61474,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'handled with that as well, please. Nikhil. Amazing.',
    tokens: [
      {
        text: 'handled',
        fromFrame: 61474,
        toFrame: 61482
      },
      {
        text: 'with',
        fromFrame: 61482,
        toFrame: 61484
      },
      {
        text: 'that',
        fromFrame: 61484,
        toFrame: 61490
      },
      {
        text: 'as',
        fromFrame: 61490,
        toFrame: 61493
      },
      {
        text: 'well,',
        fromFrame: 61493,
        toFrame: 61497
      },
      {
        text: 'please.',
        fromFrame: 61497,
        toFrame: 61509
      },
      {
        text: 'Nikhil.',
        fromFrame: 62436,
        toFrame: 62448
      },
      {
        text: 'Amazing.',
        fromFrame: 62476,
        toFrame: 62488
      }
    ],
    fromFrame: 61474,
    toFrame: 62575,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'On demand. What happened? Why is this with',
    tokens: [
      {
        text: 'On',
        fromFrame: 62575,
        toFrame: 62579
      },
      {
        text: 'demand.',
        fromFrame: 62579,
        toFrame: 62591
      },
      {
        text: 'What',
        fromFrame: 62871,
        toFrame: 62879
      },
      {
        text: 'happened?',
        fromFrame: 62879,
        toFrame: 62891
      },
      {
        text: 'Why',
        fromFrame: 62902,
        toFrame: 62910
      },
      {
        text: 'is',
        fromFrame: 62910,
        toFrame: 62912
      },
      {
        text: 'this',
        fromFrame: 62912,
        toFrame: 62918
      },
      {
        text: 'with',
        fromFrame: 62918,
        toFrame: 62923
      }
    ],
    fromFrame: 62575,
    toFrame: 62923,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "us? Okay. I'll check this. I think it",
    tokens: [
      {
        text: 'us?',
        fromFrame: 62923,
        toFrame: 62935
      },
      {
        text: 'Okay.',
        fromFrame: 63141,
        toFrame: 63152
      },
      {
        text: "I'll",
        fromFrame: 63152,
        toFrame: 63156
      },
      {
        text: 'check',
        fromFrame: 63156,
        toFrame: 63164
      },
      {
        text: 'this.',
        fromFrame: 63164,
        toFrame: 63174
      },
      {
        text: 'I',
        fromFrame: 63174,
        toFrame: 63186
      },
      {
        text: 'think',
        fromFrame: 63193,
        toFrame: 63202
      },
      {
        text: 'it',
        fromFrame: 63202,
        toFrame: 63204
      }
    ],
    fromFrame: 62923,
    toFrame: 63204,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "shouldn't be on. Concern in progress. We take",
    tokens: [
      {
        text: "shouldn't",
        fromFrame: 63204,
        toFrame: 63214
      },
      {
        text: 'be',
        fromFrame: 63214,
        toFrame: 63220
      },
      {
        text: 'on.',
        fromFrame: 63220,
        toFrame: 63232
      },
      {
        text: 'Concern',
        fromFrame: 63728,
        toFrame: 63740
      },
      {
        text: 'in',
        fromFrame: 63742,
        toFrame: 63747
      },
      {
        text: 'progress.',
        fromFrame: 63747,
        toFrame: 63759
      },
      {
        text: 'We',
        fromFrame: 64012,
        toFrame: 64015
      },
      {
        text: 'take',
        fromFrame: 64015,
        toFrame: 64023
      }
    ],
    fromFrame: 63204,
    toFrame: 64023,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Goran, is it? There are lot of ticket',
    tokens: [
      {
        text: 'Goran,',
        fromFrame: 64023,
        toFrame: 64031
      },
      {
        text: 'is',
        fromFrame: 64031,
        toFrame: 64037
      },
      {
        text: 'it?',
        fromFrame: 64037,
        toFrame: 64049
      },
      {
        text: 'There',
        fromFrame: 64117,
        toFrame: 64123
      },
      {
        text: 'are',
        fromFrame: 64123,
        toFrame: 64127
      },
      {
        text: 'lot',
        fromFrame: 64127,
        toFrame: 64131
      },
      {
        text: 'of',
        fromFrame: 64131,
        toFrame: 64133
      },
      {
        text: 'ticket',
        fromFrame: 64133,
        toFrame: 64138
      }
    ],
    fromFrame: 64023,
    toFrame: 64138,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'management. Not Goran. This is Nickel. Download field',
    tokens: [
      {
        text: 'management.',
        fromFrame: 64138,
        toFrame: 64150
      },
      {
        text: 'Not',
        fromFrame: 64167,
        toFrame: 64175
      },
      {
        text: 'Goran.',
        fromFrame: 64175,
        toFrame: 64184
      },
      {
        text: 'This',
        fromFrame: 64184,
        toFrame: 64192
      },
      {
        text: 'is',
        fromFrame: 64192,
        toFrame: 64196
      },
      {
        text: 'Nickel.',
        fromFrame: 64196,
        toFrame: 64208
      },
      {
        text: 'Download',
        fromFrame: 64436,
        toFrame: 64448
      },
      {
        text: 'field',
        fromFrame: 64453,
        toFrame: 64465
      }
    ],
    fromFrame: 64138,
    toFrame: 64473,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'for AI generated assets. Okay. This one is',
    tokens: [
      {
        text: 'for',
        fromFrame: 64473,
        toFrame: 64480
      },
      {
        text: 'AI',
        fromFrame: 64480,
        toFrame: 64486
      },
      {
        text: 'generated',
        fromFrame: 64486,
        toFrame: 64498
      },
      {
        text: 'assets.',
        fromFrame: 64501,
        toFrame: 64513
      },
      {
        text: 'Okay.',
        fromFrame: 64568,
        toFrame: 64580
      },
      {
        text: 'This',
        fromFrame: 64593,
        toFrame: 64605
      },
      {
        text: 'one',
        fromFrame: 64606,
        toFrame: 64614
      },
      {
        text: 'is',
        fromFrame: 64614,
        toFrame: 64621
      }
    ],
    fromFrame: 64473,
    toFrame: 64621,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'when? October 17th. Can you close This happened?',
    tokens: [
      {
        text: 'when?',
        fromFrame: 64621,
        toFrame: 64633
      },
      {
        text: 'October',
        fromFrame: 64746,
        toFrame: 64758
      },
      {
        text: '17th.',
        fromFrame: 64760,
        toFrame: 64772
      },
      {
        text: 'Can',
        fromFrame: 64788,
        toFrame: 64794
      },
      {
        text: 'you',
        fromFrame: 64794,
        toFrame: 64798
      },
      {
        text: 'close',
        fromFrame: 64798,
        toFrame: 64807
      },
      {
        text: 'This',
        fromFrame: 64815,
        toFrame: 64819
      },
      {
        text: 'happened?',
        fromFrame: 64819,
        toFrame: 64831
      }
    ],
    fromFrame: 64621,
    toFrame: 64838,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "We can close this. I don't know what",
    tokens: [
      {
        text: 'We',
        fromFrame: 64838,
        toFrame: 64840
      },
      {
        text: 'can',
        fromFrame: 64840,
        toFrame: 64846
      },
      {
        text: 'close',
        fromFrame: 64846,
        toFrame: 64856
      },
      {
        text: 'this.',
        fromFrame: 64856,
        toFrame: 64868
      },
      {
        text: 'I',
        fromFrame: 64872,
        toFrame: 64874
      },
      {
        text: "don't",
        fromFrame: 64874,
        toFrame: 64878
      },
      {
        text: 'know',
        fromFrame: 64878,
        toFrame: 64880
      },
      {
        text: 'what',
        fromFrame: 64880,
        toFrame: 64890
      }
    ],
    fromFrame: 64838,
    toFrame: 64890,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "happened with this asset, but when we're checking",
    tokens: [
      {
        text: 'happened',
        fromFrame: 64890,
        toFrame: 64897
      },
      {
        text: 'with',
        fromFrame: 64897,
        toFrame: 64903
      },
      {
        text: 'this',
        fromFrame: 64903,
        toFrame: 64913
      },
      {
        text: 'asset,',
        fromFrame: 64913,
        toFrame: 64922
      },
      {
        text: 'but',
        fromFrame: 64922,
        toFrame: 64932
      },
      {
        text: 'when',
        fromFrame: 64932,
        toFrame: 64940
      },
      {
        text: "we're",
        fromFrame: 64940,
        toFrame: 64945
      },
      {
        text: 'checking',
        fromFrame: 64945,
        toFrame: 64953
      }
    ],
    fromFrame: 64890,
    toFrame: 64953,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "with the beneath, it worked. Don't know what",
    tokens: [
      {
        text: 'with',
        fromFrame: 64953,
        toFrame: 64959
      },
      {
        text: 'the',
        fromFrame: 64959,
        toFrame: 64965
      },
      {
        text: 'beneath,',
        fromFrame: 64965,
        toFrame: 64970
      },
      {
        text: 'it',
        fromFrame: 64970,
        toFrame: 64976
      },
      {
        text: 'worked.',
        fromFrame: 64976,
        toFrame: 64988
      },
      {
        text: "Don't",
        fromFrame: 65169,
        toFrame: 65175
      },
      {
        text: 'know',
        fromFrame: 65175,
        toFrame: 65179
      },
      {
        text: 'what',
        fromFrame: 65179,
        toFrame: 65185
      }
    ],
    fromFrame: 64953,
    toFrame: 65185,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'was the issue. Formally critical to this feeling.',
    tokens: [
      {
        text: 'was',
        fromFrame: 65185,
        toFrame: 65190
      },
      {
        text: 'the',
        fromFrame: 65190,
        toFrame: 65196
      },
      {
        text: 'issue.',
        fromFrame: 65196,
        toFrame: 65208
      },
      {
        text: 'Formally',
        fromFrame: 66196,
        toFrame: 66208
      },
      {
        text: 'critical',
        fromFrame: 66210,
        toFrame: 66221
      },
      {
        text: 'to',
        fromFrame: 66221,
        toFrame: 66227
      },
      {
        text: 'this',
        fromFrame: 66227,
        toFrame: 66239
      },
      {
        text: 'feeling.',
        fromFrame: 66240,
        toFrame: 66252
      }
    ],
    fromFrame: 65185,
    toFrame: 66300,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Yeah. The second change. Yeah. This page should',
    tokens: [
      {
        text: 'Yeah.',
        fromFrame: 66300,
        toFrame: 66304
      },
      {
        text: 'The',
        fromFrame: 66304,
        toFrame: 66308
      },
      {
        text: 'second',
        fromFrame: 66308,
        toFrame: 66320
      },
      {
        text: 'change.',
        fromFrame: 66321,
        toFrame: 66333
      },
      {
        text: 'Yeah.',
        fromFrame: 66440,
        toFrame: 66446
      },
      {
        text: 'This',
        fromFrame: 66446,
        toFrame: 66453
      },
      {
        text: 'page',
        fromFrame: 66453,
        toFrame: 66461
      },
      {
        text: 'should',
        fromFrame: 66461,
        toFrame: 66469
      }
    ],
    fromFrame: 66300,
    toFrame: 66469,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'check. But why is this a back? There?',
    tokens: [
      {
        text: 'check.',
        fromFrame: 66469,
        toFrame: 66481
      },
      {
        text: 'But',
        fromFrame: 66492,
        toFrame: 66496
      },
      {
        text: 'why',
        fromFrame: 66496,
        toFrame: 66500
      },
      {
        text: 'is',
        fromFrame: 66500,
        toFrame: 66503
      },
      {
        text: 'this',
        fromFrame: 66503,
        toFrame: 66509
      },
      {
        text: 'a',
        fromFrame: 66509,
        toFrame: 66513
      },
      {
        text: 'back?',
        fromFrame: 66513,
        toFrame: 66525
      },
      {
        text: 'There?',
        fromFrame: 66697,
        toFrame: 66701
      }
    ],
    fromFrame: 66469,
    toFrame: 66701,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'It is back end because, this the clip',
    tokens: [
      {
        text: 'It',
        fromFrame: 66701,
        toFrame: 66707
      },
      {
        text: 'is',
        fromFrame: 66707,
        toFrame: 66710
      },
      {
        text: 'back',
        fromFrame: 66710,
        toFrame: 66714
      },
      {
        text: 'end',
        fromFrame: 66714,
        toFrame: 66722
      },
      {
        text: 'because,',
        fromFrame: 66722,
        toFrame: 66734
      },
      {
        text: 'this',
        fromFrame: 66774,
        toFrame: 66780
      },
      {
        text: 'the',
        fromFrame: 66783,
        toFrame: 66787
      },
      {
        text: 'clip',
        fromFrame: 66787,
        toFrame: 66799
      }
    ],
    fromFrame: 66701,
    toFrame: 66804,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'was not being shared. So the share will',
    tokens: [
      {
        text: 'was',
        fromFrame: 66804,
        toFrame: 66810
      },
      {
        text: 'not',
        fromFrame: 66810,
        toFrame: 66818
      },
      {
        text: 'being',
        fromFrame: 66818,
        toFrame: 66826
      },
      {
        text: 'shared.',
        fromFrame: 66826,
        toFrame: 66835
      },
      {
        text: 'So',
        fromFrame: 66835,
        toFrame: 66841
      },
      {
        text: 'the',
        fromFrame: 66843,
        toFrame: 66847
      },
      {
        text: 'share',
        fromFrame: 66847,
        toFrame: 66851
      },
      {
        text: 'will',
        fromFrame: 66851,
        toFrame: 66857
      }
    ],
    fromFrame: 66804,
    toFrame: 66857,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'be marked as Okay. Okay. On shared page.',
    tokens: [
      {
        text: 'be',
        fromFrame: 66857,
        toFrame: 66862
      },
      {
        text: 'marked',
        fromFrame: 66862,
        toFrame: 66870
      },
      {
        text: 'as',
        fromFrame: 66870,
        toFrame: 66872
      },
      {
        text: 'Okay.',
        fromFrame: 66903,
        toFrame: 66912
      },
      {
        text: 'Okay.',
        fromFrame: 66912,
        toFrame: 66922
      },
      {
        text: 'On',
        fromFrame: 66922,
        toFrame: 66930
      },
      {
        text: 'shared',
        fromFrame: 66930,
        toFrame: 66941
      },
      {
        text: 'page.',
        fromFrame: 66941,
        toFrame: 66953
      }
    ],
    fromFrame: 66857,
    toFrame: 66976,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Mhmm. Yes. Yeah. This, he should check. This,',
    tokens: [
      {
        text: 'Mhmm.',
        fromFrame: 66976,
        toFrame: 66987
      },
      {
        text: 'Yes.',
        fromFrame: 66987,
        toFrame: 66999
      },
      {
        text: 'Yeah.',
        fromFrame: 67066,
        toFrame: 67076
      },
      {
        text: 'This,',
        fromFrame: 67076,
        toFrame: 67085
      },
      {
        text: 'he',
        fromFrame: 67085,
        toFrame: 67091
      },
      {
        text: 'should',
        fromFrame: 67091,
        toFrame: 67102
      },
      {
        text: 'check.',
        fromFrame: 67102,
        toFrame: 67114
      },
      {
        text: 'This,',
        fromFrame: 67159,
        toFrame: 67163
      }
    ],
    fromFrame: 66976,
    toFrame: 67163,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I'll move to 38. Sorry. 37. So he",
    tokens: [
      {
        text: "I'll",
        fromFrame: 67163,
        toFrame: 67171
      },
      {
        text: 'move',
        fromFrame: 67171,
        toFrame: 67179
      },
      {
        text: 'to',
        fromFrame: 67179,
        toFrame: 67191
      },
      {
        text: '38.',
        fromFrame: 67196,
        toFrame: 67208
      },
      {
        text: 'Sorry.',
        fromFrame: 67223,
        toFrame: 67235
      },
      {
        text: '37.',
        fromFrame: 67238,
        toFrame: 67250
      },
      {
        text: 'So',
        fromFrame: 67336,
        toFrame: 67344
      },
      {
        text: 'he',
        fromFrame: 67344,
        toFrame: 67348
      }
    ],
    fromFrame: 67163,
    toFrame: 67348,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'has 4 tickets, out of which 2 are',
    tokens: [
      {
        text: 'has',
        fromFrame: 67348,
        toFrame: 67353
      },
      {
        text: '4',
        fromFrame: 67353,
        toFrame: 67359
      },
      {
        text: 'tickets,',
        fromFrame: 67359,
        toFrame: 67371
      },
      {
        text: 'out',
        fromFrame: 67373,
        toFrame: 67378
      },
      {
        text: 'of',
        fromFrame: 67378,
        toFrame: 67380
      },
      {
        text: 'which',
        fromFrame: 67380,
        toFrame: 67386
      },
      {
        text: '2',
        fromFrame: 67386,
        toFrame: 67392
      },
      {
        text: 'are',
        fromFrame: 67392,
        toFrame: 67394
      }
    ],
    fromFrame: 67348,
    toFrame: 67394,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'in progress. So, yeah, it should be good.',
    tokens: [
      {
        text: 'in',
        fromFrame: 67394,
        toFrame: 67399
      },
      {
        text: 'progress.',
        fromFrame: 67399,
        toFrame: 67409
      },
      {
        text: 'So,',
        fromFrame: 67409,
        toFrame: 67413
      },
      {
        text: 'yeah,',
        fromFrame: 67413,
        toFrame: 67421
      },
      {
        text: 'it',
        fromFrame: 67421,
        toFrame: 67422
      },
      {
        text: 'should',
        fromFrame: 67422,
        toFrame: 67428
      },
      {
        text: 'be',
        fromFrame: 67428,
        toFrame: 67432
      },
      {
        text: 'good.',
        fromFrame: 67432,
        toFrame: 67444
      }
    ],
    fromFrame: 67394,
    toFrame: 67543,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Oh, Pratyush. Oh, we already checked, Pratyush. My',
    tokens: [
      {
        text: 'Oh,',
        fromFrame: 67543,
        toFrame: 67551
      },
      {
        text: 'Pratyush.',
        fromFrame: 67551,
        toFrame: 67563
      },
      {
        text: 'Oh,',
        fromFrame: 67589,
        toFrame: 67595
      },
      {
        text: 'we',
        fromFrame: 67595,
        toFrame: 67601
      },
      {
        text: 'already',
        fromFrame: 67601,
        toFrame: 67608
      },
      {
        text: 'checked,',
        fromFrame: 67608,
        toFrame: 67616
      },
      {
        text: 'Pratyush.',
        fromFrame: 67616,
        toFrame: 67628
      },
      {
        text: 'My',
        fromFrame: 67629,
        toFrame: 67635
      }
    ],
    fromFrame: 67543,
    toFrame: 67635,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'bad. The call is still registered. Go ahead.',
    tokens: [
      {
        text: 'bad.',
        fromFrame: 67635,
        toFrame: 67647
      },
      {
        text: 'The',
        fromFrame: 67677,
        toFrame: 67681
      },
      {
        text: 'call',
        fromFrame: 67681,
        toFrame: 67687
      },
      {
        text: 'is',
        fromFrame: 67687,
        toFrame: 67691
      },
      {
        text: 'still',
        fromFrame: 67691,
        toFrame: 67697
      },
      {
        text: 'registered.',
        fromFrame: 67697,
        toFrame: 67709
      },
      {
        text: 'Go',
        fromFrame: 67731,
        toFrame: 67737
      },
      {
        text: 'ahead.',
        fromFrame: 67737,
        toFrame: 67749
      }
    ],
    fromFrame: 67635,
    toFrame: 67955,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "We are. 38. Yeah. I'm fine moving this",
    tokens: [
      {
        text: 'We',
        fromFrame: 67955,
        toFrame: 67961
      },
      {
        text: 'are.',
        fromFrame: 67961,
        toFrame: 67973
      },
      {
        text: '38.',
        fromFrame: 67995,
        toFrame: 68007
      },
      {
        text: 'Yeah.',
        fromFrame: 68043,
        toFrame: 68055
      },
      {
        text: "I'm",
        fromFrame: 68070,
        toFrame: 68076
      },
      {
        text: 'fine',
        fromFrame: 68076,
        toFrame: 68087
      },
      {
        text: 'moving',
        fromFrame: 68087,
        toFrame: 68099
      },
      {
        text: 'this',
        fromFrame: 68099,
        toFrame: 68108
      }
    ],
    fromFrame: 67955,
    toFrame: 68108,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'to 30 8. We have, like, that time',
    tokens: [
      {
        text: 'to',
        fromFrame: 68108,
        toFrame: 68112
      },
      {
        text: '30',
        fromFrame: 68175,
        toFrame: 68178
      },
      {
        text: '8.',
        fromFrame: 68178,
        toFrame: 68181
      },
      {
        text: 'We',
        fromFrame: 68181,
        toFrame: 68189
      },
      {
        text: 'have,',
        fromFrame: 68189,
        toFrame: 68195
      },
      {
        text: 'like,',
        fromFrame: 68195,
        toFrame: 68199
      },
      {
        text: 'that',
        fromFrame: 68199,
        toFrame: 68204
      },
      {
        text: 'time',
        fromFrame: 68204,
        toFrame: 68216
      }
    ],
    fromFrame: 68108,
    toFrame: 68227,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'in 30 8. Flip thumbnail image is inaccurate.',
    tokens: [
      {
        text: 'in',
        fromFrame: 68227,
        toFrame: 68239
      },
      {
        text: '30',
        fromFrame: 68249,
        toFrame: 68261
      },
      {
        text: '8.',
        fromFrame: 68272,
        toFrame: 68284
      },
      {
        text: 'Flip',
        fromFrame: 68295,
        toFrame: 68298
      },
      {
        text: 'thumbnail',
        fromFrame: 68298,
        toFrame: 68310
      },
      {
        text: 'image',
        fromFrame: 68310,
        toFrame: 68318
      },
      {
        text: 'is',
        fromFrame: 68318,
        toFrame: 68321
      },
      {
        text: 'inaccurate.',
        fromFrame: 68321,
        toFrame: 68333
      }
    ],
    fromFrame: 68227,
    toFrame: 68404,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Again, thumbnail. We close We already have 2',
    tokens: [
      {
        text: 'Again,',
        fromFrame: 68404,
        toFrame: 68414
      },
      {
        text: 'thumbnail.',
        fromFrame: 68414,
        toFrame: 68426
      },
      {
        text: 'We',
        fromFrame: 68486,
        toFrame: 68492
      },
      {
        text: 'close',
        fromFrame: 68492,
        toFrame: 68504
      },
      {
        text: 'We',
        fromFrame: 68527,
        toFrame: 68529
      },
      {
        text: 'already',
        fromFrame: 68529,
        toFrame: 68536
      },
      {
        text: 'have',
        fromFrame: 68536,
        toFrame: 68540
      },
      {
        text: '2',
        fromFrame: 68540,
        toFrame: 68544
      }
    ],
    fromFrame: 68404,
    toFrame: 68544,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'tickets for thumbnail. That was on edit, and',
    tokens: [
      {
        text: 'tickets',
        fromFrame: 68544,
        toFrame: 68556
      },
      {
        text: 'for',
        fromFrame: 68559,
        toFrame: 68563
      },
      {
        text: 'thumbnail.',
        fromFrame: 68563,
        toFrame: 68575
      },
      {
        text: 'That',
        fromFrame: 68686,
        toFrame: 68690
      },
      {
        text: 'was',
        fromFrame: 68690,
        toFrame: 68698
      },
      {
        text: 'on',
        fromFrame: 68698,
        toFrame: 68703
      },
      {
        text: 'edit,',
        fromFrame: 68703,
        toFrame: 68711
      },
      {
        text: 'and',
        fromFrame: 68711,
        toFrame: 68721
      }
    ],
    fromFrame: 68544,
    toFrame: 68721,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'this ticket is for the Yeah. Then okay.',
    tokens: [
      {
        text: 'this',
        fromFrame: 68721,
        toFrame: 68728
      },
      {
        text: 'ticket',
        fromFrame: 68728,
        toFrame: 68738
      },
      {
        text: 'is',
        fromFrame: 68738,
        toFrame: 68749
      },
      {
        text: 'for',
        fromFrame: 68749,
        toFrame: 68761
      },
      {
        text: 'the',
        fromFrame: 68772,
        toFrame: 68778
      },
      {
        text: 'Yeah.',
        fromFrame: 68780,
        toFrame: 68792
      },
      {
        text: 'Then',
        fromFrame: 68944,
        toFrame: 68956
      },
      {
        text: 'okay.',
        fromFrame: 68967,
        toFrame: 68979
      }
    ],
    fromFrame: 68721,
    toFrame: 68980,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'QA. But for the front end thing now,',
    tokens: [
      {
        text: 'QA.',
        fromFrame: 68980,
        toFrame: 68988
      },
      {
        text: 'But',
        fromFrame: 68988,
        toFrame: 68992
      },
      {
        text: 'for',
        fromFrame: 68992,
        toFrame: 68997
      },
      {
        text: 'the',
        fromFrame: 68997,
        toFrame: 69007
      },
      {
        text: 'front',
        fromFrame: 69007,
        toFrame: 69011
      },
      {
        text: 'end',
        fromFrame: 69011,
        toFrame: 69015
      },
      {
        text: 'thing',
        fromFrame: 69015,
        toFrame: 69021
      },
      {
        text: 'now,',
        fromFrame: 69021,
        toFrame: 69024
      }
    ],
    fromFrame: 68980,
    toFrame: 69024,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "it should there's not the back end. So",
    tokens: [
      {
        text: 'it',
        fromFrame: 69024,
        toFrame: 69028
      },
      {
        text: 'should',
        fromFrame: 69028,
        toFrame: 69032
      },
      {
        text: "there's",
        fromFrame: 69034,
        toFrame: 69042
      },
      {
        text: 'not',
        fromFrame: 69042,
        toFrame: 69045
      },
      {
        text: 'the',
        fromFrame: 69045,
        toFrame: 69049
      },
      {
        text: 'back',
        fromFrame: 69049,
        toFrame: 69055
      },
      {
        text: 'end.',
        fromFrame: 69055,
        toFrame: 69067
      },
      {
        text: 'So',
        fromFrame: 69080,
        toFrame: 69084
      }
    ],
    fromFrame: 69024,
    toFrame: 69084,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'after this should be a front end issue.',
    tokens: [
      {
        text: 'after',
        fromFrame: 69084,
        toFrame: 69092
      },
      {
        text: 'this',
        fromFrame: 69092,
        toFrame: 69095
      },
      {
        text: 'should',
        fromFrame: 69095,
        toFrame: 69099
      },
      {
        text: 'be',
        fromFrame: 69099,
        toFrame: 69105
      },
      {
        text: 'a',
        fromFrame: 69105,
        toFrame: 69107
      },
      {
        text: 'front',
        fromFrame: 69107,
        toFrame: 69111
      },
      {
        text: 'end',
        fromFrame: 69111,
        toFrame: 69115
      },
      {
        text: 'issue.',
        fromFrame: 69115,
        toFrame: 69127
      }
    ],
    fromFrame: 69084,
    toFrame: 69169,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Still observing this issue. I mean, I can't",
    tokens: [
      {
        text: 'Still',
        fromFrame: 69169,
        toFrame: 69177
      },
      {
        text: 'observing',
        fromFrame: 69177,
        toFrame: 69189
      },
      {
        text: 'this',
        fromFrame: 69191,
        toFrame: 69198
      },
      {
        text: 'issue.',
        fromFrame: 69198,
        toFrame: 69210
      },
      {
        text: 'I',
        fromFrame: 69231,
        toFrame: 69233
      },
      {
        text: 'mean,',
        fromFrame: 69233,
        toFrame: 69240
      },
      {
        text: 'I',
        fromFrame: 69240,
        toFrame: 69248
      },
      {
        text: "can't",
        fromFrame: 69248,
        toFrame: 69258
      }
    ],
    fromFrame: 69169,
    toFrame: 69258,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'extend in October. Yeah. Okay. Do we need',
    tokens: [
      {
        text: 'extend',
        fromFrame: 69258,
        toFrame: 69265
      },
      {
        text: 'in',
        fromFrame: 69265,
        toFrame: 69271
      },
      {
        text: 'October.',
        fromFrame: 69271,
        toFrame: 69283
      },
      {
        text: 'Yeah.',
        fromFrame: 69329,
        toFrame: 69341
      },
      {
        text: 'Okay.',
        fromFrame: 69533,
        toFrame: 69545
      },
      {
        text: 'Do',
        fromFrame: 69603,
        toFrame: 69606
      },
      {
        text: 'we',
        fromFrame: 69606,
        toFrame: 69612
      },
      {
        text: 'need',
        fromFrame: 69612,
        toFrame: 69620
      }
    ],
    fromFrame: 69258,
    toFrame: 69620,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'anything? Just the report will come happening again,',
    tokens: [
      {
        text: 'anything?',
        fromFrame: 69620,
        toFrame: 69632
      },
      {
        text: 'Just',
        fromFrame: 69691,
        toFrame: 69697
      },
      {
        text: 'the',
        fromFrame: 69697,
        toFrame: 69700
      },
      {
        text: 'report',
        fromFrame: 69700,
        toFrame: 69708
      },
      {
        text: 'will',
        fromFrame: 69708,
        toFrame: 69710
      },
      {
        text: 'come',
        fromFrame: 69710,
        toFrame: 69718
      },
      {
        text: 'happening',
        fromFrame: 69718,
        toFrame: 69725
      },
      {
        text: 'again,',
        fromFrame: 69725,
        toFrame: 69731
      }
    ],
    fromFrame: 69620,
    toFrame: 69731,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Anurag. Is this happening again, Preeti? Can you',
    tokens: [
      {
        text: 'Anurag.',
        fromFrame: 69731,
        toFrame: 69743
      },
      {
        text: 'Is',
        fromFrame: 69747,
        toFrame: 69750
      },
      {
        text: 'this',
        fromFrame: 69750,
        toFrame: 69758
      },
      {
        text: 'happening',
        fromFrame: 69758,
        toFrame: 69768
      },
      {
        text: 'again,',
        fromFrame: 69768,
        toFrame: 69775
      },
      {
        text: 'Preeti?',
        fromFrame: 69775,
        toFrame: 69787
      },
      {
        text: 'Can',
        fromFrame: 69787,
        toFrame: 69791
      },
      {
        text: 'you',
        fromFrame: 69791,
        toFrame: 69795
      }
    ],
    fromFrame: 69731,
    toFrame: 69795,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'check? Oh, I can check, but I think',
    tokens: [
      {
        text: 'check?',
        fromFrame: 69795,
        toFrame: 69807
      },
      {
        text: 'Oh,',
        fromFrame: 69848,
        toFrame: 69852
      },
      {
        text: 'I',
        fromFrame: 69852,
        toFrame: 69859
      },
      {
        text: 'can',
        fromFrame: 69859,
        toFrame: 69867
      },
      {
        text: 'check,',
        fromFrame: 69867,
        toFrame: 69878
      },
      {
        text: 'but',
        fromFrame: 69878,
        toFrame: 69884
      },
      {
        text: 'I',
        fromFrame: 69884,
        toFrame: 69886
      },
      {
        text: 'think',
        fromFrame: 69886,
        toFrame: 69894
      }
    ],
    fromFrame: 69795,
    toFrame: 69894,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'it will happen because we never fixed this',
    tokens: [
      {
        text: 'it',
        fromFrame: 69894,
        toFrame: 69900
      },
      {
        text: 'will',
        fromFrame: 69900,
        toFrame: 69905
      },
      {
        text: 'happen',
        fromFrame: 69905,
        toFrame: 69917
      },
      {
        text: 'because',
        fromFrame: 69917,
        toFrame: 69929
      },
      {
        text: 'we',
        fromFrame: 69938,
        toFrame: 69944
      },
      {
        text: 'never',
        fromFrame: 69944,
        toFrame: 69951
      },
      {
        text: 'fixed',
        fromFrame: 69951,
        toFrame: 69963
      },
      {
        text: 'this',
        fromFrame: 69965,
        toFrame: 69971
      }
    ],
    fromFrame: 69894,
    toFrame: 69971,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "issue. Okay. I'll keep this. This side don't",
    tokens: [
      {
        text: 'issue.',
        fromFrame: 69971,
        toFrame: 69983
      },
      {
        text: 'Okay.',
        fromFrame: 70090,
        toFrame: 70102
      },
      {
        text: "I'll",
        fromFrame: 70118,
        toFrame: 70126
      },
      {
        text: 'keep',
        fromFrame: 70126,
        toFrame: 70132
      },
      {
        text: 'this.',
        fromFrame: 70132,
        toFrame: 70144
      },
      {
        text: 'This',
        fromFrame: 70202,
        toFrame: 70207
      },
      {
        text: 'side',
        fromFrame: 70207,
        toFrame: 70217
      },
      {
        text: "don't",
        fromFrame: 70217,
        toFrame: 70229
      }
    ],
    fromFrame: 69971,
    toFrame: 70282,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'no. Okay. This side can move. 38 user.',
    tokens: [
      {
        text: 'no.',
        fromFrame: 70282,
        toFrame: 70288
      },
      {
        text: 'Okay.',
        fromFrame: 70288,
        toFrame: 70296
      },
      {
        text: 'This',
        fromFrame: 70296,
        toFrame: 70301
      },
      {
        text: 'side',
        fromFrame: 70301,
        toFrame: 70307
      },
      {
        text: 'can',
        fromFrame: 70307,
        toFrame: 70311
      },
      {
        text: 'move.',
        fromFrame: 70311,
        toFrame: 70323
      },
      {
        text: '38',
        fromFrame: 70478,
        toFrame: 70490
      },
      {
        text: 'user.',
        fromFrame: 70490,
        toFrame: 70499
      }
    ],
    fromFrame: 70282,
    toFrame: 70499,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Right? Oh, yeah. It's fine. I don't think",
    tokens: [
      {
        text: 'Right?',
        fromFrame: 70499,
        toFrame: 70511
      },
      {
        text: 'Oh,',
        fromFrame: 70807,
        toFrame: 70817
      },
      {
        text: 'yeah.',
        fromFrame: 70817,
        toFrame: 70829
      },
      {
        text: "It's",
        fromFrame: 71160,
        toFrame: 71164
      },
      {
        text: 'fine.',
        fromFrame: 71164,
        toFrame: 71176
      },
      {
        text: 'I',
        fromFrame: 71197,
        toFrame: 71201
      },
      {
        text: "don't",
        fromFrame: 71201,
        toFrame: 71206
      },
      {
        text: 'think',
        fromFrame: 71206,
        toFrame: 71214
      }
    ],
    fromFrame: 70499,
    toFrame: 71214,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "anyone understands what we're doing there. I'll move",
    tokens: [
      {
        text: 'anyone',
        fromFrame: 71214,
        toFrame: 71226
      },
      {
        text: 'understands',
        fromFrame: 71226,
        toFrame: 71238
      },
      {
        text: 'what',
        fromFrame: 71239,
        toFrame: 71247
      },
      {
        text: "we're",
        fromFrame: 71247,
        toFrame: 71250
      },
      {
        text: 'doing',
        fromFrame: 71250,
        toFrame: 71258
      },
      {
        text: 'there.',
        fromFrame: 71258,
        toFrame: 71270
      },
      {
        text: "I'll",
        fromFrame: 71291,
        toFrame: 71298
      },
      {
        text: 'move',
        fromFrame: 71298,
        toFrame: 71302
      }
    ],
    fromFrame: 71214,
    toFrame: 71302,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'it to 37. 37. But Goran will be',
    tokens: [
      {
        text: 'it',
        fromFrame: 71302,
        toFrame: 71306
      },
      {
        text: 'to',
        fromFrame: 71306,
        toFrame: 71318
      },
      {
        text: '37.',
        fromFrame: 71410,
        toFrame: 71422
      },
      {
        text: '37.',
        fromFrame: 71487,
        toFrame: 71499
      },
      {
        text: 'But',
        fromFrame: 71578,
        toFrame: 71588
      },
      {
        text: 'Goran',
        fromFrame: 71588,
        toFrame: 71597
      },
      {
        text: 'will',
        fromFrame: 71597,
        toFrame: 71603
      },
      {
        text: 'be',
        fromFrame: 71603,
        toFrame: 71607
      }
    ],
    fromFrame: 71302,
    toFrame: 71607,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "busy in recording studio now? I don't know",
    tokens: [
      {
        text: 'busy',
        fromFrame: 71607,
        toFrame: 71615
      },
      {
        text: 'in',
        fromFrame: 71615,
        toFrame: 71620
      },
      {
        text: 'recording',
        fromFrame: 71620,
        toFrame: 71632
      },
      {
        text: 'studio',
        fromFrame: 71638,
        toFrame: 71645
      },
      {
        text: 'now?',
        fromFrame: 71645,
        toFrame: 71657
      },
      {
        text: 'I',
        fromFrame: 71778,
        toFrame: 71784
      },
      {
        text: "don't",
        fromFrame: 71784,
        toFrame: 71791
      },
      {
        text: 'know',
        fromFrame: 71791,
        toFrame: 71795
      }
    ],
    fromFrame: 71607,
    toFrame: 71795,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "what's the timeline. It's December. The camera if",
    tokens: [
      {
        text: "what's",
        fromFrame: 71795,
        toFrame: 71801
      },
      {
        text: 'the',
        fromFrame: 71801,
        toFrame: 71805
      },
      {
        text: 'timeline.',
        fromFrame: 71805,
        toFrame: 71817
      },
      {
        text: "It's",
        fromFrame: 71832,
        toFrame: 71839
      },
      {
        text: 'December.',
        fromFrame: 71839,
        toFrame: 71851
      },
      {
        text: 'The',
        fromFrame: 71884,
        toFrame: 71890
      },
      {
        text: 'camera',
        fromFrame: 71890,
        toFrame: 71898
      },
      {
        text: 'if',
        fromFrame: 71898,
        toFrame: 71907
      }
    ],
    fromFrame: 71795,
    toFrame: 71907,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'he has time, we can go. This one,',
    tokens: [
      {
        text: 'he',
        fromFrame: 71907,
        toFrame: 71909
      },
      {
        text: 'has',
        fromFrame: 71909,
        toFrame: 71916
      },
      {
        text: 'time,',
        fromFrame: 71916,
        toFrame: 71926
      },
      {
        text: 'we',
        fromFrame: 71926,
        toFrame: 71930
      },
      {
        text: 'can',
        fromFrame: 71930,
        toFrame: 71938
      },
      {
        text: 'go.',
        fromFrame: 71938,
        toFrame: 71950
      },
      {
        text: 'This',
        fromFrame: 71964,
        toFrame: 71970
      },
      {
        text: 'one,',
        fromFrame: 71970,
        toFrame: 71978
      }
    ],
    fromFrame: 71907,
    toFrame: 71978,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'is it high? No. No. We just made',
    tokens: [
      {
        text: 'is',
        fromFrame: 71978,
        toFrame: 71984
      },
      {
        text: 'it',
        fromFrame: 71984,
        toFrame: 71988
      },
      {
        text: 'high?',
        fromFrame: 71988,
        toFrame: 71999
      },
      {
        text: 'No.',
        fromFrame: 71999,
        toFrame: 72003
      },
      {
        text: 'No.',
        fromFrame: 72003,
        toFrame: 72007
      },
      {
        text: 'We',
        fromFrame: 72007,
        toFrame: 72012
      },
      {
        text: 'just',
        fromFrame: 72012,
        toFrame: 72018
      },
      {
        text: 'made',
        fromFrame: 72018,
        toFrame: 72024
      }
    ],
    fromFrame: 71978,
    toFrame: 72024,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "it high. You're walking away? There's no time.",
    tokens: [
      {
        text: 'it',
        fromFrame: 72024,
        toFrame: 72028
      },
      {
        text: 'high.',
        fromFrame: 72028,
        toFrame: 72040
      },
      {
        text: "You're",
        fromFrame: 72041,
        toFrame: 72047
      },
      {
        text: 'walking',
        fromFrame: 72047,
        toFrame: 72055
      },
      {
        text: 'away?',
        fromFrame: 72055,
        toFrame: 72067
      },
      {
        text: "There's",
        fromFrame: 72120,
        toFrame: 72132
      },
      {
        text: 'no',
        fromFrame: 72132,
        toFrame: 72139
      },
      {
        text: 'time.',
        fromFrame: 72139,
        toFrame: 72151
      }
    ],
    fromFrame: 72024,
    toFrame: 72308,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "What does the issue? It's may I think",
    tokens: [
      {
        text: 'What',
        fromFrame: 72308,
        toFrame: 72315
      },
      {
        text: 'does',
        fromFrame: 72315,
        toFrame: 72323
      },
      {
        text: 'the',
        fromFrame: 72323,
        toFrame: 72335
      },
      {
        text: 'issue?',
        fromFrame: 72340,
        toFrame: 72352
      },
      {
        text: "It's",
        fromFrame: 72356,
        toFrame: 72367
      },
      {
        text: 'may',
        fromFrame: 72367,
        toFrame: 72379
      },
      {
        text: 'I',
        fromFrame: 72395,
        toFrame: 72402
      },
      {
        text: 'think',
        fromFrame: 72402,
        toFrame: 72407
      }
    ],
    fromFrame: 72308,
    toFrame: 72407,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'it was showing you were an active speaker',
    tokens: [
      {
        text: 'it',
        fromFrame: 72407,
        toFrame: 72411
      },
      {
        text: 'was',
        fromFrame: 72411,
        toFrame: 72419
      },
      {
        text: 'showing',
        fromFrame: 72419,
        toFrame: 72429
      },
      {
        text: 'you',
        fromFrame: 72429,
        toFrame: 72432
      },
      {
        text: 'were',
        fromFrame: 72432,
        toFrame: 72438
      },
      {
        text: 'an',
        fromFrame: 72438,
        toFrame: 72442
      },
      {
        text: 'active',
        fromFrame: 72442,
        toFrame: 72450
      },
      {
        text: 'speaker',
        fromFrame: 72450,
        toFrame: 72459
      }
    ],
    fromFrame: 72407,
    toFrame: 72459,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'template, but it was showing the full speaker.',
    tokens: [
      {
        text: 'template,',
        fromFrame: 72459,
        toFrame: 72471
      },
      {
        text: 'but',
        fromFrame: 72471,
        toFrame: 72481
      },
      {
        text: 'it',
        fromFrame: 72506,
        toFrame: 72510
      },
      {
        text: 'was',
        fromFrame: 72510,
        toFrame: 72515
      },
      {
        text: 'showing',
        fromFrame: 72515,
        toFrame: 72523
      },
      {
        text: 'the',
        fromFrame: 72523,
        toFrame: 72529
      },
      {
        text: 'full',
        fromFrame: 72529,
        toFrame: 72541
      },
      {
        text: 'speaker.',
        fromFrame: 72544,
        toFrame: 72556
      }
    ],
    fromFrame: 72459,
    toFrame: 72577,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Like, the image was not thumbnail did not',
    tokens: [
      {
        text: 'Like,',
        fromFrame: 72577,
        toFrame: 72582
      },
      {
        text: 'the',
        fromFrame: 72582,
        toFrame: 72586
      },
      {
        text: 'image',
        fromFrame: 72586,
        toFrame: 72596
      },
      {
        text: 'was',
        fromFrame: 72596,
        toFrame: 72600
      },
      {
        text: 'not',
        fromFrame: 72600,
        toFrame: 72604
      },
      {
        text: 'thumbnail',
        fromFrame: 72606,
        toFrame: 72618
      },
      {
        text: 'did',
        fromFrame: 72619,
        toFrame: 72625
      },
      {
        text: 'not',
        fromFrame: 72625,
        toFrame: 72630
      }
    ],
    fromFrame: 72577,
    toFrame: 72630,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "have one speaker. Assign it. I'll check this.",
    tokens: [
      {
        text: 'have',
        fromFrame: 72630,
        toFrame: 72636
      },
      {
        text: 'one',
        fromFrame: 72636,
        toFrame: 72646
      },
      {
        text: 'speaker.',
        fromFrame: 72646,
        toFrame: 72658
      },
      {
        text: 'Assign',
        fromFrame: 72773,
        toFrame: 72784
      },
      {
        text: 'it.',
        fromFrame: 72784,
        toFrame: 72790
      },
      {
        text: "I'll",
        fromFrame: 72790,
        toFrame: 72798
      },
      {
        text: 'check',
        fromFrame: 72798,
        toFrame: 72803
      },
      {
        text: 'this.',
        fromFrame: 72803,
        toFrame: 72815
      }
    ],
    fromFrame: 72630,
    toFrame: 73001,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Okay. Fine. I think it's good. This, I",
    tokens: [
      {
        text: 'Okay.',
        fromFrame: 73001,
        toFrame: 73013
      },
      {
        text: 'Fine.',
        fromFrame: 73083,
        toFrame: 73095
      },
      {
        text: 'I',
        fromFrame: 73099,
        toFrame: 73103
      },
      {
        text: 'think',
        fromFrame: 73103,
        toFrame: 73108
      },
      {
        text: "it's",
        fromFrame: 73108,
        toFrame: 73120
      },
      {
        text: 'good.',
        fromFrame: 73128,
        toFrame: 73140
      },
      {
        text: 'This,',
        fromFrame: 73169,
        toFrame: 73173
      },
      {
        text: 'I',
        fromFrame: 73173,
        toFrame: 73176
      }
    ],
    fromFrame: 73001,
    toFrame: 73176,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "don't know. New Relic. I'll move it to",
    tokens: [
      {
        text: "don't",
        fromFrame: 73176,
        toFrame: 73182
      },
      {
        text: 'know.',
        fromFrame: 73182,
        toFrame: 73194
      },
      {
        text: 'New',
        fromFrame: 73221,
        toFrame: 73224
      },
      {
        text: 'Relic.',
        fromFrame: 73224,
        toFrame: 73236
      },
      {
        text: "I'll",
        fromFrame: 73238,
        toFrame: 73246
      },
      {
        text: 'move',
        fromFrame: 73246,
        toFrame: 73253
      },
      {
        text: 'it',
        fromFrame: 73253,
        toFrame: 73259
      },
      {
        text: 'to',
        fromFrame: 73259,
        toFrame: 73271
      }
    ],
    fromFrame: 73176,
    toFrame: 73271,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: '38. Who else is left? Gagan. Ayush. K.',
    tokens: [
      {
        text: '38.',
        fromFrame: 73271,
        toFrame: 73283
      },
      {
        text: 'Who',
        fromFrame: 73393,
        toFrame: 73397
      },
      {
        text: 'else',
        fromFrame: 73397,
        toFrame: 73403
      },
      {
        text: 'is',
        fromFrame: 73403,
        toFrame: 73407
      },
      {
        text: 'left?',
        fromFrame: 73407,
        toFrame: 73419
      },
      {
        text: 'Gagan.',
        fromFrame: 73420,
        toFrame: 73432
      },
      {
        text: 'Ayush.',
        fromFrame: 73470,
        toFrame: 73482
      },
      {
        text: 'K.',
        fromFrame: 73536,
        toFrame: 73548
      }
    ],
    fromFrame: 73271,
    toFrame: 73872,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Is this fine on 37, Ashwin? Yeah. It',
    tokens: [
      {
        text: 'Is',
        fromFrame: 73872,
        toFrame: 73876
      },
      {
        text: 'this',
        fromFrame: 73876,
        toFrame: 73884
      },
      {
        text: 'fine',
        fromFrame: 73884,
        toFrame: 73891
      },
      {
        text: 'on',
        fromFrame: 73891,
        toFrame: 73897
      },
      {
        text: '37,',
        fromFrame: 73897,
        toFrame: 73909
      },
      {
        text: 'Ashwin?',
        fromFrame: 73920,
        toFrame: 73932
      },
      {
        text: 'Yeah.',
        fromFrame: 74058,
        toFrame: 74062
      },
      {
        text: 'It',
        fromFrame: 74062,
        toFrame: 74064
      }
    ],
    fromFrame: 73872,
    toFrame: 74064,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'should be fine. No. Wow. Preeti? This was',
    tokens: [
      {
        text: 'should',
        fromFrame: 74064,
        toFrame: 74070
      },
      {
        text: 'be',
        fromFrame: 74070,
        toFrame: 74072
      },
      {
        text: 'fine.',
        fromFrame: 74072,
        toFrame: 74084
      },
      {
        text: 'No.',
        fromFrame: 74943,
        toFrame: 74955
      },
      {
        text: 'Wow.',
        fromFrame: 74964,
        toFrame: 74976
      },
      {
        text: 'Preeti?',
        fromFrame: 75112,
        toFrame: 75124
      },
      {
        text: 'This',
        fromFrame: 75612,
        toFrame: 75617
      },
      {
        text: 'was',
        fromFrame: 75617,
        toFrame: 75623
      }
    ],
    fromFrame: 74064,
    toFrame: 75623,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "some goal cast recording. Oh, they're saying yeah.",
    tokens: [
      {
        text: 'some',
        fromFrame: 75623,
        toFrame: 75629
      },
      {
        text: 'goal',
        fromFrame: 75629,
        toFrame: 75637
      },
      {
        text: 'cast',
        fromFrame: 75637,
        toFrame: 75644
      },
      {
        text: 'recording.',
        fromFrame: 75644,
        toFrame: 75656
      },
      {
        text: 'Oh,',
        fromFrame: 75660,
        toFrame: 75665
      },
      {
        text: "they're",
        fromFrame: 75665,
        toFrame: 75671
      },
      {
        text: 'saying',
        fromFrame: 75671,
        toFrame: 75683
      },
      {
        text: 'yeah.',
        fromFrame: 75700,
        toFrame: 75706
      }
    ],
    fromFrame: 75623,
    toFrame: 75706,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "It's okay. I think I this issue. Yeah.",
    tokens: [
      {
        text: "It's",
        fromFrame: 75706,
        toFrame: 75711
      },
      {
        text: 'okay.',
        fromFrame: 75711,
        toFrame: 75721
      },
      {
        text: 'I',
        fromFrame: 75721,
        toFrame: 75731
      },
      {
        text: 'think',
        fromFrame: 75731,
        toFrame: 75743
      },
      {
        text: 'I',
        fromFrame: 75759,
        toFrame: 75768
      },
      {
        text: 'this',
        fromFrame: 75777,
        toFrame: 75789
      },
      {
        text: 'issue.',
        fromFrame: 75800,
        toFrame: 75812
      },
      {
        text: 'Yeah.',
        fromFrame: 75921,
        toFrame: 75932
      }
    ],
    fromFrame: 75706,
    toFrame: 75932,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "It's fine. Simon. Yeah. We are done. Fine.",
    tokens: [
      {
        text: "It's",
        fromFrame: 75932,
        toFrame: 75938
      },
      {
        text: 'fine.',
        fromFrame: 75938,
        toFrame: 75950
      },
      {
        text: 'Simon.',
        fromFrame: 76167,
        toFrame: 76179
      },
      {
        text: 'Yeah.',
        fromFrame: 76184,
        toFrame: 76192
      },
      {
        text: 'We',
        fromFrame: 76192,
        toFrame: 76194
      },
      {
        text: 'are',
        fromFrame: 76194,
        toFrame: 76197
      },
      {
        text: 'done.',
        fromFrame: 76197,
        toFrame: 76209
      },
      {
        text: 'Fine.',
        fromFrame: 76467,
        toFrame: 76471
      }
    ],
    fromFrame: 75932,
    toFrame: 76471,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "I'm tired. No. I layout this. Yeah. You",
    tokens: [
      {
        text: "I'm",
        fromFrame: 76471,
        toFrame: 76479
      },
      {
        text: 'tired.',
        fromFrame: 76479,
        toFrame: 76490
      },
      {
        text: 'No.',
        fromFrame: 76490,
        toFrame: 76502
      },
      {
        text: 'I',
        fromFrame: 76601,
        toFrame: 76612
      },
      {
        text: 'layout',
        fromFrame: 76612,
        toFrame: 76624
      },
      {
        text: 'this.',
        fromFrame: 76631,
        toFrame: 76643
      },
      {
        text: 'Yeah.',
        fromFrame: 76650,
        toFrame: 76660
      },
      {
        text: 'You',
        fromFrame: 76660,
        toFrame: 76672
      }
    ],
    fromFrame: 76471,
    toFrame: 76683,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'would. Test. How much English English starting with',
    tokens: [
      {
        text: 'would.',
        fromFrame: 76683,
        toFrame: 76687
      },
      {
        text: 'Test.',
        fromFrame: 76687,
        toFrame: 76699
      },
      {
        text: 'How',
        fromFrame: 76758,
        toFrame: 76770
      },
      {
        text: 'much',
        fromFrame: 76810,
        toFrame: 76822
      },
      {
        text: 'English',
        fromFrame: 77187,
        toFrame: 77199
      },
      {
        text: 'English',
        fromFrame: 77297,
        toFrame: 77303
      },
      {
        text: 'starting',
        fromFrame: 77303,
        toFrame: 77311
      },
      {
        text: 'with',
        fromFrame: 77311,
        toFrame: 77316
      }
    ],
    fromFrame: 76683,
    toFrame: 77316,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'the half an hour. I think it was',
    tokens: [
      {
        text: 'the',
        fromFrame: 77316,
        toFrame: 77318
      },
      {
        text: 'half',
        fromFrame: 77318,
        toFrame: 77322
      },
      {
        text: 'an',
        fromFrame: 77322,
        toFrame: 77327
      },
      {
        text: 'hour.',
        fromFrame: 77327,
        toFrame: 77332
      },
      {
        text: 'I',
        fromFrame: 77332,
        toFrame: 77344
      },
      {
        text: 'think',
        fromFrame: 77380,
        toFrame: 77382
      },
      {
        text: 'it',
        fromFrame: 77382,
        toFrame: 77386
      },
      {
        text: 'was',
        fromFrame: 77386,
        toFrame: 77393
      }
    ],
    fromFrame: 77316,
    toFrame: 77393,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'ended at 3:30. And only record 30 minutes',
    tokens: [
      {
        text: 'ended',
        fromFrame: 77393,
        toFrame: 77403
      },
      {
        text: 'at',
        fromFrame: 77403,
        toFrame: 77407
      },
      {
        text: '3:30.',
        fromFrame: 77407,
        toFrame: 77419
      },
      {
        text: 'And',
        fromFrame: 77447,
        toFrame: 77453
      },
      {
        text: 'only',
        fromFrame: 77453,
        toFrame: 77459
      },
      {
        text: 'record',
        fromFrame: 77459,
        toFrame: 77471
      },
      {
        text: '30',
        fromFrame: 77532,
        toFrame: 77537
      },
      {
        text: 'minutes',
        fromFrame: 77537,
        toFrame: 77547
      }
    ],
    fromFrame: 77393,
    toFrame: 77547,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'or record Oh. Oh. Big boss. But recording.',
    tokens: [
      {
        text: 'or',
        fromFrame: 77547,
        toFrame: 77551
      },
      {
        text: 'record',
        fromFrame: 77551,
        toFrame: 77563
      },
      {
        text: 'Oh.',
        fromFrame: 77583,
        toFrame: 77595
      },
      {
        text: 'Oh.',
        fromFrame: 77614,
        toFrame: 77626
      },
      {
        text: 'Big',
        fromFrame: 77630,
        toFrame: 77642
      },
      {
        text: 'boss.',
        fromFrame: 77709,
        toFrame: 77712
      },
      {
        text: 'But',
        fromFrame: 77712,
        toFrame: 77716
      },
      {
        text: 'recording.',
        fromFrame: 77716,
        toFrame: 77728
      }
    ],
    fromFrame: 77547,
    toFrame: 77776,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Good. Initially, the recording. Customer, sir. They are',
    tokens: [
      {
        text: 'Good.',
        fromFrame: 77776,
        toFrame: 77788
      },
      {
        text: 'Initially,',
        fromFrame: 77856,
        toFrame: 77868
      },
      {
        text: 'the',
        fromFrame: 77879,
        toFrame: 77881
      },
      {
        text: 'recording.',
        fromFrame: 77881,
        toFrame: 77893
      },
      {
        text: 'Customer,',
        fromFrame: 77982,
        toFrame: 77994
      },
      {
        text: 'sir.',
        fromFrame: 77994,
        toFrame: 78004
      },
      {
        text: 'They',
        fromFrame: 78004,
        toFrame: 78009
      },
      {
        text: 'are',
        fromFrame: 78009,
        toFrame: 78013
      }
    ],
    fromFrame: 77776,
    toFrame: 78013,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "also not liking that. We don't tell and",
    tokens: [
      {
        text: 'also',
        fromFrame: 78013,
        toFrame: 78025
      },
      {
        text: 'not',
        fromFrame: 78030,
        toFrame: 78036
      },
      {
        text: 'liking',
        fromFrame: 78036,
        toFrame: 78048
      },
      {
        text: 'that.',
        fromFrame: 78048,
        toFrame: 78060
      },
      {
        text: 'We',
        fromFrame: 78065,
        toFrame: 78069
      },
      {
        text: "don't",
        fromFrame: 78069,
        toFrame: 78077
      },
      {
        text: 'tell',
        fromFrame: 78077,
        toFrame: 78080
      },
      {
        text: 'and',
        fromFrame: 78080,
        toFrame: 78086
      }
    ],
    fromFrame: 78013,
    toFrame: 78086,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "record. Oh, I thought I'm not recording. So",
    tokens: [
      {
        text: 'record.',
        fromFrame: 78086,
        toFrame: 78098
      },
      {
        text: 'Oh,',
        fromFrame: 78111,
        toFrame: 78123
      },
      {
        text: 'I',
        fromFrame: 78136,
        toFrame: 78140
      },
      {
        text: 'thought',
        fromFrame: 78140,
        toFrame: 78152
      },
      {
        text: "I'm",
        fromFrame: 78155,
        toFrame: 78167
      },
      {
        text: 'not',
        fromFrame: 78171,
        toFrame: 78180
      },
      {
        text: 'recording.',
        fromFrame: 78180,
        toFrame: 78192
      },
      {
        text: 'So',
        fromFrame: 78198,
        toFrame: 78203
      }
    ],
    fromFrame: 78086,
    toFrame: 78203,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'you have an interrupt. Woah. Yep. Take care.',
    tokens: [
      {
        text: 'you',
        fromFrame: 78203,
        toFrame: 78205
      },
      {
        text: 'have',
        fromFrame: 78205,
        toFrame: 78209
      },
      {
        text: 'an',
        fromFrame: 78209,
        toFrame: 78213
      },
      {
        text: 'interrupt.',
        fromFrame: 78213,
        toFrame: 78225
      },
      {
        text: 'Woah.',
        fromFrame: 78254,
        toFrame: 78266
      },
      {
        text: 'Yep.',
        fromFrame: 78277,
        toFrame: 78288
      },
      {
        text: 'Take',
        fromFrame: 78288,
        toFrame: 78298
      },
      {
        text: 'care.',
        fromFrame: 78298,
        toFrame: 78310
      }
    ],
    fromFrame: 78203,
    toFrame: 78319,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: "Yeah. Alright. That's it. Full video editing is",
    tokens: [
      {
        text: 'Yeah.',
        fromFrame: 78319,
        toFrame: 78331
      },
      {
        text: 'Alright.',
        fromFrame: 78344,
        toFrame: 78356
      },
      {
        text: "That's",
        fromFrame: 78365,
        toFrame: 78369
      },
      {
        text: 'it.',
        fromFrame: 78369,
        toFrame: 78381
      },
      {
        text: 'Full',
        fromFrame: 78492,
        toFrame: 78498
      },
      {
        text: 'video',
        fromFrame: 78498,
        toFrame: 78507
      },
      {
        text: 'editing',
        fromFrame: 78507,
        toFrame: 78517
      },
      {
        text: 'is',
        fromFrame: 78517,
        toFrame: 78523
      }
    ],
    fromFrame: 78319,
    toFrame: 78523,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'in testing yet. No. Otherwise, we would have',
    tokens: [
      {
        text: 'in',
        fromFrame: 78523,
        toFrame: 78534
      },
      {
        text: 'testing',
        fromFrame: 78534,
        toFrame: 78546
      },
      {
        text: 'yet.',
        fromFrame: 78569,
        toFrame: 78581
      },
      {
        text: 'No.',
        fromFrame: 78582,
        toFrame: 78594
      },
      {
        text: 'Otherwise,',
        fromFrame: 78645,
        toFrame: 78653
      },
      {
        text: 'we',
        fromFrame: 78653,
        toFrame: 78655
      },
      {
        text: 'would',
        fromFrame: 78655,
        toFrame: 78661
      },
      {
        text: 'have',
        fromFrame: 78661,
        toFrame: 78663
      }
    ],
    fromFrame: 78523,
    toFrame: 78663,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'digited that part. Is it? Sure. The full',
    tokens: [
      {
        text: 'digited',
        fromFrame: 78663,
        toFrame: 78674
      },
      {
        text: 'that',
        fromFrame: 78674,
        toFrame: 78680
      },
      {
        text: 'part.',
        fromFrame: 78680,
        toFrame: 78692
      },
      {
        text: 'Is',
        fromFrame: 78722,
        toFrame: 78724
      },
      {
        text: 'it?',
        fromFrame: 78724,
        toFrame: 78735
      },
      {
        text: 'Sure.',
        fromFrame: 78745,
        toFrame: 78757
      },
      {
        text: 'The',
        fromFrame: 78789,
        toFrame: 78793
      },
      {
        text: 'full',
        fromFrame: 78793,
        toFrame: 78799
      }
    ],
    fromFrame: 78663,
    toFrame: 78799,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'video editing. No. No. Not more time, sir.',
    tokens: [
      {
        text: 'video',
        fromFrame: 78799,
        toFrame: 78807
      },
      {
        text: 'editing.',
        fromFrame: 78807,
        toFrame: 78812
      },
      {
        text: 'No.',
        fromFrame: 78812,
        toFrame: 78818
      },
      {
        text: 'No.',
        fromFrame: 78818,
        toFrame: 78830
      },
      {
        text: 'Not',
        fromFrame: 78834,
        toFrame: 78841
      },
      {
        text: 'more',
        fromFrame: 78841,
        toFrame: 78847
      },
      {
        text: 'time,',
        fromFrame: 78847,
        toFrame: 78853
      },
      {
        text: 'sir.',
        fromFrame: 78853,
        toFrame: 78865
      }
    ],
    fromFrame: 78799,
    toFrame: 78889,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  },
  {
    text: 'Okay. Cool. Okay. Bye. Thank you. Bye bye.',
    tokens: [
      {
        text: 'Okay.',
        fromFrame: 78889,
        toFrame: 78901
      },
      {
        text: 'Cool.',
        fromFrame: 78912,
        toFrame: 78924
      },
      {
        text: 'Okay.',
        fromFrame: 78997,
        toFrame: 79009
      },
      {
        text: 'Bye.',
        fromFrame: 79074,
        toFrame: 79086
      },
      {
        text: 'Thank',
        fromFrame: 79114,
        toFrame: 79120
      },
      {
        text: 'you.',
        fromFrame: 79120,
        toFrame: 79125
      },
      {
        text: 'Bye',
        fromFrame: 79125,
        toFrame: 79131
      },
      {
        text: 'bye.',
        fromFrame: 79131,
        toFrame: 79143
      }
    ],
    fromFrame: 78889,
    toFrame: 79296,
    style: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      position: 'absolute',
      left: '2.5%',
      top: '82%',
      width: '95%',
      height: '18%',
      textTransform: 'none'
    },
    groupedTokensStyle: {
      display: 'flex',
      fontFamily: 'Inter',
      fontSize: 48,
      flexWrap: 'wrap',
      justifyContent: 'center',
      alignItems: 'center',
      columnGap: '16px',
      lineHeight: '72px'
    },
    activeTokenStyle: {
      color: '#BB32E5',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 1,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    },
    inactiveTokenStyle: {
      color: '#FFCB05',
      fontFamily: 'inherit',
      lineHeight: 'inherit',
      backgroundColor: 'transparent',
      opacity: 0.6,
      textShadow:
        '-1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05, -1.2px -1.2px 0.7px #FFCB05, 1.2px -1.2px 0.7px #FFCB05, -1.2px 1.2px 0.7px #FFCB05, 1.2px 1.2px 0.7px #FFCB05'
    }
  }
] as any;

export default captionPages;
