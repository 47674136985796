import { memo, useMemo } from 'react';
import { BackgroundTransitionTemplateMock } from './types';

function MockTemplateVideoRenderer({
  element,
  scale
}: {
  element: BackgroundTransitionTemplateMock['timeline']['segments'][0]['elements'][0];
  scale: number;
}) {
  const styles = useMemo(() => {
    const elementWidth = element.width || 0;
    const elementHeight = element.height || 0;
    const elementX = element.x || 0;
    const elementY = element.y || 0;
    const elementRotation = element.rotation || 0;
    const elementCropX = element.cropX || 0;
    const elementCropY = element.cropY || 0;
    const elementCropWidth = element.cropWidth || 0;
    const elementCropHeight = element.cropHeight || 0;
    const elementBorderRadius = element.borderRadius || 0;

    return {
      width: `${elementWidth * scale}px`,
      height: `${elementHeight * scale}px`,
      top: `${elementY * scale}px`,
      left: `${elementX * scale}px`,
      transform: `rotate(${elementRotation}deg)`,
      clipPath: `inset(${elementCropY * scale}px ${
        elementWidth * scale - (elementCropX * scale + elementCropWidth * scale)
      }px ${elementHeight * scale - (elementCropY * scale + elementCropHeight * scale)}px ${elementCropX * scale}px ${
        elementBorderRadius ? `round ${elementBorderRadius * scale}px` : ''
      })`,
      borderRadius: `${elementBorderRadius * scale}px`
    };
  }, [element, scale]);

  return (
    <video
      className="absolute inset-0 object-fill"
      loop={true}
      muted={true}
      style={styles}
      autoPlay={true}
      poster={element.poster}
      src={element.src}
    >
      <track kind="captions" />
    </video>
  );
}

export default memo(MockTemplateVideoRenderer);
