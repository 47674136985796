import { memo, useState, useSyncExternalStore } from 'react';
import { timeline } from '../timeline';
import { TrackType } from '../types/Track/Type';
import { SegmentsTrack } from '../types/Track/SegmentsTrack';
import { SegmentType } from '../types/Segment/Type';
import { CompositeSegment } from '../types/Segment/CompositeSegment';
import Toggle from '@/components/atoms/Toggle';
import Button from '@/components/atoms/Button/Button';

const ValuesChangeInScene = ({ clipStart, clipEnd }: { clipStart: number; clipEnd: number }) => {
  const [start, setStart] = useState(clipStart);
  const [end, setEnd] = useState(clipEnd);
  const timelineStore = useSyncExternalStore(timeline.subscribe, timeline.getSnapshot);
  const [speakersInScene, setSpeakersInScene] = useState<string[]>([]);
  const [speakerIdsToShow, setSpeakerIdsToShow] = useState<string>('');
  const [showSpeakers, setShowSpeakers] = useState<boolean>(true);

  const findVideosInRange = (start: number, end: number) => {
    const segmentsTrack = timelineStore.tracks.find(track => track.type === TrackType.SEGMENTS) as SegmentsTrack;
    const segment = segmentsTrack.segments.find(s => s.type === SegmentType.COMPOSITE) as CompositeSegment;

    const elements = segment.elements;

    // TODO: @AshwinBhatkal to handle elements outside as well
    const elementsAffected = elements.filter(el => el.fromFrame >= start && el.toFrame <= end);

    const speakerIds = new Set(elementsAffected.map(el => el.id.split('-')[2]));

    setSpeakersInScene(Array.from(speakerIds));
  };

  const findAndReplace = (start: number, end: number) => {
    const segmentsTrack = timelineStore.tracks.find(track => track.type === TrackType.SEGMENTS) as SegmentsTrack;
    const segment = segmentsTrack.segments.find(s => s.type === SegmentType.COMPOSITE) as CompositeSegment;

    const elements = segment.elements;

    // TODO: @AshwinBhatkal to handle elements outside as well
    const elementsAffected = elements.filter(el => el.fromFrame >= start && el.toFrame <= end);
    const untouchedElements = elements.filter(el => !(el.fromFrame >= start && el.toFrame <= end));

    const updatedElements = elementsAffected.filter(el => speakersInScene.includes(el.id.split('-')[2]));

    const newElementsArray = [...untouchedElements, ...updatedElements];
    newElementsArray.sort((a, b) => {
      if (a.fromFrame < b.fromFrame) return 1;
      else return 0;
    });

    timeline.update(data => {
      const mainTrack = data.tracks[0];
      if (mainTrack.type === TrackType.SEGMENTS && mainTrack.segments[0].type === SegmentType.COMPOSITE) {
        mainTrack.segments[0].elements = newElementsArray;
      }
      return data;
    });
  };

  return (
    <div className="px-4 py-3">
      <div className="font-bold">- Change values in a scene</div>

      <div className="flex gap-2">
        <input type="number" value={start} onChange={e => setStart(+e.target.value)} />
        <input type="number" value={end} onChange={e => setEnd(+e.target.value)} />
      </div>

      <div className="mt-4 flex items-center gap-2">
        <div>SpeakerIds to show</div>
        <input
          type="text"
          className="flex-1 rounded border border-gray-300 px-3 py-2 text-sm"
          placeholder="Enter speaker ids"
          value={speakerIdsToShow}
          onChange={e => setSpeakerIdsToShow(e.target.value)}
        />
        {!!speakerIdsToShow.length && (
          <pre className="mt-2 text-xs">{JSON.stringify(speakerIdsToShow.split(','), null, 2)}</pre>
        )}
      </div>

      <div className="mt-4 flex gap-2">
        <Button variation="filled" trackingId="find & replace" onClick={() => findAndReplace(start, end)}>
          Find & Replace
        </Button>
        <Button variation="outline" trackingId="find" onClick={() => findVideosInRange(start, end)}>
          Find Videos in Range
        </Button>
        <Button
          variation="filled"
          trackingId="reset"
          onClick={() => {
            setStart(clipStart);
            setEnd(clipEnd);
            setSpeakersInScene([]);
          }}
        >
          Reset
        </Button>
      </div>

      <div className="mt-6 rounded bg-gray-100 p-4">
        <div className=" flex items-center gap-3 text-sm font-medium">
          <div>Speakers Value</div>
          <Toggle on={showSpeakers} onToggle={() => setShowSpeakers(s => !s)} />
        </div>
        {showSpeakers && <pre className="mt-2 text-xs">{JSON.stringify(speakersInScene, null, 2)}</pre>}
      </div>
    </div>
  );
};

export default memo(ValuesChangeInScene);
