import { memo, useCallback, useMemo } from 'react';
import { TranscriptSelection } from '../types';
import { editClipWord, saveCorrectWordChanges } from './utils';
import IconButton from '@/components/atoms/Button/IconButton';
import TooltipV2 from '@/components/TooltipV2';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';

function TranscriptHideMenuOptions({
  transcriptSelection,
  onCloseMenu,
  onEditActionTriggered
}: {
  transcriptSelection?: TranscriptSelection | null;
  onCloseMenu: () => void;
  onEditActionTriggered: () => void;
}) {
  const { clipData, clipCaptionsLoading } = useClipsContext();

  const areAllSelectedWordsHidden = useMemo(() => {
    if (!transcriptSelection) return false;
    const clipCaptions = clipData.asset_metadata.captions.filter(
      w =>
        +w.start_time >= transcriptSelection.selectionStartTime && +w.end_time <= transcriptSelection.selectionEndTime
    );
    return clipCaptions.every(w => w.hidden);
  }, [clipData.asset_metadata.captions, transcriptSelection]);

  const areAllSelectedWordsVisible = useMemo(() => {
    if (!transcriptSelection) return false;
    const clipCaptions = clipData.asset_metadata.captions.filter(
      w => w.start_time >= transcriptSelection.selectionStartTime && w.end_time <= transcriptSelection.selectionEndTime
    );
    return !clipCaptions.some(w => w.hidden);
  }, [clipData.asset_metadata.captions, transcriptSelection]);

  const hideInTranscript = useCallback(() => {
    onEditActionTriggered();
    const value = transcriptSelection?.words?.reduce((acc, word) => editClipWord(word, '', acc), clipData);
    if (value) saveCorrectWordChanges(value, clipData);
    onCloseMenu();
  }, [clipData, onCloseMenu, transcriptSelection?.words]);

  const showInTranscript = useCallback(() => {
    onEditActionTriggered();
    const edits = { ...clipData.asset_metadata.edits };
    transcriptSelection?.words?.forEach(({ start_time }) => delete edits[start_time]);
    saveCorrectWordChanges(
      {
        ...clipData,
        asset_metadata: {
          ...clipData.asset_metadata,
          edits
        }
      },
      clipData
    );
    onCloseMenu();
  }, [clipData, transcriptSelection?.words, onCloseMenu]);

  return (
    <>
      {areAllSelectedWordsVisible && (
        <TooltipV2
          place="left"
          tooltipId="hide-in-transcript-tooltip"
          tooltipContent="Hide in captions"
          size="xsmall"
          tooltipElement={
            <IconButton
              icon="IconEyeOff"
              trackingId="icon-hide-transcript"
              onClick={hideInTranscript}
              variation="checkbox"
              disabled={clipCaptionsLoading}
              buttonClassName="w-10 h-10"
              data-tooltip-id="hide-in-transcript-tooltip"
            />
          }
        />
      )}

      {areAllSelectedWordsHidden && (
        <TooltipV2
          place="left"
          tooltipId="show-in-transcript-tooltip"
          tooltipContent="Show in captions"
          size="xsmall"
          tooltipElement={
            <IconButton
              icon="IconEye"
              trackingId="icon-show-transcript"
              onClick={showInTranscript}
              variation="checkbox"
              disabled={clipCaptionsLoading}
              buttonClassName="w-10 h-10"
              data-tooltip-id="show-in-transcript-tooltip"
            />
          }
        />
      )}
    </>
  );
}

export default memo(TranscriptHideMenuOptions);
