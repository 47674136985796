import { Popover } from '@headlessui/react';
import { memo, useCallback, useMemo, useSyncExternalStore } from 'react';
import { IconSparkles } from '@tabler/icons-react';
import ClipCustomizerAspectRatioOption from './ClipCustomizerAspectRatioOption';
import SpeakersConfiguration from './Speakers/SpeakerConfiguration';
import ClipCustomizerMagicLayoutOption from './ClipCustomizerMagicLayoutOption';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { selectMagicLayout, toggleHideBorders } from '@/stores/clip';
import Toggle from '@/components/atoms/Toggle';
import { clipHistory } from '@/stores/clipHistory';
import { core } from '@/stores/core';

function ClipCustomizerLayoutPopup({
  isFullRecordingEdit = false,
  useNewRecordingsPlayer = false
}: {
  isFullRecordingEdit?: boolean;
  useNewRecordingsPlayer?: boolean;
}) {
  const { clipId, clipData } = useClipsContext();
  const clipHistoryStore = useSyncExternalStore(clipHistory.subscribe, clipHistory.getSnapshot);
  const coreStore = useSyncExternalStore(core.subscribe, core.getSnapshot);
  const clipHistoryForClip = clipHistoryStore[clipId];

  const clipLayout = useMemo(() => clipData.asset_metadata.layout, [clipData.asset_metadata.layout]);

  const isAudioContent = useMemo(() => {
    return coreStore.content?.media_type === 'AUDIO';
  }, [coreStore.content]);

  const toggleAudiogram = useCallback(
    (isOn: boolean) => {
      // If turning off Audiogram, revert to layout that was before turn on
      if (!isOn && clipHistoryForClip.index >= 1) {
        const previous = clipHistoryForClip.states[clipHistoryForClip.index - 1];
        selectMagicLayout(clipId, !!previous.layout && previous.layout !== 'AUDIOGRAM' ? previous.layout : 'DEFAULT');
        return;
      }
      selectMagicLayout(clipId, isOn ? 'AUDIOGRAM' : 'DEFAULT');
    },
    [clipId, clipHistoryForClip]
  );

  const onBorderToggle = useCallback(
    (value: boolean) => {
      toggleHideBorders(clipId, !value);
    },
    [clipId]
  );

  return (
    <Popover className="absolute -top-28 right-20 flex max-h-[54vh] w-[23rem] flex-col overflow-y-auto rounded-lg border bg-white shadow">
      <div className="border-b">
        {/* Aspect ratio */}
        <div className="px-4 py-3">
          <div className="font-medium">Aspect Ratio</div>
          <div className="mt-1 flex space-x-2 py-1">
            <ClipCustomizerAspectRatioOption sizeOption="LANDSCAPE" />
            <ClipCustomizerAspectRatioOption
              sizeOption="SQUARE"
              isDisabled={useNewRecordingsPlayer}
              disabledReason="Support for recordings coming soon..."
            />
            <ClipCustomizerAspectRatioOption
              sizeOption="PORTRAIT"
              isDisabled={useNewRecordingsPlayer}
              disabledReason="Support for recordings coming soon..."
            />
          </div>
        </div>
        {/* Audiogram toggle */}
        {!useNewRecordingsPlayer && (
          <div className="border-y py-3">
            <div className="flex items-center justify-between px-4">
              <div>
                <div className="font-medium">Audiogram</div>
                <div className="text-xs text-slate-600">Clip features a speaker image, waveform and captions</div>
              </div>
              <Toggle on={clipLayout === 'AUDIOGRAM'} onToggle={toggleAudiogram} disabled={isAudioContent} />
            </div>
          </div>
        )}
        {!isAudioContent && (
          <>
            {(!isFullRecordingEdit || useNewRecordingsPlayer) && (
              <>
                {/* Magic layout */}
                <div className="border-y px-4 py-3">
                  <div className="flex font-medium">
                    AI Speaker Reframe <IconSparkles size={24} className="ml-1" />
                  </div>
                  <div className="mt-1 flex space-x-2 py-1">
                    <ClipCustomizerMagicLayoutOption
                      magicLayoutOption="SPEAKER"
                      useNewRecordingsPlayer={useNewRecordingsPlayer}
                    />
                    <ClipCustomizerMagicLayoutOption
                      magicLayoutOption="GRID"
                      useNewRecordingsPlayer={useNewRecordingsPlayer}
                    />
                  </div>
                </div>
                {/* Speaker togglers & show speaker labels */}
                <SpeakersConfiguration />
              </>
            )}
            {/* Borders toggle */}
            <div className="py-3">
              <div className="flex items-center justify-between space-x-4 px-4">
                <div>
                  <div className="font-medium">Borders</div>
                </div>
                <Toggle
                  on={!clipData.asset_metadata.hide_borders}
                  onToggle={onBorderToggle}
                  disabled={clipData.asset_metadata.layout === 'AUDIOGRAM'}
                />
              </div>
            </div>
          </>
        )}
      </div>
    </Popover>
  );
}

export default memo(ClipCustomizerLayoutPopup);
