import { Audio, Sequence, useCurrentFrame } from 'remotion';
import { AudioElement } from '@/App/remotion/MainPlayer/types/Element/AudioElement';

const AudioElementComponent: React.FC<{
  element: AudioElement;
}> = ({ element }) => {
  const { fromFrame, toFrame, details, trim } = element;
  const durationInFrames = Math.round(toFrame - fromFrame);

  const frame = useCurrentFrame();
  const currentTrim = trim.find(t => t.fromFrame <= frame && t.toFrame >= frame);

  if (!currentTrim && trim.length !== 0) return null;

  return (
    <Sequence from={fromFrame} durationInFrames={durationInFrames} premountFor={100} style={{ display: 'contents' }}>
      <div style={{ color: '#FFCB05', fontSize: 72, marginTop: 90 }}>Audio Playing</div>
      <Audio
        src={details.src}
        pauseWhenBuffering
        startFrom={currentTrim?.fromFrame || 0}
        endAt={currentTrim?.toFrame || 0}
      />
    </Sequence>
  );
};

export default AudioElementComponent;
