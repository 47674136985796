import { Fragment, useSyncExternalStore } from 'react';
import { stageTimeSpansStore } from '../stores/stageTimeSpansStore';

export const speakers = [
  { id: 'spk_0', name: 'Speaker 0', color: '#FF6B6B' },
  { id: 'spk_1', name: 'Speaker 1', color: '#4ECDC4' },
  { id: 'spk_2', name: 'Speaker 2', color: '#45B7D1' }
];

function StageTimeSpans() {
  const timeSpans = useSyncExternalStore(stageTimeSpansStore.subscribe, stageTimeSpansStore.getSnapshot);

  const handleRangeChange = (speakerId: string, value: string) => {
    stageTimeSpansStore.update(data => ({
      ...data,
      [speakerId]: value
    }));
  };

  return (
    <Fragment>
      <div className="mt-4 font-bold">- Creation/Consumption of stage time spans</div>
      <div className="space-y-4">
        {speakers.map(speaker => (
          <div key={speaker.id} className="flex items-center gap-4">
            <div className="w-24 font-medium">{speaker.name}</div>
            <input
              type="text"
              className="flex-1 rounded border border-gray-300 px-3 py-2 text-sm"
              placeholder="Enter ranges (e.g. 0-10,15-20,25-30)"
              value={timeSpans[speaker.id]}
              onChange={e => handleRangeChange(speaker.id, e.target.value)}
            />
          </div>
        ))}
      </div>
    </Fragment>
  );
}

export default StageTimeSpans;
