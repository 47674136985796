import { Fragment, memo, useEffect, useState } from 'react';
import { getActiveSpeakersWithinTimeRange } from '../utils/activeSpeaker';
import { useTranscriptContext } from '@/context/TranscriptContext/TranscriptContext';

function ActiveSpeakerRanges({ clipStart, clipEnd }: { clipStart: number; clipEnd: number }) {
  const transcriptContext = useTranscriptContext();
  const [start, setStart] = useState(clipStart * 0);
  const [end, setEnd] = useState(clipEnd * 0 + 2248); // some random end for now

  useEffect(() => {
    if (!(transcriptContext.transcript.length > 0)) return;
    // if (!isEmpty(speakerSpeakingSlots)) {
    const activeSpeakers = getActiveSpeakersWithinTimeRange(start, end, { ...transcriptContext.speakersWithDetails });
    // eslint-disable-next-line no-console
    console.log(activeSpeakers);
  }, [start, end]);

  return (
    <Fragment>
      <div className="font-bold">- Creation of active speaker information</div>
      <label>
        <div className="ml-8 inline-block w-[200px]">Start {start}</div>
        <input type="range" value={start} min="0" max="2248" onChange={e => setStart(+e.target.value)} />
      </label>
      <br />
      <label>
        <div className="ml-8 inline-block w-[200px]">End {end}</div>
        <input type="range" value={end} min="0" max="2248" onChange={e => setEnd(+e.target.value)} />
      </label>
    </Fragment>
  );
}

export default memo(ActiveSpeakerRanges);
