import { memo, useCallback, useMemo } from 'react';
import { ASPECT_RATIO_OPTIONS } from './constants';
import ClipCustomizerLayoutIconButton from './ClipCustomizerLayoutIconButton';
import { changeSize } from '@/stores/clip';
import { useClipsContext } from '@/context/ClipsContext/ClipsContext';
import { SizeType } from '@/domains/asset';

function ClipCustomizerAspectRatioOption({
  sizeOption,
  isDisabled = false,
  disabledReason = ''
}: {
  sizeOption: SizeType;
  isDisabled?: boolean;
  disabledReason?: string;
}) {
  const { clipId, clipData } = useClipsContext();

  const clipSize = useMemo(() => {
    return clipData.asset_metadata.size;
  }, [clipData.asset_metadata.size]);

  const changeClipSize = useCallback(() => {
    changeSize(clipId, sizeOption);
  }, []);

  return (
    <ClipCustomizerLayoutIconButton
      optionDetails={ASPECT_RATIO_OPTIONS[sizeOption]}
      trackingId={`customizer-aspect-ratio-${sizeOption}`}
      onClick={changeClipSize}
      isActive={clipSize === sizeOption}
      isDisabled={isDisabled}
      disabledReason={disabledReason}
    />
  );
}

export default memo(ClipCustomizerAspectRatioOption);
