const speaker_stream_mapping = {
  main: 'https://stream.goldcast.io/prod/vod/a4e61a6e-51fb-4557-80ff-cf9a6b8c8c1f/8875c437-d989-437f-97df-3b43d9164978/hls/6000.m3u8',
  spk_0:
    'https://d6d4ismr40iw.cloudfront.net/videos/individual_recording/a4e61a6e-51fb-4557-80ff-cf9a6b8c8c1f/8875c437-d989-437f-97df-3b43d9164978/1003297268_0_merge_av/stream.m3u8',
  spk_1:
    'https://d6d4ismr40iw.cloudfront.net/videos/individual_recording/a4e61a6e-51fb-4557-80ff-cf9a6b8c8c1f/8875c437-d989-437f-97df-3b43d9164978/3307513_0_merge_av/stream.m3u8',
  spk_2:
    'https://d6d4ismr40iw.cloudfront.net/videos/individual_recording/a4e61a6e-51fb-4557-80ff-cf9a6b8c8c1f/8875c437-d989-437f-97df-3b43d9164978/3307506_0_merge_av/stream.m3u8'
};

export default speaker_stream_mapping;
